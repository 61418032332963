import React from "react";

export default function CustomTick({ x, y, payload }) {
  return (
    <g transform={`translate(${x + 10},${y + 10})`}>
      <text
        height="auto"
        fontSize={12}
        transform={"rotate(0)"}
        textAnchor="end"
        x={0}
        y={0}
      >
        <title>{payload.value}</title>
        {payload.value.length > 8 ? payload.value.substr(0, 8) : payload.value}
      </text>
    </g>
  );
}
