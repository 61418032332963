import React from "react";
import Select from "react-select";
import moment from "moment";
import MuteIndex from "./MuteIndex";

const durations = {
  "1 day": [1, "d"],
  "1 week": [1, "w"],
  "1 month": [1, "m"]
};

export default class MuteAlert extends React.Component {
  state = {
    selectedVehicles: this.props.selectedVehicles,
    vehicles: this.props.vehicles,
    selectedDurations: null,
    mute_start_time: moment().format(),
    mute_stop_time: null,
    alertName: this.props.alertName,
    reason: "",
    success: true
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      alertName: nextProps.alertName,
      selectedVehicles: nextProps.selectedVehicles,
      success: true
    });
  }

  _submitForm = () => {
    let {
      selectedVehicles,
      mute_start_time,
      mute_stop_time,
      alertName,
      reason
    } = this.state;
    //console.log(selectedVehicles, mute_start_time, mute_stop_time, alertName)
    let url = `alert_subscriptions/mute_subscription_config.json?mute_start_time=${mute_start_time}&mute_stop_time=${mute_stop_time}&type=${alertName}&vehicle_nos=${selectedVehicles}&reason=${reason}`;
    this.setState(
      state => ({ success: false }),
      () => {
        fetch(url, {
          method: "POST",
          headers: ReactOnRails.authenticityHeaders({
            "Content-Type": "application/json"
          }),
          credentials: "same-origin"
        })
          .then(res => res.json())
          .then(data => {
            swal("Success", "Muted successfully", "success");
            this.setState({ success: true });
          })
          .catch(err => {
            console.log(err);
            swal("Sorry!", "Something Went Wrong, Please try again", "warning");
          });
      }
    );
  };

  _handleReasonChange = event => {
    this.setState({
      reason: event.target.value
    });
  };

  _populateVehicles = selectedOption => {
    let vehicles = selectedOption.map(obj => {
      return obj.label;
    });
    this.setState({ selectedVehicles: vehicles });
  };

  _populateDurations = selectedOption => {
    let setTime = (days, ref) => {
      ref.setState({
        mute_stop_time: moment()
          .add(days, "days")
          .format()
      });
    };

    let value = selectedOption.value;
    if (value instanceof Array) {
      let duration = value[0];
      let type = value[1];
      switch (type) {
        case "d":
          setTime(duration * 1, this);
          break;
        case "w":
          setTime(duration * 7, this);
          break;
        case "m":
          setTime(duration * 30, this);
          break;
      }
    } else {
      //set days
      setTime(value * 1, this);
    }
    console.log(selectedOption);
    this.setState({ selectedDurations: selectedOption });
  };

  render() {
    return (
      <div>
        <div className="row d-flex">
          <div className="col pr-2">
            <label htmlFor="vehicles">Vehicles</label>
            <Select
              isMulti
              options={this.state.vehicles.map(provider => ({
                label: provider,
                value: provider
              }))}
              placeholder="Vehicle Nos"
              onChange={this._populateVehicles}
              value={this.state.selectedVehicles}
            />
          </div>
          <div className="col pr-2">
            <label htmlFor="Durations">Duration</label>
            <Select
              options={Object.keys(durations).map(key => ({
                label: key,
                value: durations[key]
              }))}
              placeholder="Select Duration"
              onChange={this._populateDurations}
              value={this.state.selectedDurations}
            />
          </div>
          <div className="col pr-2">
            <label htmlFor="reason">Reason</label>
            <input
              name="reason"
              className="form-control"
              value={this.state.reason}
              onChange={this._handleReasonChange}
            />
          </div>
          <div className="col pr-2">
            <button
              type="button"
              className="btn btn-success mt-2em mr-2"
              onClick={this._submitForm}
            >
              <i className="fa fa-check" /> Submit
            </button>
          </div>
        </div>
        {this.state.success && <MuteIndex alertName={this.state.alertName} />}
      </div>
    );
  }
}
