import React from 'react'
import ReactTable from 'react-table'
import Columns from './Columns'
import {
  ranges,
  formatDate,
  filterDateColumn,
  calculateDuration
} from './../../../../utils'

const Arrow = props => (
  <div>
    {props.value}{' '}
    <i
      className={`fa fa-arrow-${
        props.sign > 0 ? 'up text-green' : 'down text-red'
      }`}
    />
  </div>
)

export default class Table extends React.Component {
  render() {
    const { userRole, ...props } = this.props
    const COLUMNS = Columns(props, userRole)
    let TABLE_COLUMNS = []
    props.columns.forEach(column => {
      TABLE_COLUMNS.push(COLUMNS[column])
    })
    return (
      <ReactTable
        defaultPageSize={15}
        data={props.data}
        columns={TABLE_COLUMNS}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id])
            .toLowerCase()
            .indexOf(filter.value.toLowerCase()) > -1
        }
        className="-striped -highlight"
      />
    )
  }
}
