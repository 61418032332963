import React from "react";
import ShipmentMap from "./ShipmentMap";
import ShipmentSidebar from "./ShipmentSidebar";
import styled from "@emotion/styled";

const ShipmentHistoryContainer = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-areas: "map" "sidebar";
  @media screen and (min-width: 769px) {
    grid-template-columns: 1fr 3fr;
    grid-template-areas: "sidebar map";
  }
`

const randomHsl = ({ h = Math.random() * 360, s = 100, l = 50, a = 1 }) =>
  `hsla(${h}, ${s}%, ${l}%, ${a})`;

function ShipmentContainer({ shipments: data, colorConfig }) {
  const [shipments, setShipments] = React.useState(data);
  const [selectedId, setSelectedId] = React.useState(null);
  const [
    selectedHistoryMarkerTimestamp,
    setSelectedHistoryMarkerTimestamp
  ] = React.useState(null);

  function onJourneySelect(sequence) {
    const seq = sequence !== selectedId ? sequence : null;
    const shipments = seq
      ? data.filter(item => item.sequence === sequence)
      : data;
    setShipments(shipments);
    setSelectedId(seq);
  }

  function onHistorySelect(e) {
    setSelectedHistoryMarkerTimestamp(
      e.target.value !== selectedHistoryMarkerTimestamp ? e.target.value : null
    );
  }

  return (
    <ShipmentHistoryContainer>
      <div style={{gridArea: "sidebar"}}>
        <ShipmentSidebar
          shipments={data}
          colorConfig={colorConfig}
          onJourneySelect={onJourneySelect}
          selectedId={selectedId}
          onHistorySelect={onHistorySelect}
          selectedHistoryMarkerTimestamp={selectedHistoryMarkerTimestamp}
        />
      </div>
      <div style={{gridArea: "map"}}>
        <ShipmentMap
          shipments={shipments}
          colorConfig={colorConfig}
          selectedHistoryMarkerTimestamp={selectedHistoryMarkerTimestamp}
        />
      </div>
    </ShipmentHistoryContainer>
  );
}

function ShipmentHistory({ shipments }) {
  const COLOR_CONFIG = [
    {
      origin: randomHsl({ h: 122, s: 39, l: 49, a: 0.5 }),
      path: randomHsl({ h: 122, s: 39, l: 49, a: 0.75 }),
      destination: randomHsl({ h: 122, s: 39, l: 49, a: 1 })
    }
  ];

  shipments.forEach((item, index) => {
    if (index > 0 && index < shipments.length - 1) {
      const color = {
        origin: randomHsl({
          s: 100,
          l: 50,
          a: 0.5
        }),
        path: randomHsl({
          s: 100,
          l: 50,
          a: 0.75
        }),
        destination: randomHsl({
          s: 100,
          l: 50,
          a: 1
        })
      };
      COLOR_CONFIG.push(color);
    }
  });

  COLOR_CONFIG.push({
    origin: randomHsl({ h: 14, s: 100, l: 63, a: 0.5 }),
    path: randomHsl({ h: 14, s: 100, l: 63, a: 0.75 }),
    destination: randomHsl({ h: 14, s: 100, l: 63, a: 1 })
  });

  return (
    <div>
      <h1 style={{ color: "#E55B25", fontSize: 18 }}>Shipment History</h1>
      <hr />
      <ShipmentContainer shipments={shipments} colorConfig={COLOR_CONFIG} />
    </div>
  );
}

export default ShipmentHistory;
