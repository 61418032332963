import React from "react";
import InputRange from "react-input-range";
import { format } from "date-fns";

export const NearbyRangeFilter = ({
  maxValue,
  filterNearby,
  minValue,
}: any) => {
  const twelveHrsAgo = new Date().getTime() - 12 * 60 * 60 * 1000;
  const sv =
    minValue < twelveHrsAgo < maxValue
      ? twelveHrsAgo
      : (minValue + maxValue) / 2;
  const [sliderValue, setSliderValue] = React.useState(sv);

  // React.useEffect(() => {
  //   setSliderValue(sv);
  // }, [sv]);

  // const [minSliderValue, setMinSliderValue] = React.useState(minValue);

  return (
    <div style={{ width: "100%", padding: "1em" }}>
      <h6>Filter Last Ping Time</h6>
      {/* <input
        type="checkbox"
        onChange={() =>
          setMinSliderValue(new Date().getTime() - 12 * 60 * 60 * 1000 - 1)
        }
        id="fix_12_hr"
      />
      <label htmlFor="fix_12_hr">Show past 24hrs only</label> */}
      <div style={{ margin: "20px 40px" }}>
        {minValue && sliderValue && maxValue ? (
          <InputRange
            formatLabel={(value: number) =>
              format(new Date(value), "DD-MM-YY HH:mm")
            }
            maxValue={Math.min(maxValue, new Date().getTime())}
            minValue={minValue}
            value={sliderValue}
            onChange={(value) => {
              if (value === maxValue) {
                value = Number(value) - 1000;
              }
              setSliderValue(Number(value));
              filterNearby(value);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

NearbyRangeFilter.displayName = "NearbyRangeFilter";
