import React from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import mentionStyle from './mentionStyle'

export default function CommentForm({ threadId, refreshComments, token }) {
  const [comment, setComment] = React.useState('')
  const [users, setUsers] = React.useState(null)

  function populateForm(event, newValue, newPlainTextValue, mentions) {
    if (newPlainTextValue.split('').pop() === '@') {
      getUsers()
    }
    setComment(newValue)
  }

  async function addComment() {
    try {
      if (threadId) {
        const getCommentsForThread = `/commontator/threads/${threadId}/comments.json`
        const response = await (await fetch(getCommentsForThread, {
          method: 'POST',
          headers: ReactOnRails.authenticityHeaders({
            'Content-Type': 'application/json'
          }),
          mode: 'cors',
          credentials: 'include',
          body: JSON.stringify({ comment: { body: comment }, token })
        })).json()
        if (response) {
          setComment('')
          refreshComments()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getUsers() {
    try {
      const response = await (await fetch('/alert_user_list.json', {
        method: 'GET',
        headers: ReactOnRails.authenticityHeaders({
          'Content-Type': 'application/json'
        })
      })).json()
      if (response) {
        setUsers(response.users)
      }
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    getUsers()
  }, [])

  const mentionUsers = users
    ? users.map(x => ({
        display: x.email,
        id: x.id
      }))
    : null

  return (
    <div>
      <label style={{ padding: '1em 0' }}>COMMENT:</label>
      <MentionsInput
        value={comment}
        onChange={populateForm}
        singleLine={false}
        style={mentionStyle}
      >
        <Mention
          markup="@[__display__](email:__id__)"
          trigger="@"
          data={mentionUsers}
          row={3}
          style={{ backgroundColor: '#d1c4e9' }}
          appendSpaceOnAdd={true}
        />
      </MentionsInput>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => addComment()}
        style={{ marginTop: '0.5em' }}
      >
        Add Comment
      </button>
    </div>
  )
}
