import { divIcon, latLng } from 'leaflet'
import sortBy from 'lodash/sortBy'
import React from 'react'
import {
  LayersControl,
  Map,
  Marker,
  Polyline,
  Popup,
  TileLayer
} from 'react-leaflet'
import FullscreenControl from 'react-leaflet-fullscreen'
import { GoogleLayer } from 'react-leaflet-google'
import HistoryMarker from './HistoryMarker'

const markerHtmlStyles = (color, isDestination = false) => `
  background-color: ${color};
  width: 2em;
  height: 2em;
  display: block;
  position: relative;
  border-radius: 1em 1em 0;
  transform: rotate(45deg);
  left: -24px;
  top: -24px;
  border: 2px solid ${isDestination ? '#333' : '#fff'};
  opacity: ${isDestination ? 0.6 : 1}
`

const { BaseLayer } = LayersControl
const key = 'AIzaSyAfHNk2CTX44LJiSKEYy1jmYgSuMk8IABA'
const isDev = true
const terrain = 'TERRAIN'
const road = 'ROADMAP'
const satellite = 'SATELLITE'
const hybrid = 'HYBRID'

export default function ShipmentMap({
  shipments,
  colorConfig,
  selectedHistoryMarkerTimestamp
}) {
  shipments = sortBy(shipments, 'sequence')
  let bounds = []

  shipments.forEach((item, index) => {
    if (item.origin) {
      bounds.push(latLng(item.origin.latitude, item.origin.longitude))
    }
    if (item.destination) {
      bounds.push(latLng(item.destination.latitude, item.destination.longitude))
    }
    if (item.history) {
      item.history.map(his => {
        bounds.push(latLng(his.latitude, his.longitude))
      })
    }
  })

  const mapBounds = bounds.length > 0 ? bounds : undefined

  return (
    <div
      className="shipmentsMap"
      style={{ display: 'flex', height: '85vh', width: '100%' }}
    >
      <Map
        center={latLng(
          shipments[0].origin.latitude,
          shipments[0].origin.longitude
        )}
        zoom={10}
        maxZoom={18}
        attributionControl={true}
        zoomControl={true}
        bounds={mapBounds}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
      >
        <FullscreenControl position="topleft" />
        <LayersControl position="topright">
          <BaseLayer checked={isDev} name="OpenStreetMap.Mapnik">
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer checked={!isDev} name="Google Maps Roads">
            <GoogleLayer googlekey={key} maptype={road} />
          </BaseLayer>
          <BaseLayer checked={!isDev} name="Google Maps Terrain">
            <GoogleLayer googlekey={key} maptype={terrain} />
          </BaseLayer>
          <BaseLayer checked={!isDev} name="Google Maps Satellite">
            <GoogleLayer googlekey={key} maptype={satellite} />
          </BaseLayer>
          <BaseLayer checked={!isDev} name="Google Maps Hybrid">
            <GoogleLayer googlekey={key} maptype={hybrid} />
          </BaseLayer>
        </LayersControl>
        {shipments &&
          shipments.map((shipment, index) => (
            <React.Fragment key={`shipment${index}`}>
              {shipment.origin && (
                <Marker
                  position={L.latLng(
                    shipment.origin.latitude,
                    shipment.origin.longitude
                  )}
                  icon={
                    new divIcon({
                      className: 'my-custom-pin',
                      iconAnchor: [0, 0],
                      labelAnchor: [-6, 0],
                      popupAnchor: [0, -36],
                      html: `<span style="${markerHtmlStyles(
                        colorConfig[index].origin
                      )}" />`
                    })
                  }
                >
                  <Popup>{shipment.origin.city}</Popup>
                </Marker>
              )}
              {shipment.destination && (
                <Marker
                  position={L.latLng(
                    shipment.destination.latitude,
                    shipment.destination.longitude
                  )}
                  icon={
                    new divIcon({
                      className: 'my-custom-pin',
                      iconAnchor: [0, 0],
                      labelAnchor: [-6, 0],
                      popupAnchor: [0, -36],
                      html: `<span style="${markerHtmlStyles(
                        colorConfig[index].destination,
                        true
                      )}" />`
                    })
                  }
                >
                  <Popup>{shipment.destination.city}</Popup>
                </Marker>
              )}
              {shipment.history && (
                <React.Fragment>
                  <Polyline
                    positions={shipment.history.map(item => ({
                      lat: Number(item.latitude),
                      lng: Number(item.longitude)
                    }))}
                    color={colorConfig[index].path}
                  />
                  {shipment.history.map((location, historyIndex) => (
                    <HistoryMarker
                      shipment={shipment}
                      location={location}
                      selected={
                        Number(selectedHistoryMarkerTimestamp) ===
                        new Date(location.ist_timestamp).getTime()
                      }
                      colorConfig={colorConfig[index]}
                      key={`shipmentHistory_${historyIndex}_${location.id}`}
                    />
                  ))}
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
      </Map>
    </div>
  )
}
