import moment from 'moment';
import React from 'react';
import {SkyLightStateless} from 'react-skylight';
import AddPoi from './AddPoi';
import PoiMap from './PoiMap';
import PoiSidebar from './PoiSidebar';
import PoiVivoChart from './PoiVivoChart';

const bigModal = {
  width: '55%',
  height: '55%',
  overflow: 'auto',
};

export default class PoiOverview extends React.Component {
  state = {
    modalVisible: false,
    pois: this.props.pois,
    history: null,
    graphData: null,
    sidebarData: null,
    mapData: null,
    apiPoiId: this.props.id,
  };

  componentDidMount() {
    this.fetchMonthly();
  }

  componentWillReceiveProps(nextProps) {
    this.fetchMonthly();
  }

  fetchMonthly = () => {
    fetch(`/pois/previous_month_history.json`, {
      method: 'GET',
      headers: ReactOnRails.authenticityHeaders({
        'Content-Type': 'application/json',
      }),
      mode: 'cors',
      credentials: 'include',
    })
      .then(res => res.json())
      .then(data => {
        if (
          data &&
          Object.values(data)[0] &&
          Object.values(data)[0].poi_info.id
        ) {
          this.fetchPOI(Object.values(data)[0].poi_info.id);
        }
        this.setState({
          sidebarData: data,
          mapData: !!data ? Object.values(data)[0] : null,
        });
      })
      .catch(err => console.log(err));
  };

  fetchPOI = id => {
    let endDate = moment().format('YYYY-MM-DD');
    let startDate = moment()
      .subtract(1, 'months')
      .format('YYYY-MM-DD');

    fetch(
      `/pois/show_poi_histories.json?start_date=${startDate}&end_date=${endDate}&poi_id=${id}`,
      {
        method: 'GET',
        headers: ReactOnRails.authenticityHeaders({
          'Content-Type': 'application/json',
        }),
        mode: 'cors',
        credentials: 'include',
      },
    )
      .then(res => res.json())
      .then(data => {
        this.setState({graphData: !!data ? Object.values(data)[0] : null});
      })
      .catch(err => console.log(err));
  };

  openModal = () => {
    this.setState({modalVisible: true});
  };

  closeModal = () => {
    this.setState({modalVisible: false});
  };

  sidebarItemClick = res => {
    this.fetchPOI(res.poi_info.id);
    this.setState({mapData: res});
  };

  render() {
    return (
      <div className="row h100">
        <div className="col-md-3 h100 cpoi">
          <button className="btn btn-warning-outline" onClick={this.openModal}>
            Create POI
          </button>
          <SkyLightStateless
            dialogStyles={{width: '60%', closeButtonStyle: {color: '#333'}}}
            isVisible={this.state.modalVisible}
            onCloseClicked={() => this.closeModal()}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 400,
              }}>
              <div style={{flex: 1}}>
                <h3>Create new Point of Interests</h3>
              </div>
              <div style={{flex: 14, position: 'relative'}}>
                <div
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                  }}>
                  <AddPoi {...this.props} />
                </div>
              </div>
            </div>
          </SkyLightStateless>
          <br />
          {this.state.sidebarData && (
            <PoiSidebar
              data={this.state.sidebarData}
              onClick={this.sidebarItemClick}
            />
          )}
        </div>
        <div className="col-md-9" style={{padding: '7.5px 2.25em'}}>
          <div className="row">
            <div className="col-md-12 ml-2" style={{paddingRight: '1.5em'}}>
              <div className="row" style={{position: 'relative'}}>
                {this.state.mapData && this.state.mapData.poi_info ? (
                  <PoiMap
                    data={this.state.mapData}
                    types={this.props.types}
                    containerElement={<div style={{height: `320px`}} />}
                    mapElement={<div style={{height: `100%`}} />}
                  />
                ) : null}
              </div>
            </div>
            {/* <div className="col-md-6" style={{paddingLeft: '1.5em'}}>
              {this.state.mapData && (
                <PoiCard
                  data={this.state.mapData}
                  info={this.state.graphData}
                />
              )}
            </div> */}
          </div>
          <div className="row pt-3">
            {this.state.graphData && (
              <PoiVivoChart data={this.state.graphData} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
