import React from 'react'
import { Marker, InfoWindow } from 'react-google-maps'
import moment from 'moment'
const momentDurationFormatSetup = require('moment-duration-format')
import InfoWindowBox from '../../components/Maps/InfoWindowBox'
import { customCircleMarker } from '../../../../utils'

export default class CircleMAMarker extends React.Component {
  state = {
    isOpen: this.props.isOpen
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: !this.state.isOpen })
    }
  }

  onToggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { isOpen } = this.state
    const { location, colorRef, value } = this.props
    return (
      <Marker
        icon={customCircleMarker({
          fillColor: '#f7f7f7'
        })}
        opacity={0.1}
        position={location}
        onClick={this.onToggleOpen}
      >
        {isOpen && (
          <InfoWindow onCloseClick={this.onToggleOpen}>
            <InfoWindowBox>
              <div>No of Vehicles : {Object.keys(value).length}</div>
              <div>
                Duration :{' '}
                {moment
                  .duration(
                    Object.values(value)
                      .map(e => e.duration)
                      .reduce((a, b) => a + b, 0),
                    'second'
                  )
                  .format('HH [hr] mm [min]')}
              </div>
              <div style={{ textWrap: 'no-wrap', wordWrap: 'break-word' }}>
                Vehicles: {String(Object.keys(value))}
              </div>
            </InfoWindowBox>
          </InfoWindow>
        )}
      </Marker>
    )
  }
}
