import React from "react";
import Table from "./Table";

export default class StateTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      filteredData: this.props.data[this.props.selectedEntry["name"]],
      statuses: null,
      columns: [
        "vehicle_no",
        "origin",
        "destination",
        "status",
        "loading_in_time",
        "loading_out_time",
        "unloading_in_time",
        "sta",
        "transporter",
        "consigner_name",
        "consignee_name"
      ]
    };
  }

  componentDidMount() {
    if (this.props.data[this.props.selectedEntry["name"]]) {
      let statuses = [
        ...new Set(
          this.props.data[this.props.selectedEntry["name"]].map(
            trip => trip.status
          )
        )
      ].filter(n => n);
      this.setState({ statuses });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      filteredData: this.props.data[nextProps.selectedEntry["name"]]
    });
    if (nextProps.selectedEntry["name"].toLowerCase() === "enroute") {
      this.setState({
        columns: [
          "vehicle_no",
          "origin",
          "destination",
          "status",
          "loading_in_time",
          "loading_out_time",
          "percentage_trip_completed",
          "transit_time",
          "sta",
          "transporter",
          "consigner_name",
          "consignee_name"
        ]
      });
    } else if (nextProps.selectedEntry["name"].toLowerCase() === "completed") {
      this.setState({
        columns: [
          "vehicle_no",
          "origin",
          "destination",
          "status",
          "loading_in_time",
          "loading_out_time",
          "unloading_in_time",
          "unloading_out_time",
          "transit_time",
          "sta",
          "transporter",
          "consigner_name",
          "consignee_name"
        ]
      });
    } else {
      this.setState({
        columns: [
          "vehicle_no",
          "origin",
          "destination",
          "status",
          "loading_in_time",
          "loading_out_time",
          "unloading_in_time",
          "sta",
          "transporter",
          "consigner_name",
          "consignee_name"
        ]
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.columns && (
          <Table
            data={this.state.filteredData}
            tripStatuses={this.state.statuses}
            columns={this.state.columns}
            tripCompleted={this.props.tripCompleted}
            userRole={this.props.userRole}
          />
        )}
      </div>
    );
  }
}
