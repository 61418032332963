import React from 'react'
import InfoWindowBox from '../../../components/Maps/InfoWindowBox.js'
import { Marker, InfoWindow } from 'react-google-maps'
import { blackDotMarker } from '../../../../../utils'

class HistoryMarker extends React.Component {
  state = {
    isOpen: this.props.isOpen
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: !this.state.isOpen })
    }
  }

  _onToggleOpen = () => {
    this.props.showHistoryInfoWindow()
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return (
      <Marker
        position={{
          lat: Number(this.props.location.latitude),
          lng: Number(this.props.location.longitude)
        }}
        icon={blackDotMarker}
        opacity={0.25}
        labelClass={this.props.labelClassName || 'default'}
        onClick={this._onToggleOpen}
        key={'history_marker_' + this.props.location.ist_timestamp}
      >
        {this.state.isOpen && (
          <InfoWindow onCloseClick={this._onToggleClose}>
            <InfoWindowBox info={this.props} />
          </InfoWindow>
        )}
      </Marker>
    )
  }
}

export default HistoryMarker
