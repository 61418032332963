import React from "react";
import { GoogleMap, withGoogleMap } from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import PoiCircle from "../../components/Maps/PoiCircle";
import VehicleMarker from "../../components/Maps/VehicleMarker";
import m1 from "images/react/markercluster/m1.png";
import PolygonMarker from "./PolygonMarker";
const mapRef = React.createRef();

class MapContainer extends React.PureComponent {
  state = {
    bounds: null,
    center: { lat: 22.0574, lng: 78.9382 },
    vehicles: this.props.vehicles
  };

  componentDidMount() {
    this.onMapMounted();
  }

  onMapMounted = () => {
    if (!mapRef && !mapRef.current) {
      return;
    }
    let bounds = new google.maps.LatLngBounds();
    this.props.vehicles.forEach(vehicle => {
      bounds.extend(
        new google.maps.LatLng(
          vehicle.location.latitude,
          vehicle.location.longitude
        )
      );
    });
    mapRef.current.fitBounds(bounds);
  };

  _clusterClicked = markerClusterer => {
    if (markerClusterer.getMap().getZoom() > 17) {
      const clusters = markerClusterer.markerClusterer_.clusters_;
      const markersArr = clusters.map(cluster => cluster.markers_);
      const vehiclesArr = markersArr
        .map(markerArr => {
          return {
            size: markerArr.length,
            vehicles: markerArr.map(marker => marker.label.text)
          };
        })
        .filter(cluster => cluster.size > 1);
      let popUpText =
        "Google Map has reached maximum zoom level. Your vehicles corresponding to cluster size are as follows: \n";
      vehiclesArr.forEach(cluster => {
        popUpText += ` Cluster Size ${cluster.size} : ${String(
          cluster.vehicles
        )} \n \n`;
      });
      swal(popUpText);
    }
  };
  
  render() {
    return (
      <GoogleMap
        defaultZoom={5}
        defaultCenter={this.state.center}
        ref={mapRef}
        options={{ mapTypeId: this.props.inplant ? "satellite" : "roadmap" }}
        onMapMounted={this.onMapMounted}
      >
        {this.props.clustering && (
          <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={60}
            onClick={this._clusterClicked}
            styles={[
              {
                url: m1,
                height: 53,
                width: 53
              }
            ]}
          >
            {this.props.vehicles &&
              this.props.vehicles.map((vehicle, i) => (
                <VehicleMarker vehicle={vehicle} key={i} />
              ))}
          </MarkerClusterer>
        )}
        {this.props.pois && <PoiCircle pois={this.props.pois} />}
        {!this.props.clustering &&
          this.props.vehicles &&
          this.props.vehicles.map((vehicle, i) => (
            <VehicleMarker vehicle={vehicle} key={i} />
          ))}
        {this.props.inplant &&
          this.props.polygonPOIs &&
          this.props.polygonPOIs.map(poi => {
            const hasGeo = JSON.parse(poi.poi_bounds)
	    if (!hasGeo) { return null }
            return (<PolygonMarker
              info={poi}
              paths={JSON.parse(poi.poi_bounds).geometry.coordinates[0].map(
                coords => ({
                  lat: Number(coords[0]),
                  lng: Number(coords[1])
                })
              )}
              options={{
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 3,
                fillColor: "#FF0000",
                fillOpacity: this.props.inplant ? 0.05 : 0.35
              }}
              visible
              key={poi.id}
            />
          )})}
      </GoogleMap>
    );
  }
}

export const MapRenderer = withGoogleMap(props => <MapContainer {...props} />);
