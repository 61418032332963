import React from 'react';
import moment from 'moment';
require('moment-duration-format');
import _ from 'lodash';

const sticky = {
  position: 'sticky',
  top: '-1px',
  zIndex: 10,
  background: 'dimgray',
  width: '100%',
  color: 'white',
};

export default class PoiSidebar extends React.Component {
  state = {
    data: Object.values(this.props.data),
    selectedId: Object.keys(this.props.data)[0],
  };

  selectItem(event, id) {
    event.preventDefault();
    let newData = _.sortBy(this.state.data, item => item.poi_info.id !== id);
    this.setState({data: newData});
    this.props.onClick(this.props.data[id]);
  }

  _searchPois = event => {
    let filteredIds = event.target.value
      ? Object.keys(this.props.data).filter(id => {
          return Object.values(this.props.data[id].poi_info).some(
            val =>
              val &&
              String(val)
                .toLowerCase()
                .includes(event.target.value.toLowerCase()),
          );
        })
      : this.props.data;
    let filteredPois = [];
    filteredIds.forEach(id => filteredPois.push(this.props.data[id]));
    this.setState({data: filteredPois});
  };

  render() {
    return (
      <div
        className="row"
        style={{
          height: '80vh',
          background: '#333',
          overflow: 'auto',
          position: 'relative',
        }}>
        <input
          type="search"
          className="form-control"
          placeholder="Search POIs"
          onChange={this._searchPois}
          style={sticky}
        />
        {this.state.data.map((poi, index) => {
          return (
            <div key={index}>
              <a
                href="/"
                onClick={e => this.selectItem(e, poi.poi_info.id)}
                className={`react-sidebar-link ${
                  poi.poi_info.id === this.state.selectedId ? 'active' : null
                }`}>
                <h5>{poi.poi_info.poi_nick_name}</h5>
                <div className="row" style={{width: '100%'}}>
                  <div className="col-md-12">
                    <strong className="text-muted">POI</strong>
                    <br />
                    {poi.poi_info.poi_type}
                  </div>
                </div>
              </a>
            </div>
          );
        })}
      </div>
    );
  }
}
