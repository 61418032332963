import React, { useEffect, useState } from "react";
import RSelect from "react-select";
import Refresh from "../Refresh";
import BarCharts from "./BarChart";
import Card from "./Card";

const style = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "5px",
    marginLeft: "-15px",
    marginRight: "-15px",
    minHeight: "100vh",
    background: "#ada996",
    background:
      "-webkit-linear-gradient(to bottom, #ada996, #f2f2f2, #dbdbdb, #eaeaea)",
    background: "linear-gradient(to bottom, #ada996, #f2f2f2, #dbdbdb, #eaeaea)"
  },
  header: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    padding: "5px",
    background: "rgba(255, 255, 255, 0.2)"
  },
  button: {
    background: "#235449",
    border: "0px solid transparent",
    outline: "none",
    borderRadius: "3px",
    color: "#FFF",
    margin: "1px 2px"
  },
  content: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    flexWrap: "wrap",
    padding: "5px",
    marginTop: "1em",
    boxShadow: "inset 0 0 10px -2px rgba(128,128,128,.7)"
  },
  graphWrapper: {
    border: "1px solid rgba(186,186,186,.3)",
    padding: "5px",
    marginBottom: "1em",
    marginRight: "1%",
    borderRadius: "8px",
    boxShadow: "0 0 5px 1px rgba(128,128,128,.2)"
  },
  graph: {
    flex: 2,
    height: "200px",
    marginTop: "10px",
    background: "white",
    borderRadius: "8px",
    padding: "10px 0"
  },
  tableStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    border: "1px solid black",
    padding: "15px 15px"
  }
};

const TripWiseStatus = ({
  graphData,
  groupsDetail,
  groupedVehicle,
  interval
}) => {
  const [groupSelection, changeGroupSelection] = useState([]);
  const [view, setView] = useState("grid");
  useEffect(() => {
    let query = location.search
      .replace(/\?|&/g, "")
      .split(/group_id=|group_id\[]=/)
      .slice(1)
      .map(key => {
        const temp = groupsDetail.find(item => {
          return item.id == key;
        });
        return { label: temp.name, value: temp.id };
      });

    changeGroupSelection(query);
  }, []);
  function _handleGroupChange(event) {
    changeGroupSelection(event);
  }
  let _groupUrlChange = () => {
    let query = "?";
    if (groupSelection.length === 1) {
      query += `group_id=${groupSelection[0].value}`;
    } else if (groupSelection.length > 1) {
      groupSelection.map((group, index) => {
        query += `group_id[]=${group.value}${
          index === groupSelection.length - 1 ? "" : "&"
        }`;
      });
    }
    window.open(`${location.origin + location.pathname + query}`, "_self");
  };
  let _groupResetUrlChange = e => {
    window.open(`${location.origin + location.pathname}`, "_self");
  };
  const groupDataList = Object.keys(graphData);
  let totalCount = 0;
  let groupDetailArray = [];
  groupsDetail.map(group =>
    groupDetailArray.push({
      label: group.name,
      value: group.id
    })
  );
  groupDataList.forEach(val => {
    Object.keys(graphData[val]).forEach(data => {
      if (data === "Total") {
        totalCount += graphData[val][data];
      }
    });
  });
  return (
    <div style={style.wrapper}>
      <div style={style.header}>
        <Refresh interval={interval} />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <RSelect
            options={groupDetailArray}
            isMulti
            clearable
            placeholder="Select Group"
            onChange={_handleGroupChange}
            value={groupSelection}
            styles={{
              container: base => ({
                ...base,
                width: "250px"
              }),
              valueContainer: (styles, state) => ({
                ...styles,
                height: "35px",
                overflow: "auto"
              })
            }}
          />
          <button style={style.button} onClick={_groupUrlChange}>
            Submit
          </button>
          <button style={style.button} onClick={_groupResetUrlChange}>
            Reset All
          </button>
          <button
            style={style.button}
            onClick={() => {
              setView(view === "grid" ? "list" : "grid");
            }}
          >
            {view === "grid" ? <span>&#8801;</span> : <span>&#9638;</span>}
          </button>
        </div>
      </div>
      {/* {!location.search && (
        <div style={style.fleetDiv}>
          Total Fleet <span> {totalCount}</span>
        </div>
      )} */}
      <div
        style={{
          ...style.content,
          flexDirection: `${view === "grid" ? "row" : "column"}`
        }}
      >
        {groupDataList.map(key => {
          return (
            <RouteWiseGraph
              key={key}
              style={{
                ...style.graphWrapper,
                width: `${view === "grid" ? "49%" : "100%"}`
              }}
              data={graphData[key]}
              text={key}
              view={view}
              graphStyle={style.graph}
              bar={{
                dataKey: key,
                data: graphData[key],
                groupedVehicle: groupedVehicle
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const RouteWiseGraph = ({ data, text, style, bar, view, graphStyle }) => {
  const [optionSelected, setOptionSelected] = useState(null);
  function onCardClick(opt, key) {
    setOptionSelected(opt === "Total" ? null : `${key}[${opt}]`);
  }
  return (
    <div style={style}>
      <Card
        data={data}
        text={text}
        view={view}
        onClick={x => onCardClick(x, text)}
      />
      <div style={graphStyle}>
        <BarCharts {...bar} optionSelected={optionSelected} />
      </div>
    </div>
  );
};

export default props => <TripWiseStatus {...props} />;
