import { distanceInWords } from "date-fns";
import React from "react";
import { FaCalendar, FaMobile, FaUserCircle } from "react-icons/fa";
import Linkify from "react-linkify";
import styled from "styled-components";

const Flex = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const Row = styled(Flex)`
  flex-direction: row;
`;

const Column = styled(Flex)`
  flex-direction: column;
`;

const FlexItem = styled("div")`
  flex: 1;
`;

function CommentBody({ comment }) {
  if (!comment) return "";
  let taggedEmails = comment.match(
    /(@)(\[)([\w-+]+(?:\.[\w-+]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7})(\])(\()((?:[a-z][a-z]+))(:)(\d+)(\))/g
  );
  let newComment = comment;
  if (taggedEmails) {
    taggedEmails.forEach((taggedEmail, index) => {
      const extractedEmail = taggedEmail.match(
        /([\w-+]+(?:\.[\w-+]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7})/g
      )[0];
      newComment = newComment.replace(taggedEmail, extractedEmail);
    });
  }
  return (
    <FlexItem>
      <Linkify>{newComment}</Linkify>
    </FlexItem>
  );
}

export default function Comment({ data }) {
  return (
    <Column
      style={{
        padding: "0.5em",
        border: "1px dashed bisque",
        marginTop: "0.25em"
      }}
    >
      <Row>
        <CommentBody comment={data.body} />
      </Row>
      <Row style={{ fontSize: 14, color: "#b3b3b3" }}>
        <FlexItem>
          <FaUserCircle size={12} color="#d2d2d2" /> {data.creator.full_name}
        </FlexItem>
        <FlexItem>
          <FaCalendar size={12} color="#d2d2d2" />{" "}
          {distanceInWords(new Date(data.created_at), new Date())}
        </FlexItem>
      </Row>
    </Column>
  );
}
