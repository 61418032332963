import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import _ from 'lodash'
import moment from 'moment'
import queryString from 'query-string'
import Pagination from 'rc-pagination/lib'
import React from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'react-tagsinput/react-tagsinput.css'
import RowTripInfo from '../../components/Eta/RowTripInfo'
import Filter from './Filter'
import Timeline from './Timeline'

const theme = createMuiTheme()

const timelineContainer = React.createRef()

const getStatusColor = (status, eta) => {
  const _status = new Date() - eta && status === 'Enroute' ? 'Delayed' : status
  if (_status === 'Ontime') {
    return '#5cb85c'
  } else if (
    _status === 'Delayed' ||
    (_status === 'At Unloading City' && new Date() - eta)
  ) {
    return '#d9534f'
  } else if (_status === 'Early') {
    return '#5bc0de'
  }
  return ''
}

const getSignalStatus = signal => {
  if (signal < 11) {
    return { color: '#f3f', text: `Bad signal strength : ${signal}` }
  } else if (signal > 19) {
    return { color: 'cyan', text: 'Good signal strength' }
  }
  return { color: 'yellow', text: 'Average signal strength' }
}

const getDisconnectionStatus = (ist, isDisconnected) => {
  const logic = moment().diff(ist, 'days') > 1
  const disconnectedColor = logic ? 'yellow' : 'aqua'
  const preTitle = logic ? 'Inactive since' : 'Last ping at'
  if (isDisconnected) {
    return {
      color: disconnectedColor,
      text: `${preTitle} ${moment(ist).format('DD-MM-YYYY HH:mm:ss')}`
    }
  }
  return {
    color: disconnectedColor,
    text: `Disconnected since ${moment(ist).format('DD-MM-YYYY HH:mm:ss')}`
  }
}

const getBatteryStatus = voltage => {
  if (voltage < 3.2) {
    return {
      icon: 'fa fa-battery-empty',
      color: 'red',
      text: `Bad battery strength : ${voltage} V`
    }
  } else if (voltage > 3.2 && voltage < 3.4) {
    return {
      icon: 'fa fa-battery-quarter',
      color: 'gray',
      text: `Poor battery strength`
    }
  } else if (voltage > 3.4 && voltage < 4) {
    return {
      icon: 'fa fa-battery-three-quarter',
      color: 'aqua',
      text: `Average battery strength`
    }
  }
  return {
    icon: 'fa fa-battery-full',
    color: 'green',
    text: `Good battery strength`
  }
}

export default class Eta extends React.Component {
  state = {
    trips: this.props.trips,
    location: this.props.location,
    tags: [],
    alertTypes: null,
    users: null,
    // see here for all these details
    currentPage: 1,
    perPage: 3,
    start_date: null,
    end_date: null,
    startDate: moment(),
    endDate: moment(),
    today: moment().format('YYYY-MM-DD'),
    displayWidth: 1342,
    filterParams: null
  }

  componentDidMount() {
    this.setState({
      displayWidth: timelineContainer.current
        ? timelineContainer.current.offsetWidth
        : window.innerWidth
    })
    this.populate(this.props)
  }

  dateRangeHandler = (event, picker) => {
    this.setState({
      start_date: picker.startDate.isValid()
        ? picker.startDate.format('YYYY-MM-DD')
        : null,
      end_date: picker.endDate.isValid()
        ? picker.endDate.format('YYYY-MM-DD')
        : null,
      startDate: picker.startDate,
      endDate: picker.endDate
    })
  }

  handleChange = tags => {
    this.setState({ tags: tags })
    const q = queryString.stringify(
      _.omitBy(
        {
          search: 'Hisar'
        },
        _.isNil
      )
    )
    this.fetchData(q)
  }

  fetchData = (q = null) => {
    fetch(
      q
        ? `${window.location.pathname}.json?${q}`
        : `${window.location.pathname}.json`,
      {
        method: 'GET',
        headers: ReactOnRails.authenticityHeaders({
          'Content-Type': 'application/json'
        }),
        mode: 'cors',
        credentials: 'include'
      }
    )
      .then(res => res.json())
      .then(response => {
        this.populate(response.data)
      })
      .catch(err => console.log(err))
  }

  populate = ({ trips }) => {
    this.setState({ trips })
  }

  //handleChange = (type, selectedOption) => {
  //  selectedOption = {...this.state.selectedOption, [type]: selectedOption};
  //  this.setState({selectedOption});
  // };

  paginationChange = (currentPage, pageSize) => {
    let { start_date, end_date } = this.state
    const q = queryString.stringify(
      _.omitBy(
        {
          alert_user_id: this.state.selectedOption.user.value,
          type: this.state.selectedOption.alertType.value,
          start_date,
          end_date,
          page: currentPage
        },
        _.isNil
      )
    )
    this.fetchData(q)
  }

  filterTrips = async () => {
    try {
      const { start_date, end_date, filterParams } = this.state
      const q = queryString.stringify(
        _.omitBy(
          {
            ...filterParams,
            start_date,
            end_date
          },
          _.isNil
        ),
        { arrayFormat: 'bracket' }
      )
      const response = await (await fetch(
        `${window.location.pathname}.json?${q}`,
        {
          method: 'GET',
          headers: ReactOnRails.authenticityHeaders({
            'Content-Type': 'application/json'
          }),
          mode: 'cors',
          credentials: 'include'
        }
      )).json()
      this.setState({ trips: response.trips })
      this.populate(response)
    } catch (error) {
      console.log(error)
    }
  }

  onFilter = filterParams => {
    this.setState(
      state => ({ ...state, filterParams }),
      () => {
        if (!this.state.filterParams) {
          this.fetchData()
        }
      }
    )
  }

  render() {
    const { trips, location, startDate, endDate } = this.state
    const start = startDate.format('YYYY-MM-DD')
    const end = endDate.format('YYYY-MM-DD')
    const label = start + ' - ' + end
    return (
      <div className="col-md-12">
        <h3>E.T.A</h3>
        <hr />
        <div
          className="row d-flex"
          style={{ marginBottom: '0.5em', alignItems: 'flex-end' }}
        >
          <div style={{ width: '60%' }}>
            <Filter onFilter={this.onFilter} />
          </div>
          <div style={{ width: '30%' }}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              ranges={this.state.ranges}
              maxDate={moment()}
              onEvent={this.dateRangeHandler}
            >
              <button
                className="selected-date-range-btn"
                style={{ width: '80%', height: '36px', marginTop: '1px' }}
              >
                <div className="pull-left">
                  <i className="fa fa-calendar" />
                </div>
                <div className="pull-right">
                  <span>{label}</span>
                  <span className="caret" />
                </div>
              </button>
            </DateRangePicker>
          </div>
          <div style={{ width: '10%', textAlign: 'center' }}>
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => this.filterTrips()}
            >
              Search
            </button>
          </div>
        </div>
        <div className="row mt-2" style={{ marginTop: 12 }} />
        <div
          className="d-flex"
          style={{ justifyContent: 'center', alignItems: 'center' }}
        >
          <Pagination
            className="ant-pagination"
            defaultCurrent={this.state.currentPage}
            total={this.state.totalPages}
            onChange={this.paginationChange}
          />
        </div>
        <div>
          <MuiThemeProvider theme={theme}>
            {trips &&
              trips.map(trip => {
                const gps = location[trip.id]
                return (
                  <Card
                    style={{
                      marginTop: '1em',
                      marginBottom: '1em',
                      borderLeft: `20px solid ${getStatusColor(
                        trip.status,
                        trip.eta
                      )}`
                    }}
                    key={trip.slug}
                  >
                    <CardContent>
                      <div className="row" style={{ padding: '2.5px 15px' }}>
                        {gps && gps.alert_data && (
                          <React.Fragment>
                            <i
                              className={`fa fa-plug`}
                              style={{
                                color: `${
                                  getDisconnectionStatus(
                                    gps.ist_timestamp,
                                    gps.alert_data[8]
                                  ).color
                                }`,
                                margin: '0 10px 0 0'
                              }}
                              title={
                                getDisconnectionStatus(
                                  gps.ist_timestamp,
                                  gps.alert_data[8]
                                ).text
                              }
                            />
                          </React.Fragment>
                        )}
                        {gps && gps.signal_strength && (
                          <React.Fragment>
                            <i
                              className={`fa fa-signal`}
                              style={{
                                color: `${
                                  getSignalStatus(gps.signal_strength).color
                                }`,
                                margin: '0 10px'
                              }}
                              title={getSignalStatus(gps.signal_strength).text}
                            />
                            <i
                              className={
                                getBatteryStatus(gps.signal_strength).icon
                              }
                              style={{
                                color: `${
                                  getBatteryStatus(gps.signal_strength).color
                                }`,
                                margin: '0 10px'
                              }}
                              title={getBatteryStatus(gps.signal_strength).text}
                            />
                          </React.Fragment>
                        )}
                      </div>
                      <div
                        className="row"
                        style={{ padding: '2.5px 15px 0px' }}
                      >
                        <RowTripInfo trip={trip} />
                      </div>
                      <div
                        className="row"
                        style={{ padding: '0px 15px' }}
                        ref={timelineContainer}
                      >
                        <Timeline
                          containerWidth={this.state.displayWidth}
                          trip={{
                            ...trip,
                            percentage_distance_covered: this.props
                              .percentage_distance_covered[trip.id]
                          }}
                          location={this.props.location[trip.id]}
                          stoppages={this.props.stoppages[trip.id]}
                          tolls_h={this.props.tolls_h[trip.id]}
                          tolls_a={this.props.tolls_a[trip.id]}
                          distances={this.props.distances[trip.id]}
                          eta={this.props.eta[trip.id]}
                          sta={trip.eta}
                          key={trip.id}
                        />
                      </div>
                    </CardContent>
                  </Card>
                )
              })}
          </MuiThemeProvider>
        </div>
      </div>
    )
  }
}
