import moment from "moment";
import React from "react";

function ShowChildNodes({ childNodes }) {
  return (
    <div>
      <h6>Child Nodes</h6>
      <table className="table">
        <thead className="thead-inverse">
          <tr style={{ fontSize: "12px", fontWeight: "bold" }}>
            <td>Name</td>
            <td>S.T.A</td>
            <td>State</td>
            <td>Enter</td>
            <td>Exit</td>
          </tr>
        </thead>
        <tbody>
          {childNodes &&
            childNodes.map((node, index) => (
              <tr key={`childNodes_${index}`} style={{ fontSize: "12px" }}>
                <td>{node.name}</td>
                <td>{moment.utc(node.sta).format("YY-MM-DD HH:mm A")}</td>
                <td>{node.state}</td>
                <td>
                  {node.enter_time
                    ? moment.utc(node.enter_time).format("YY-MM-DD HH:mm A")
                    : ""}
                </td>
                <td>
                  {node.exit_time
                    ? moment.utc(node.exit_time).format("YY-MM-DD HH:mm A")
                    : ""}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default ShowChildNodes;
