import moment from "moment";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

const CustomTooltip = props => {
  const { active } = props;
  if (active) {
    const { payload, label } = props;
    return (
      <div style={{ background: "#fff", padding: "1em" }}>
        <h6>Date : {moment(label).format("YYYY-MM-DD")}</h6>
        {payload[0].payload && payload[0].payload.info && (
          <div>
            <strong>Distance Travelled</strong> :{" "}
            {parseFloat(payload[0].payload.distance).toFixed(2)} Km <br />
            <strong>Max Speed</strong> :{" "}
            {parseFloat(payload[0].payload.info.max_speed).toFixed(2)} Km/hr{" "}
            <br />
            <strong>Overspeed Instance</strong> :{" "}
            {payload[0].payload.info.overspeed_instances} ( 50 Km/hr ) <br />
            {payload[0].payload.info.n_distance_travelled ? (
              <div>
                <strong>Night Distance</strong> :{" "}
                {parseFloat(
                  payload[0].payload.info.n_distance_travelled
                ).toFixed(2)}{" "}
                Km
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
  return null;
};

const DistancePerDayChart = props => {
  const data = props.data
    .map((e, i) => {
      return {
        name: moment(new Date(e.date)).format("YYYY-MM-DD"),
        distance: parseFloat(e.distance_travelled.toFixed(2)),
        info: e
      };
    })
    .sort((a, b) => new Date(a.name).getTime() - new Date(b.name).getTime());

  return (
    <div>
      <h6 style={{ padding: "20px 0px" }}>
        <b>Total distance travelled : </b>
        {props.data
          .reduce((acc, item) => acc + item.distance_travelled, 0)
          .toFixed(2)}{" "}
        Km
      </h6>
      <h6 style={{ padding: "20px 0px" }}>Distance travelled each day</h6>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis
          dataKey="name"
          tickFormatter={date => moment(date).format("YYYY-MM-DD")}
        />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar dataKey="distance" fill="#8884d8" />
      </BarChart>
    </div>
  );
};

export default DistancePerDayChart;
