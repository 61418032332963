import { startCase } from "lodash";
import qs from "qs";
import React from "react";
import Select from "react-select";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LabelList
} from "recharts";
import { IFilter } from ".";
import useDeepCompareEffect from "../../../../customHooks/useDeepCompareEffect";
import { IJourney } from "../../../../types";
import GraphContainer from "./GraphContainer";
import CustomTick from "./CustomTick";

interface RoutePerformanceByScoreProps {
  filter: IFilter;
  showJourney: (journeys: IJourney[]) => void;
}

interface IStatus {
  ontime: IJourney[];
  early: IJourney[];
  delayed: IJourney[];
  unknownStatus: IJourney[];
}

interface IRoutePerformanceResponse {
  name: string;
  score: number;
  unknownStatus: number;
}

export default function RoutePerformanceByScore({
  filter,
  showJourney
}: RoutePerformanceByScoreProps) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState<null | IRoutePerformanceResponse[]>(
    null
  );

  const [visibleCount, setVisibleCount] = React.useState(5);
  const [maxCount, setMaxCount] = React.useState(5);
  const [orderAsc, setOrderAsc] = React.useState({
    label: "DESC",
    value: false
  });
  const [selectedXAxisLabels, setSelectedXAxisLabels] = React.useState(null);

  useDeepCompareEffect(() => {
    fetchData();
  }, [filter]);

  async function fetchData() {
    try {
      setLoading(true);
      const q = qs.stringify(filter, {
        encode: false,
        skipNulls: true,
        indices: false
      });
      const url = `${location.href}/route_score.json?${q}`;
      const response = await (await fetch(url, {
        headers: {
          "Key-Inflection": "camel"
        }
      })).json();
      if (response && response.data) {
        setData(response.data);
        setMaxCount(response.data.length);
        setVisibleCount(Math.min(response.data.length, 5));
      }
    } catch (error) {
      console.log(error);
      setError(false);
    } finally {
      setLoading(false);
    }
  }

  let graphData =
    data &&
    data.sort((a, b) =>
      orderAsc.value ? a.score - b.score : b.score - a.score
    );
  if (selectedXAxisLabels && graphData) {
    graphData = graphData.filter(item =>
      selectedXAxisLabels.map(label => label.value).includes(item.name)
    );
  }
  if (graphData && visibleCount) {
    graphData = graphData.filter((_, index) => index < visibleCount);
  }

  return (
    <GraphContainer title="Route Score" loading={loading} error={error}>
      <ResponsiveContainer height={400} width="100%">
        <BarChart
          width={350}
          height={400}
          data={graphData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            interval={0}
            tick={({ x, y, payload }) => (
              <CustomTick x={x} y={y} payload={payload} />
            )}
          />
          <YAxis />

          <Tooltip />
          <Legend formatter={(value, entry, index) => startCase(value)} />
          <Bar
            dataKey="onTime"
            stackId="a"
            fill={"green"}
            onClick={({ journeys }) => showJourney(journeys)}
          />
          <Bar
            dataKey="delayed"
            stackId="a"
            fill={"yellow"}
            onClick={({ journeys }) => showJourney(journeys)}
          />
          <Bar
            dataKey="nonDelivered"
            stackId="a"
            fill={"red"}
            onClick={({ journeys }) => showJourney(journeys)}
          >
            <LabelList dataKey="score" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      {data && (
        <React.Fragment>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1, marginLeft: "0.125em" }}>
              Showing
              <input
                type="number"
                min="1"
                step="1"
                max={maxCount}
                value={visibleCount}
                onChange={e => setVisibleCount(Number(e.target.value))}
              />{" "}
              of {data.length}
            </div>
            <div style={{ flex: 1, marginRight: "0.125em" }}>
              <Select
                placeholder="Sort Score"
                options={[
                  { label: "ASC", value: true },
                  { label: "DESC", value: false }
                ]}
                onChange={selected => setOrderAsc(selected)}
                value={orderAsc}
              />
            </div>
          </div>
          <div style={{ marginTop: "0.25em" }}>
            <Select
              placeholder="Select Routes"
              options={data.map(item => ({
                label: item.name,
                value: item.name
              }))}
              onChange={selected => setSelectedXAxisLabels(selected)}
              value={selectedXAxisLabels}
              isMulti
            />
          </div>
        </React.Fragment>
      )}
    </GraphContainer>
  );
}
