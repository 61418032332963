// Compare it with indent_placement branch
import _ from "lodash";
import React from "react";
import { INITIAL_VALUE, TOOL_NONE } from "react-svg-pan-zoom";
import {
  addDays,
  closest,
  compareEtaSta,
  formatTime,
  getMaxTime,
  getNumberOfDays,
  getStatus,
  getTimeDiff,
  handleNullTime,
  statusColor,
  utcDateTime
} from "../../../../utils";
import DayInterval from "./DayInterval";
import DistanceIndicators from "./DistanceIndicators";
import Stoppage from "./Stoppage";
import Timelineframe from "./Timelineframe";
import Tolls from "./Tolls";
import TravelledPath from "./TravelledPath";

const containerStyle = {
  display: "flex",
  height: "1em",
  alignItems: "center",
  justifyContent: "center"
};

export default class Timeline extends React.Component {
  state = {
    containerWidth: this.props.containerWidth || 1342,
    svgWidth: this.props.containerWidth - 40 || 1302,
    // Trip times
    tripStartTime: utcDateTime(
      getMaxTime([this.props.trip.loading_in_time, this.props.trip.start_date])
    ),
    tripEndTime: utcDateTime(
      getMaxTime([
        this.props.sta,
        this.props.eta,
        this.props.trip.unloading_in_time
      ])
    ),
    lastPingTime: this.props.location
      ? utcDateTime(this.props.location.ist_timestamp)
      : new Date(),
    loadingOutTime: handleNullTime(this.props.trip.loading_out_time),
    unloadingInTime: handleNullTime(this.props.trip.unloading_in_time),
    loadingOutWidth: 0,
    unloadingInWidth: 0,
    // Eta related stuff
    isEtaAfterSta: compareEtaSta(this.props.eta, this.props.sta),
    etaWidth: 0,
    staWidth: 0,
    noOfDays: getNumberOfDays(
      this.props.trip.start_date,
      getMaxTime([this.props.sta, this.props.eta])
    ),
    dayIntervalWidthArr: [],
    totalDuration: getTimeDiff(
      getMaxTime([this.props.sta, this.props.eta]),
      this.props.trip.start_date
    ),
    halfJourneyCompleted: false,
    // Trip data
    stoppageInfo: null,
    trip: this.props.trip,
    eta: this.props.eta,
    sta: this.props.sta,
    // Toll booth
    tolls: this.props.tolls_a
      ? this.props.tolls_a.map((item, index) => ({
          ...item,
          status: getStatus(item.loading_out, item.exit_time, item.ideal_time_t)
        }))
      : null,
    tollsInfo: null,
    distanceArr: null,
    tool: TOOL_NONE,
    value: INITIAL_VALUE
  };

  static getDerivedStateFromProps(props, state) {
    if (props.containerWidth !== state.containerWidth) {
      return {
        containerWidth: props.containerWidth,
        svgWidth: props.containerWidth - 40
      };
    }
    return null;
  }

  componentDidMount() {
    //this.Viewer.fitToViewer();
    this.initTimelineWireFrame();
  }

  /**
   * Initialize Timeline Wireframe
   * 1. Check if half of the trip has been completed
   * 2. Generate data for rendering timeline
   */
  initTimelineWireFrame = () => {
    const { lastPingTime, noOfDays, tripStartTime } = this.state;
    const halfJourneyCompleted =
      getTimeDiff(
        lastPingTime,
        addDays(tripStartTime, Math.floor(noOfDays / 2), true)
      ) > 0;

    this.setState({ halfJourneyCompleted }, () => this.generateTimelineDate());
  };

  /**
   * Base parameters are width of timeline and total duration of trip
   * @return {svgWidth, totalDuration}
   */
  calculateBaseParameters = () => {
    const {
      halfJourneyCompleted,
      lastPingTime,
      tripStartTime,
      svgWidth,
      totalDuration
    } = this.state;

    const pathWidth = !halfJourneyCompleted
      ? Number((svgWidth / 2).toFixed(2))
      : this.calculateWidth({
          startTime: tripStartTime,
          endTime: lastPingTime,
          duration: totalDuration,
          width: svgWidth
        });
    const pathDuration = getTimeDiff(lastPingTime, tripStartTime);
    return { pathWidth, pathDuration };
  };

  generateTimelineDate = () => {
    const { pathWidth, pathDuration } = this.calculateBaseParameters();
    this.setState(
      {
        path: {
          pathWidth,
          pathDuration
        }
      },
      () => {
        this.populateDayIntervals();
        this.populateStoppages();
        this.populateTolls();
        this.populateDistanceArr();
      }
    );
  };

  populateDayIntervals = () => {
    const {
      tripStartTime,
      lastPingTime,
      tripEndTime,
      halfJourneyCompleted,
      path,
      totalDuration,
      svgWidth,
      loadingOutTime,
      unloadingInTime
    } = this.state;
    const { pathWidth, pathDuration } = path;

    const noOfDaysTillLastPing = getNumberOfDays(tripStartTime, lastPingTime);
    const arrDayIntervalWidthBeforeLastPing = this.getDateArr({
      noOfDays: noOfDaysTillLastPing,
      startTime: tripStartTime,
      width: pathWidth,
      duration: pathDuration
    });
    const loadingOutWidth = Boolean(loadingOutTime)
      ? this.calculateWidth({
          startTime: tripStartTime,
          endTime: loadingOutTime,
          width: pathWidth,
          duration: pathDuration
        })
      : 0;

    const noOfDaysFromLastPing = getNumberOfDays(lastPingTime, tripEndTime);
    const remainingWidth = svgWidth - pathWidth;
    const remainingDuration = getTimeDiff(tripEndTime, lastPingTime);
    const arrDayIntervalWidthAfterLastPing = this.getDateArr(
      {
        noOfDays: noOfDaysFromLastPing,
        startTime: lastPingTime,
        width: remainingWidth,
        duration: remainingDuration
      },
      pathWidth
    );

    const unloadingInWidth = Boolean(unloadingInTime)
      ? this.calculateWidth({
          startTime: lastPingTime,
          endTime: unloadingInTime,
          width: remainingWidth,
          duration: remainingDuration
        }) + pathWidth
      : 0;
    const dayIntervalWidthArr = [
      ...arrDayIntervalWidthBeforeLastPing,
      ...arrDayIntervalWidthAfterLastPing
    ];

    const { etaWidth, staWidth } = this.calculateEtaStaWidth(
      dayIntervalWidthArr
    );

    this.setState({
      dayIntervalWidthArr,
      etaWidth,
      staWidth,
      loadingOutWidth,
      unloadingInWidth
    });
  };

  populateStoppages = () => {
    const { path, tripStartTime } = this.state;
    const { pathWidth, pathDuration } = path;

    const filteredStoppages = this.getFilteredStoppages(this.props.stoppages);
    if (filteredStoppages) {
      const stoppageInfo = filteredStoppages
        .map(stoppage => ({
          x: this.calculateWidth({
            startTime: tripStartTime,
            endTime: stoppage.start_timestamp,
            duration: pathDuration,
            width: pathWidth
          }),
          width: this.calculateWidth({
            startTime: stoppage.start_timestamp,
            endTime: stoppage.end_timestamp,
            duration: pathDuration,
            width: pathWidth
          }),
          info: stoppage
        }))
        .filter(n => n);

      this.setState({
        stoppageInfo
      });
    }
  };

  populateTolls = () => {
    const { tolls, lastPingTime, path } = this.state;
    const { pathWidth, pathDuration } = path;

    if (tolls && tolls.length) {
      const filteredTolls = tolls.filter(
        toll =>
          getTimeDiff(lastPingTime, utcDateTime(new Date(toll.enter_time))) > 0
      );

      const tollsX = filteredTolls
        .map(toll => ({
          x: this.calculateWidth({
            endTime: toll.enter_time,
            duration: pathDuration,
            width: pathWidth
          }),
          color: toll.color,
          info: toll
        }))
        .filter((currentItem, index, arr) => currentItem && currentItem.x > 0);

      const tollsInfo = [];

      tollsX.forEach((toll, index) => {
        if (index === 0 || index === tollsX.length - 1) {
          tollsInfo.push(toll);
        } else {
          const lastItemOfArr = [...tollsInfo].pop();
          //if (lastItemOfArr && toll.x - lastItemOfArr.x > 25) {
          toll["info"]["ideal_time_taken"] =
            toll.info.exit_time - lastItemOfArr.info.exit_time;
          tollsInfo.push(toll);
          //}
        }
      });

      const pathInfo = tollsInfo
        .map((toll, index, tollArr) => {
          if (index === tollArr.length - 1) return;
          return {
            x: toll.x,
            width: Number((tollArr[index + 1].x - toll.x).toFixed(2)),
            startTime: toll.info.exit_time,
            color: !!toll.info.status
              ? statusColor[toll.info.status.label]
              : "#f3f3f3",
            ...this.populateAdditionalPathData(toll, tollArr[index + 1])
          };
        })
        .filter(x => x);

      this.setState({ tollsInfo, pathInfo });
    }
  };

  populateAdditionalPathData = (toll, nextToll) => {
    if (!nextToll || !this.props.stoppages) return {};
    const stops = this.getFilteredStoppages(this.props.stoppages);
    let stoppages;
    let stoppageDuration = 0;
    let runningDuration = 0;
    let idealTimeTaken;
    if (stops) {
      stoppages = this.filterStoppageByToll(stops, toll, nextToll);
    }

    const totalDuration = getTimeDiff(
      nextToll.info.enter_time,
      toll.info.exit_time
    );

    const allStoppages = this.filterStoppageByToll(
      this.getFilteredStoppages(
        this.props.stoppages.filter(item => item.old_state === "stopped")
      ),
      toll,
      nextToll
    );
    const runningInstances = this.filterStoppageByToll(
      this.getFilteredStoppages(
        this.props.stoppages.filter(item => item.old_state !== "stopped")
      ),
      toll,
      nextToll
    );

    if (allStoppages && allStoppages.length) {
      allStoppages.forEach(stoppage => {
        stoppageDuration += stoppage.duration * 1000;
      });
    }

    if (runningInstances && runningInstances.length) {
      runningInstances.forEach(instance => {
        runningDuration += instance.duration * 1000;
      });
    }
    if (nextToll.info.ideal_time_t && toll.info.ideal_time_t) {
      idealTimeTaken = getTimeDiff(
        nextToll.info.ideal_time_t,
        toll.info.ideal_time_t
      );
    }

    return {
      stoppages,
      stoppageDuration: stoppageDuration,
      runningDuration: runningDuration,
      totalDuration,
      idealTimeTaken
    };
  };

  populateDistanceArr = () => {
    const { distances: distanceKm } = this.props;
    const { lastPingTime, path, tripStartTime } = this.state;
    const { pathWidth, pathDuration } = path;
    if (distanceKm && distanceKm.length) {
      const refStopKm = distanceKm[0].distance_km;

      const distances = distanceKm
        .map((d, index) => ({
          x: this.calculateWidth({
            startTime: tripStartTime,
            endTime: d.start_timestamp,
            width: pathWidth,
            duration: pathDuration
          }),
          km: Number((d.distance_km - refStopKm).toFixed(2)),
          info: d
        }))
        .filter(n => n.km > 0);
      let kmArr = distances.map(d => d.km);
      const maxDistance = Math.max(...kmArr);

      const noOfIterations = Math.floor(maxDistance / 50);

      const result = Array(noOfIterations)
        .fill()
        .map((_, i) => i)
        .map((n, i) => {
          const goal = 50 * (n + 1);
          return closest(kmArr, goal);
        });

      const distanceArr = [];
      const filteredDist = distances.filter(d => result.includes(d.km));
      filteredDist.forEach((el, index) => {
        if (index === 0 || index === filteredDist.length) distanceArr.push(el);
        const b = distanceArr[distanceArr.length - 1];
        if (el.x - b.x >= 34) {
          distanceArr.push(el);
        }
      });
      this.setState({ distanceArr });
    }
  };

  calculateEtaStaWidth = dayIntervalWidthArr => {
    if (dayIntervalWidthArr) {
      const { eta, sta, trip, svgWidth } = this.state;
      const etaDistance = this.distanceWrtDate(dayIntervalWidthArr, eta);
      const staDistance = this.distanceWrtDate(dayIntervalWidthArr, sta);
      let etaWidth = 0;
      let staWidth = 0;
      if (Object.keys(etaDistance).includes("width")) {
        etaWidth = this.calculateWidth(
          {
            startTime: utcDateTime(eta).startOf("day"),
            endTime: eta,
            width: etaDistance.width,
            duration: 24 * 60 * 60 * 1000
          },
          etaDistance.start
        );
      }
      if (Object.keys(staDistance).includes("width")) {
        staWidth = this.calculateWidth(
          {
            startTime: utcDateTime(sta).startOf("day"),
            endTime: sta,
            width: staDistance.width,
            duration: 24 * 60 * 60 * 1000
          },
          staDistance.start
        );
      }
      return {
        staWidth: svgWidth * (staWidth / (staWidth + etaWidth)),
        etaWidth: svgWidth * (etaWidth / (staWidth + etaWidth))
      };
    }
    return { staWidth: 0, etaWidth: 0 };
  };

  getFilteredStoppages = stoppages => {
    if (!stoppages) return null;
    const { lastPingTime, tripStartTime, loadingOutTime } = this.state;

    const baseTimeRef =
      getTimeDiff(tripStartTime, loadingOutTime) > 0
        ? tripStartTime
        : loadingOutTime;

    const filteredStoppages = stoppages.filter(
      stoppage =>
        getTimeDiff(lastPingTime, utcDateTime(stoppage.start_timestamp)) > 0 &&
        getTimeDiff(utcDateTime(stoppage.start_timestamp), baseTimeRef) > 0
    );

    return filteredStoppages;
  };

  filterStoppageByToll = (stops, toll, nextToll) =>
    _.uniqWith(
      stops.filter(
        stop =>
          new Date(stop.start_timestamp) - new Date(toll.info.enter_time) >=
            0 &&
          new Date(stop.start_timestamp) - new Date(nextToll.info.enter_time) <
            0
      ),
      _.isEqual
    );

  // Helper function
  getDateArr = ({ noOfDays, startTime, width, duration }, offset = 0) => {
    if (!noOfDays || noOfDays < 0 || width < 0) return [];
    return [...Array(noOfDays)]
      .map((_, index) => {
        const nextDay = addDays(startTime, 1 + index, true);
        return {
          x: this.calculateWidth(
            {
              startTime,
              endTime: nextDay,
              width,
              duration
            },
            offset
          ),
          label: nextDay,
          identifier: Number(utcDateTime(nextDay).format("DD"))
        };
      })
      .filter(result => result.x > 0);
  };

  calculateWidth = ({ startTime, endTime, duration, width }, offset = 0) => {
    if (!startTime) startTime = this.state.tripStartTime;
    return Number(
      (
        (getTimeDiff(utcDateTime(endTime), utcDateTime(startTime)) /
          Number(duration)) *
          width +
        offset
      ).toFixed(2)
    );
  };

  distanceWrtDate = (dayIntervalWidthArr, refDate) => {
    const arr = dayIntervalWidthArr.filter(item => {
      const arr = [
        Number(formatTime(refDate, "DD")),
        Number(formatTime(refDate, "DD")) + 1
      ];
      return arr.includes(item.identifier);
    });

    let result;
    if (arr.length >= 2) {
      result = arr.reduce((a, b) => {
        return {
          start: a.x,
          width: Number((b.x - a.x).toFixed(2))
        };
      });
    } else if (arr.length === 1) {
      const only = arr[0];
      result = {
        start: only.x,
        width: Number((this.state.svgWidth - only.x).toFixed(2))
      };
    } else {
      result = { start: 0, width: 0 };
    }
    return result;
  };

  changeTool = nextTool => {
    this.setState({ tool: nextTool });
  };

  changeValue = nextValue => {
    this.setState({ value: nextValue });
  };

  fitToViewer = () => {
    this.Viewer.fitToViewer();
  };

  fitSelection = () => {
    this.Viewer.fitSelection(40, 40, 200, 200);
  };

  zoomOnViewerCenter = () => {
    this.Viewer.zoomOnViewerCenter(1.1);
  };

  render() {
    const {
      containerWidth,
      svgWidth,
      lastPingTime,
      tripStartTime,
      tripEndTime,
      dayIntervalWidthArr,
      stoppageInfo,
      trip,
      isEtaAfterSta,
      etaWidth,
      staWidth,
      eta,
      sta,
      tollsInfo,
      pathInfo,
      path,
      distanceArr,
      loadingOutWidth,
      unloadingInWidth,
      loadingOutTime,
      unloadingInTime
    } = this.state;
    const lastPingTimeFormatted = formatTime(lastPingTime);
    const tripStartTimeFormatted = formatTime(tripStartTime);
    const tripEndTimeFormatted = formatTime(tripEndTime);
    const { percentage_distance_covered: percentageCompleted } = trip;
    const svgHeight = 150;
    const endLabel = isEtaAfterSta
      ? { name: "E.T.A", width: etaWidth }
      : { name: "S.T.A", width: staWidth };

    const etaSta = {
      "E.T.A": {
        name: "S.T.A",
        width: staWidth,
        value: formatTime(sta)
      },
      "S.T.A": {
        name: "E.T.A",
        width: etaWidth,
        value: formatTime(eta)
      }
    };
    const oppositeLabel = etaSta[endLabel.name];
    return (
      <div style={[{ width: `${containerWidth} px` }, containerStyle]}>
        <svg height={svgHeight} width={svgWidth}>
          <Timelineframe
            svgHeight={svgHeight}
            width={svgWidth}
            startDate={tripStartTimeFormatted}
            endDate={tripEndTimeFormatted}
            endLabel={endLabel}
            etaOrSta={oppositeLabel}
            unloadingInWidth={unloadingInWidth}
            loadingOutWidth={loadingOutWidth}
            loadingOutTime={loadingOutTime ? formatTime(loadingOutTime) : null}
            unloadingInTime={
              unloadingInTime ? formatTime(unloadingInTime) : null
            }
          />
          {stoppageInfo &&
            stoppageInfo.map((stoppage, index) => (
              <Stoppage
                svgHeight={svgHeight}
                stoppage={stoppage}
                key={`stoppage_${index}`}
              />
            ))}
          {path && (
            <TravelledPath
              svgHeight={svgHeight}
              width={path.pathWidth}
              svgWidth={svgWidth}
              percentageCompleted={percentageCompleted}
              endLabel={lastPingTimeFormatted}
              pathInfo={pathInfo}
            />
          )}
          {dayIntervalWidthArr &&
            dayIntervalWidthArr.map((interval, index) => (
              <DayInterval
                svgHeight={svgHeight}
                interval={interval}
                key={`day_${index}`}
              />
            ))}
          {tollsInfo && (
            <Tolls svgHeight={svgHeight} svgWidth={svgWidth} data={tollsInfo} />
          )}
          {distanceArr &&
            distanceArr.map((indicator, index) => (
              <DistanceIndicators
                indicator={indicator}
                svgHeight={svgHeight}
                key={`indicator_${index}`}
              />
            ))}
        </svg>
      </div>
    );
  }
}
