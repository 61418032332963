import React from 'react'
import Table from './Table'

export default class UnloadingVivoTable extends React.Component {
  state = {
    data: this.props.data,
    avgVIVO: this.props.avgVIVO,
    tripStates: [...new Set(this.props.data.map(trip => trip.state))].filter(
      n => n
    ),
    statuses: [...new Set(this.props.data.map(trip => trip.status))].filter(
      n => n
    ),
    vivoOptions: [[0, 5], [5, 10], [10, 25], [25, 40], [40, 60], [60]],
    columns: [
      'vehicle_no',
      'origin',
      'destination',
      'unloading_in_time',
      'unloading_out_time',
      'vivo',
      'avg_unloading_vivo_percentage',
      'sta',
      'state',
      'status',
      'transporter'
    ]
  }

  render() {
    return (
      <div>
        {this.state.columns && (
          <Table
            data={this.state.data}
            statuses={this.state.statuses}
            tripStates={this.state.tripStates}
            avgVIVO={this.state.avgVIVO}
            vivoOptions={this.state.vivoOptions}
            columns={this.state.columns}
            tripCompleted={this.props.tripCompleted}
            userRole={this.props.userRole}
          />
        )}
      </div>
    )
  }
}
