import React from 'react'

import { compose, withProps, withStateHandlers, lifecycle } from 'recompose'
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
  Polyline,
  InfoWindow
} from 'react-google-maps'
import flatten from 'lodash/flatten'
import VehicleMAMarker from './VehicleMAMarker'
import CircleMAMarker from './CircleMAMarker'
import InfoWindowBox from '../../components/Maps/InfoWindowBox'
import { blackDotMarker, customCircleMarker } from '../../../../utils'

const MapAnalyticsGoogleMap = compose(
  withProps({
    containerElement: <div style={{ height: `80vh`, width: '100%' }} />,
    mapElement: (
      <div style={{ height: `100%`, width: '100%', padding: '12.5px' }} />
    )
  }),
  lifecycle({
    componentWillMount() {
      const refs = []
      this.setState({
        bounds: null,
        onMapMounted: ref => {
          refs.map = ref
          if (!ref) {
            return
          }
          let bounds = new google.maps.LatLngBounds()
          Object.keys(this.props.result).forEach(location => {
            bounds.extend(
              new google.maps.LatLng(
                location.split(',')[0],
                location.split(',')[1]
              )
            )
          })
          refs.map.fitBounds(bounds)
        },
        center: { lat: 20.9607443, lng: 86.0475726 }
      })
    }
  }),
  withGoogleMap
)(props => {
  const circleCoords = props.result ? Object.keys(props.result) : null
  const vehicleCoords = props.result
    ? flatten(Object.values(props.result).map(i => Object.values(i)))
    : null
  console.log(props)
  const { selectedVehicleMarker, selectedCircleMarker } = props
  const durationArr = vehicleCoords.map(loc => loc.duration / 36000)
  const colorRef = Math.floor(Math.max(...durationArr))

  return (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: 20.9607443, lng: 86.0475726 }}
    >
      {circleCoords &&
        circleCoords.map((location, index) => {
          return (
            <Circle
              key={`circle_${index}`}
              center={{
                lat: Number(location.split(',')[0]),
                lng: Number(location.split(',')[1])
              }}
              radius={2000}
              options={{
                strokeColor: 'blue',
                strokeOpacity: 0.3,
                strokeWeight: 5,
                fillColor: 'blue',
                fillOpacity: 0.4
              }}
            />
          )
        })}
      {circleCoords &&
        circleCoords.map((location, index) => {
          return (
            <CircleMAMarker
              colorRef={colorRef}
              key={`circle_${location}_${index}`}
              location={{
                lat: Number(location.split(',')[0]),
                lng: Number(location.split(',')[1])
              }}
              isOpen={`${location}_${index}` === selectedCircleMarker}
              value={props.result[location]}
            />
          )
        })}
      {vehicleCoords &&
        vehicleCoords
          .filter(item => item.trip)
          .map((location, index) => {
            return (
              <VehicleMAMarker
                colorRef={colorRef}
                key={`vehicle_${location.trip.vehicle_no}_${location.duration}`}
                location={location}
                isOpen={
                  `${location.trip.vehicle_no}_${location.duration}` ===
                  selectedVehicleMarker
                }
              />
            )
          })}
    </GoogleMap>
  )
})

export default MapAnalyticsGoogleMap
