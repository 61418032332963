import { startCase } from "lodash";
import qs from "qs";
import React from "react";
import Select from "react-select";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { IFilter } from ".";
import useDeepCompareEffect from "../../../../customHooks/useDeepCompareEffect";
import { IJourney } from "../../../../types";
import { formatDuration } from "../../../../utils";
import GraphContainer from "./GraphContainer";
import CustomTick from "./CustomTick";

interface LoadingPerformanceProps {
  filter: IFilter;
  showJourney: (journeys: IJourney[]) => void;
}

interface LoadingPerformanceResponse {
  avgVivo: number;
  name: string;
  journeys: IJourney[];
}

export default function LoadingPerformance({
  filter,
  showJourney
}: LoadingPerformanceProps) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState<null | LoadingPerformanceResponse[]>(
    null
  );
  const [visibleCount, setVisibleCount] = React.useState(5);
  const [maxCount, setMaxCount] = React.useState(5);
  const [orderAsc, setOrderAsc] = React.useState({
    label: "DESC",
    value: false
  });
  const [selectedXAxisLabels, setSelectedXAxisLabels] = React.useState(null);
  useDeepCompareEffect(() => {
    fetchData();
  }, [filter]);

  async function fetchData() {
    try {
      setLoading(true);
      const q = qs.stringify(filter, {
        encode: false,
        skipNulls: true,
        indices: false
      });
      const url = `${location.href}/loading_performance.json?${q}`;
      const response = await (await fetch(url, {
        headers: {
          "Key-Inflection": "camel"
        }
      })).json();
      if (response && response.data) {
        setData(response.data);
        setVisibleCount(Math.min(response.data.length, 5));
        setMaxCount(response.data.length);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  let graphData =
    data &&
    data.sort((a, b) =>
      orderAsc.value ? a.avgVivo - b.avgVivo : b.avgVivo - a.avgVivo
    );
  if (selectedXAxisLabels && graphData) {
    graphData = graphData.filter(item =>
      selectedXAxisLabels.map(label => label.value).includes(item.name)
    );
  }
  if (graphData && visibleCount) {
    graphData = graphData.filter((_, index) => index < visibleCount);
  }
  return (
    <GraphContainer title="Loading Performance" loading={loading} error={error}>
      <ResponsiveContainer height={400} width="100%">
        <BarChart
          width={400}
          height={400}
          data={graphData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            interval={0}
            tick={({ x, y, payload }) => (
              <CustomTick x={x} y={y} payload={payload} />
            )}
          />
          <YAxis
            tickFormatter={time =>
              Number(time) > 0
                ? formatDuration(time, {
                    keepDecimalsOnWholeSeconds: false,
                    unitCount: 2
                  })
                : 0
            }
          />
          <Tooltip
            formatter={(value, name, props) => {
              if (value) {
                return Number(value) > 0
                  ? formatDuration(Math.floor(value), {
                      keepDecimalsOnWholeSeconds: false,
                      unitCount: 2
                    })
                  : 0;
              }
              return "...";
            }}
          />
          <Legend formatter={(value, entry, index) => startCase(value)} />
          <Bar
            dataKey="avgVivo"
            fill="#8884d8"
            onClick={({ journeys }) => showJourney(journeys)}
          />
        </BarChart>
      </ResponsiveContainer>
      {data && (
        <React.Fragment>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1, marginLeft: "0.125em" }}>
              Showing
              <input
                type="number"
                min="1"
                step="1"
                max={maxCount}
                value={visibleCount}
                onChange={e => setVisibleCount(Number(e.target.value))}
              />{" "}
              of {data.length}
            </div>
            <div style={{ flex: 1, marginRight: "0.125em" }}>
              <Select
                placeholder="Sort VIVO"
                options={[
                  { label: "ASC", value: true },
                  { label: "DESC", value: false }
                ]}
                onChange={selected => setOrderAsc(selected)}
                value={orderAsc}
              />
            </div>
          </div>
          <div style={{ marginTop: "0.25em" }}>
            <Select
              placeholder="Select Loading point"
              options={data.map(item => ({
                label: item.name,
                value: item.name
              }))}
              onChange={selected => setSelectedXAxisLabels(selected)}
              value={selectedXAxisLabels}
              isMulti
            />
          </div>
        </React.Fragment>
      )}
    </GraphContainer>
  );
}
