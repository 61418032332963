import _ from "lodash";
import React from "react";
import Select from "react-select";
import { SkyLightStateless } from "react-skylight";
import CreatableSelectInput from "./CreatableSelectInput";
import MuteAlert from "./MuteAlert";
import UserForm, {FormContainer} from "./UserForm";
import xor from "lodash/xor";
import flatten from "lodash/flatten";
import { bigModal } from "../../../../utils";
import styled from "@emotion/styled"

const AlertTitle = styled.h4`
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export default class AlertForm extends React.Component {
  state = {
    alert: null,
    selectedPois: null,
    alertFormModel: null,
    showForm: !this.props.users,
    alertUserIds: null,
    alertForm: {},
    userForm: [
      {
        full_name: undefined,
        email: undefined,
        phone_no: undefined,
        designation: undefined
      }
    ],
    providerIds: null,
    selectedOption: { value: "", label: "" },
    count: 1,
    users: this.props.users,
    eraseForm: false,
    isConsolidated: true,
    modalVisible: false,
    vehicle_nos: this.props.vehicle_nos,
    selectedUserArr: null,
    settings: null,
    userFormError: null
  };

  componentDidMount() {
    if (this.props.alert) {
      this.setState({
        alert: Object.keys(this.props.alert)[0],
        alertFormModel: Object.values(this.props.alert)[0],
        eraseForm: false,
        isConsolidated:
          Object.keys(this.props.alert)[0] !==
          "InnplantOverspeedAlertSubscription"
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.alert && !_.isEqual(Object.keys(props.alert)[0], state.alert)) {
      const alertForm = {};
      const kArr = Object.values(Object.values(props.alert)[0].config)[0];
      const formKeys = kArr ? Object.keys(kArr) : null;
      if (formKeys) {
        formKeys.forEach(key => {
          alertForm[key] = "";
        });
      }
      return {
        alert: Object.keys(props.alert)[0],
        alertForm,
        alertFormModel: Object.values(props.alert)[0],
        eraseForm: false,
        isConsolidated:
          Object.keys(props.alert)[0] !== "InnplantOverspeedAlertSubscription",
        userForm: [
          {
            full_name: undefined,
            email: undefined,
            phone_no: undefined,
            designation: undefined
          }
        ],
        providerIds: null,
        alertUserIds: null,
        selectedPois: null,
        selectedUserArr: null,
        settings: null
      };
    }
    return null;
  }

  openMuteModal = () => {
    this.setState({
      modalVisible: true
    });
  };

  closeMuteModal = () => {
    this.setState({
      modalVisible: false
    });
  };

  toggleForm = () => {
    if (!this.state.count) {
      this._addRow;
    }
    this.setState({ showForm: !this.state.showForm });
  };

  populateAlertFormData = selectedOption => {
    const newSettings = {};
    Object.entries(selectedOption).forEach(([key, obj]) => {
      newSettings[key] = obj.value;
    });
    this.setState(({ alertForm, settings }) => ({
      alertForm: { ...alertForm, ...selectedOption },
      settings: { ...settings, ...newSettings }
    }));
  };

  createSubscription = () => {
    const users = this.state.userForm.filter(item => item.full_name);
    let data = {
      alert_subscription: {
        type: this.state.alert,
        alert_user_ids: this.state.alertUserIds,
        settings: this.state.settings,
        mrole_id: this.props.mrole.id,
        mrole_type: this.props.mrole.type,
        is_consolidated: this.state.isConsolidated
      },
      provider_ids: this.state.providerIds
        ? this.state.providerIds.map(opt => opt.value)
        : null
    };
    if (users.length > 0) {
      data.users = users;
    }
    if (
      this.state.alert !== "DisconnectionAlertSubscription" &&
      (!this.state.alertForm ||
        (this.state.alertForm &&
          Object.values(this.state.alertForm).filter(n => !n).length))
    ) {
      swal("Oops", "You haven't configured alert setting", "error");
      return;
    }
    if (!this.state.alertUserIds && users.length < 1) {
      swal("Oops", "Please select a user", "warning");
      return;
    }
    if (
      !data.provider_ids ||
      (data.provider_ids && !data.provider_ids.length)
    ) {
      swal("Oops", "Please select a provider", "warning");
      return;
    }
    if (
      !this.state.alertUserIds &&
      (this.state.userForm &&
        Object.values(this.state.userForm).filter(n => !n).length)
    ) {
      swal("Oops", "Please enter user details", "warning");
      return;
    }
    if (this.state.userFormError) {
      swal(
        "Oops",
        `Please enter user form details correctly. ${this.state.userFormError}`,
        "warning"
      );
      return;
    }
    this.props.add(data);
    this.setState(
      state => ({
        ...state,
        eraseForm: true,
        providerIds: null,
        alert_user_ids: null,
        selectedUserArr: null,
        showForm: false,
        userForm: [
          {
            full_name: undefined,
            email: undefined,
            phone_no: undefined,
            designation: undefined
          }
        ]
      }),
      () => {
        if (this.state.alertForm) {
          const alertForm = {};
          Object.keys(this.state.alertForm).forEach(key => {
            alertForm[key] = "";
          });
          this.setState({ alertForm });
        }
      }
    );
  };

  updateUser = selectedUserArr => {
    if (selectedUserArr) {
      const alertUserIds = selectedUserArr.map(user => user.value);
      this.setState({ selectedUserArr, alertUserIds });
    } else {
      this.setState({ selectedUserArr });
    }
  };

  populateUserInfo = (userFormItem, i) => {
    const { userForm } = this.state;
    userForm[i] = { ...userForm[i], ...userFormItem };
    this.setState({ userForm });
  };

  selectPoi = selectedPois => {
    const poiIdArr = selectedPois ? selectedPois.map(item => item.value) : [];
    this.setState(state => ({
      settings: {
        ...state.settings,
        poi_ids: state.settings
          ? xor(flatten(state.settings.poi_ids), poiIdArr)
          : poiIdArr
      },
      selectedPois
    }));
  };

  populateProviders = selectedOption => {
    this.setState({ providerIds: selectedOption });
  };

  toggleConsolidation = () => {
    this.setState({ isConsolidated: !this.state.isConsolidated });
  };

  _addRow = () => {
    this.setState({
      userForm: [
        ...this.state.userForm,
        {
          full_name: undefined,
          email: undefined,
          phone_no: null,
          designation: null
        }
      ]
    });
  };

  _removeRow = index => {
    let { userForm } = this.state;
    userForm = userForm.filter((_, i) => i !== index);
    this.setState({ userForm });
  };

  setUserFormErrors = userFormError => {
    this.setState({ userFormError });
  };

  render() {
    return (
      <div>
        <div className="d-block">
            {this.state.alertFormModel && (
              <AlertTitle className="">{this.state.alertFormModel.name}</AlertTitle>
            )}
          <button
            style={{ float: "right", marginRight: "2em" }}
            className="btn btn-danger"
            onClick={this.openMuteModal}
          >
            <i className="fa fa-volume-off" /> Mute
          </button>
          {this.state.alertFormModel && (
            <p className="">{this.state.alertFormModel.desc}</p>
          )}
        </div>
        <hr />

        <FormContainer>
          {this.state.alertFormModel &&
            this.state.alertFormModel.units &&
            Object.keys(this.state.alertFormModel.units).map((item, index) => (
              <div style={{ flex: 1 }} key={`unit_${index}`}>
                <CreatableSelectInput
                  name={this.state.alert}
                  model={this.state.alertFormModel}
                  item={item}
                  value={this.state.alertForm[item]}
                  unit={this.state.alertFormModel.units[item]}
                  onChange={this.populateAlertFormData}
                  erase={this.state.eraseForm}
                />
              </div>
            ))}
          {this.state.alertFormModel &&
            this.state.alertFormModel.name.toLowerCase().includes("poi") && (
              <div className="col">
                <Select
                  placeholder="Select POIs"
                  name="select-poi"
                  value={this.state.selectedPois}
                  onChange={this.selectPoi}
                  options={this.props.pois.map(poi => ({
                    value: poi.id,
                    label: poi.poi_nick_name
                  }))}
                  isMulti={true}
                />
              </div>
            )}
        </FormContainer>
        <div
          className="d-flex"
          style={{ marginTop: "1em", flexDirection: "column" }}
        >
          {this.state.alert !== "InnplantOverspeedAlertSubscription" && (
            <div
              className="checkbox"
              style={{
                marginLeft: "0.5em",
                marginRight: "0.5em",
                fontSize: "18px"
              }}
            >
              <label className="c-input c-checkbox">
                <input
                  type="checkbox"
                  value={this.state.isConsolidated}
                  checked={this.state.isConsolidated}
                  onChange={this.toggleConsolidation}
                />
                <span className="c-indicator" />
                Consolidate Alerts
                <small className="text-muted" style={{ display: "block" }}>
                  Consolidated means alerts will be clubbed together.
                </small>
              </label>
            </div>
          )}
          <div
            className="col"
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "1em"
            }}
          >
            <FormContainer>
              <div style={{ flex: 1 }}>
                <Select
                  isMulti
                  isClearable
                  options={this.props.providers
                    .map(provider => ({
                      label: provider.name,
                      value: provider.id
                    }))
                    .filter(opt =>
                      this.state.isConsolidated &&
                      this.state.alert !== "InnplantOverspeedAlertSubscription"
                        ? opt.label !== "SMS"
                        : opt
                    )}
                  placeholder="Select Providers"
                  onChange={this.populateProviders}
                  value={this.state.providerIds}
                />
              </div>
              <div style={{ flex: 1 }}>
                <Select
                  options={this.props.users.map(user => ({
                    label: user.full_name,
                    value: user.id
                  }))}
                  placeholder="Select User"
                  onChange={this.updateUser}
                  value={this.state.selectedUserArr}
                  isMulti
                />
              </div>
              <div style={{ flex: 1, textAlign: "end" }}>
                <button
                  className={`btn btn-sm btn-${
                    this.state.showForm ? "danger" : "warning"
                  }`}
                  onClick={this.toggleForm}
                >
                  <i
                    className={`fa fa-${
                      this.state.showForm ? "minus" : "plus"
                    }`}
                  />{" "}
                  {this.state.showForm ? " Use Options" : " Add User"}
                </button>
              </div>
            </FormContainer>
            <div style={{ flex: 1, display: "flex", margin: "1em 0" }}>
              {!this.state.showForm && (
                <button
                  type="button"
                  className="btn btn-success mt-2em mr-2"
                  onClick={this.createSubscription}
                  disabled={this.state.userFormError}
                >
                  <i className="fa fa-check" /> Submit
                </button>
              )}
            </div>
          </div>
        </div>
        {this.state.showForm && (
          <React.Fragment>
            {this.state.userForm &&
              this.state.userForm.map((_, i) => (
                <div className="row pl-3 pr-3" key={`${i}`}>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-10">
                        <UserForm
                          fieldValues={this.state.userForm[i]}
                          populateUserInfo={userField =>
                            this.populateUserInfo(userField, i)
                          }
                          setUserFormErrors={this.setUserFormErrors}
                        />
                      </div>
                      <div
                        className="col-md-1"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {this.state.userForm.length > 1 && (
                          <button
                            type="button"
                            className="btn btn-danger mt-2em"
                            onClick={() => this._removeRow(i)}
                          >
                            <i className="fa fa-minus" />
                          </button>
                        )}
                      </div>
                      <div
                        className="col-md-1"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <button
                          type="button"
                          className="btn btn-success mt-2em"
                          onClick={i => this._addRow(i)}
                        >
                          <i className="fa fa-plus" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <div className="row">
              <button
                type="button"
                className="btn btn-success mt-2"
                onClick={this.createSubscription}
                style={{ marginTop: "1em" }}
                disabled={this.state.userFormError}
              >
                <i className="fa fa-check" /> Submit
              </button>
            </div>
          </React.Fragment>
        )}

        {this.state.alert && (
          <SkyLightStateless
            dialogStyles={bigModal}
            isVisible={this.state.modalVisible}
            onCloseClicked={() => this.closeMuteModal()}
          >
            <div className="row skylight-dipper">
              <div className="row text-center">Mute {this.state.alert}</div>
            </div>

            <div className="container">
              <MuteAlert
                selectedVehicles={[]}
                alertName={this.state.alert}
                vehicles={this.state.vehicle_nos}
              />
            </div>
          </SkyLightStateless>
        )}
      </div>
    );
  }
}
