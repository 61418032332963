import React from "react";
import Segments from "./Segments";

const PercentageTripBox = ({
  percentageCompleted,
  width,
  svgHeight,
  svgWidth
}) => (
  <React.Fragment>
    <path
      d={`M${width},${svgHeight - 48.5} v-30`}
      fill="yellow"
      stroke="#4DB6AC"
      strokeWidth="3"
    />
    <text
      x={Math.min(width - 25, svgWidth - 125)}
      y={svgHeight - 120}
      fill="#616161"
      style={{ fontSize: "15px" }}
    >
      % Covered: {percentageCompleted}
    </text>
  </React.Fragment>
);

const TravelledPath = ({
  width,
  percentageCompleted,
  endLabel,
  svgHeight,
  svgWidth,
  pathInfo
}) => (
  <React.Fragment>
    <rect
      x="0"
      y={svgHeight - 68.5}
      width={width}
      height="5"
      style={{
        fill: "#512DA8",
        strokeWidth: 1,
        stroke: "#5E35B1",
        opacity: 0.85
      }}
    />
    {pathInfo &&
      pathInfo.map((segment, index) => (
        <Segments
          segment={segment}
          svgHeight={svgHeight}
          key={`segment_${index}`}
        />
      ))}
    <text
      x={width - 27.5}
      y={svgHeight - 35}
      fill="#5E35B1"
      style={{ fontSize: "10px", float: "right" }}
    >
      {endLabel.split(" ")[1]}
    </text>
    {percentageCompleted && (
      <PercentageTripBox
        percentageCompleted={percentageCompleted}
        width={width}
        svgWidth={svgWidth}
        svgHeight={svgHeight}
      />
    )}
  </React.Fragment>
);

export default TravelledPath;
