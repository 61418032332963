import React, { useState } from 'react'
import { statusColor as colors } from '../../../../utils'

const style = {
  cardWrapper: {
    display: 'flex',
    flexDirection: 'row',
    listStyleType: 'none',
    flexWrap: 'wrap'
  },
  card: {
    display: 'flex',
    flexGrow: 1,
    padding: '2px',
    flexDirection: 'row',
    background: 'white',
    borderRadius: '4px',
    color: 'white',
    fontWeight: 400,
    fontSize: '14px',
    width: '100%',
    justifyContent: 'space-between'
  },
  heading: {
    overflow: 'hidden'
  }
}

const Card = ({ data, text, view, onClick }) => {
  const keysList = Object.keys(data)
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns:
          '[col-1-start] 1fr [col-2-start] 1fr [col-3-start] 1fr [col-4-start] 1fr [col-4-end]',
        gridTemplateRows: '[row-1-start] 1fr [row-2-start] 1fr [row-3-end]',
        gridGap: '3px'
      }}
    >
      {keysList.map((key, index) => {
        return (
          <div
            key={key}
            style={{
              ...style.card,
              background: index ? colors[key] : '#fff',
              color: index ? style.card.color : '#333',
              gridRow: index ? 'unset' : 'row-1-start/row-2-end',
              flexDirection: index ? style.card.flexDirection : 'column',
              justifyContent: index
                ? style.card.justifyContent
                : 'space-evenly',
              alignItems: index ? 'unset' : 'center'
            }}
            onClick={() => {
              return onClick(key)
            }}
          >
            <div style={style.heading}>
              {key.toLowerCase() === 'total' ? text : key}{' '}
            </div>
            <div style={{ fontSize: index ? '12px' : '20px' }}>{data[key]}</div>
          </div>
        )
      })}
    </div>
  )
}
export default Card
