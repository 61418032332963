import React from 'react'
import moment from 'moment'
import titleize from 'titleize'

const styles = {
  ellipsis: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  h4: {
    color: 'rgb(85, 85, 86)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}

const RowTripInfo = ({ trip }) => (
  <div
    className="row"
    style={{
      marginBottom: '1em'
    }}
  >
    <div className="col-md-3">
      <div className="row">
        <div className="col-sm-5">
          <h4 style={styles.h4}>{trip.origin}</h4>
          <small>{moment.utc(trip.start_date).format('DD-MM-YYYY')}</small>
          <small style={styles.ellipsis} title={trip.consigner_name}>
            {trip.consigner_name}
          </small>
        </div>
        <div className="col-sm-2">
          <i className="fa fa-arrow-right" />
        </div>
        <div className="col-sm-5">
          <h4 style={styles.h4}>{trip.destination}</h4>
          <small>{moment.utc(new Date(trip.eta)).format('DD-MM-YYYY')} </small>
          <small style={styles.ellipsis} title={trip.consignee_name}>
            {trip.consignee_name}
          </small>
        </div>
      </div>
    </div>
    <div className="col-md-2">
      <h3 style={styles.h4}>#{trip.invoice_no}</h3>
      <h5>{trip.slug}</h5>
    </div>
    <div className="col-md-2">
      <h4 style={styles.h4}>{trip.vehicle_no}</h4>
      <small style={styles.ellipsis} title={trip.transporter}>
        {trip.transporter}
      </small>
    </div>
    <div className="col-md-3">
      <h5 style={styles.h4}>Loading:</h5>
      {moment
        .utc(new Date(trip.loading_in_time))
        .format('DD/MM/YY HH:mm')} -{' '}
      {moment.utc(new Date(trip.loading_out_time)).format('DD/MM/YY HH:mm')}
    </div>
    <div className="col-md-2">
      <h5>{trip.unloading_in_time ? 'Unloading' : 'Trip State'}</h5>
      {trip.unloading_in_time
        ? moment.utc(new Date(trip.unloading_in_time)).format('DD/MM/YY HH:mm')
        : trip.state
        ? titleize(trip.state)
        : '...'}
    </div>
  </div>
)

export default RowTripInfo
