import React from "react";

const lineColor = label => (label === "S.T.A" ? "#4CAF50" : "#f44336");

const TimelineFrame = ({
  width,
  startDate,
  endDate,
  endLabel,
  etaOrSta,
  svgHeight,
  loadingOutWidth,
  unloadingInWidth,
  loadingOutTime,
  unloadingInTime
}) => {
  const additionalLabel = etaOrSta;
  const heightObj = {
    textBottom: svgHeight,
    textNextToBottom: svgHeight - 10,
    dashedPath: svgHeight - 66,
    verticalLine: svgHeight - 40,
    endLabelHeight: svgHeight - 110
  };
  return (
    <React.Fragment>
      <g fill="none" stroke="black" strokeWidth="2">
        <path
          strokeDasharray="5,5"
          d={`M5 ${heightObj.dashedPath} l${width} 0`}
        />
      </g>
      <path
        d={`M0,${heightObj.verticalLine} v-50`}
        fill="yellow"
        stroke="blue"
        strokeWidth="3"
        style={{ opacity: 0.95 }}
      />
      {loadingOutWidth && (
        <React.Fragment>
          <path
            d={`M${loadingOutWidth},${heightObj.verticalLine} v-50`}
            fill="yellow"
            stroke="#03A9F4"
            strokeWidth="3"
            style={{ opacity: 0.95 }}
          />
          <text
            x={loadingOutWidth}
            y={heightObj.textNextToBottom}
            fill="#03A9F4"
            style={{ fontSize: "10px" }}
          >
            Load
          </text>
          <text
            x={loadingOutWidth}
            y={heightObj.textBottom}
            fill="#03A9F4"
            style={{ fontSize: "10px" }}
          >
            {loadingOutTime.split(" ")[1]}
          </text>
        </React.Fragment>
      )}
      {unloadingInWidth && (
        <React.Fragment>
          <path
            d={`M${unloadingInWidth},${heightObj.verticalLine} v-50`}
            fill="yellow"
            stroke="#03A9F4"
            strokeWidth="3"
            style={{ opacity: 0.95 }}
          />
          <text
            x={unloadingInWidth}
            y={heightObj.textNextToBottom}
            fill="#03A9F4"
            style={{ fontSize: "10px" }}
          >
            Unload
          </text>
          <text
            x={unloadingInWidth}
            y={heightObj.textBottom}
            fill="#03A9F4"
            style={{ fontSize: "10px" }}
          >
            {unloadingInTime.split(" ")[1]}
          </text>
        </React.Fragment>
      )}
      <text
        x="0"
        y={heightObj.textNextToBottom}
        fill="blue"
        style={{ fontSize: "10px" }}
      >
        {startDate.split(" ")[0]}
      </text>
      <text
        x="0"
        y={heightObj.textBottom}
        fill="blue"
        style={{ fontSize: "10px" }}
      >
        {startDate.split(" ")[1]}
      </text>
      {additionalLabel &&
        Number(additionalLabel.width) > 0 &&
        width > additionalLabel.width && (
          <React.Fragment>
            <rect
              x={additionalLabel.width}
              y={svgHeight - 70.5}
              width={width - additionalLabel.width}
              height="10"
              style={{
                fill: additionalLabel.name === "E.T.A" ? "#CDDC39" : "#e57373",
                strokeWidth: 1,
                stroke:
                  additionalLabel.name === "E.T.A" ? "#CDDC39" : "#e57373",
                opacity: 0.5
              }}
            />
            <path
              d={`M${additionalLabel.width},${heightObj.verticalLine} v-50`}
              fill="yellow"
              stroke={lineColor(additionalLabel.name)}
              strokeWidth="3"
            />
            <text
              x={additionalLabel.width - 27.5}
              y={heightObj.endLabelHeight}
              fill={lineColor(additionalLabel.name)}
              style={{ fontSize: "10px", float: "right" }}
            >
              {additionalLabel.name}
            </text>
            <text
              x={additionalLabel.width - 27.5}
              y={heightObj.textNextToBottom}
              fill={lineColor(additionalLabel.name)}
              style={{ fontSize: "10px", float: "right" }}
            >
              {additionalLabel.value.split(" ")[0]}
            </text>
            <text
              x={additionalLabel.width - 27.5}
              y={heightObj.textBottom}
              fill={lineColor(additionalLabel.name)}
              style={{ fontSize: "10px" }}
            >
              {additionalLabel.value.split(" ")[1]}
            </text>
          </React.Fragment>
        )}
      <path
        d={`M${width},${heightObj.verticalLine} v-50`}
        fill="yellow"
        stroke={lineColor(endLabel.name)}
        strokeWidth="3"
      />
      <text
        x={width - 27.5}
        y={heightObj.endLabelHeight}
        fill={lineColor(endLabel.name)}
        style={{ fontSize: "10px", float: "right" }}
      >
        {endLabel.name}
      </text>
      <text
        x={width - 27.5}
        y={heightObj.textNextToBottom}
        fill={lineColor(endLabel.name)}
        style={{ fontSize: "10px", float: "right" }}
      >
        {endDate.split(" ")[0]}
      </text>
      <text
        x={width - 27.5}
        y={heightObj.textBottom}
        fill={lineColor(endLabel.name)}
        style={{ fontSize: "10px" }}
      >
        {endDate.split(" ")[1]}
      </text>
    </React.Fragment>
  );
};

export default TimelineFrame;
