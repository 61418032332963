import React from 'react'
import Comment from './Comment'
import CommentForm from './CommentForm'

export default function CommentsContainer({
  issueId,
  threadId,
  count: comment_count,
  token
}) {
  const [open, setOpen] = React.useState(comment_count < 1)
  const [comments, setComments] = React.useState([])

  async function getComments() {
    try {
      const commentsUrl = `/show_comments/${issueId}.json`
      const response = await (await fetch(commentsUrl)).json()
      if (response && response.data && response.data.comments) {
        setComments(response.data.comments)
      }
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    getComments()
  }, [])

  return (
    <div>
      <button onClick={() => setOpen(!open)}>
        {!open ? 'Show' : 'Hide'} Comments
        {!open && comment_count ? `( ${comment_count} )` : null}
      </button>
      {open && (
        <div>
          <CommentForm
            issueId={issueId}
            threadId={threadId}
            refreshComments={getComments}
            token={token}
          />
          <div style={{ marginTop: '1em' }}>
            {comments.map(comment => (
              <Comment
                data={comment}
                key={`${issueId}_comment_${comment.id}`}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
