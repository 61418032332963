import { MapControl, withLeaflet } from 'react-leaflet'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch'
import { removeClassName } from '../../../../../utils'

class SearchLocation extends MapControl {
  constructor(props) {
    super(props)
  }

  attachEvents() {
    this.leafletElement.onSubmit = e => {
      const el = this.leafletElement.searchElement.elements.container
      el.classList.remove('active')
      this.props.submit(e)
    }
  }

  createLeafletElement() {
    const provider = new OpenStreetMapProvider()
    return GeoSearchControl({
      provider: provider,
      maxMarkers: 1,
      keepResult: false
    })
  }

  componentDidMount() {
    const { map } = this.props.leaflet
    map.addControl(this.leafletElement)
    this.attachEvents()
  }
}
export default withLeaflet(SearchLocation)
