import { purifySearchParams } from "@fareye/utils";
import isEmpty from "lodash/isEmpty";
import React from "react";
import ReactOnRails from "react-on-rails";
import NearbyForm from "./NearbyForm";
import NearbyList from "./NearbyList";
import NearbyMap from "./NearbyMap";
import styled from "@emotion/styled";

const NearbyContainer = styled.div`
  display: grid;
  grid-template-areas:
    "form"
    "map";
  grid-gap: 1em;
  @media (min-width: 787px) {
    grid-template-columns: 1fr 2fr;
    grid-template-areas: "form map";
  }
`;

function Nearby({
  vehicles: vehicleList,
  location: locationList,
  pois: poiList,
  filterOptions,
}) {
  const [center, setCenter] = React.useState({
    lat: 28.7041,
    lng: 77.1025,
    radius: 0,
  });
  const [vehicles, setVehicles] = React.useState(vehicleList);
  const [apiLocation, setApiLocation] = React.useState(locationList);
  const [location, setLocation] = React.useState(locationList);
  const [selectedVehicle, setSelectedVehicle] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [pois, setPois] = React.useState(poiList);

  const [sliderRange, setSliderRange] = React.useState({
    maxValue: new Date().getTime(),
    minValue: new Date().getTime() - 24 * 60 * 60 * 1000,
    // current: new Date().getTime() - 12 * 60 * 60 * 1000,
  });

  async function fetchData(form = {}) {
    try {
      setLoading(true);
      const q = purifySearchParams(form);
      const response: any = await (await fetch(
        `${window.location.pathname}.json?${q}`,
        {
          method: "GET",
          headers: ReactOnRails.authenticityHeaders({
            "Content-Type": "application/json",
          }),
        }
      )).json();
      if (response) {
        const { vehicles, pois, location, center } = response;
        setVehicles(vehicles);
        setApiLocation(location);
        setPois(pois);
        setCenter(center);
        setLoading(false);
        const lc = {};
        const sliderValue = new Date().getTime() - 12 * 60 * 60 * 1000;
        Object.entries(location).forEach(([vehicleNo, vehicleObj]: any) => {
          if (
            new Date(vehicleObj.location.ist_timestamp).getTime() -
              sliderValue >
            0
          ) {
            lc[vehicleNo] = vehicleObj;
          }
        });
        setLocation(lc);
        const timeArr = Object.values(location).map((i: any) =>
          new Date(i.location.ist_timestamp).getTime()
        );
        const maxValue = Math.max(...timeArr);
        const minValue = Math.min(...timeArr);
        setSliderRange({ ...sliderRange, maxValue, minValue });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  function filterNearby(sliderValue: number) {
    const lc = {};
    Object.entries(apiLocation).forEach(([vehicleNo, vehicleObj]: any) => {
      if (
        new Date(vehicleObj.location.ist_timestamp).getTime() - sliderValue >
        0
      ) {
        lc[vehicleNo] = vehicleObj;
      }
    });
    setLocation(lc);
  }

  return (
    <NearbyContainer>
      <div style={{ gridArea: "form" }}>
        <NearbyForm
          filterOptions={filterOptions}
          vehicles={vehicles}
          pois={pois}
          location={apiLocation}
          fetchData={fetchData}
        />
        {!isEmpty(apiLocation) && (
          <div style={{ marginTop: "1em" }}>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                {location && sliderRange && (
                  <NearbyList
                    data={location}
                    onVehicleSelect={setSelectedVehicle}
                    filterNearby={filterNearby}
                    sliderRange={sliderRange}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div style={{ gridArea: "map", height: "75vh", width: "100%" }}>
        {loading ? (
          <p>Loading ...</p>
        ) : (
          <>
            {location && (
              <NearbyMap
                center={center}
                data={location}
                selectedVehicle={selectedVehicle}
              />
            )}
          </>
        )}
      </div>
    </NearbyContainer>
  );
}

export default (props) => <Nearby {...props} />;
