import React from "react";
import TripHistoryTable from "./TripHistoryTable";
import HistorySliderFilter from "./HistorySliderFilter";
import { exportTableToCSV } from "../../../../../../utils";

const TripHistoryPanel = (props) => {
  // const historyDates = props.info.history
  //   ? props.info.history
  //       .map(h => new Date(h.ist_timestamp).getTime())
  //       .filter(n => n)
  //   : null;
  const sliderMin = props.info.historySlider ? props.info.historySlider.min : 0;
  const sliderMax = props.info.historySlider
    ? props.info.historySlider.max
    : 10;
  return (
    <div>
      <div className="row">
        <div className="col-md-9">
          <input
            type="search"
            className="form-control form-control-sm"
            name="search"
            placeholder="Search history by location / landmark"
            onChange={props.info.search}
          />
        </div>
	  {props.info.history && props.info.history.length ? (<div className="col-md-3">
          <button
            className="btn btn-sm btn-info fa fa-file-excel-o"
            onClick={() => {
              let name = `History_${props.info.history[0].vehicle_no}`
	      if (props.info.tripInfo) {
	         name += `_INV_${props.info.tripInfo.invoice_no}_Loading_${new Date(props.info.tripInfo.start_date).toJSON().slice(0, 10)}`
	      }
	      exportTableToCSV(
                "tripHistoryTableReact",
                 `${name}.csv`
	      )
            }}
          >
            Download
          </button>
        </div>) : null }
      </div>
      {sliderMin && sliderMax && (
        <HistorySliderFilter
          minValue={sliderMin}
          maxValue={sliderMax}
          selectedValue={props.info.newHistorySlider}
          filterHistorySlider={props.info.filterHistorySlider}
        />
      )}
      <div style={{ height: "70vh", overflowY: "auto" }}>
        <TripHistoryTable
          data={props.info.history}
          showHistoryInfoWindow={props.info.showHistoryInfoWindow}
          selectedHistoryMarkerTimestamp={
            props.info.selectedHistoryMarkerTimestamp
          }
        />
      </div>
    </div>
  );
};

export default TripHistoryPanel;
