import axios from "axios";
import { titleCase } from "change-case";
import xor from "lodash/xor";
import qs from "qs";
import React from "react";
import ReactOnRails from "react-on-rails";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { convertObjKeysToSnakeCase } from "../../../../utils";
const CancelToken = axios.CancelToken;
let cancel;

export function searchAndCreateFilterObj(filterableColumns, selectedFilters) {
  const result = {};
  Object.entries(selectedFilters).forEach(([queryParamKey, arrValue]) => {
    const qKey = Object.keys(filterableColumns)[
      Object.values(filterableColumns).findIndex((x: string[]) =>
        x.includes(queryParamKey)
      )
    ];
    const keyName = `${qKey}[${qKey}.${queryParamKey}]`;

    result[keyName] = arrValue;
  });
  return result;
}

function JourneyFilter({
  onClick,
  selectedFilters,
  isActive,
  startDate,
  endDate,
  setFilterableColumns
}) {
  const [loading, setLoading] = React.useState(false);
  const [ownFilterableColumns, setOwnFilterableColumns] = React.useState({});
  const [filterOptions, setFilterOptions] = React.useState(null);
  const [selectedOptions, setSelectedOptions] = React.useState({});
  const [searchOption, setSearchOption] = React.useState({});

  async function fetchFilters() {
    try {
      setLoading(true);
      const q = qs.stringify(
        {
          ...convertObjKeysToSnakeCase({
            active: isActive ? Number(isActive) : null,
            start_date: startDate,
            end_date: endDate
          }),
          ...searchAndCreateFilterObj(ownFilterableColumns, searchOption)
        },
        {
          encode: false,
          skipNulls: true
        }
      );
      cancel && cancel();
      const res = await axios.request({
        method: "GET",
        url: `${window.location.origin}/journey_overview/filters.json?${q}`,
        headers: ReactOnRails.authenticityHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        }),
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        })
      });
      const response = res.data;

      if (response.data) {
        setOwnFilterableColumns(response.data.filterableColumns);
        setFilterableColumns(response.data.filterableColumns);
        setFilterOptions(response.data.searchableColumns);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    setSelectedOptions(selectedFilters);
  }, [selectedFilters]);

  React.useEffect(() => {
    fetchFilters();
  }, [searchOption, isActive, startDate, endDate]);

  return (
    <div>
      {filterOptions ? (
        <Tabs>
          <TabList>
            {Object.keys(filterOptions).map((name, index) => (
              <Tab key={`journeyfilterTab_${name}_${index}`}>
                {titleCase(name)}
                <span style={{ margin: "0 0.25em" }}>
                  {selectedOptions[name]
                    ? `(${selectedOptions[name].length})`
                    : null}
                </span>
              </Tab>
            ))}
          </TabList>
          {Object.keys(filterOptions).map((name, index) => (
            <TabPanel key={`journeyfilterTabPanel_${name}_${index}`}>
              <OptionsListRenderer
                data={filterOptions[name]}
                name={name}
                onOptionSelect={res =>
                  setSelectedOptions({ ...selectedOptions, [name]: res })
                }
                selectedOptions={selectedOptions}
                onSearch={setSearchOption}
                loading={loading}
              />
              <button
                className="btn btn-success"
                onClick={() => onClick(selectedOptions)}
              >
                Apply
              </button>
            </TabPanel>
          ))}
        </Tabs>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

function OptionsListRenderer({
  data,
  onOptionSelect,
  name,
  selectedOptions,
  onSearch,
  loading
}) {
  return (
    <div>
      <input
        type="search"
        onChange={e => onSearch({ [name]: e.target.value })}
        style={{ width: "100%" }}
        placeholder={`Search ${titleCase(name)}`}
      />
      {!loading ? (
        <div style={{ height: 300, overflowY: "auto", padding: "1em 0" }}>
          {data &&
            data.map((item, index) => {
              const selected = selectedOptions[name];
              const checked = selected && selected.includes(item.value);
              return (
                <label
                  style={{
                    display: "inline-flex",
                    height: "2em",
                    borderRadius: "1em",
                    padding: "0 0.5em",
                    background: checked ? "#03A9F4" : "#E2E2E2",
                    marginRight: "0.25em",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  key={`OptionsListRenderer_${item.value}_${index}`}
                >
                  <input
                    type="checkbox"
                    onChange={() => {
                      onOptionSelect(xor(selected, [item.value]));
                    }}
                    checked={checked}
                    style={{ display: "none" }}
                  />
                  {item.label.toUpperCase()}
                </label>
              );
            })}
        </div>
      ) : (
        <p>Loading ...</p>
      )}
    </div>
  );
}

export default JourneyFilter;
