import React from "react";
import { compose, withProps, withStateHandlers } from "recompose";
import { Marker, InfoWindow } from "react-google-maps";
import OverspeedInfoWindow from "./OverspeedInfoWindow";
import { overspeedMarker } from "../../../../../utils";

export default class OverspeedMarker extends React.Component {
  state = {
    isOpen: this.props.isOpen
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: !this.state.isOpen });
    }
  }

  _onToggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <Marker
        position={{
          lat: Number(this.props.info.latitude),
          lng: Number(this.props.info.longitude)
        }}
        icon={overspeedMarker}
        onClick={this._onToggleOpen}
      >
        {this.state.isOpen && (
          <InfoWindow onCloseClick={this._onToggleOpen}>
            <OverspeedInfoWindow
              info={this.props.info}
              isOpen={this.state.isOpen}
            />
          </InfoWindow>
        )}
      </Marker>
    );
  }
}
