import changeCaseObject from "change-case-object";
import ColorHash from "color-hash";
import BlackDotMarker from "images/react/mapMarkers/BlackDotMarker.png";
import GreyVehicleMarker from "images/react/mapMarkers/GreyVehicleMarker.png";
import IdleVehicleMarker from "images/react/mapMarkers/IdleVehicleMarker.png";
import MovingVehicleMarker from "images/react/mapMarkers/MovingVehicleMarker.png";
import OverspeedMarker from "images/react/mapMarkers/OverspeedMarker.png";
import StoppageMarker from "images/react/mapMarkers/StoppageMarker.png";
import StoppedVehicleMarker from "images/react/mapMarkers/StoppedVehicleMarker.png";
import VehicleMarker from "images/react/mapMarkers/VehicleMarker.png";
import _10TYRETRUCK from "images/react/vehicleType/10TYRETRUCK.png";
import _2AXLETRAILER from "images/react/vehicleType/2AXLETRAILER.png";
import _3AXLETRAILER from "images/react/vehicleType/3AXLETRAILER.png";
import CANTER from "images/react/vehicleType/CANTER.png";
import CARCARRIER from "images/react/vehicleType/CARCARRIER.png";
import CEMENT from "images/react/vehicleType/CEMENT.png";
import CONTAINER from "images/react/vehicleType/CONTAINER.png";
import CRANE from "images/react/vehicleType/CRANE.png";
import HYDRA from "images/react/vehicleType/HYDRA.png";
import HYDRAULICTRAILER from "images/react/vehicleType/HYDRAULICTRAILER.png";
import JEEP from "images/react/vehicleType/JEEP.png";
import LOWBED from "images/react/vehicleType/LOWBED.png";
import LOWBEDTRAILER from "images/react/vehicleType/LOWBEDTRAILER.png";
import PICKUPVAN from "images/react/vehicleType/PICKUPVAN.png";
import REFRIGERATED from "images/react/vehicleType/REFRIGERATED.png";
import SCOOTER from "images/react/vehicleType/SCOOTER.png";
import TANKER from "images/react/vehicleType/TANKER.png";
import TATA407 from "images/react/vehicleType/TATA407.png";
import TATA709 from "images/react/vehicleType/TATA709.png";
import TEMPO from "images/react/vehicleType/TEMPO.png";
import TIPPER from "images/react/vehicleType/TIPPER.png";
import TRAILER from "images/react/vehicleType/TRAILER.png";
import TRAILOR from "images/react/vehicleType/TRAILOR.png";
import TRUCK from "images/react/vehicleType/TRUCK.png";
import RAIL from "images/react/vehicleType/RAIL.png"

import snakeCase from "lodash/snakeCase";
import moment from "moment";
import prettyMs from "pretty-ms";
export const defaultVehicle = VehicleMarker;
export const blackDotMarker = BlackDotMarker;
export const dipperTruckMarker = VehicleMarker;
export const stoppageMarker = StoppageMarker;
export const overspeedMarker = OverspeedMarker;
export const trainIcon = RAIL;

export const iconByState = {
  idle: IdleVehicleMarker,
  moving: MovingVehicleMarker,
  stopped: StoppedVehicleMarker,
  none: GreyVehicleMarker
};

export const markerLabelColor = {
  idle: "yellow",
  stopped: "#FF5722",
  moving: "green",
  none: "grey"
};

export const statusColor = {
  Maintenance: "#3f51b5",
  Available: "#f44336",
  Unknown: "#9E9E9E",
  "At Primary Unloading Point": "#FFD54F",
  "Loading/Unloading": "#ffab00",
  "Booked/Unknown": "#607d8b",
  "At Loading City": "rgb(44,160,44)",
  Enroute: "rgb(31,119,180)",
  "At Primary Loading Point": "#8E24AA",
  "At Unloading City": "rgb(255,127,14)",
  Completed: "#F44336",
  Early: "rgb(136, 132, 216)",
  Delayed: "rgb(255, 87, 34)",
  Ontime: "rgb(130, 202, 157)"
};

export const googleMapUrl = `//maps.googleapis.com/maps/api/js?v=3.42&libraries=places&key=AIzaSyCK482B8_jLz2rUTdg8bWKAwjo7fo1z5vM`;

export const filterQueryParams = (obj, changeCase = "") => {
  Object.keys(obj).forEach(item => {
    if (obj[item] === null || obj[item] === undefined) {
      delete obj[item];
    }
  });
  const res = {
    ...obj
  };
  if (!changeCase) {
    return res;
  }
  if (changeCase === "camel") {
    return changeCaseObject.camelCase(res);
  }
  if (changeCase === "snake") {
    return changeCaseObject.snakeCase(res);
  }
  if (changeCase === "param") {
    return changeCaseObject.paramCase(res);
  }
  return res;
};
export const customMapIcon = opts => {
  return Object.assign(
    {
      path:
        "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0",
      scale: 1,
      strokeWeight: 0.2,
      strokeColor: "black",
      strokeOpacity: 0.5,
      fillColor: "#fff",
      fillOpacity: 0.9
    },
    opts
  );
};

export const customCircleMarker = option => {
  return Object.assign(
    {
      path:
        "M 50 10 A 40 40 0 1 0 50 90 A 40 40 0 1 0 50 10 Z M 50 30 A 20 20 0 1 1 50 70 A 20 20 0 1 1 50 30 Z",
      scale: 0.5,
      fillColor: "#FF0000",
      fillOpacity: 0.85,
      strokeWeight: 0,
      scale: 0.1
    },
    option
  );
};

export const downloadCSV = (csv, filename) => {
  var csvFile;
  var downloadLink;

  // CSV file
  csvFile = new Blob([csv], { type: "text/csv" });

  // Download link
  downloadLink = document.createElement("a");

  // File name
  downloadLink.download = filename;

  // Create a link to the file
  downloadLink.href = window.URL.createObjectURL(csvFile);

  // Hide download link
  downloadLink.style.display = "none";

  // Add the link to DOM
  document.body.appendChild(downloadLink);

  // Click download link
  downloadLink.click();
};

export const ranges = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
  "Last 7 Days": [moment().subtract(6, "days"), moment()],
  "Last 30 Days": [moment().subtract(29, "days"), moment()],
  "This Month": [moment().startOf("month"), moment().endOf("month")],
  "Last Month": [
    moment()
      .subtract(1, "month")
      .startOf("month"),
    moment()
      .subtract(1, "month")
      .endOf("month")
  ]
};

export const exportTableToCSV = (className, filename) => {
  var csv = [];
  var rows = document.querySelectorAll(`table.${className} tr`);
  for (var i = 0; i < rows.length; i++) {
    var row = [],
      cols = rows[i].querySelectorAll("td, th");

    for (var j = 0; j < cols.length; j++)
      row.push(cols[j].innerText.replace(/[ ]*,[ ]*|[ ]+/g, "-"));

    csv.push(row.join(","));
  }
  // Download CSV file
  downloadCSV(csv.join("\n"), filename);
};

export const formatDate = (obj, param, format) =>
  obj[param] ? moment.utc(new Date(obj[param])).format(format) : null;

export function toUTC(input, format) {
  return moment(input, format).toDate();
}

export const filterDateColumn = (filter, row, format) => {
  if (!filter.value.startDate) {
    return true;
  }
  if (row[filter.id]) {
    let selectedDate = moment(row[filter.id], format);
    let startDate = filter.value.startDate;
    let endDate = filter.value.endDate;
    return selectedDate >= startDate && selectedDate <= endDate;
  }
  return false;
};

export const calculateDuration = (start, end, format) => {
  return end && start
    ? moment
        .duration(
          (new Date(end).getTime() - new Date(start).getTime()) / 1000,
          "seconds"
        )
        .format(format)
    : "";
};

// Timeline utils
export function closest(numbers, goal) {
  return numbers.reduce(function(prev, curr) {
    return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
  });
}

export function utcDateTime(date) {
  return moment.utc(new Date(date));
}

export function formatTime(date, format = "DD-MM HH:mm") {
  return utcDateTime(date).format(format);
}

export function getNumberOfDays(day1, day2) {
  return utcDateTime(day2)
    .startOf("day")
    .diff(utcDateTime(day1).startOf("day"), "days");
}

export function getTimeDiff(day1, day2) {
  return new Date(day1) - new Date(day2);
}

export function addDays(inputDate, noOfDaysToAdd = 1, onlyStart) {
  const result = utcDateTime(utcDateTime(inputDate)).add(noOfDaysToAdd, "day");

  return onlyStart ? result.startOf("day") : result;
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function getMaxTime(timeArr) {
  const timeMillisecondsArr = timeArr.map(t =>
    isValidDate(new Date(t)) ? new Date(t).getTime() : 0
  );
  const indexOfMax = timeMillisecondsArr.indexOf(
    Math.max(...timeMillisecondsArr)
  );
  const result = timeArr[indexOfMax];
  return result;
}

export function compareEtaSta(eta, sta) {
  return new Date(eta).getTime() - new Date(sta).getTime() > 0;
}

export function momentify(input, format) {
  return moment.utc(input, format).toDate();
}

export function getStatus(day1, day2, hrs) {
  if (!hrs) return null;
  day1 = new Date(day1);
  day2 = new Date(day2);
  const diffInHrs = moment.utc(day2).diff(moment.utc(day1), "hours");
  const idealTime = moment.utc(day1).add(hrs, "hours");
  //Time taken / Ideal time
  const value = 1 - diffInHrs / hrs;

  return {
    label: getStatusName(value),
    value: diffInHrs,
    percentage: Math.abs(value) * 100,
    ideal: formatTime(idealTime, "DD-MM HH:mm"),
    text: `${getStatusName(value)} by ${prettyMs(
      Math.abs(new Date(day2) - new Date(idealTime))
    )} should reach by ${formatTime(idealTime, "DD-MM HH:mm")} `
  };
}

export function getStatusName(value) {
  if (value > 0.1) {
    return "Early";
  } else if (value < -0.1) {
    return "Delayed";
  }
  return "Ontime";
}

export function handleNullTime(time) {
  return time ? utcDateTime(time) : 0;
}

export function isBetween(n, a, b) {
  return (n - a) * (n - b) <= 0;
}

export function formatDuration(ms, config = {}) {
  return prettyMs(ms, config);
}

export function tommorow() {
  return moment
    .utc()
    .add(1, "days")
    .startOf("day");
}

export function today() {
  return moment.utc().startOf("day");
}

export function momentDate(date) {
  return moment.utc(new Date(date));
}

export function momentDiff(day1, day2) {
  return moment.utc(day1) - moment.utc(day2);
}

export function generateStringColor(str) {
  const colorHash = new ColorHash({
    hue: {
      min: 90,
      max: 270
    },
    lightness: 0.5
  });
  return colorHash.hex(str);
}

export const INDENT_PIE_COLORS = {
  "Tnsprt Confirmed": "#43A047",
  Cancelled: "#FF6E40",
  Pending: "#FFCA28",
  "Vehicle Reported": "#80D8FF",
  Future: "#7E57C2",
  Current: "#43A047"
};

export const fullScreenSkyLight = {
  width: "100%",
  height: "100%",
  overflow: "auto",
  marginTop: "0px",
  marginLeft: "0px",
  top: "5%",
  left: "0"
};

const iconsByVehicleSubtype = {
  "10TYRE TRUCK": _10TYRETRUCK,
  "2AXLETRAILER": _2AXLETRAILER,
  "3AXLETRAILER": _3AXLETRAILER,
  BULKER: "",
  CANTER,
  CARCARRIER,
  CEMENT,
  CHAMPION: "",
  CONTAINER,
  CRANE,
  DUMMY: "",
  FLATBED: "",
  FULLBODY: "",
  HALFBODY: "",
  HYDRA,
  HYDRAULICTRAILER,
  JEEP,
  LONGBED: "",
  LOWBED,
  LOWBEDTRAILER,
  OPEN: "",
  OPENTRUCK: "",
  PICKUPVAN,
  PLATFORM: "",
  REFRIGERATED,
  SCOOTER,
  SHORTBED: "",
  SILO: "",
  TANKER,
  TATA407,
  TATA709: TATA709,
  TATAACE: "",
  TEMPO,
  TIPPER,
  TRAILER,
  TRAILOR,
  TRUCK,
  TURBO: ""
};

export const getIconByVehicleType = vehicleType => {
  vehicleType = vehicleType.replace(/\s/g, "").toUpperCase();
  return iconsByVehicleSubtype[vehicleType] || null;
};

export const addClassName = (element, className) => {
  if (element && !element.classList.contains(className)) {
    element.classList.add(className);
  }
};

export const removeClassName = (element, className) => {
  if (element && element.classList.contains(className)) {
    element.classList.remove(className);
  }
};

export function convertObjKeysToSnakeCase(selectedFilters) {
  const result = Object.keys(selectedFilters).reduce((destination, key) => {
    destination[snakeCase(key)] = selectedFilters[key];
    return destination;
  }, {});
  return result;
}

export const bigModal = {
  width: "100%",
  height: "calc(100% - 45px)",
  overflow: "auto",
  marginTop: "0px",
  marginLeft: "0px",
  top: "45px",
  left: "0"
};

export function avgOfNumbers(arr) {
  const result = arr.reduce((p, c) => p + c, 0) / arr.length;
  return Number(result.toFixed(2));
}
