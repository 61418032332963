import React from 'react'
import Draw from '../Leaflet/Draw'

const AddPoi = props => (
  <Draw
    {...props}
    apiUrl={`/${props.userRole.toLowerCase()}/pois/new_geofence.json`}
  />
)

export default AddPoi
