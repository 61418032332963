import PoiFlagMarker from "images/react/mapMarkers/PoiFlagMarker";
import React from "react";
import { Circle, InfoWindow, Marker } from "react-google-maps";

function PoiCircle({ pois }) {
  return (
    <React.Fragment>
      {pois &&
        pois.map((poi, index) => (
          <RenderPoi poi={poi} key={`poiCircle_${index}`} />
        ))}
    </React.Fragment>
  );
}

function RenderPoi({ poi }) {
  const [isOpen, setIsOpen] = React.useState(false);

  function onToggleOpen() {
    setIsOpen(!isOpen);
  }
  return (
    <React.Fragment>
      <Circle
        center={{
          lat: Number(poi.latitude),
          lng: Number(poi.longitude)
        }}
        radius={Number(poi.radius) * 1000}
        options={{
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35
        }}
        onClick={() => onToggleOpen()}
      />
      <Marker
        position={{
          lat: Number(poi.latitude),
          lng: Number(poi.longitude)
        }}
        icon={PoiFlagMarker}
        opacity={0.25}
        onClick={onToggleOpen}
      >
        {isOpen && (
          <InfoWindow onCloseClick={onToggleOpen}>
            <div>
              <strong>POI Name: </strong>
              {poi.poi_act_name || "..."}
              <br />
              <strong>Radius: </strong>
              {poi.radius} Km <br />
              <strong>Type: </strong>
              {poi.poi_type}
            </div>
          </InfoWindow>
        )}
      </Marker>
    </React.Fragment>
  );
}

export default PoiCircle;
