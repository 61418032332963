import _ from "lodash";
import React from "react";
import { iconByState } from "../../../../utils";
import { MapBtn } from "../../components/layouts/Grid";

export const MapFilters = ({
  filters,
  selectedFilter,
  onMapFilterOptionsClick,
}) => {
  const {
    statuses,
    states,
    tripStatuses,
    tripStates,
    vehicleStatuses,
    regions,
    materials,
    engineStates
  } = filters;
  const materialObj = {};
  Object.keys(materials).map((key) => {
    const tempSplit = key.split(",");
    tempSplit.map((item) => {
      materialObj[item] = materialObj[item]
        ? materialObj[item] + materials[key]
        : materials[key];
    });
  });

  return (
    <div className="map_all_filters" style={{ zIndex: 1 }}>
      {!_.isEmpty(vehicleStatuses) && (
        <React.Fragment>
          <MapBtn
            position="relative"
            marginTop="20px"
            top="0"
            right="0"
            onClick={() => onMapFilterOptionsClick({ vehicleStatus: null })}
          >
            Show All
          </MapBtn>
          {Object.entries(vehicleStatuses).map(
            ([vehicleStatus, vehicleStatusCount], index) => {
              if (vehicleStatus === "null") return null;
              return (
                <MapBtn
                  key={vehicleStatus + "_" + index}
                  position="relative"
                  right="0"
                  top="0"
                  border={
                    selectedFilter.vehicleStatus === vehicleStatus
                      ? "2px solid #E55B25"
                      : "#fff"
                  }
                  marginTop="10px"
                  onClick={() => onMapFilterOptionsClick({ vehicleStatus })}
                >
                  {vehicleStatus} : {vehicleStatusCount}
                </MapBtn>
              );
            }
          )}
        </React.Fragment>
      )}
      {!_.isEmpty(regions) && (
        <React.Fragment>
          <MapBtn
            position="relative"
            marginTop="30px"
            top="0"
            right="0"
            onClick={() => onMapFilterOptionsClick({ region: null })}
          >
            Show All Regions
          </MapBtn>
          {Object.entries(regions)
            .sort()
            .map(([region, regionCount], index) => {
              if (region === "null" || region === "undefined") return;
              return (
                <MapBtn
                  key={region + "_" + index}
                  position="relative"
                  right="0"
                  top="0"
                  marginTop="10px"
                  border={
                    selectedFilter.region === region
                      ? "2px solid #E55B25"
                      : "#fff"
                  }
                  onClick={() => onMapFilterOptionsClick({ region })}
                >
                  {region} : {regionCount}
                </MapBtn>
              );
            })}
        </React.Fragment>
      )}
      {!_.isEmpty(materialObj) && (
        <React.Fragment>
          <MapBtn
            position="relative"
            marginTop="30px"
            top="0"
            right="0"
            onClick={() => onMapFilterOptionsClick({ material: null })}
          >
            Show All Materials
          </MapBtn>
          {Object.entries(materialObj).map(
            ([material, materialCount], index) => {
              if (material === "null" || material === "undefined") return;
              return (
                <MapBtn
                  key={material + "_" + index}
                  position="relative"
                  right="0"
                  top="0"
                  marginTop="10px"
                  border={
                    selectedFilter.material === material
                      ? "2px solid #E55B25"
                      : "#fff"
                  }
                  onClick={() => onMapFilterOptionsClick({ material })}
                >
                  {material} : {materialCount}
                </MapBtn>
              );
            }
          )}
        </React.Fragment>
      )}
      {statuses && (
        <React.Fragment>
          <MapBtn
            position="relative"
            marginTop="50px"
            top="0"
            right="0"
            onClick={() => onMapFilterOptionsClick({ gpsStatus: null })}
          >
            Show All Vehicles
          </MapBtn>
          {Object.keys(statuses).map((gpsStatus, index) => (
            <MapBtn
              key={index}
              position="relative"
              right="0"
              top="0"
              marginTop="10px"
              border={
                selectedFilter.gpsStatus === gpsStatus
                  ? "2px solid #E55B25"
                  : "#fff"
              }
              onClick={() => onMapFilterOptionsClick({ gpsStatus })}
            >
              <img
                src={
                  iconByState[gpsStatus]
                    ? iconByState[gpsStatus]
                    : iconByState.none
                }
                style={{ height: "30px" }}
              />{" "}
              {gpsStatus} : {statuses[gpsStatus]}
            </MapBtn>
          ))}
        </React.Fragment>
      )}
      {!_.isEmpty(tripStatuses) && (
        <React.Fragment>
          <MapBtn
            position="relative"
            marginTop="30px"
            top="0"
            right="0"
            onClick={() => onMapFilterOptionsClick({ tripStatus: null })}
          >
            Clear Trip Status
          </MapBtn>
          {Object.entries(tripStatuses).map(
            ([tripStatus, tripStatusCount], index) => {
              if (tripStatus === "null") return;
              return (
                <MapBtn
                  key={tripStatus + "_" + index}
                  position="relative"
                  right="0"
                  top="0"
                  marginTop="10px"
                  border={
                    selectedFilter.tripStatus === tripStatus
                      ? "2px solid #E55B25"
                      : "#fff"
                  }
                  onClick={() => onMapFilterOptionsClick({ tripStatus })}
                >
                  {tripStatus} : {tripStatusCount}
                </MapBtn>
              );
            }
          )}
        </React.Fragment>
      )}
      {!_.isEmpty(tripStates) && (
        <React.Fragment>
          <MapBtn
            position="relative"
            marginTop="30px"
            top="0"
            right="0"
            onClick={() => onMapFilterOptionsClick({ tripState: null })}
          >
            Clear Trip State
          </MapBtn>
          {Object.entries(tripStates).map(
            ([tripState, tripStateCount], index) => {
              if (tripState === "null") return;
              return (
                <MapBtn
                  key={tripState + "_" + index}
                  position="relative"
                  right="0"
                  top="0"
                  border={
                    selectedFilter.tripStates === tripState
                      ? "2px solid #E55B25"
                      : "#fff"
                  }
                  marginTop="10px"
                  onClick={() => onMapFilterOptionsClick({ tripState })}
                >
                  {tripState} : {tripStateCount}
                </MapBtn>
              );
            }
          )}
        </React.Fragment>
      )}
      {!_.isEmpty(engineStates) && (
        <React.Fragment>
          <MapBtn
            position="relative"
            marginTop="30px"
            top="0"
            right="0"
            onClick={() => onMapFilterOptionsClick({ engineState: null })}
          >
            Clear Engine State
          </MapBtn>
          {Object.entries(engineStates).map(
            ([engineState, tripStateCount], index) => {
              if (engineState === "null") return;
              return (
                <MapBtn
                  key={engineState + "_" + index}
                  position="relative"
                  right="0"
                  top="0"
                  border={
                    selectedFilter.engineStates === engineState
                      ? "2px solid #E55B25"
                      : "#fff"
                  }
                  marginTop="10px"
                  onClick={() => onMapFilterOptionsClick({ engineState })}
                >
                  {engineState} : {tripStateCount}
                </MapBtn>
              );
            }
          )}
        </React.Fragment>
      )}
    </div>
  );
};
