import axios from "axios";
import {
  countBy,
  flatten,
  isEqual,
  isNil,
  omitBy,
  pick,
  uniq,
  xor,
} from "lodash";
import qs from "query-string";
import React from "react";
import { DebounceInput } from "react-debounce-input";
import ReactOnRails from "react-on-rails";
import { SkyLightStateless } from "react-skylight";
import BarLoader from "react-spinners/BarLoader";
import { exportTableToCSV, googleMapUrl } from "../../../../utils";
import JourneyFilter, {
  searchAndCreateFilterObj,
} from "../JourneyOverview/JourneyFilter";
import { MapFilters } from "../MapAllVehicles/MapFilters";
import { MapRenderer } from "../MapAllVehicles/MapRenderer";
import MapSidebar from "../MapAllVehicles/MapSidebar";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import styled from "@emotion/styled";

const MapAllJourneysContainer = styled.div`
  display: grid;
  height: inherit;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 50px 1fr;
  grid-template-areas: "a b c d" "x y z v" "e f f f";
  @media screen and (max-width: 425px) {
    grid-template-areas: "a a b b" "x x x x" "f f f f" "e e e e";
  }
`;

const DateFilter = styled.div`
  grid-area: x;
  display: flex;
  gap: 1em;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    margin: 1em 0;

    button {
      width: 100%;
    }
  }
`;

const CancelToken = axios.CancelToken;
let cancel;

function isValidForCount(vehicles, str) {
  const res =
    uniq(vehicles.map((v) => v.filter[str])).filter((x) => !!x).length > 0;
  return res;
}

function MapAllJourneyVehicles(props: any) {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [showFilterModalContent, setShowFilterModalContent] = React.useState(
    false
  );
  const [queryParams, setQueryParams] = React.useState<any>({
    active: 1,
  });
  const [filterableColumns, setFilterableColumns] = React.useState(null);
  const [selectedFilters, setSelectedFilters] = React.useState({});
  const [vehicleTrackingData, setVehicleTrackingData] = React.useState<any>(
    null
  );
  const [vehicles, setVehicles] = React.useState<any>(null);
  const [sidebarVehicles, setSidebarVehicles] = React.useState<any>(null);
  const [userRole, setUserRole] = React.useState("Consigner");
  const [pois, setPois] = React.useState<any>(null);
  const [showActive, setShowActive] = React.useState(true);
  const [range, setRange] = React.useState({
    startDate: moment().subtract(7, "days"),
    endDate: moment(),
  });
  const [selectedVehicles, setSelectedVehicles] = React.useState<any>([]);
  const [mapFilters, setMapFilters] = React.useState<any>({
    statuses: [],
    states: [],
    tripStates: [],
    vehicleStatuses: [],
    tripStatuses: [],
    regions: [],
    materials: [],
    engineStates: [],
  });

  const [filter, setFilter] = React.useState({
    gpsStatus: null,
    vehicleStatus: null,
    tripState: null,
    tripStatus: null,
    engineState: null,
  });

  const [searchItem, setSearchItem] = React.useState("");

  async function fetchJourneyData() {
    try {
      setLoading(true);
      const basePath = `${window.location.pathname}_data.json`;
      let qp = queryParams;
      if (!showActive) {
        qp = {
          ...queryParams,
          start_date: range.startDate.format("YYYY-MM-DD"),
          end_date: range.endDate.format("YYYY-MM-DD"),
        };
      }
      const url = basePath + "?" + qs.stringify(qp, { arrayFormat: "bracket" });
      const response = await axios.request({
        url,
        method: "GET",
        headers: ReactOnRails.authenticityHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        }),
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      });
      if (response.status === 200) {
        const vehicles = response.data.vehicles;
        response.data.vehicles;
        response.data.vehicles;
        setVehicleTrackingData(vehicles);
        setVehicles(vehicles);
        setSidebarVehicles(vehicles);
        setMapFilters({
          statuses: isValidForCount(vehicles, "gpsStatus")
            ? countBy(vehicles, (x) => x.filter.gpsStatus)
            : [],
          engineStates: isValidForCount(vehicles, "engineState")
            ? countBy(vehicles, (x) => x.filter.engineState)
            : [],
          states: [],
          tripStates: isValidForCount(vehicles, "tripState")
            ? countBy(vehicles, (x) => x.filter.tripState)
            : [],
          tripStatuses: isValidForCount(vehicles, "tripStatus")
            ? countBy(vehicles, (x) => x.filter.tripStatus)
            : [],
          vehicleStatuses: countBy(vehicles, (x) => x.filter.vehicleStatus),
          regions: isValidForCount(vehicles, "region")
            ? countBy(vehicles, (x) => x.filter.region)
            : [],
          materials: isValidForCount(vehicles, "material")
            ? countBy(vehicles, (x) => x.filter.material)
            : [],
        });
        setUserRole(response.data.user_role);
        setPois(response.data.pois);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  }

  const start = range.startDate.format("YYYY-MM-DD");
  const end = range.endDate.format("YYYY-MM-DD");
  const label = start + " - " + end;

  React.useEffect(() => {
    fetchJourneyData();
  }, [queryParams, label]);

  React.useEffect(() => {
    if (showActive) {
      fetchJourneyData();
    }
  }, [showActive]);

  function onJourneyFilter(selectedFilters) {
    setSelectedFilters(selectedFilters);
    setShowFilterModalContent(false);
    const queryParams = searchAndCreateFilterObj(
      filterableColumns,
      selectedFilters
    );
    setQueryParams({ active: 1, ...queryParams });
  }

  function onVehicleSelect(selectedVehicle) {
    const svs = xor(selectedVehicles, [selectedVehicle]);
    setSelectedVehicles(svs);
  }

  function onMapFilterOptionsClick(opt = {}) {
    setFilter({ ...filter, ...opt });
  }

  function applyFilter() {
    let vh = vehicleTrackingData;
    let updateMap = true;
    if (Object.values(filter).filter((x) => x).length) {
      vh = vh.filter((vehicle) => {
        const nonEmptySelectedFilter = omitBy(filter, isNil);
        const nonEmptyVehicleStatuses = pick(
          vehicle.filter,
          Object.keys(nonEmptySelectedFilter)
        );
        let isSame = false;
        if (
          Object.keys(nonEmptyVehicleStatuses).length === 0 &&
          isEqual(nonEmptySelectedFilter, nonEmptyVehicleStatuses)
        ) {
          return true;
        }
        Object.entries(nonEmptySelectedFilter).forEach(([key, value]) => {
          if (nonEmptyVehicleStatuses[key]) {
            isSame = nonEmptyVehicleStatuses[key].includes(value);
          }
        });
        return isSame;
      });
    }
    if (searchItem) {
      vh = vh.filter((vehicle) => {
        const { consigner_trip, location } = vehicle;
        let arr = consigner_trip
          ? Object.values(consigner_trip)
          : Object.values(location);
        return arr.some((el) => {
          return String(el)
            .toLowerCase()
            .includes(searchItem.toLowerCase());
        });
      });
      updateMap = false;
    }
    if (selectedVehicles && selectedVehicles.length) {
      vh = vh.filter((vehicle) =>
        selectedVehicles.includes(vehicle.vehicle_no)
      );
    }
    populateStatus(vh, updateMap);
  }

  function populateStatus(vehicles, updateMap) {
    setSidebarVehicles(vehicles);
    if (updateMap) {
      setVehicles(vehicles);
    }
  }

  React.useEffect(() => {
    if (vehicleTrackingData) {
      applyFilter();
    }
  }, [filter, searchItem, selectedVehicles]);

  return (
    <div
      style={{
        marginTop: "-20px",
        height: "calc(100vh - 44px)",
        padding: "1em",
      }}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "calc(100vh - 44px)",
          }}
        >
          <BarLoader
            sizeUnit={"px"}
            size={150}
            color={"#123abc"}
            loading={loading}
          />
        </div>
      ) : (
        <React.Fragment>
          <MapAllJourneysContainer>
            <div style={{ gridArea: "a" }}>
              <h3 style={{ color: "#E55B25" }}>Map All Vehicles</h3>
            </div>
            <div style={{ gridArea: "b" }}>
              <button
                type="button"
                className="btn btn-info"
                onClick={() =>
                  setShowFilterModalContent(!showFilterModalContent)
                }
                style={{ margin: "0 1em", height: "40px", width: "130px" }}
              >
                Select Filters
              </button>
              {uniq(flatten(Object.values(selectedFilters))).length > 0 && (
                <button
                  className="btn btn-secondary"
                  onClick={() => onJourneyFilter({})}
                  style={{ marginLeft: "1.5em" }}
                >
                  Clear
                </button>
              )}
            </div>
            <DateFilter>
              <div>
                <button
                  className={`btn btn-${showActive ? "info" : "warning"}`}
                  onClick={() => {
                    setShowActive(!showActive);
                  }}
                  style={{ margin: "1em 0" }}
                >
                  {showActive ? "Enable Date Filter" : "Disable Date Filter"}
                </button>
              </div>
              {!showActive ? (
                <div>
                  <DateRangePicker
                    startDate={range.startDate}
                    endDate={range.endDate}
                    ranges={{
                      Today: [moment(), moment()],
                      Yesterday: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                      ],
                      "Last 7 Days": [moment().subtract(6, "days"), moment()],
                      "Last 30 Days": [moment().subtract(29, "days"), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "Last Month": [
                        moment()
                          .subtract(1, "month")
                          .startOf("month"),
                        moment()
                          .subtract(1, "month")
                          .endOf("month"),
                      ],
                    }}
                    onApply={(_, picker) => {
                      setRange({
                        startDate: picker.startDate,
                        endDate: picker.endDate,
                      });
                    }}
                  >
                    <button
                      className="selected-date-range-btn"
                      style={{ width: "100%", height: "37px" }}
                    >
                      <div className="pull-left">
                        <i className="fa fa-calendar" />
                      </div>
                      <div className="pull-right">
                        <span>{label}</span>
                        <span className="caret" />
                      </div>
                    </button>
                  </DateRangePicker>
                </div>
              ) : null}
            </DateFilter>
            <div style={{ gridArea: "e", height: "calc(100vh - 50px)" }}>
              <div style={{ display: "flex" }}>
                <DebounceInput
                  type="search"
                  className="form-control form-control-sm"
                  name="search"
                  placeholder="Search vehicles"
                  minLength={3}
                  debounceTimeout={500}
                  onChange={(event) => setSearchItem(event.target.value)}
                />
                <button
                  className="btn btn-sm btn-info fa fa-file-excel-o"
                  onClick={() =>
                    exportTableToCSV(
                      "tableMapAllVehicles",
                      `MapAll_${new Date().toJSON().slice(0, 10)}.csv`
                    )
                  }
                >
                  &nbsp;Download
                </button>
              </div>
              <div style={{ height: "80vh", overflowY: "auto" }}>
                {sidebarVehicles && (
                  <MapSidebar
                    vehicles={sidebarVehicles}
                    onSelect={onVehicleSelect}
                    selectedVehicles={selectedVehicles}
                    userRole={userRole}
                    isInplant={false}
                  />
                )}
              </div>
            </div>
            <div style={{ gridArea: "f", height: "calc(100vh - 50px)", position: "relative" }}>
              {vehicles && (
                <>
                  <details
                    className="map-filter"
                    style={{
                      position: "absolute",
                      right: 0,
                      top: "3em",
                      paddingRight: 0,
                      maxWidth: "175px",
                      maxHeight: "75vh",
                      overflowY: "auto",
                      zIndex: 1,
                    }}
                  >
                    <summary
                      style={{ position: "relative", marginRight: "2em" }}
                    >
                      Filters
                    </summary>
                    <div style={{ position: "relative" }}>
                      <MapFilters
                        filters={mapFilters}
                        selectedFilter={filter}
                        onMapFilterOptionsClick={onMapFilterOptionsClick}
                      />
                    </div>
                  </details>
                  <MapRenderer
                    googleMapURL={googleMapUrl}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `88vh` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    vehicles={vehicles}
                    pointPOIs={undefined}
                    clustering={true}
                    inplant={false}
                    pois={pois}
                    polygonPOIs={undefined}
                  />
                </>
              )}
            </div>
          </MapAllJourneysContainer>
          <hr />
          <SkyLightStateless
            isVisible={showFilterModalContent}
            onCloseClicked={() =>
              setShowFilterModalContent(!showFilterModalContent)
            }
            title=""
          >
            <React.Fragment>
              <h5 style={{ color: "#E55B25" }}>Select Filters</h5>
              <hr />
              <JourneyFilter
                startDate={null}
                endDate={null}
                selectedFilters={selectedFilters}
                setFilterableColumns={setFilterableColumns}
                onClick={onJourneyFilter}
                isActive={true}
              />
            </React.Fragment>
          </SkyLightStateless>
        </React.Fragment>
      )}
    </div>
  );
}

export default (props) => <MapAllJourneyVehicles {...props} />;
