import axios from "axios";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import snakeCase from "lodash/snakeCase";
import moment from "moment";
import qs from "qs";
import RandomMC from "random-material-color";
import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ContainerDimensions from "react-container-dimensions";
import InfiniteScroll from "react-infinite-scroll-component";
import RSelect from "react-select";
import titleCase from "title-case";
import { filterQueryParams } from "../../../../utils";
import { DebounceInput } from "react-debounce-input";

import {
  CountContainer,
  FilterButton,
  FilterContainer,
  JourneyCard,
  PageContainer,
  ShipmentCard,
  TopLevelFilters,
} from "./../../components/Shipment";

function generateObjKey(input, type) {
  const [first, second] = input.split("_");
  return `${first}[${snakeCase(second)}.${snakeCase(type)}]`;
}

const CancelToken = axios.CancelToken;
let cancel;

function CardTimeline() {
  const [searchValue, setSearchValue] = React.useState("");
  const [cardData, setCardData] = React.useState([]);
  const [pageMeta, setPageMeta] = React.useState({
    pageNumber: 1,
    perPage: 10,
  });
  const [orderDirection, setOrderDirection] = React.useState("desc");
  const [selectedColumn, setSelectedColumn] = React.useState(null);
  const [queryParams, setQueryParams] = React.useState(null);
  const [selectedLabel, setSelectedLabel] = React.useState({});
  const [mergeData, setMergeData] = React.useState(false);
  const [active, setActive] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [aggregations, setAggregations] = React.useState(null);
  const [selectBoxSelected, setSelectBoxSelected] = React.useState(false);
  const [searchableColumns, setSearchableColumns] = React.useState([]);
  const [filterableColumns, setFilterableColumns] = React.useState({});
  const [searchable, setSearchable] = React.useState("");
  const searchText = React.useRef();
  const [dateRange, setDateRange] = React.useState({
    startDate: moment(),
    endDate: moment(),
    start_date: null,
    end_date: null,
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment()
          .subtract(1, "month")
          .startOf("month"),
        moment()
          .subtract(1, "month")
          .endOf("month"),
      ],
    },
  });

  const [selectedBtnLabel, setSelectedBtnLabel] = React.useState(
    "Select date range"
  );

  function dateRangeHandler(event, picker) {
    const isActiveSelected = picker.chosenLabel === "Active Trips";
    setSelectedBtnLabel(picker.chosenLabel);
    setLoading(true);
    setDateRange({
      ...dateRange,
      start_date: picker.startDate.isValid()
        ? picker.startDate.format("YYYY-MM-DD")
        : null,
      end_date: picker.endDate.isValid()
        ? picker.endDate.format("YYYY-MM-DD")
        : null,
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
  }

  const { startDate, endDate, start_date, end_date, ranges } = dateRange;

  function fetchNext() {
    setMergeData(true);
    setPageMeta({ ...pageMeta, pageNumber: Number(pageMeta.pageNumber) + 1 });
  }

  function fetchData() {
    try {
      setSelectBoxSelected(false);
      let url = window.location.origin + window.location.pathname + "/listing";
      const { perPage, pageNumber } = pageMeta;
      let qObj = {
        perPage,
        pageNumber: Number(pageNumber),
        active: Number(active),
        startDate: start_date,
        endDate: end_date,
      };
      if (!window.location.pathname.includes("shipment")) {
        qObj = {
          ...qObj,
          order_by: "status_update_timestamp",
          orderDirection,
        };
      }
      if (!active) {
        delete qObj["active"];
      }
      qObj = filterQueryParams(qObj, "snake");
      if (Object.keys(selectedLabel).length) {
        qObj = { ...qObj, ...selectedLabel };
      }
      if (queryParams) {
        const queryParamKey = Object.keys(queryParams)[0];
        const qKey = Object.keys(filterableColumns)[
          Object.values(filterableColumns).findIndex((x) =>
            x.includes(queryParamKey)
          )
        ];
        const keyName = `${qKey}[${qKey}.${queryParamKey}]`;

        qObj = {
          ...qObj,
          [keyName]: Object.values(queryParams)[0],
        };
      }
      let q = qs.stringify(qObj, {
        encode: false,
        skipNulls: true,
        arrayFormat: "bracket",
      });
      const prevParams = window.location.search.substr(1);
      if (prevParams) {
        q += `&${prevParams}`;
      }
      url = q ? url + `.json?${q}` : url + ".json";
      getResultFromApi(url);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function getResultFromApi(url) {
    try {
      cancel && cancel();
      const res = await axios.request({
        url,
        method: "GET",
        headers: ReactOnRails.authenticityHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Key-Inflection": "camel",
        }),
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      });
      const response = res.data;
      if (response) {
        const {
          data,
          meta,
          aggregations,
          searchableColumns,
          filterableColumns,
          searchable,
        } = response;
        setCardData(!mergeData ? data : [...cardData, ...data]);
        setPageMeta({ ...meta, pageNumber: Number(meta.pageNumber) });
        setAggregations(aggregations);
        setSearchableColumns(searchableColumns);
        setFilterableColumns(filterableColumns);
        setSearchable(searchable);
      }
      setMergeData(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  function selectLabel({ key, type }) {
    const objKey = generateObjKey(searchable, type);
    const clear = isEqual(selectedLabel, {
      [objKey]: key,
    });
    setMergeData(false);
    setPageMeta({
      ...pageMeta,
      pageNumber: 1,
    });
    setSelectedLabel(!clear ? { [objKey]: key } : {});
    setLoading(true);
  }

  function selectColumn(column) {
    setSelectedColumn(column);
    setQueryParams(column);
    setSearchValue("");
    if (column && column.value) {
      setQueryParams(null);
    }
  }

  function search(value) {
    setSearchValue(value);
    setLoading(true);
    setPageMeta({ ...pageMeta, pageNumber: 1 });
    setQueryParams(value ? { [selectedColumn.value]: value } : null);
  }

  function onActiveSelect(e) {
    setActive(e.target.checked);
    setLoading(true);
    setUpdateCount(true);
  }

  React.useEffect(() => {
    if (!selectBoxSelected) {
      fetchData();
    }
  }, [
    loading,
    pageMeta.pageNumber,
    queryParams,
    searchValue,
    start_date,
    end_date,
    orderDirection,
  ]);

  if (!searchable) {
    return "Loading ...";
  }

  const journeyOrShipment = searchable.split("_")[0].toLowerCase();
  const { pageNumber, pages } = pageMeta;
  return (
    <PageContainer>
      <TopLevelFilters>
        <CountContainer>
          {aggregations &&
            Object.keys(aggregations).map((type, index) => {
              const agg = aggregations[type];
              return (
                <div
                  className="agg_by"
                  key={`aggBy_${index}`}
                  style={{
                    display: "flex",
                    justifyContent: index === 1 ? "flex-end" : "flex-start",
                  }}
                >
                  {Object.values(agg).map(({ label, key, val }, optIndex) => {
                    const objKey = generateObjKey(searchable, type);
                    let isActive = selectedLabel[objKey] + 1 === key + 1;
                    return (
                      <FilterButton
                        isActive={isActive}
                        key={`aggBy_${index}_${optIndex}`}
                        onClick={() => selectLabel({ type, key, val })}
                      >
                        <div
                          style={{
                            color: RandomMC.getColor({
                              text: titleCase(label),
                            }),
                            fontSize: "18px",
                          }}
                        >
                          {val}
                        </div>
                        <div style={{ fontSize: "12px" }}>
                          {titleCase(label)}
                        </div>
                      </FilterButton>
                    );
                  })}
                </div>
              );
            })}
        </CountContainer>
        <FilterContainer>
          <div>Search</div>
          <div>
            <RSelect
              options={searchableColumns}
              value={selectedColumn}
              placeholder="Select column"
              name="select-columns"
              onChange={selectColumn}
            />
          </div>
          <div>
            <DebounceInput
              minLength={3}
              debounceTimeout={1000}
              onChange={(event) => search(event.target.value)}
              disabled={!selectedColumn}
              ref={searchText}
            />
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                value={active}
                checked={active}
                onChange={(e) => onActiveSelect(e)}
                style={{ marginRight: "0.5em" }}
              />
              Show active trips
            </label>
          </div>
          <div>
            {!journeyOrShipment.includes("shipment") ? (
              <select
                onChange={(e) => {
                  setPageMeta({
                    ...pageMeta,
                    pageNumber: 1,
                  });
                  setOrderDirection(e.target.value);
                }}
                value={orderDirection}
              >
                <option value="asc">Ping : ASC</option>
                <option value="desc">Ping: DESC</option>
              </select>
            ) : null}
          </div>
          <div>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              ranges={ranges}
              maxDate={moment()}
              onApply={dateRangeHandler}
            >
              <button
                className="selected-date-range-btn"
                style={{ width: "100%", height: "37px" }}
              >
                <div className="pull-left">
                  <i className="fa fa-calendar" />
                </div>
                <div className="pull-right">
                  <span>{selectedBtnLabel}</span>
                  <span className="caret" />
                </div>
              </button>
            </DateRangePicker>
          </div>
        </FilterContainer>
      </TopLevelFilters>
      <div style={{ padding: "0 1em" }}>
        {loading && "Loading ..."}
        <>
          {!isEmpty(cardData) ? (
            <InfiniteScroll
              dataLength={cardData.length} //This is important field to render the next cardData
              next={fetchNext}
              hasMore={pageNumber < pages}
              loader={
                <p style={{ textAlign: "center" }}>
                  <b>Loading...</b>
                </p>
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Complete data loaded</b>
                </p>
              }
            >
              <div style={{ display: "grid", gridRowGap: "0.5em" }}>
                <ContainerDimensions>
                  {({ width }) => (
                    <React.Fragment>
                      {!isEmpty(cardData) &&
                        cardData.map((item, index) => (
                          <React.Fragment key={`${item.lr_no}_${index}`}>
                            {journeyOrShipment.includes("shipments") ? (
                              <ShipmentCard
                                shipment={item}
                                dimension={{ width: width - 32 }}
                              />
                            ) : (
                              <JourneyCard
                                journey={item}
                                dimension={{ width: width - 32 }}
                              />
                            )}
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  )}
                </ContainerDimensions>
              </div>
            </InfiniteScroll>
          ) : (
            <div>No data found.</div>
          )}
        </>
      </div>
    </PageContainer>
  );
}

export default (props) => <CardTimeline {...props} />;
