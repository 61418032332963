import React from "react";

const generateLink = (role, id) => {
  switch (role.toLowerCase()) {
    case "trucker":
      return `/gps/history_track/truck_history/${id}`;
    case "shipper":
      return `/shipper/geo_trips/${id}/consigner_trip_trucks_history`;
    case "consigner":
      return `/gps/history_track/trip_history/${id}`;
    default:
      return `/gps/history_track/trip_history/${id}`;
  }
};

const Link = ({ role, id, isInplant }) => {
  const link = !isInplant
    ? generateLink(role, id)
    : `/consigner/inplant_track/${id}/trip_trucks_history`;
  return (
    <a className="btn btn-sm" href={link}>
      <i
        className="fa fa-history fa-2x"
        data-toggle="tooltip"
        data-placement="bottom"
        title=""
        data-original-title="Click to view Vehicle history"
      />
    </a>
  );
};

const JourneyLink = ({ slug }) => {
  return (
    <a className="btn btn-sm" href={`/gps/history_track/journey_history/${slug}`}>
      <i
        className="fa fa-history fa-2x"
        data-toggle="tooltip"
        data-placement="bottom"
        title=""
        data-original-title="Click to view Vehicle history"
      />
    </a>
  );
};

function SidebarCheckbox({ index, vehicle, onChange }) {
  const [checked, setChecked] = React.useState(false);
  const [value, setValue] = React.useState(vehicle);

  function onSelectCheckbox(event) {
    const { target } = event;
    const { checked, value } = target;
    setValue(value);
    setChecked(checked);
    onChange(value);
  }

  return (
    <React.Fragment>
      <input
        type="checkbox"
        name="vehicle_no"
        id={index}
        value={value}
        onChange={(e) => onSelectCheckbox(e)}
        checked={checked}
        style={{ display: "none" }}
      />
      <label
        htmlFor={index}
        style={{
          fontWeight: checked ? "bold" : "normal",
          color: checked ? "#E55B25" : "#373a3c",
          cursor: "pointer",
        }}
      >
        {value ? value.toUpperCase() : ""}
      </label>
    </React.Fragment>
  );
}

const MapSidebar = ({
  vehicles,
  selectedVehicles,
  onSelect,
  userRole,
  isInplant,
}) => {
  const hostname = window.location.hostname;
  return (
    <table className="table table-sm tableMapAllVehicles">
      <thead>
        <tr>
          <th>Vehicle No</th>
          {vehicles && vehicles[0] && vehicles[0].consigner_trip ? <th>Transporter</th> : null}
          {!hostname.includes("tatasteelbsl") && <th>History</th>}
          <th>Driver</th>
        </tr>
      </thead>
      <tbody>
        {vehicles &&
          vehicles.map(
            ({ consigner_trip: trip, driver, location, vehicle_no, journeys }, index) => (
              <tr key={vehicle_no}>
                <td>
                  <SidebarCheckbox
                    index={index}
                    vehicle={location.vehicle_no}
                    onChange={onSelect}
                  />
                </td>
                {trip && <td>{trip.transporter}</td>}
                {!hostname.includes("tatasteelbsl") && (
                  <td>
                    {trip && (
                      <Link
                        role={userRole}
                        isInplant={isInplant}
                        id={
                          userRole !== "Trucker"
                            ? trip.slug
                            : location.vehicle_no
                        }
                      />
                    )}
                    {journeys && journeys.length ? <JourneyLink slug={journeys[0].slug} />: null}
                  </td>
                )}
                <td>
                  {driver && driver.driver_name} - {driver && driver.phone_no}
                </td>
              </tr>
            )
          )}
      </tbody>
    </table>
  );
};

export default MapSidebar;
