import React from 'react'
import { Tooltip } from 'react-svg-tooltip'
import { formatTime } from '../../../../utils'

const Tolls = ({ data: tolls, svgHeight }) => {
  const tollRef = React.createRef()
  return (
    <React.Fragment>
      {tolls.map((toll, index) => (
        <React.Fragment key={`toll_${index}`}>
          <g ref={tollRef}>
            <circle
              cx={Number(toll.x) + 10}
              cy={svgHeight - 65}
              r={10}
              style={{
                fill: `${toll.color}`,
                strokeWidth: 1,
                stroke: `${toll.color}`,
                opacity: 0.8
              }}
              onMouseEnter={() => console.log(toll)}
            />
            <text
              x={Number(toll.x) + 7.5}
              y={svgHeight - 61}
              fontSize={10}
              fill="white"
            >
              {toll.info.status ? toll.info.status.label.split('')[0] : ''}
            </text>
          </g>
          <Tooltip for={tollRef}>
            <rect
              x={0}
              y={-90}
              width={275}
              height={70}
              rx={5}
              ry={5}
              fill="rgba(0, 0, 0, 0.75)"
            />
            <text x={5} y={-75} fontSize={13.5} fill="white">
              Toll - {toll.info.name}
            </text>
            <text x={5} y={-60} fontSize={12} fill="white">
              Enter: {formatTime(toll.info.enter_time, 'DD-MM-YYYY HH:mm')}
            </text>
            <text x={5} y={-45} fontSize={12} fill="white">
              Exit: {formatTime(toll.info.exit_time, 'DD-MM-YYYY HH:mm')}
            </text>
            {toll.info.status && (
              <text x={5} y={-30} fontSize={12} fill="white">
                Status: {toll.info.status.text || '...'}
              </text>
            )}
          </Tooltip>
        </React.Fragment>
      ))}
    </React.Fragment>
  )
}

export default Tolls
