import axios from "axios";
import _ from "lodash";
import qs from "qs";
import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ReactOnRails from "react-on-rails";
import { SkyLightStateless } from "react-skylight";
import { convertObjKeysToSnakeCase } from "../../../../utils";
import LoadingVivo from "./LoadingVivo";
import PerformanceChart from "./PerformanceChart";
import RoutePerformance from "./RoutePerformance";
import StateChart from "./StateChart";
import StatusChart from "./StatusChart";
import TripFilter from "./TripFilter";
import UnloadingVivo from "./UnloadingVivo";
import VendorPerformance from "./VendorPerformance";
import styled from "@emotion/styled";

const ChartOverviewContainer = styled.div`
  display: grid;
  grid-gap: 1em;
  width: 100%;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  @media screen and (max-width: 768px) and (min-width: 426px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const FilterContainer = styled.div`
  display: flex;
  margin: 1em 0;
  background: #fafafa;
  align-items: flex-end;
  justify-content: space-evenly;
  gap: 1em;
  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
  div.date-filter {
    width: 100%;
    max-width: 370px;
  }
`

const CancelToken = axios.CancelToken;
let cancel;

function ConsignerOverview() {
  const [isActive, setIsActive] = React.useState(undefined);
  const [hideTitle, setHideTitle] = React.useState(true);
  const [percentageTripCompleted, setPercentageTripCompleted] = React.useState(
    0
  );
  const [userRole, setUserRole] = React.useState("consigner");
  const [trips, setTrips] = React.useState(undefined);
  const [vivo, setVivo] = React.useState({
    loading: undefined,
    unloading: undefined,
  });
  const [hide, setHide] = React.useState([
    "vehicleKmPerformance",
    "vehicleStopTime",
  ]);
  const [loading, setLoading] = React.useState(false);
  const [selectedFilters, setSelectedFilters] = React.useState({});
  const [showFilterModalContent, setShowFilterModalContent] = React.useState(
    false
  );
  const [dateRange, setDateRange] = React.useState({
    startDate: moment(),
    endDate: moment(),
    start_date: null,
    end_date: null,
    ranges: {
      "Active Trips": [null],
      Today: [moment()],
      Yesterday: [moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment()
          .subtract(1, "month")
          .startOf("month"),
        moment()
          .subtract(1, "month")
          .endOf("month"),
      ],
    },
  });

  const [selectedLabel, setSelectedLabel] = React.useState("Select date range");

  function dateRangeHandler(event, picker) {
    const isActiveSelected = picker.chosenLabel === "Active Trips";
    setSelectedLabel(picker.chosenLabel);
    if (!isActiveSelected) {
      setIsActive(0);
      setDateRange({
        ...dateRange,
        start_date: picker.startDate.isValid()
          ? picker.startDate.format("YYYY-MM-DD")
          : null,
        end_date: picker.endDate.isValid()
          ? picker.endDate.format("YYYY-MM-DD")
          : null,
        startDate: picker.startDate,
        endDate: picker.endDate,
        deriveState: true,
      });
    } else {
      setIsActive(1);
      setDateRange({
        ...dateRange,
        start_date: null,
        end_date: null,
        deriveState: true,
      });
    }
  }

  const {
    startDate,
    endDate,
    start_date,
    end_date,
    ranges,
    deriveState,
  } = dateRange;

  let start = startDate.format("YYYY-MM-DD");
  let end = endDate.format("YYYY-MM-DD");

  async function fetchData() {
    try {
      setLoading(true);
      let windowUrl = window.location.pathname + "_data";
      let qObj = {
        ...selectedFilters,
        start_date,
        end_date,
      };
      if (isActive) {
        delete qObj["start_date"];
        delete qObj["end_date"];
        qObj["is_active"] = isActive;
      }

      const q = qs.stringify(convertObjKeysToSnakeCase(qObj), {
        encode: false,
        skipNulls: true,
        indices: false,
        arrayFormat: "bracket",
      });
      let url = `${windowUrl}.json?${q}`;

      cancel && cancel();
      const res = await axios.request({
        url,
        method: "GET",
        headers: ReactOnRails.authenticityHeaders({
          "Content-Type": "application/json",
        }),
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      });
      if (res && res.data) {
        let {
          loadingVivo,
          unloadingVivo,
          trips,
          hide,
          hideTitle,
          userRole,
          percentageTripCompleted,
        } = res.data;
        setHide(hide);
        setUserRole(userRole);
        setHideTitle(hideTitle);
        setPercentageTripCompleted(percentageTripCompleted);
        setVivo({
          loading: loadingVivo,
          unloading: unloadingVivo,
        });
        setTrips(trips);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  React.useEffect(() => {
    fetchData();
  }, [selectedFilters, isActive, start, end]);

  function onTripFilter(selectedFilters) {
    // if (!selectedFilters.state.includes("Completed")) {}
    // selectedFilters = convertObjKeysToSnakeCase(selectedFilters)
    setSelectedFilters(selectedFilters);
    setShowFilterModalContent(false);
  }

  function clearFilter() {
    setSelectedFilters({});
  }

  const tripsByState = _.groupBy(trips, (trip) => trip.state);
  const tripsByStatus = _.groupBy(trips, (trip) => trip.status);
  const tripsByConsigner = _.groupBy(trips, (trip) => trip.consigner_name);
  const tripsByConsignee = _.groupBy(trips, (trip) => trip.consignee_name);
  return (
    <div className="container-fluid">
      {!hideTitle && (
        <div>
          <h3>Overview</h3>
          <hr />
        </div>
      )}
      <FilterContainer>
        <div className="date-filter">
          <h6>Select trip date range</h6>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            ranges={ranges}
            maxDate={moment()}
            onApply={dateRangeHandler}
          >
            <button
              className="selected-date-range-btn"
              style={{ width: "100%", height: "37px" }}
            >
              <div className="pull-left">
                <i className="fa fa-calendar" />
              </div>
              <div className="pull-right">
                <span>{selectedLabel}</span>
                <span className="caret" />
              </div>
            </button>
          </DateRangePicker>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-info"
            onClick={() => setShowFilterModalContent(!showFilterModalContent)}
          >
            Select Filters
          </button>
          {Object.keys(selectedFilters).length > 0 && (
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => clearFilter()}
            >
              Clear Filters
            </button>
          )}
          <SkyLightStateless
            isVisible={showFilterModalContent}
            onCloseClicked={() =>
              setShowFilterModalContent(!showFilterModalContent)
            }
            title=""
          >
            <React.Fragment>
              <h5 style={{ color: "#E55B25" }}>Select Filters</h5>
              <hr />
              <TripFilter
                isActive={isActive}
                startDate={start_date}
                endDate={end_date}
                onClick={onTripFilter}
                selectedFilters={selectedFilters}
              />
            </React.Fragment>
          </SkyLightStateless>
        </div>
      </FilterContainer>
      {loading ? (
        <p>Loading ...</p>
      ) : (
        <ChartOverviewContainer>
          {!hide.includes("vehicleKmPerformance") ? (
            <>
              <PerformanceChart
                title="Vehicle Km Performance"
                url="/trucker/reports/get_distance_metric"
                chartName="vehiclePerformance"
                userRole={userRole}
              />
              {!hide.includes("vehicleStopTime") && (
                <PerformanceChart
                  title="Vehicle Stop Time"
                  url="/trucker/reports/get_idle_time_metric_fast"
                  chartName="stopTimePerformance"
                  userRole={userRole}
                />
              )}
            </>
          ) : null}
          {vivo.loading && tripsByConsigner ? (
            <LoadingVivo
              vivo={vivo.loading}
              trips={tripsByConsigner}
              tripCompleted={percentageTripCompleted}
              startDate={start_date}
              deriveState={deriveState}
              endDate={end_date}
              userRole={userRole}
              type="Loading"
            />
          ) : null}
          {tripsByState ? (
            <StateChart
              trips={tripsByState}
              tripCompleted={percentageTripCompleted}
              userRole={userRole}
            />
          ) : null}
          {vivo.unloading && tripsByConsignee ? (
            <UnloadingVivo
              vivo={vivo.unloading}
              trips={tripsByConsignee}
              deriveState={deriveState}
              tripCompleted={percentageTripCompleted}
              startDate={start_date}
              endDate={end_date}
              userRole={userRole}
              type="Unloading"
            />
          ) : null}
          {!hide.includes("vendor") && trips ? (
            <VendorPerformance
              trips={trips}
              tripCompleted={percentageTripCompleted}
              userRole={userRole}
            />
          ) : null}
          {tripsByStatus ? (
            <StatusChart
              trips={tripsByStatus}
              tripCompleted={percentageTripCompleted}
              userRole={userRole}
            />
          ) : null}
          {trips ? (
            <RoutePerformance
              trips={trips}
              tripCompleted={percentageTripCompleted}
              userRole={userRole}
            />
          ) : null}
        </ChartOverviewContainer>
      )}
    </div>
  );
}

export default (props) => <ConsignerOverview {...props} />;
