import React from "react";
import moment from "moment";

const OverspeedInfoWindow = props => (
  <div>
    <h5 className="text-muted">Overspeeding Instance</h5>
    <hr />
    <div>
      <strong>Landmark</strong>: {props.info.landmark}
    </div>
    <div>
      <strong>Time</strong>: {moment.utc(props.info.ist_timestamp).format('lll')}
    </div>
    <div>
      <strong>Speed</strong>: {props.info.speed || "..."} Km/hr
    </div>
  </div>
);

export default OverspeedInfoWindow;
