import React from "react";
import { Polyline } from "react-google-maps";
import SimpleMarker from "../../../components/Maps/SimpleMarker";
import HistoryMarker from "./HistoryMarker";

class TripHistoryPath extends React.Component {
  state = {
    progress: [],
  };

  path = this.props.history.map((h) => ({
    lat: Number(h.latitude),
    lng: Number(h.longitude),
  }));

  velocity = window.localStorage.getItem("vehicleMarkerSpeed")
    ? Number(window.localStorage.getItem("vehicleMarkerSpeed"))
    : 1000;
  initialDate = new Date();

  getDistance = () => {
    // seconds between when the component loaded and now
    const differentInTime = (new Date() - this.initialDate) / 1000; // pass to seconds
    return differentInTime * this.velocity; // d = v*t
  };

  componentDidMount = () => {
    this.interval = window.setInterval(this.moveObject, 1000);
  };

  componentWillUnmount = () => {
    window.clearInterval(this.interval);
  };

  moveObject = () => {
    const distance = this.getDistance();
    if (!distance) {
      console.log("distance null");
      return;
    }
    let progress = this.path.filter(
      (coordinates) => coordinates.distance < distance
    );

    const nextLine = this.path.find(
      (coordinates) => coordinates.distance > distance
    );
    if (!nextLine) {
      this.setState({ progress });
      return; // it's the end!
    }
    const lastLine = progress[progress.length - 1];

    const lastLineLatLng = new window.google.maps.LatLng(
      lastLine.lat,
      lastLine.lng
    );

    const nextLineLatLng = new window.google.maps.LatLng(
      nextLine.lat,
      nextLine.lng
    );

    // distance of this line
    const totalDistance = nextLine.distance - lastLine.distance;
    const percentage = (distance - lastLine.distance) / totalDistance;

    const position = window.google.maps.geometry.spherical.interpolate(
      lastLineLatLng,
      nextLineLatLng,
      percentage
    );

    progress = progress.concat({ lat: position.lat(), lng: position.lng() });

    if (this.props.moveVehicleMarker) {
      this.setState({ progress });
    }
  };

  componentWillMount = () => {
    this.path = this.path.map((coordinates, i, array) => {
      if (i === 0) {
        return { ...coordinates, distance: 0 }; // it begins here!
      }
      const { lat: lat1, lng: lng1 } = coordinates;
      const latLong1 = new window.google.maps.LatLng(lat1, lng1);

      const { lat: lat2, lng: lng2 } = array[0];
      const latLong2 = new window.google.maps.LatLng(lat2, lng2);

      // in meters:
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        latLong1,
        latLong2
      );

      return { ...coordinates, distance };
    });

    // console.log(this.path)
  };

  render() {
    let vehicleLastLocation = null;
    if (this.state.progress && this.state.progress.length) {
      vehicleLastLocation = this.state.progress[this.state.progress.length - 1];
    }
    return (
      <div>
        <Polyline
          path={this.path}
          options={{
            geodesic: true,
            strokeColor: "#7C4DFF",
            strokeOpacity: 0.1,
            strokeWeight: 5,
          }}
        />
        {this.props.history.map(
          (item, i) =>
            this.props.showHistoricalPath && (
              <HistoryMarker
                location={item}
                key={i}
                isOpen={
                  new Date(item.ist_timestamp).getTime() ===
                  Number(this.props.selectedHistoryMarkerTimestamp)
                }
                trip={this.props.tripInfo}
                showHistoryInfoWindow={this.props.showHistoryInfoWindow}
                labelClassName="historyLabel"
              />
            )
        )}
        {this.props.startLocation && (
          <SimpleMarker
            location={this.props.startLocation}
            isOpen={
              new Date(this.props.startLocation.ist_timestamp).getTime() ===
              Number(this.props.selectedHistoryMarkerTimestamp)
            }
            label="A"
            consigner_trip={this.props.tripInfo}
            labelClassName="historyLabel"
            hideTripInfo={true}
          />
        )}
        <SimpleMarker
          location={
            vehicleLastLocation
              ? {
                  latitude: vehicleLastLocation.lat,
                  longitude: vehicleLastLocation.lng,
                }
              : this.props.vehicleLocation
          }
          key="VehicleLocation"
          isOpen={
            new Date(this.props.vehicleLocation.ist_timestamp).getTime() ===
            Number(this.props.selectedHistoryMarkerTimestamp)
          }
          label={`.`}
          consigner_trip={this.props.tripInfo}
          vehicle={this.props.vehicleInfo}
          driver={this.props.driverInfo}
          labelClassName="historyLabel"
          mode={this.props.tripInfo ? this.props.tripInfo.transport_mode : null}
        />
        )
        {this.props.tripInfo && (
          <SimpleMarker
            location={{
              latitude: this.props.tripInfo.consigner_lat,
              longitude: this.props.tripInfo.consigner_long,
            }}
            label="O"
            consigner_trip={this.props.tripInfo}
            hideLocationInfo={true}
            key="Origin"
          />
        )}
        {this.props.tripInfo && (
          <SimpleMarker
            location={{
              latitude: this.props.tripInfo.consignee_lat,
              longitude: this.props.tripInfo.consignee_long,
            }}
            label="B"
            consigner_trip={this.props.tripInfo}
            hideLocationInfo={true}
            key="End"
          />
        )}
      </div>
    );
  }
}

export default TripHistoryPath;
