import React from 'react'
import ReactSelect from 'react-select'

export default function RenderForm(props) {
  const { index, label } = props
  return (
    <div
      style={{
        gridArea: `${index !== 1 ? 2 : 1}/${index !== 1 ? index - 1 : 'auto'}/${
          index > 1 ? 'auto' : 'span 1'
        }/${index > 1 ? 'auto' : 'span 4'}`
      }}
    >
      <label>{label}</label>
      <RenderFormElement {...props} />
    </div>
  )
}

export const RenderFormElement = props => {
  const { type, onChange, name, value: val, ...rest } = props
  if (type.includes('select')) {
    return (
      <ReactSelect
        {...rest}
        value={
          props.options
            ? props.options.filter(item => item.value === val)[0]
            : { label: '', value: '' }
        }
        onChange={({ value }) => onChange(name, value)}
      />
    )
  }

  return (
    <input
      {...props}
      onChange={e => onChange(name, e.target.value)}
      type={type.replace('input.', '')}
      className={`form-control ${
        type === 'input.date' ? 'form-control-sm' : ''
      }`}
      style={type === 'input.date' ? { lineHeight: 'unset' } : {}}
    />
  )
}
