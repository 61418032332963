import React from "react";
import ReactDom from "react-dom";
import { Tooltip } from "react-svg-tooltip";
import prettyMs from "pretty-ms";
import { formatTime } from "../../../../utils";

const Stoppage = ({ stoppage, svgHeight }) => {
  const stoppageRef = React.createRef();
  if (stoppage.x > 0) return null;
  return (
    <React.Fragment>
      <rect
        x={stoppage.x}
        y={svgHeight - 73}
        width={stoppage.width}
        height="15"
        style={{
          fill: "#FFCA28",
          strokeWidth: 1,
          stroke: "#FFB300",
          opacity: 0.99
        }}
        onMouseEnter={() => console.log(stoppage)}
        ref={stoppageRef}
      />
      <Tooltip for={stoppageRef}>
        <rect
          x={0}
          y={-82.5}
          width={300}
          height={70}
          rx={5}
          ry={5}
          fill="rgba(0, 0, 0, 0.75)"
        />
        <text x={5} y={-65} fill="white" fontSize={14}>
          Stoppage
        </text>
        <text x={5} y={-50} fill="white" fontSize={12}>
          {stoppage.info.start_landmark}
        </text>
        <text x={5} y={-35} fontSize={12} fill="white">
          Start: {formatTime(stoppage.info.start_timestamp, "DD-MM-YYYY HH:mm")}{" "}
          - End: {formatTime(stoppage.info.end_timestamp, "DD-MM-YYYY HH:mm")}
        </text>
        <text x={5} y={-22} fontSize={12} fill="white">
          Duration:{" "}
          {prettyMs(
            new Date(stoppage.info.end_timestamp) -
              new Date(stoppage.info.start_timestamp)
          )}
        </text>
      </Tooltip>
    </React.Fragment>
  );
};

export default Stoppage;
