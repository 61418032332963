export const colors = {
  early: "#29B6F6",
  onTime: "#82ca9d",
  delayed: "#ef5350",
  unknownStatus: "#607D8B",
  completed: "#FF9800",
  atStartJn: "#B3E5FC",
  enroute: "#26A69A",
  atTransitJn: "#7E57C2",
  atEndJn: "#EC407A",
  unknownState: "#607D8B"
};
