import ChangeCase from 'change-case'
import flattenDeep from 'lodash/flattenDeep'
import isEqual from 'lodash/isEqual'
import React from 'react'
import Autosuggest from 'react-autosuggest'
import TagsInput from 'react-tagsinput'

function mergeObjArr(arrObj) {
  const result = arrObj.reduce((b, a) => {
    if (Object.keys(b)[0] === Object.keys(a)[0]) {
      return {
        [Object.keys(b)[0]]: [Object.values(b)[0], Object.values(a)[0]]
      }
    }
    return { ...a, ...b }
  })

  return Object.assign(
    {},
    ...Object.entries(result).map(([key, value], index) => ({
      [key]: flattenDeep([value])
    }))
  )
}

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

function getSuggestions(data, value) {
  const escapedValue = escapeRegexCharacters(value.trim())

  if (escapedValue === '') {
    return []
  }

  const regex = new RegExp('^' + escapedValue, 'i')

  const result = data
    .map(section => ({
      title: ChangeCase.sentence(section.name),
      name: section.name,
      values: section.value.filter(item => regex.test(item))
    }))
    .filter(section => section.values && section.values.length > 0)

  return result
}

function getSuggestionValue(suggestion) {
  return suggestion
}

function renderSuggestion(suggestion) {
  return <span>{suggestion}</span>
}

function renderSectionTitle(section) {
  return <strong>{section.title}</strong>
}

function getSectionSuggestions(section) {
  return section.values
}

class AutocompleteRenderInput extends React.Component {
  state = {
    data: [],
    value: '',
    suggestions: []
  }

  componentDidMount() {
    this.populateFilters()
  }

  populateFilters = async () => {
    try {
      const response = await (await fetch(
        `/consigner/overview/populate_filters.json`,
        {
          method: 'GET',
          headers: ReactOnRails.authenticityHeaders({
            'Content-Type': 'application/json'
          }),
          mode: 'cors',
          credentials: 'include'
        }
      )).json()
      if (!response) throw new Error('Unable to fetch filters')
      const data = Object.entries(response).map(([key, value], index) => ({
        name: key,
        value
      }))
      this.setState({ data })
    } catch (error) {
      console.log(error)
    }
  }

  onChange = (e, { newValue, method }) => {
    if (method === 'enter') {
      e.preventDefault()
    } else {
      this.setState({
        value: newValue
      })
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState(state => ({
      suggestions: getSuggestions(state.data, value)
    }))
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  onSuggestionSelected = (event, { suggestionValue, sectionIndex }) => {
    event.preventDefault()
    const category = this.state.suggestions[sectionIndex].name
    this.props.onChange({ category, value: suggestionValue })
    this.setState({ value: '' })
  }

  render() {
    const { value, suggestions } = this.state
    const inputProps = {
      placeholder: 'Search something',
      value,
      onChange: this.onChange
    }
    return (
      <React.Fragment>
        {suggestions && (
          <Autosuggest
            multiSection={true}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            renderSectionTitle={renderSectionTitle}
            getSectionSuggestions={getSectionSuggestions}
            inputProps={inputProps}
          />
        )}
      </React.Fragment>
    )
  }
}

function defaultRenderTag(props) {
  let {
    tag,
    key,
    disabled,
    onRemove,
    classNameRemove,
    getTagDisplayValue,
    ...other
  } = props
  const val = Object.values(tag)[0]
  return (
    <span key={key} {...other}>
      {getTagDisplayValue(val)}
      {!disabled && (
        <a
          className={classNameRemove}
          onClick={e => {
            e.preventDefault()
            onRemove(tag)
          }}
        />
      )}
    </span>
  )
}

export default class Filter extends React.Component {
  state = {
    options: []
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    })
  }

  handleTagChange = ({ category, value }) => {
    this.setState(
      state => ({
        options: [...state.options, { [category]: value }]
      }),
      () => this.passOptionsToParent()
    )
  }

  handleChange = option => {
    this.setState(
      state => ({
        options: [...state.options, option]
      }),
      () => this.passOptionsToParent()
    )
  }

  handleRemove = tag => {
    this.setState(
      state => ({
        options: state.options.filter(n => !isEqual(n, tag))
      }),
      () => this.passOptionsToParent()
    )
  }

  passOptionsToParent = () => {
    const { options } = this.state
    if (options && options.length) {
      const data = mergeObjArr(options)
      this.props.onFilter(data)
    } else {
      this.props.onFilter(null)
    }
  }

  populateCategory = (options, newVal) => {
    const key = Object.keys(newVal)[0]
    const val = Object.values(newVal)[0]
    if (Object.keys(options).includes(key)) {
      return { [key]: [...options[key], val] }
    }
    return { [key]: [val] }
  }

  render() {
    const { options: value } = this.state
    return (
      <TagsInput
        renderInput={() => (
          <AutocompleteRenderInput onChange={this.handleTagChange} />
        )}
        renderTag={props =>
          defaultRenderTag({ ...props, onRemove: this.handleRemove })
        }
        onChange={this.handleChange}
        value={value}
      />
    )
  }
}
