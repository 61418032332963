import React from 'react';

const DeviationsReport = props => {
  const {deviationsReport} = props.info;
  return (
    <div>
      <table className="table table-sm">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Deviation</th>
          </tr>
        </thead>
        <tbody>
          {deviationsReport &&
            deviationsReport.map((deviation, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{deviation}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default DeviationsReport;
