import React from 'react'
import CreatableSelect from 'react-select/creatable'

function generateUniqOptions(input, item) {
  return _.uniqBy(
    Object.values(input.config).map(e => ({
      value: e[item],
      label: String(e[item] + ' ' + input.units[item])
    })),
    opt => opt.value
  )
}

export default function CreatableSelectInput({
  model,
  item,
  onChange,
  unit,
  value,
  name,
  erase
}) {
  const [selected, setSelected] = React.useState(value)
  const [options, setOptions] = React.useState(generateUniqOptions(model, item))

  React.useEffect(() => {
    setSelected(null)
    setOptions(generateUniqOptions(model, item))
  }, [name, model.name, erase])

  function handleCreate(inputValue) {
    const selected = {
      value: inputValue,
      label: inputValue + ' ' + unit
    }
    setSelected(selected)
    onChange({ [item]: selected })
  }

  function handleChange(newValue, actionMeta) {
    setSelected(newValue)
    setOptions(_.uniqBy([...options, newValue], opt => opt.value))
    onChange({ [actionMeta]: newValue })
  }

  return (
    <div>
      <CreatableSelect
        name="form-field-name"
        value={selected}
        onChange={d => handleChange(d, item)}
        options={options}
        onCreateOption={handleCreate}
        placeholder={`Choose ${item}`}
        isClearable
      />
    </div>
  )
}
