import React from 'react'

import Select from 'react-select'
import DateRangePicker from 'react-bootstrap-daterangepicker'

import moment from 'moment'

export default class MapAnalyticsForm extends React.Component {
  state = {
    form: {
      origin: null,
      destination: null,
      start_date: this.props.start_date,
      end_date: this.props.end_date,
      filter_above: 4
    }
  }

  setLocation = (location, selectedOption) => {
    this.setState(state => ({
      form: { ...state.form, [location]: selectedOption.value }
    }))
  }

  changeDate = (event, picker) => {
    this.setState(state => ({
      form: {
        ...state.form,
        start_date: moment(picker.startDate).format('YYYY-MM-DD'),
        end_date: moment(picker.endDate).format('YYYY-MM-DD')
      }
    }))
  }

  submit = () => {
    const { form: data } = this.state
    this.props.submitForm(data)
  }

  setFilter = count => {
    this.setState(state => ({ form: { ...state.form, filter_above: count } }))
  }

  render() {
    const { form } = this.state
    const { origin, destination, start_date, end_date, filter_above } = form
    return (
      <div className="row" style={{ marginTop: '1em' }}>
        <div className="col-md-12">
          <div className="col-sm-3">
            <label htmlFor="origin">Select Origin</label>
            <Select
              name="origin"
              value={origin}
              onChange={val => this.setLocation('origin', val)}
              options={this.props.origins.map(name => ({
                value: name,
                label: name
              }))}
              noResultsText="Select Origin"
            />
          </div>
          <div className="col-sm-3">
            <label htmlFor="destination">Select Destination</label>
            <Select
              name="destination"
              value={destination}
              onChange={val => this.setLocation('destination', val)}
              options={this.props.destinations.map(name => ({
                value: name,
                label: name
              }))}
              noResultsText="Select Destination"
            />
          </div>
          <div className="col-sm-3">
            <DateRangePicker
              startDate={moment(start_date)}
              endDate={moment(end_date)}
              onEvent={this.changeDate}
            >
              <button
                className="btn btn-secondary"
                style={{ marginTop: '1.6em' }}
              >
                Date : {start_date} to {end_date}
              </button>
            </DateRangePicker>
          </div>
          <div className="col-sm-2 hidden d-none">
            <label htmlFor="duration">Min. No Of Trucks</label>
            <br />
            <input
              type="number"
              min={0}
              step={1}
              value={filter_above}
              onChange={event => this.setFilter(event.target.value)}
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-1">
            <button
              className="btn btn-success"
              style={{ marginTop: '1.6em' }}
              onClick={this.submit}
            >
              Filter
            </button>
          </div>
        </div>
      </div>
    )
  }
}
