import pickBy from "lodash/pickBy";
import upperFirst from "lodash/upperFirst";
import React from "react";

const EPodPanel = ({ info }) => {
  const epodFields =
    info.tripInfo && info.tripInfo.meta_data
      ? pickBy(
          info.tripInfo.meta_data,
          (val, key) => key.includes("epod_") && !!val
        )
      : null;
  return (
    <>
      {epodFields &&
        Object.entries(epodFields).map(([key, val], index) => {
          console.log(key);
          return (
            <div key={`epod_${index}`}>
              {key.includes("photos") ? (
                <>
                  {val.split(";").map((url, i) => (
                    <img
                      src={url}
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        marginBottom: "0.5em",
                      }}
                      alt={url}
                      key={`epodImage_${i}`}
                    />
                  ))}
                </>
              ) : (
                `${upperFirst(key.replace("epod_", ""))} : ${val}`
              )}
            </div>
          );
        })}
    </>
  );
};

export default EPodPanel;
