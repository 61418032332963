import React from "react";
import moment from "moment";
import uniqBy from "lodash/uniqBy";
import InfiniteScroll from "react-infinite-scroll-component";
import queryString from "query-string";

export default class TransactionSidebar extends React.Component {
  state = {
    trips: this.props.trips,
    id: this.props.id,
    params: {
      per_page: 10,
      page_number: 1,
      search: null,
    },
  };

  componentDidMount() {
    if (this.props.currentTrip) {
      let trips = uniqBy([this.props.currentTrip, ...this.props.trips], "id");
      this.setState({ trips });
    }
  }

  filterTrips = async (event) => {
    try {
      if (String(event.target.value).length >= 3) {
        const search = event.target.value;
        this.setState(
          (state) => ({ ...state, params: { ...state.params, search } }),
          () => this.fetchData({ updatePageNumber: false })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  fetchData = async (options) => {
    try {
      const { params } = this.state;
      const q = queryString.stringify(params, { arrayFormat: "bracket" });
      let updatePageNumber = true;
      if (options) {
        updatePageNumber = options.updatePageNumber;
      }
      const response = await (await fetch(
        `${window.location.pathname}.json?${q}`,
        {
          method: "GET",
          headers: ReactOnRails.authenticityHeaders({
            "Content-Type": "application/json",
          }),
          mode: "cors",
          credentials: "include",
        }
      )).json();
      if (response) {
        const { currentTrip, trips, id } = response;
        this.setState((state) => ({
          trips: uniqBy([currentTrip, ...trips].filter((n) => n), "id"),
          id: id,
          currentTrip: currentTrip,
          params: {
            ...state.params,
            page_number: updatePageNumber
              ? state.params.page_number + 1
              : state.params.page_number,
          },
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  refresh = () => {
    console.log("refresh");
  };

  render() {
    const { trips, params } = this.state;
    const tripsPresent = Boolean(trips ? trips.length : 0);
    return (
      <div className="sidebar-sticky">
        <input
          type="text"
          name="search_transactions"
          className="search-sidebar"
          placeholder="Search using card / invoice details"
          onChange={this.filterTrips}
        />
        <div
          className="transaction-list"
          style={{ height: "105vh", overflow: "auto" }}
        >
          {tripsPresent && (
            <InfiniteScroll
              dataLength={trips.length}
              next={this.fetchData}
              hasMore={true}
              loader={<h4>Loading...</h4>}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              refreshFunction={this.refresh}
              pullDownToRefresh
              pullDownToRefreshContent={
                <h3 style={{ textAlign: "center" }}>
                  &#8595; Pull down to refresh
                </h3>
              }
              releaseToRefreshContent={
                <h3 style={{ textAlign: "center" }}>
                  &#8593; Release to refresh
                </h3>
              }
            >
              {trips.map((trip, index) => (
                <a
                  href={`/consigner/transactions/${trip.slug}`}
                  key={trip.slug + "_" + index}
                  className={`transaction-item text-white key_${index + 1}`}
                >
                  <div className="card_no t-label">
                    Inv : {trip.invoice_no},{" "}
                    {trip.leg_no && (
                      <React.Fragment>
                        <div>Leg : {trip.leg_no}</div>
                      </React.Fragment>
                    )}
                  </div>
                  <div className="t-route">
                    {trip.origin ? trip.origin : "..."} to {trip.destination}
                  </div>
                  <div>{trip.vehicle_no}</div>
                  <div>Consignee: {trip.consignee_name}</div>
                  <div>Consigner: {trip.consigner_name}</div>
                  <div>Vendor: {trip.transporter}</div>
                  <div>{moment(trip.start_date).format("YYYY-MM-DD")}</div>
                </a>
              ))}
            </InfiniteScroll>
          )}
        </div>
      </div>
    );
  }
}
