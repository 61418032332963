import React from 'react'
import swal from 'sweetalert'
import RenderForm from './RenderForm'

export default function AddIssueForm({
  resourceId,
  resourceType,
  setNewIssueCreated,
  formConfig
}) {
  const [issue, setIssue] = React.useState({
    title: '',
    sla: '',
    category: '',
    severity: 0,
    status: '',
    resourceId: resourceId,
    resourceType: resourceType
  })

  function onChange(type, value) {
    setIssue({ ...issue, [type]: value })
  }

  async function createIssue() {
    try {
      const response = await (await fetch('/issues.json', {
        method: 'POST',
        headers: ReactOnRails.authenticityHeaders({
          'Content-Type': 'application/json'
        }),
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({ issue })
      })).json()
      if (response && response.created) {
        setNewIssueCreated(true)
        swal('Success', 'Issue created', 'success')
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div
      style={{
        display: 'grid',
        gridGap: '1em',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        padding: '1em'
      }}
    >
      <div
        style={{
          display: 'grid',
          gridGap: '0.25em'
        }}
      >
        {formConfig &&
          formConfig.map((config, index) => (
            <RenderForm
              {...config}
              key={config.name}
              value={issue[config.name]}
              index={index + 1}
              onChange={onChange}
            />
          ))}
      </div>
      <div>
        <button className="btn btn-warning" onClick={() => createIssue()}>
          Create Issue
        </button>
      </div>
    </div>
  )
}
