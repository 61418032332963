import moment from "moment";
import React from "react";

const TextIfPresent = ({ label, value }) =>
  value ? (
    <div>
      <strong>{label}</strong>: {value}
    </div>
  ) : null;

const TripInfo = ({
  trip: {
    reference_no,
    origin,
    destination,
    eta,
    consigner_name,
    consignee_name,
    transporter,
    state,
    status,
    vehicle_entry_time,
    vehicle_exit_time
  }
}) => (
  <React.Fragment>
    <TextIfPresent label="Invoice No" value={reference_no} />
    <TextIfPresent label="Route" value={`${origin} to ${destination}`} />
    {eta && (
      <TextIfPresent
        label="S.T.A"
        value={moment.utc(eta).format("YYYY-MM-DD")}
      />
    )}
    <TextIfPresent label="Consigner" value={consigner_name} />
    <TextIfPresent label="Consignee" value={consignee_name} />
    <TextIfPresent label="Transporter" value={transporter} />
    <TextIfPresent label="Trip State" value={state} />
    <TextIfPresent label="Trip Status" value={status} />
    <TextIfPresent label="Vehicle Entry" value={vehicle_entry_time} />
    <TextIfPresent label="Vehicle Exit" value={vehicle_exit_time} />
    <br />
  </React.Fragment>
);

const ConsignerTripDetails = props => {
  const { vehicle, trips, trip } = props;
  return (
    <div>
      <h5 className="text-muted">Trip Details : </h5>
      <hr />
      {vehicle && vehicle.truck_manager && (
        <React.Fragment>
          <TextIfPresent
            label="Vehicle Manager"
            value={vehicle.truck_manager}
          />
          <TextIfPresent
            label="Vehicle Manager Contact"
            value={vehicle.truck_manager_phone}
          />
        </React.Fragment>
      )}
      {trips ? (
        <React.Fragment>
          {trips.map(trip => (
            <TripInfo trip={trip} key={`trip_${trip.invoice_no}`} />
          ))}
        </React.Fragment>
      ) : (
        <TripInfo trip={trip} />
      )}
    </div>
  );
};

const BookingDetails = ({
  booking: {
    route_start,
    route_end,
    total_amount,
    material_type,
    consigner_name,
    consignee_name,
    pickup_address,
    drop_address
  }
}) => (
  <div>
    <h5 className="text-muted">Booking Details : </h5>
    <hr />
    <TextIfPresent label="Route" value={`${route_start} to ${route_end}`} />
    <TextIfPresent
      label="Booked Amt"
      value={`Rs ${total_amount} | (${total_amount} per ton)`}
    />
    <TextIfPresent label="Material" value={material_type} />
    <TextIfPresent label="Consigner" value={consigner_name} />
    <TextIfPresent label="Consignee" value={consignee_name} />
    <TextIfPresent label="Loading" value={pickup_address} />
    <TextIfPresent label="Unloading" value={drop_address} />
    <br />
  </div>
);

const VehicleTrackingDetails = ({ location, vehicle }) => {
  const { landmark, speed, state, idle_since, ist_timestamp } = location
    ? location
    : {};
  const { truck_manager, truck_manager_phone, truck_type } = vehicle
    ? vehicle
    : {};
  return (
    <div>
      <h5 className="text-muted">Tracking Details :</h5>
      <hr />
      {ist_timestamp && (
        <TextIfPresent
          label="Last Updated"
          value={moment.utc(ist_timestamp).format("YYYY-MM-DD HH:mm:ss")}
        />
      )}
      <TextIfPresent label="Landmark" value={landmark} />
      <TextIfPresent label="Speed" value={speed ? `${speed} Km/hr` : "..."} />
      <TextIfPresent label="Vehicle Status" value={state} />
      {truck_manager && (
        <React.Fragment>
          <TextIfPresent label="Vehicle Manager" value={truck_manager} />
          <TextIfPresent
            label="Vehicle Manager Contact"
            value={truck_manager_phone}
          />
        </React.Fragment>
      )}
      {truck_type && <TextIfPresent label="Vehicle Type" value={truck_type} />}
      {idle_since && (
        <TextIfPresent
          label="Idle Since"
          value={moment.utc(idle_since).format("YYYY-MM-DD HH:mm:ss")}
        />
      )}
      {/* <TextIfPresent
      label="Idle Since"
      value={moment
        .utc(new Date())
        .subtract(Number(idle_time) / 1000, 'seconds')
        .format('YYYY-MM-DD HH:mm:ss')}
    /> */}
      <br />
    </div>
  );
};

const DriverDetails = ({ info: { driver_name, phone_no } }) => (
  <div>
    <h5 className="text-muted">Driver Details :</h5>
    <hr />
    <TextIfPresent label="Driver Name" value={driver_name} />
    <TextIfPresent label="Driver Phone" value={phone_no} />
  </div>
);

const JourneyDetails = ({ data }) => (
  <div>
    <h5 className="text-muted">Journey Details :</h5>
    <hr />
    <div>
      <strong>Route</strong>: {data.route}
    </div>
    <div>
      <strong>Driver Name</strong>: {data.driver_name}
    </div>
    <div>
      <strong>Driver Phone No</strong>: {data.driver_phone_no}
    </div>
    <div>
      <strong>Reference No</strong>: {data.lr_no}
    </div>
    <div>
      <strong>S.T.A</strong>:{" "}
      {moment.utc(data.sta).format("YYYY-MM-DD HH:mm:ss")}
    </div>
    <div>
      <strong>E.T.A</strong>:{" "}
      {moment.utc(data.eta).format("YYYY-MM-DD HH:mm:ss")}
    </div>
    <hr />
    {data.shipments &&
      data.shipments.map((shipment, index) => (
        <React.Fragment key={shipment.shipment_no}>
          <strong>Consignment {index + 1}</strong>
          <div>
            <strong>Consignment No</strong>: {shipment.shipment_no}
          </div>
          <div>
            <strong>Origin</strong>: {shipment.start_node}
          </div>
          <div>
            <strong>End</strong>: {shipment.end_node}
          </div>
          <div>
            <strong>Time</strong>:{" "}
            {moment.utc(shipment.start_time).format("YYYY-MM-DD HH:mm:ss")} -{" "}
            {shipment.end_time
              ? moment.utc(shipment.end_time).format("YYYY-MM-DD HH:mm:ss")
              : null}
          </div>
          <div>
            <strong>Material</strong>: {shipment.material}
          </div>
          <hr />
        </React.Fragment>
      ))}
  </div>
);

const InfoWindowBox = ({ info, children }) => {
  const {
    hideTripInfo,
    hideLocationInfo,
    consigner_trip,
    booking,
    location,
    driver,
    vehicle,
    journeys,
    vehicle_no,
    transporter,
    trips
  } = info || {};
  return (
    <div>
      {vehicle_no && (
        <>
          <h5>Vehicle No: {vehicle_no}</h5>
          {transporter && <div>Transporter: {transporter}</div>}
          <hr />
        </>
      )}
      {!hideLocationInfo && location && (
        <VehicleTrackingDetails location={location} vehicle={vehicle} />
      )}
      {!hideTripInfo && consigner_trip && (
        <ConsignerTripDetails
          trips={trips}
          trip={consigner_trip}
          vehicle={vehicle}
        />
      )}
      {journeys &&
        journeys.map(journey => (
          <JourneyDetails data={journey} key={journey.slug} />
        ))}
      {booking && <BookingDetails booking={booking} />}

      {driver && <DriverDetails info={driver} />}
      {vehicle && (
        <TextIfPresent label="Vehicle Type" value={vehicle.truck_type} />
      )}
      {location && location.ist_timestamp && (
        <TextIfPresent
          label="Last Updated"
          value={moment
            .utc(location.ist_timestamp)
            .format("YYYY-MM-DD HH:mm:ss")}
        />
      )}
      {children}
    </div>
  );
};

export default InfoWindowBox;
