import styled from "@emotion/styled";
import moment from "moment";
import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { SkyLightStateless } from "react-skylight";
import "react-table/react-table.css";
import { IJourney } from "../../../../types";
import { convertObjKeysToSnakeCase } from "./../../../../utils";
import JourneyFilter, { searchAndCreateFilterObj } from "./JourneyFilter";
import JourneyStatePerformance from "./JourneyStatePerformance";
import JourneyTable from "./JourneyTable";
import LoadingPerformance from "./LoadingPerformance";
import NodePerformance from "./NodePerformance";
import RoutePerformance from "./RoutePerformance";
import RoutePerformanceByScore from "./RoutePerformanceByScore";
import TransitPerformance from "./TransitPerformance";
import UnloadingPerformance from "./UnloadingPerformance";
import VendorPerformance from "./VendorPerformance";

export interface IFilter {
  startDate: any;
  endDate: any;
  asc?: boolean;
  active: boolean | number;
}

const JourneyOverviewContainer = styled("div")`
  display: grid;
  grid-gap: 1em;
  padding: 1em;
  @media screen and (min-width: 426px) and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 769px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`

const DateSelector = styled.div`
  width: 100%;
  padding: 1em;
  @media screen and (min-width: 426px) {
    width: 350px;
    padding: 0;
  }
`

function JourneyOverview({ chartsToShow }: { chartsToShow: string[] }) {
  const [isActive, setIsActive] = React.useState(1);
  const [showFilterModalContent, setShowFilterModalContent] = React.useState(
    false
  );
  const [filterableColumns, setFilterableColumns] = React.useState(null);
  const [selectedFilters, setSelectedFilters] = React.useState({});
  const [queryParams, setQueryParams] = React.useState({});
  const [isVisible, setIsVisible] = React.useState(false);
  const [modalJourneys, setModalJourneys] = React.useState<null | IJourney[]>(
    null
  );
  const [dateRange, setDateRange] = React.useState<any>({
    startDate: moment(),
    endDate: moment(),
    start_date: null,
    end_date: null,
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment()
          .subtract(1, "month")
          .startOf("month"),
        moment()
          .subtract(1, "month")
          .endOf("month")
      ]
    }
  });

  const SDR = "Select date range"

  const [selectedLabel, setSelectedLabel] = React.useState(SDR);

  function dateRangeHandler(event, picker) {
    const isActiveSelected = picker.chosenLabel === "Active Trips";
    const start_date = picker.startDate.isValid() ? picker.startDate.format("YYYY-MM-DD") : null
    const end_date = picker.endDate.isValid() ? picker.endDate.format("YYYY-MM-DD") : null
    setSelectedLabel(picker.chosenLabel === "Custom Range" ? `${start_date} - ${end_date}`: picker.chosenLabel);
    if (!isActiveSelected) {
      //setIsActive(0)
      setDateRange({
        ...dateRange,
        start_date,
        end_date,
        startDate: picker.startDate,
        endDate: picker.endDate
      });
    } else {
      //setIsActive(1)
      setDateRange({
        ...dateRange,
        start_date: null,
        end_date: null
      });
    }
  }

  const { startDate, endDate, start_date, end_date, ranges } = dateRange;

  function showJourney(journeys) {
    setIsVisible(!isVisible);
    setModalJourneys(journeys);
  }

  function onJourneyFilter(selectedFilters) {
    setSelectedFilters(selectedFilters);
    setShowFilterModalContent(false);
    const queryParams = searchAndCreateFilterObj(
      filterableColumns,
      selectedFilters
    );
    setQueryParams(queryParams);
  }

  function clearFilter() {
    setSelectedFilters({});
    setQueryParams({});
    setDateRange({ ...dateRange, start_date: null, end_date: null });
  }

  const filter = {
    ...convertObjKeysToSnakeCase({
      startDate: start_date,
      endDate: end_date,
      active: isActive
    }),
    ...queryParams
  };

  if (!isActive) {
    delete filter["active"];
  }

  return (
    <div>
      <SkyLightStateless
        isVisible={isVisible}
        onCloseClicked={() => setIsVisible(!isVisible)}
        title="Journeys"
        dialogStyles={{
          top: "40%",
          left: "50%",
          transform: "translate('-50%','-50%')"
        }}
        hideOnOverlayClicked
      >
        <div style={{ height: "520px", overflowY: "auto" }}>
          {modalJourneys && <JourneyTable journeys={modalJourneys} />}
        </div>
      </SkyLightStateless>
      <FilterContainer>
        <DateSelector>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            ranges={ranges}
            maxDate={moment()}
            onApply={dateRangeHandler}
          >
            <button
              className="selected-date-range-btn"
              style={{ width: "100%", height: "37px" }}
            >
              <div className="pull-left">
                <i className="fa fa-calendar" />
              </div>
              <div className="pull-right">
                <span>{selectedLabel}</span>
                <span className="caret" />
              </div>
            </button>
          </DateRangePicker>
          <label>
            <input
              type="checkbox"
              value={isActive}
              checked={Boolean(isActive)}
              onChange={() => {
                if (Boolean(isActive) && selectedLabel === SDR) {
                  const startDate = moment().subtract(15, "days")
                  const endDate = moment()
                  const start_date = startDate.format("YYYY-MM-DD")
                  const end_date = endDate.format("YYYY-MM-DD")
                  setSelectedLabel(`${start_date} - ${end_date}`);
                  setDateRange({
                    start_date,
                    end_date,
                    startDate,
                    endDate
                  });
                }
                setIsActive(Number(!Boolean(isActive)))
              }}
            />{" "}
            Active trips
          </label>
        </DateSelector>
        <div>
          <button
            type="button"
            className="btn btn-info"
            onClick={() => setShowFilterModalContent(!showFilterModalContent)}
            style={{ margin: "0 1em" }}
          >
            Select Filters
          </button>
        </div>
        {Object.keys(selectedFilters).length > 0 && (
          <div>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => clearFilter()}
              style={{ marginLeft: "1em" }}
            >
              Clear Filters
            </button>
          </div>
        )}
        <SkyLightStateless
          isVisible={showFilterModalContent}
          onCloseClicked={() =>
            setShowFilterModalContent(!showFilterModalContent)
          }
          title=""
        >
          <React.Fragment>
            <h5 style={{ color: "#E55B25" }}>Select Filters</h5>
            <hr />
            <JourneyFilter
              isActive={isActive}
              startDate={start_date}
              endDate={end_date}
              onClick={onJourneyFilter}
              selectedFilters={selectedFilters}
              setFilterableColumns={setFilterableColumns}
            />
          </React.Fragment>
        </SkyLightStateless>
      </FilterContainer>
      <JourneyOverviewContainer>
        {chartsToShow.includes("loadingPerformance") && (
          <LoadingPerformance filter={filter} showJourney={showJourney} />
        )}
        {chartsToShow.includes("unloadingPerformance") && (
          <UnloadingPerformance filter={filter} showJourney={showJourney} />
        )}
        {chartsToShow.includes("routePerformance") && (
          <RoutePerformance filter={filter} showJourney={showJourney} />
        )}
        {chartsToShow.includes("routePerformanceByScore") && (
          <RoutePerformanceByScore filter={filter} showJourney={showJourney} />
        )}
        {chartsToShow.includes("vendorPerformance") && (
          <VendorPerformance filter={filter} showJourney={showJourney} />
        )}
        {chartsToShow.includes("journeyPerformance") && (
          <JourneyStatePerformance filter={filter} showJourney={showJourney} />
        )}
        {chartsToShow.includes("transitPerformance") && (
          <TransitPerformance filter={filter} showJourney={showJourney} />
        )}
        {chartsToShow.includes("nodePerformance") && (
          <NodePerformance filter={filter} showJourney={showJourney} />
        )}
      </JourneyOverviewContainer>
    </div>
  );
}
export default props => <JourneyOverview {...props} />;
