import {formatDate as format} from "../../components/Shipment/JourneyCard";
import React from "react";

function ShipmentPanelCard({
  shipment,
  colorConfig,
  onJourneySelect,
  selectedId
}) {
  const { journey } = shipment;
  return (
    <div
      style={{
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s",
        padding: "1em",
        margin: "0.25em",
        height: "10em",
        marginBottom: "1em",
        background: selectedId === shipment.sequence ? "#0275d8" : "#fff",
        color: selectedId === shipment.sequence ? "#fff" : "#444"
      }}
      onClick={() => onJourneySelect(shipment.sequence)}
    >
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            background: colorConfig.path,
            height: 16,
            width: 16,
            borderRadius: 8,
            borderSize: "1px",
            borderStyle: "solid",
            borderColor: journey.is_active ? "black" : "transparent"
          }}
        />
        <p>
          <b>Vehicle :</b> {journey.vehicle_no} <br />
          <b>LR No:</b>{" "}
          <a
            href={`/gps/history_track/journey_history/${journey.lr_no}`}
            target="_blank"
            style={{
              color: selectedId === shipment.sequence ? "#fff" : "#0275d8"
            }}
          >
            {journey.lr_no}
          </a>
          <br />
          <b>Invoice No:</b> {journey.invoice_no}
          <br />
          <b>Start Date:</b> {format(journey.start_date)}
          <br />
          {journey.end_date && (
            <p>
              <b>End Date:</b> {format(journey.end_date)}
            </p>
          )}
        </p>
      </div>
    </div>
  );
}

export default function ShipmentPanel({
  shipments,
  colorConfig,
  onJourneySelect,
  selectedId
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "85vh",
        overflowY: "auto"
      }}
    >
      {shipments.map((shipment, index) => (
        <ShipmentPanelCard
          shipment={shipment}
          selectedId={selectedId}
          colorConfig={colorConfig[index]}
          onJourneySelect={onJourneySelect}
          key={`${shipment.lr_no}_${index}`}
        />
      ))}
    </div>
  );
}
