import React from 'react'

import moment from 'moment'

const VALIDATIONS = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}

export default class CustomCall extends React.Component {
  state = {
    trip_id: this.props.trip_id,
    config: this.props.config,
    types: Object.keys(this.props.config),
    selected: Object.keys(this.props.config)
      ? Object.keys(this.props.config)[0]
      : null,
    selectedSubtype: '',
    formConfig: null,
    form: {},
    inputMultiple: {}
  }

  select = event => this.setState({ selected: event.target.value })

  selectSubType = event => {
    const selectedSubtype = event.target.value
    this.setState({ selectedSubtype }, () => {
      const { config, selected, selectedSubtype } = this.state
      const formConfig = config[selected][selectedSubtype]
      this.setState({ formConfig })
    })
  }

  updateForm = (name, val, multiple = false) => {
    const { form } = this.state
    this.setState({
      form: {
        ...form,
        [name]: name.includes('_date') ? moment(val).format('YYYY-MM-DD') : val
      }
    })
  }

  submit = () => {
    fetch('/trip_admin/custom_call.json', {
      method: 'POST',
      headers: ReactOnRails.authenticityHeaders({
        'Content-Type': 'application/json'
      }),
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({
        class_name: this.state.selected,
        method_name: this.state.selectedSubtype,
        args: this.state.form,
        trip_id: this.state.trip_id
      })
    })
      .then(data => data.json())
      .then(res => {
        swal('Done', 'Done', 'success')
        this.setState({ form: null })
      })
      .catch(err => console.log(err))
  }

  render() {
    const {
      config,
      types,
      selected,
      selectedSubtype,
      formConfig,
      form,
      inputMultiple
    } = this.state
    const selectedConfig = config[selected]
    const selectedTypes = Object.keys(selectedConfig)
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="col-md-4">
            <select
              className="form-control"
              value={selected}
              onChange={this.select}
            >
              {types &&
                types.map(type => (
                  <option value={type} key={type}>
                    {type}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-4">
            <select
              className="form-control"
              value={selectedSubtype}
              onChange={this.selectSubType}
            >
              <option value="''">Please select</option>
              {selectedTypes &&
                selectedTypes.map(type => (
                  <option value={type} key={type}>
                    {type}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="col-md-12">
          {formConfig &&
            Object.keys(formConfig)
              .filter(name => name !== 'description')
              .map((name, index) => {
                const inputOptions = formConfig[name]
                return (
                  <div
                    className={`col-md-${Math.ceil(
                      12 / (Object.keys(formConfig).length - 1)
                    )} `}
                    key={name}
                  >
                    <div id={name} className="form-group">
                      <label
                        htmlFor={`${name}_${index}`}
                      >{`Enter ${name}`}</label>
                      {inputOptions.multiple ? (
                        <input
                          type="text"
                          className="form-control"
                          name={name}
                          id={`${name}_${index}`}
                          value={form[name]}
                          placeholder={`Enter comma seperated ${name}`}
                          onChange={e => this.updateForm(name, e.target.value)}
                        />
                      ) : (
                        <input
                          className="form-control"
                          type={inputOptions.type}
                          name={name}
                          id={`${name}_${index}`}
                          value={form[name] || ''}
                          onChange={e => this.updateForm(name, e.target.value)}
                        />
                      )}
                    </div>
                  </div>
                )
              })}
          <div className="col-md-12">
            <button
              style={{ marginTop: '12px' }}
              type="button"
              className="btn btn-primary"
              onClick={this.submit}
              disabled={form.length > 0}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    )
  }
}
