import React from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { ranges } from "./../../../../utils";

export default function DateFilter({ filter, onChange }) {
  return (
    <div className="row">
      <div className="col-md-6">
        <DateRangePicker
          ranges={ranges}
          startDate={filter ? filter.value.startDate : moment()}
          endDate={filter ? filter.value.endDate : moment()}
          onEvent={(event, picker) => onChange(picker)}
        >
          <div>
            <i
              className="fa fa-filter fa-lg"
              style={{
                marginTop: "0.5em",
                color: "#E55B25"
              }}
            />
          </div>
        </DateRangePicker>
      </div>
      <div className="col-md-6 text-center">
        <i
          className={
            filter && filter.value.startDate
              ? "fa fa-times-circle fa-lg"
              : "hidden fa fa-filter fa-lg"
          }
          style={{
            marginTop: "0.5em",
            color: "#bebdbd"
          }}
          onClick={event => onChange(event)}
        />
      </div>
    </div>
  );
}
