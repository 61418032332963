import format from "date-fns/format";
import addMinutes from "date-fns/add_minutes";
import React from "react";

export function formatDate(input, formatTo = "DD-MM-YYYY HH:mm") {
  if (!input) return "...";
  const tzOffset = new Date().getTimezoneOffset()
  input = addMinutes(input, tzOffset)
  return format(input, formatTo);
}

function generateNodeColor(state) {
  if (!state) state = "";
  if (state.toLowerCase() === "delayed") {
    return "#C85757";
  } else if (state.toLowerCase() === "early") {
    return "#4FC3F7";
  } else if (state.toLowerCase() === "onTime") {
    return "#81C784";
  } else {
    return "#D7D7D7";
  }
}

export function ChildNode({ cx, r, info, dimension }) {
  const { svgHeight } = dimension;
  const svgMiddle = svgHeight / 2;
  const d = 2 * r;
  const textCy = svgMiddle + d + 10;
  return (
    <React.Fragment>
      <circle
        cx={cx}
        cy={svgMiddle}
        r={r}
        fill={generateNodeColor(info.state)}
      />
      {/* S.T.A */}
      {info.sta && (
        <text x={cx} y={textCy - 32 - 2 * d} fill="#B4B4B4" fontSize={10}>
          S.T.A : {formatDate(info.sta, "DD-MM HH:mm")}
        </text>
      )}
      {/* E.T.A */}
      {info.eta && (
        <text x={cx} y={textCy - 16 - d} fill="#B4B4B4" fontSize={10}>
          E.T.A : {formatDate(info.eta, "DD-MM HH:mm")}
        </text>
      )}
      {/* Node name */}
      <foreignObject x={cx} y={textCy - 15} width="150" height="20">
        <p
          style={{
            fontSize: 10,
            color: "#B4B4B4",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          title={info.name}
          xmlns="http://www.w3.org/1999/xhtml"
        >
          {info.name}
        </p>
      </foreignObject>
      {/* Node enter time */}
      {info.enterTime && (
        <text x={cx} y={textCy + d} fill="#B4B4B4" fontSize={10}>
          Enter: {formatDate(info.enterTime, "DD-MM HH:mm")}
        </text>
      )}
      {/* Node exit time */}
      {info.exitTime && (
        <text x={cx} y={textCy + 2 * d} fill="#B4B4B4" fontSize={10}>
          Exit: {formatDate(info.exitTime, "DD-MM HH:mm")}
        </text>
      )}
    </React.Fragment>
  );
}
