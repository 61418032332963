import React from "react";

import queryString from "query-string";
import _ from "lodash";
import MapAnalyticsForm from "./Form";
import MapAnalyticsSidebar from "./Sidebar";
import MapAnalyticsGoogleMap from "./Map";

export default class MapAnalytics extends React.Component {
  state = {
    form: this.props.form,
    map: this.props.map,
    selectedVehicleMarker: null,
    selectedCircleMarker: null,
  };
  submitForm = (data) => {
    let url = "/gps/set_gps/point_cluster.json";
    if (data) {
      url += "?" + queryString.stringify(data, { arrayFormat: "bracket" });
    }
    fetch(url, {
      method: "GET",
      headers: ReactOnRails.authenticityHeaders({
        "Content-Type": "application/json",
      }),
      mode: "cors",
      credentials: "include",
    })
      .then((data) => data.json())
      .then((res) => {
        const { map, form: resForm } = res;
        this.setState(({ form }) => ({
          map,
          form: { ...this.props.form, ...resForm },
        }));
      })
      .catch((err) => console.log(err));
  };

  selectVehicle = (vehicleNumber, index) => {
    this.setState({ selectedVehicleMarker: vehicleNumber + "_" + index });
  };

  selectCircle = (res) => {
    if (res) {
      const { location, index } = res;
      this.setState({ selectedCircleMarker: location + "_" + index });
    } else {
      this.setState({ selectedCircleMarker: null });
    }
  };

  render() {
    const {
      form,
      map,
      selectedVehicleMarker,
      selectedCircleMarker,
    } = this.state;
    return (
      <div className="row">
        <div className="col-md-12">
          {form && !_.isEmpty(form) && (
            <MapAnalyticsForm {...form} submitForm={this.submitForm} />
          )}
        </div>
        <div className="col-md-12">
          {_.isEmpty(map) ? (
            <p>Oops ! No data found </p>
          ) : (
            <div className="row">
              <div className="col-md-3">
                {map && !_.isEmpty(map) && (
                  <MapAnalyticsSidebar
                    result={map}
                    selectVehicle={this.selectVehicle}
                    selectCircle={this.selectCircle}
                  />
                )}
              </div>
              <div className="col-md-9">
                <div style={{ padding: "12.5px" }}>
                  {map && !_.isEmpty(map) && (
                    <MapAnalyticsGoogleMap
                      result={map}
                      selectedVehicleMarker={selectedVehicleMarker}
                      selectedCircleMarker={selectedCircleMarker}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
