import ReactOnRails from "react-on-rails";
import Alert from "../bundles/web/scenes/Alert";
import AlertIncidents from "../bundles/web/scenes/AlertIncidents";
import CardTimeline from "../bundles/web/scenes/CardTimeline";
import Comments from "../bundles/web/scenes/Comments";
import ConsignerOverview from "../bundles/web/scenes/ConsignerOverview";
import ConsignmentTripsHistory from "../bundles/web/scenes/Consignments/TripsHistory";
import CountdownTimer from "../bundles/web/scenes/CountdownTimer";
import CustomCall from "../bundles/web/scenes/CustomCall";
import GetEpodImages from "../bundles/web/scenes/Epod/GetImages";
import Eta from "../bundles/web/scenes/Eta";
import GenerateReport from "../bundles/web/scenes/GenerateReport";
import Geocomplete from "../bundles/web/scenes/Geocomplete";
import JourneyOverview from "../bundles/web/scenes/JourneyOverview";
import MapAllJourneyVehicles from "../bundles/web/scenes/MapAllJourneyVehicles";
import MapAllVehicles from "../bundles/web/scenes/MapAllVehicles";
import MapAnalytics from "../bundles/web/scenes/MapAnalytics";
import Nearby from "../bundles/web/scenes/Nearby";
import AddPoi from "../bundles/web/scenes/Poi/AddPoi";
import PoiOverview from "../bundles/web/scenes/Poi/PoiOverview";
import Refresh from "../bundles/web/scenes/Refresh";
import ShipmentHistory from "../bundles/web/scenes/ShipmentHistory";
import TransactionMap from "../bundles/web/scenes/TransactionMap";
import TransactionSidebar from "../bundles/web/scenes/TransactionSidebar";
import TripHistory from "../bundles/web/scenes/TripHistory/TripHistory";
import TripWiseStatus from "../bundles/web/scenes/TripWiseStatus";
import TruckHistory from "../bundles/web/scenes/TruckHistory";
// This is how react_on_rails can see the HelloWorld in the browser.

ReactOnRails.register({
  TransactionMap,
  Geocomplete,
  TransactionSidebar,
  MapAllVehicles,
  MapAllJourneyVehicles,
  TripHistory,
  TruckHistory,
  ConsignerOverview,
  PoiOverview,
  Alert,
  AlertIncidents,
  Refresh,
  GenerateReport,
  MapAnalytics,
  Eta,
  GetEpodImages,
  CountdownTimer,
  ConsignmentTripsHistory,
  AddPoi,
  TripWiseStatus,
  ShipmentHistory,
  JourneyOverview,
  CardTimeline,
  Comments,
  CustomCall,
  Nearby
});
