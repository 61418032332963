import React from "react";
import { Marker, InfoWindow } from "react-google-maps";
import StoppageInfoWindow from "./StoppageInfoWindow";
import { stoppageMarker } from "../../../../../utils";
import StoppageMarker from "./StoppageMarker";

const Stoppages = props => (
  <div>
    {props.data &&
      props.data
        .map((stoppage, i) => (
          <StoppageMarker
            info={stoppage}
            key={i}
            isOpen={Number(new Date(stoppage.end_timestamp).getTime()) === Number(props.selectedStoppageMarkerTimestamp)}
          />
        ))}
  </div>
);
export default Stoppages;
