import _ from "lodash";
import React from "react";
import { SkyLightStateless } from "react-skylight";
import { exportTableToCSV, googleMapUrl } from "../../../../utils";
import { MapFilters } from "./MapFilters";
import { MapRenderer } from "./MapRenderer";
import MapSidebar from "./MapSidebar";
import styled from "@emotion/styled";

export const MapAllVehiclesContainer = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-areas:
    "map"
    "list";
  @media screen and (min-width: 769px) {
    grid-template-columns: 400px minmax(0, 2fr);
    grid-template-areas: "list map";
  }
`;

export const MapContainer = styled.div`
  grid-area: map;
  @media screen and (maxwidth: 768px) {
    maxheight: calc(100vh - 175px);
  }
`;

class MapAllVehicles extends React.Component {
  state = {
    lastSelectedFilter: null,
    userRole: this.props.userRole,
    vehicles: this.props.vehicles,
    vehiclesOnTrips: this.props.vehicles.filter((v) => !!v.consigner_trip),
    sidebarVehicles: this.props.vehicles,
    statuses: _.countBy(this.props.vehicles, (x) => x.filter.gpsStatus),
    tripStates: _.countBy(this.props.vehicles, (x) => x.filter.tripState),
    materials: _.countBy(this.props.vehicles, (x) => x.filter.material),
    regions: _.countBy(this.props.vehicles, (x) => x.filter.region),
    engineStates: _.countBy(this.props.vehicles, (x) => x.filter.engineState),
    vehicleStatuses: _.countBy(
      this.props.vehicles,
      (x) => x.filter.vehicleStatus
    ),
    tripStatuses: _.countBy(this.props.vehicles, (x) => x.filter.tripStatus),
    selectedVehicles: [],
    pointPOIs: this.props.pointPOIs,
    polygonPOIs: this.props.polygonPOIs,
    filter: {
      gpsStatus: null,
      vehicleStatus: null,
      tripState: null,
      tripStatus: null,
      engineState: null,
    },
    vehiclesByLocationState: _.groupBy(
      this.props.vehicles,
      (vehicle) => vehicle.location.state
    ),
    showEditForm: false,
    editForm: {
      // start_date: null,
      // end_date: null,
      status: null,
    },
    placeSelected: {
      location: null,
      coords: null,
      radius: null,
    },
    loading: false,
    radiusSelected: 0,
    pois: this.props.pois,
    regions: _.countBy(this.props.vehicles, (x) => x.filter.region),
  };

  componentDidMount() {
    this.populateDefaultStatuses(this.props.vehicles);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      _.isEqual(
        nextState.vehicles.map((x) => x.vehicle_no),
        this.state.vehicles.map((x) => x.vehicle_no)
      )
    ) {
      return false;
    }
    return true;
  }

  populateDefaultStatuses = (vehicles) => {
    this.setState({ loading: true }, () => {
      this.populateStatus(vehicles);
    });
  };

  populateStatus = (vehicles) => {
    let { statuses, lastSelectedFilter } = this.state;
    if (lastSelectedFilter !== "gpsStatus") {
      statuses = _.countBy(vehicles, (x) => x.filter.gpsStatus);
    }

    this.setState({
      sidebarVehicles: vehicles,
      vehicles,
      statuses,
      loading: false,
    });
  };

  search = (event) => {
    event.preventDefault();
    if (event.target.value) {
      let result = this.props.vehicles.filter((vehicle) => {
        const { consigner_trip, location } = vehicle;
        let arr = consigner_trip
          ? Object.values(consigner_trip)
          : Object.values(location);
        return arr.some((el) =>
          String(el)
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        );
      });
      this.populateStatus(result);
    }
  };

  onSelect = (selectedVehicle) => {
    let { selectedVehicles } = this.state;
    let { vehicles } = this.props;
    selectedVehicles = _.xor(selectedVehicles, [selectedVehicle]);

    if (selectedVehicles && selectedVehicles.length) {
      vehicles = vehicles.filter((vehicle, index) =>
        selectedVehicles.includes(vehicle.vehicle_no)
      );
    }

    this.setState({
      selectedVehicles,
      vehicles,
    });
  };

  applyFilter = () => {
    const { filter: selectedFilter } = this.state;
    let { vehicles } = this.props;
    if (Object.values(selectedFilter).filter((x) => !x).length > 0) {
      vehicles = vehicles.filter((vehicle) => {
        const nonEmptySelectedFilter = _.omitBy(selectedFilter, _.isNil);
        const nonEmptyVehicleStatuses = _.pick(
          vehicle.filter,
          Object.keys(nonEmptySelectedFilter)
        );
        let isEqual = false;
        if (
          Object.keys(nonEmptyVehicleStatuses).length === 0 &&
          _.isEqual(nonEmptySelectedFilter, nonEmptyVehicleStatuses)
        ) {
          return true;
        }
        Object.entries(nonEmptySelectedFilter).forEach(([key, value]) => {
          if (nonEmptyVehicleStatuses[key]) {
            isEqual = nonEmptyVehicleStatuses[key].includes(value);
          }
        });
        return isEqual;
      });
    }

    this.populateStatus(vehicles);
  };

  onMapFilterOptionsClick = (opt = {}) => {
    this.setState(
      ({ filter }) => ({
        filter: { ...filter, ...opt },
        lastSelectedFilter: Object.keys(opt)[0],
      }),
      () => {
        this.applyFilter();
      }
    );
  };

  openVehicleEditModal = (truck_number) => {
    this.setState(
      (state) => ({ editForm: { ...state.editForm, truck_number } }),
      () => this.setState({ showEditForm: true })
    );
  };

  closeVehicleEditModal = () => {
    this.setState({ showEditForm: false });
  };

  populateEditForm = (e) => {
    const selected = {
      [e.target.getAttribute("name")]: e.target.value,
    };
    this.setState((state) => ({
      editForm: {
        ...state.editForm,
        ...selected,
      },
    }));
  };

  submitEditForm = (e) => {
    e.preventDefault();
    const { editForm } = this.state;
    const { start_date, end_date } = editForm;
    if (Object.values(editForm).filter((n) => !n).length > 0) {
      alert("Please select all the fields");
    }
    // if(new Date(end_date) - new Date(start_date) < 0) {
    //   alert("Please select end date after start date")
    // }
    this.submit(editForm);
  };

  submit = async ({ truck_number, start_date, end_date, status }) => {
    try {
      const response = await (await fetch(
        `/trucker/trucks/${truck_number}.json`,
        {
          method: "PATCH",
          body: JSON.stringify({ truck: { status, start_date, end_date } }),
          headers: ReactOnRails.authenticityHeaders({
            "Content-Type": "application/json",
          }),
          mode: "cors",
          credentials: "include",
        }
      )).json();
      if (response && response.success) {
        this.closeVehicleEditModal();
        alert("Updated !");
      }
    } catch (error) {
      this.closeVehicleEditModal();
      alert("Oops ! Unable to update. Please try later.");
    }
  };

  onPlaceSelect = (placeSelected) => {
    this.setState((state) => ({
      placeSelected: { ...state.placeSelected, ...placeSelected },
    }));
  };

  handleRadiusChange = (e) => {
    const val = event.target.value;
    this.setState((state) => ({
      placeSelected: { ...state.placeSelected, radius: Number(val) },
      radiusSelected: Number(val),
    }));
  };

  handleRadiusMetricsChange = (e) => {
    const val = e.target.value;
    const { radiusSelected } = this.state;
    const radius = radiusSelected / val;
    this.setState((state) => ({
      placeSelected: { ...state.placeSelected, radius },
    }));
  };

  searchPlace = () => {
    this.setState({ loading: true }, () => {
      const { coords, radius } = this.state.placeSelected;
      const [lat, lng] = Object.values(JSON.parse(coords));
      fetch(
        `${window.location.href}.json?lat=${lat}&lng=${lng}&radius=${radius}`,
        {
          headers: ReactOnRails.authenticityHeaders({
            "Content-Type": "application/json",
          }),
          credentials: "same-origin",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            this.setState(
              (state) => ({ ...state, ...data }),
              () => {
                this.populateDefaultStatuses(data.vehicles);
              }
            );
          }
        })
        .catch((err) => console.log(err));
    });
  };

  clearPlace = () => {
    this.populateDefaultStatuses(this.props.vehicles);
  };

  render() {
    const {
      statuses,
      states,
      tripStates,
      tripStatuses,
      vehicleStatuses,
      sidebarVehicles,
      selectedVehicles,
      userRole,
      vehicles,
      pointPOIs,
      polygonPOIs,
      showEditForm,
      editForm,
      placeSelected,
      loading,
      filter,
      regions,
      materials,
      pois,
      engineStates
    } = this.state;
    const { inplant: isInplant, clustering } = this.props;
    const { truck_number, status } = editForm;

    const searchButtonDisabled =
      Object.values(placeSelected).filter((n) => !n).length > 0;
    return (
      <MapAllVehiclesContainer id="map-all-vehicles-container">
        <div style={{ gridArea: "list" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              type="search"
              className="form-control form-control-sm"
              name="search"
              placeholder="Search vehicles"
              onChange={this.search}
            />
            <button
              className="btn btn-sm btn-info fa fa-file-excel-o"
              onClick={() =>
                exportTableToCSV(
                  "tableMapAllVehicles",
                  `MapAll_${new Date().toJSON().slice(0, 10)}.csv`
                )
              }
            >
              &nbsp;Download
            </button>
          </div>
          {!loading ? (
            <div style={{ height: "75vh", overflowY: "auto" }}>
              <MapSidebar
                vehicles={sidebarVehicles}
                onSelect={this.onSelect}
                selectedVehicles={selectedVehicles}
                userRole={userRole}
                isInplant={isInplant}
                edit={this.openVehicleEditModal}
              />
            </div>
          ) : (
            <p>Loading ...</p>
          )}
          <SkyLightStateless
            isVisible={showEditForm}
            onCloseClicked={() => this.closeVehicleEditModal()}
            title={`Edit : ${truck_number}`}
          >
            <p className="lead">Make this vehicle</p>
            <form>
              <select
                className="form-control"
                name="status"
                defaultValue={status}
                onChange={this.populateEditForm}
              >
                <option value="">Select new state</option>
                <option value="inactive">Inactive</option>
                <option value="maintainance">Under Maintainance</option>
              </select>
              <button
                className="btn btn-warning"
                type="submit"
                onClick={this.submitEditForm}
                style={{ marginTop: "1em" }}
              >
                Save
              </button>
            </form>
          </SkyLightStateless>
        </div>
        <MapContainer>
          {!loading ? (
            <React.Fragment>
              <details
                className="map-filter"
                open
                style={{
                  position: "absolute",
                  right: 0,
                  top: "3em",
                  paddingRight: 0,
                  maxWidth: "175px",
                  maxHeight: "75vh",
                  overflowY: "auto",
                  zIndex: 1,
                }}
              >
                <summary style={{ position: "relative", marginRight: "2em" }}>
                  Filters
                </summary>
                <div style={{ position: "relative" }}>
                  <MapFilters
                    filters={{
                      statuses,
                      states,
                      tripStates,
                      vehicleStatuses,
                      tripStatuses,
                      regions,
                      materials,
                      engineStates,
                    }}
                    selectedFilter={filter}
                    onMapFilterOptionsClick={this.onMapFilterOptionsClick}
                  />
                </div>
              </details>
              <MapRenderer
                googleMapURL={googleMapUrl}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `82vh` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                vehicles={vehicles}
                pointPOIs={pointPOIs}
                clustering={clustering}
                inplant={isInplant}
                pois={pois}
                polygonPOIs={polygonPOIs}
              />
            </React.Fragment>
          ) : (
            <p>Loading Map ...</p>
          )}
        </MapContainer>
      </MapAllVehiclesContainer>
    );
  }
}

export default MapAllVehicles;
