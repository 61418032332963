import React from 'react'
import _ from 'lodash'
import Table from './Table'

class VendorPerformanceTable extends React.Component {
  state = {
    data: this.props.data,
    tripStates: [...new Set(this.props.data.map(trip => trip.state))].filter(
      n => n
    ),
    statuses: [...new Set(this.props.data.map(trip => trip.status))].filter(
      n => n
    ),
    columns: [
      'vehicle_no',
      'origin',
      'destination',
      'loading_in_time',
      'loading_out_time',
      'unloading_in_time',
      'unloading_out_time',
      'transit_time',
      'sta',
      'state',
      'percentage_trip_completed',
      'transporter',
      'consigner_name',
      'consignee_name'
    ],
    hasNewData: false
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.data, state.data)) {
      return { hasNewData: true }
    }
    return null
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hasNewData !== prevProps.hasNewData && prevProps.hasNewData) {
      this.populateData(this.props.data)
    }
  }

  populateData = data => {
    this.setState({
      data: data,
      tripStates: [...new Set(data.map(trip => trip.state))].filter(n => n),
      statuses: [...new Set(data.map(trip => trip.status))].filter(n => n)
    })
  }

  render() {
    return (
      <div>
        {this.state.columns && (
          <Table
            data={this.state.data}
            statuses={this.state.statuses}
            tripStates={this.state.tripStates}
            columns={this.state.columns}
            tripCompleted={this.props.tripCompleted}
            userRole={this.props.userRole}
          />
        )}
      </div>
    )
  }
}

export default VendorPerformanceTable
