import React from 'react'

import moment from 'moment'
const momentDurationFormatSetup = require('moment-duration-format')
export default class MapAnalyticsSidebar extends React.Component {
  state = {
    checked: null
  }

  selectCircle = (event, { location, index }) => {
    const checked = event.target.checked
    if (checked) {
      this.setState({ checked: index })
      this.props.selectCircle({ location, index })
    } else {
      this.setState({ checked: null })
      this.props.selectCircle(null)
    }
  }

  selectVehicle = (event, { vehicle, index }) => {
    event.preventDefault()
    this.props.selectVehicle(vehicle, index)
  }
  render() {
    console.log(this.props)
    const { result: data } = this.props
    const coordArr = Object.keys(data)
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="row" style={{ padding: '0.5em 1em' }}>
            <div className="col-md-1" />
            <div className="col-md-4">
              <p className="lead">Vehicles</p>
            </div>
            <div className="col-md-5">
              <p className="lead">Avg Stoppage</p>
            </div>
            <div className="col-md-2" />
          </div>
          <div style={{ maxHeight: '66vh', overflowY: 'auto' }}>
            {!!data &&
              Object.values(data).map((item, index) => (
                <div
                  className="row"
                  key={`sidebar_item_${index}`}
                  style={{ padding: '0.5em 1em' }}
                >
                  <div className="col-md-1">
                    <input
                      type="checkbox"
                      onChange={e =>
                        this.selectCircle(e, {
                          location: coordArr[index],
                          index
                        })
                      }
                      checked={this.state.checked === index}
                    />
                  </div>
                  <div
                    className="col-md-4"
                    style={{
                      maxHeight: '150px',
                      overflowY: 'auto',
                      padding: '0 5'
                    }}
                  >
                    {Object.keys(item).map(vehicle => (
                      <a
                        key={`sidebar_link_${vehicle}_${index}`}
                        onClick={e =>
                          this.selectVehicle(e, {
                            vehicle,
                            index: item[vehicle].duration
                          })
                        }
                        style={{ display: 'block' }}
                      >
                        {vehicle}
                      </a>
                    ))}
                  </div>
                  <div className="col-md-5">
                    {moment
                      .duration(
                        Object.values(item)
                          .map(e => e.duration)
                          .reduce((a, b) => a + b, 0),
                        'second'
                      )
                      .format('HH [hr] mm [min]')}
                  </div>
                  <div className="col-md-2">
                    <button type="button" className="btn btn-sm btn-dipper">
                      <i className="fa fa-plus" />
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }
}
