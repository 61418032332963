import React from "react";
import { InfoWindow, Marker } from "react-google-maps";
import { customMapIcon, dipperTruckMarker, trainIcon } from "../../../../utils";
import InfoWindowBox from "./InfoWindowBox";

class SimpleMarker extends React.Component {
  state = {
    isOpen: this.props.isOpen,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: !this.state.isOpen });
    }
  }

  _onToggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <Marker
        position={{
          lat: Number(this.props.location.latitude),
          lng: Number(this.props.location.longitude),
        }}
        markerWithLabel={window.MarkerWithLabel}
        onClick={this._onToggleOpen}
        icon={
          this.props.label === "A"
            ? customMapIcon({ fillColor: "#2ecc71" })
            : this.props.label === "B"
            ? customMapIcon({ fillColor: "#e52116" })
            : this.props.label === "O"
            ? customMapIcon({ fillColor: "#2196F3" })
            : this.props.mode === "rail"
            ? trainIcon
            : dipperTruckMarker
        }
        label={{
          color: "white",
          text: this.props.label || "",
        }}
        labelClass={this.props.labelClassName || "default"}
        key={"simple_marker_" + this.props.location.ist_timestamp}
      >
        {this.state.isOpen && (
          <InfoWindow onCloseClick={this._onToggleOpen}>
            <InfoWindowBox info={this.props} />
          </InfoWindow>
        )}
      </Marker>
    );
  }
}

export default SimpleMarker;
