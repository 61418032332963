import React from 'react'

export default function SubscribeIssue({
  isSubscribed,
  threadId,
  type,
  token
}) {
  const [subscription, setSubscription] = React.useState(isSubscribed)

  async function subscribe() {
    try {
      const type = isSubscribed ? 'unsubscribe' : 'subscribe'
      const url = `/commontator/threads/${threadId}/${type}.json`
      const response = await (await fetch(
        token ? url + `?token=${token}` : url,
        {
          method: 'PUT',
          headers: ReactOnRails.authenticityHeaders({
            'Content-Type': 'application/json'
          }),
          mode: 'cors',
          credentials: 'include',
          body: JSON.stringify({ token: token })
        }
      )).json()
      if (response) {
        setSubscription(!subscription)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <button
      type="button"
      className="btn btn-secondary"
      onClick={() => subscribe()}
    >
      {subscription ? `Unsubscribe` : 'Subscribe'}
    </button>
  )
}
