import React from "react";
import {
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaFlag,
  FaHome,
} from "react-icons/fa";
import { Flex } from "./../../components/Shipment";
import { ChildNode, formatDate } from "./ChildNode";
import styled from "@emotion/styled";

const TimelineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TimelineRow = styled.div`
  display: grid;
  grid-template-columns: 90px auto 90px;
  ${"" /* grid-template-rows: repeat(2, 1fr); */}
  grid-template-areas:
    "start-node timeline end-node"
    "footer footer footer";
  @media screen and (max-width: 425px) {
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 1em;
    grid-template-areas:
      "start-node start-node start-node"
      "timeline timeline timeline"
      "end-node end-node end-node"
      "footer footer footer";
  }
`;

const TimelineBase = ({ x, y, width, height, fill = "#C4C4C4" }) => {
  return <rect x={x} y={y} width={width} height={height} fill={fill} />;
};

function Timeline({ dimension, data }) {
  const screenWidth = window.screen.width;
  let minNodes = 6;
  if (screenWidth < 426) {
    minNodes = 1;
  }
  if (screenWidth > 425 && screenWidth < 769) {
    minNodes = 2;
  }
  const [nodeIndex, setNodeIndex] = React.useState(0);
  const [nodesToShow, setNodesToShow] = React.useState(
    Math.max(Math.min(minNodes, data.childNodes.length - 2), 0)
  );

  React.useEffect(() => {
    setNodesToShow(Math.max(Math.min(minNodes, data.childNodes.length - 2), 0));
  }, [data]);

  let { width: svgWidth } = dimension;
  svgWidth -= 260;
  svgWidth = Math.max(175, svgWidth);
  const svgHeight = 80;
  const baseLineHeight = 2;
  const timelineMiddle = svgHeight / 2 - baseLineHeight / 2;
  const dataNodeRadius = 5;
  const timelineWidth = svgWidth - 2 * dataNodeRadius;

  let { childNodes: dataNodes } = data;
  const firstNode = dataNodes[0];
  const lastNode = dataNodes[dataNodes.length - 1];
  const noOfNodes = Math.min(dataNodes.length, nodesToShow);

  dataNodes = dataNodes.filter(
    (_, i) => i > nodeIndex && i <= nodeIndex + noOfNodes
  );
  const headStart = timelineWidth / (noOfNodes + 1);
  const nodes = dataNodes.map((node, index, nodeArr) => ({
    cx: headStart * (index + 1) + dataNodeRadius * 2,
    svgHeight,
    r: dataNodeRadius,
    info: node,
    currentNodeIndex: index,
    lastNodeIndex: nodeArr.length - 1,
    sequence: node.sequence,
  }));

  const disableNext =
    data.childNodes.length - 2 <= nodesToShow ||
    nodeIndex === data.childNodes.length - 2 - nodesToShow;
  const disablePrevious = nodeIndex < 1;

  let showNodeNav = true;
  // if (dataNodes.length >= nodesToShow) {
  //   showNodeNav = true;
  // }

  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "0 1em" }}>
      <TimelineContainer id="timeline-row">
        {showNodeNav && (
          <div style={{ width: 20 }}>
            <button
              onClick={() => setNodeIndex(nodeIndex - 1)}
              disabled={disablePrevious}
              style={{
                border: "none",
                backgroundColor: "transparent",
              }}
            >
              <FaChevronCircleLeft
                size="20"
                color={disablePrevious ? "#727272" : "#03A9F4"}
              />
            </button>
          </div>
        )}
        <TimelineRow clssName="timeline-row">
          <Flex
            direction="column"
            justifyContent="center"
            style={{ gridArea: "start-node" }}
          >
            <div style={{textAlign: "center"}}>
              {firstNode.sta && (
                <div style={{ fontSize: 10, color: "#B4B4B4" }}>
                  Loc. Start <br />
                  {formatDate(firstNode.sta, "DD-MM HH:mm")}
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaHome color="#A3A3A3" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <div style={{ fontSize: 10, textAlign: "center" }}>
                {firstNode.name} {firstNode.city ? firstNode.city : null}
              </div>
              {firstNode.enterTime && (
                <div style={{ fontSize: 10, color: "#B4B4B4" }}>
                  Enter - {formatDate(firstNode.enterTime, "DD-MM HH:mm")}
                </div>
              )}
              {firstNode.exitTime && (
                <div style={{ fontSize: 10, color: "#B4B4B4" }}>
                  Exit - {formatDate(firstNode.exitTime, "DD-MM HH:mm")}
                </div>
              )}
            </div>
          </Flex>
          {nodes && (
            <div style={{ gridArea: "timeline" }}>
              <svg
                width={svgWidth}
                height={svgHeight}
                viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                fill="none"
                overflow="auto"
                xmlns="http://www.w3.org/2000/svg"
              >
                <TimelineBase
                  x={dataNodeRadius}
                  y={timelineMiddle}
                  width={timelineWidth}
                  height={baseLineHeight}
                />
                {nodes.map((node, index) => (
                  <ChildNode
                    {...node}
                    dimension={{ svgHeight }}
                    key={`data_${data.lrNo}_node_${index}`}
                  />
                ))}
              </svg>
            </div>
          )}
          <Flex
            direction="column"
            justifyContent="center"
            style={{ gridArea: "end-node" }}
          >
            <div style={{textAlign: "center"}}>
              {lastNode.sta && (
                <div style={{ fontSize: 10, color: "#B4B4B4" }}>
                  Loc. STA <br />
                  {formatDate(lastNode.sta, "DD-MM HH:mm")}
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaFlag color="#A3A3A3" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <div style={{ fontSize: 10, textAlign: "center" }}>
                {lastNode.name} {lastNode.city ? lastNode.city : null}
              </div>
              {lastNode.enterTime && (
                <div style={{ fontSize: 10, color: "#B4B4B4" }}>
                  Enter - {formatDate(lastNode.enterTime, "DD-MM HH:mm")}
                </div>
              )}
              {lastNode.exitTime && (
                <div style={{ fontSize: 10, color: "#B4B4B4" }}>
                  Exit - {formatDate(lastNode.exitTime, "DD-MM HH:mm")}
                </div>
              )}
            </div>
          </Flex>
          {data.childNodes.length > 2 ? (
            <div
              style={{
                gridArea: "footer",
                textAlign: "center",
                color: "#B4B4B4",
                width: "100%",
              }}
            >
              Showing{" "}
              <input
                type="number"
                min={1}
                step={1}
                max={data.childNodes.length - 2}
                value={nodesToShow}
                onChange={(e) => setNodesToShow(e.target.value)}
                style={{ fontSize: "1em", height: "1.5em" }}
              />{" "}
              / {data.childNodes.length - 2} nodes
            </div>
          ) : null}
        </TimelineRow>
        {showNodeNav && (
          <div style={{ width: 20 }}>
            <button
              onClick={() => setNodeIndex(nodeIndex + 1)}
              disabled={disableNext}
              style={{
                border: "none",
                backgroundColor: "transparent",
              }}
            >
              <FaChevronCircleRight
                size="20"
                color={disableNext ? "#727272" : "#03A9F4"}
              />
            </button>
          </div>
        )}
      </TimelineContainer>
    </div>
  );
}

export default Timeline;
