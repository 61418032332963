import React from 'react'
import { InfoWindow, Polygon } from 'react-google-maps'

export default function PolygonMarker({ paths, options, visible, info }) {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <>
      <Polygon
        paths={paths}
        options={options}
        visible={visible}
        onRightClick={() => {
          setIsOpen(!isOpen)
        }}
      />
      {isOpen ? (
        <InfoWindow
          position={{ lat: Number(info.latitude), lng: Number(info.longitude) }}
          onCloseClick={() => {
            console.log('close')
            setIsOpen(!isOpen)
          }}
        >
          <div>{info.poi_nick_name}</div>
        </InfoWindow>
      ) : null}
    </>
  )
}
