import React from "react";
const titleize = require("titleize");

const AlertList = (props) => {
  const subs = props.subscriptions
    ? props.subscriptions.filter(
        (sub) => sub.type === Object.keys(props.alert)[0]
      )
    : null;
  return (
    <div>
      {subs && (
        <table className="table">
          <thead className="thead-inverse">
            <tr>
              <th>User</th>
              {subs &&
                !!subs[0] &&
                subs[0].settings &&
                Object.keys(subs[0].settings).map((name) => (
                  <th key={name}>{titleize(name)}</th>
                ))}
              <th />
            </tr>
          </thead>
          <tbody>
            {subs &&
              subs.map((subscription) => (
                <tr key={subscription.id}>
                  <td>
                    {subscription.users.map((user) => (
                      <div
                        style={{ display: "flex", flexDirection: "row" }}
                        key={user.id}
                      >
                        <div style={{ flex: 1 }}>{user.full_name}</div>
                        <div style={{ flex: 1 }}>{user.phone_no}</div>
                        <div style={{ flex: 1 }}>{user.email}</div>
                      </div>
                    ))}
                  </td>
                  {subscription &&
                    subscription.settings &&
                    Object.keys(subscription.settings).map((name) => {
                      return (
                        <td key={name}>
                          {subscription.settings[name]}{" "}
                          {props.alert[subscription.type].units[name]}
                        </td>
                      );
                    })}
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => props.delete(subscription.id)}
                    >
                      <i className="fa fa-trash-o" />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
export default AlertList;
