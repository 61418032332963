import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { SkyLightStateless } from 'react-skylight'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import PoiVivoChartTable from './PoiVivoChartTable'
require('moment-duration-format')

const VivoTooltip = props => {
  let { active } = props
  if (active) {
    const { payload, label } = props
    return !!payload[0] ? (
      <div className="custom-tooltip">
        <p className="label">Date: {payload[0].date}</p>
        <p className="intro">
          Avg VIVO :{' '}
          {moment
            .duration(payload[0].payload.avg, 'seconds')
            .format('h [hr], m [min]')}
        </p>
      </div>
    ) : null
  }
  return null
}

const bigModal = {
  width: '100%',
  height: '100%',
  overflow: 'auto',
  marginTop: '0px',
  marginLeft: '0px',
  top: '5%',
  left: '0'
}

export default class PoiVivoChart extends React.Component {
  state = {
    data: null,
    trips: null,
    startDate: null,
    modalVisible: false,
    avgVivoSelected: null
  }

  componentDidMount() {
    this.populateData(this.props.data)
  }

  componentWillReceiveProps(nextProps) {
    this.populateData(nextProps.data)
  }

  populateData = poiData => {
    let data = poiData.all
    let newData = {}
    data.forEach(item => {
      if (!newData[moment.utc(item.enter_time).format('YYYY-MM-DD')])
        newData[moment.utc(item.enter_time).format('YYYY-MM-DD')] = []
      newData[moment.utc(item.enter_time).format('YYYY-MM-DD')].push(item)
    })
    newData = Object.keys(newData)
      .map(key => {
        let avg = Number(
          (
            _.sum(
              [...newData[key]].map(
                item =>
                  (new Date(item.exit_time).getTime() -
                    new Date(item.enter_time).getTime()) /
                  1000
              )
            ) / newData[key].length
          ).toFixed(2)
        )
        return { date: key, avg }
      })
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    this.setState({ data: newData })
  }

  _showGraphModal = (data, index) => {
    let startDate = data.activeLabel
    let avgVivoSelected = moment
      .duration(data.activePayload[0].payload.avg, 'seconds')
      .format('d hh [hr] mm [min]')
    let trips = this.props.data.all.filter(
      trip =>
        moment.utc(new Date(trip.enter_time)).format('YYYY-MM-DD') === startDate
    )
    this.setState({ startDate, trips, modalVisible: true, avgVivoSelected })
  }

  closeModal = () => {
    this.setState({
      modalVisible: false
    })
  }

  render() {
    return (
      <div>
        <hr />
        {this.state.data && this.state.data.length ? (
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              width={600}
              height={300}
              data={this.state.data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              onClick={this._showGraphModal}
            >
              <XAxis dataKey="date" padding={{ left: 30, right: 30 }} />
              <YAxis
                tickFormatter={duration =>
                  moment.duration(duration, 'seconds').format('h [hr]')
                }
                width={50}
              />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip content={<VivoTooltip />} />
              <Legend />
              <Line
                type="monotone"
                dataKey="avg"
                stroke="#e55B25"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : null}
        <SkyLightStateless
          title="Vivo Analysis"
          dialogStyles={bigModal}
          isVisible={this.state.modalVisible}
          onCloseClicked={() => this.closeModal()}
          title={
            <div className="row skylight-dipper">
              <h5 className="text-center">
                Vehicles with avg. vivo : {this.state.avgVivoSelected}, Entered
                inside POI on: {this.state.startDate}
              </h5>
            </div>
          }
        >
          {this.state.trips && (
            <PoiVivoChartTable
              data={this.state.trips}
              date={this.state.startDate}
              avgVivo={this.state.avgVivoSelected}
            />
          )}
        </SkyLightStateless>
      </div>
    )
  }
}
