import React from 'react'
import moment from 'moment'
require('moment-duration-format')
import DateFilter from './DateFilter'
import {
  ranges,
  formatDate,
  filterDateColumn,
  calculateDuration
} from './../../../../utils'

const Arrow = props => (
  <div>
    {props.value}{' '}
    <i
      className={`fa fa-arrow-${
        props.sign > 0 ? 'up text-green' : 'down text-red'
      }`}
    />
  </div>
)

const Columns = (props, userRole = 'Consigner') => ({
  vehicle_no: {
    Header: 'Vehicle Number',
    id: 'vehicle_no',
    accessor: d => {
      let aHref = `/consigner/transactions/${d.slug}`
      if (userRole !== 'Consigner') {
        aHref = `/${userRole.toLowerCase()}/geo_trips/${
          d.slug
        }/consigner_trip_trucks_history`
      }
      return (
        <a href={aHref} target="_blank">
          {d.vehicle_no}
        </a>
      )
    },
    filterMethod: (filter, row) => {
      return row[filter.id].props.children.includes(filter.value)
    }
  },
  origin: {
    Header: 'Origin',
    id: 'origin',
    accessor: 'origin' // String-based value accessors!
  },
  destination: {
    Header: 'Destination',
    id: 'destination',
    accessor: 'destination' // String-based value accessors!
  },
  loading_in_time: {
    Header: 'Loading In',
    id: 'loading_in_time',
    accessor: d => formatDate(d, 'loading_in_time', 'YY-MM-DD hh:mm A'),
    sortMethod: (a, b) => new Date(b) - new Date(a),
    filterMethod: (filter, row) =>
      filterDateColumn(filter, row, 'YY-MM-DD hh:mm A'),
    Filter: ({ filter, onChange }) => (
      <DateFilter filter={filter} onChange={onChange} />
    )
  },
  loading_out_time: {
    Header: 'Loading Out',
    id: 'loading_out_time',
    accessor: d => formatDate(d, 'loading_out_time', 'YY-MM-DD hh:mm A'),
    sortMethod: (a, b) => new Date(b) - new Date(a),
    filterMethod: (filter, row) =>
      filterDateColumn(filter, row, 'YY-MM-DD hh:mm A'),
    Filter: ({ filter, onChange }) => (
      <DateFilter filter={filter} onChange={onChange} />
    )
  },
  unloading_in_time: {
    Header: 'Unloading In',
    id: 'unloading_in_time',
    accessor: d => formatDate(d, 'unloading_in_time', 'YY-MM-DD hh:mm A'),
    sortMethod: (a, b) => new Date(b) - new Date(a),
    filterMethod: (filter, row) =>
      filterDateColumn(filter, row, 'YY-MM-DD hh:mm A'),
    Filter: ({ filter, onChange }) => (
      <DateFilter filter={filter} onChange={onChange} />
    )
  },
  unloading_out_time: {
    Header: 'Unloading Out',
    id: 'unloading_out_time',
    accessor: d => formatDate(d, 'unloading_out_time', 'YY-MM-DD hh:mm A'),
    sortMethod: (a, b) => new Date(b) - new Date(a),
    filterMethod: (filter, row) =>
      filterDateColumn(filter, row, 'YY-MM-DD hh:mm A'),
    Filter: ({ filter, onChange }) => (
      <DateFilter filter={filter} onChange={onChange} />
    )
  },
  percentage_trip_completed: {
    Header: '% Trip Completed',
    id: 'percentage_trip_completed',
    accessor: d =>
      props.tripCompleted[d.id]
        ? props.tripCompleted[d.id] + ' %'
        : d.state.toLowerCase() !== 'completed'
        ? '...'
        : '100%',
    sort: (a, b) => Number(a.replace(' %', '')) - Number(b.replace(' %', '')),
    filterMethod: (filter, row) =>
      row[filter.id].replace(' %', '').includes(filter.value)
  },
  vivo: {
    Header: 'VIVO',
    id: 'vivo',
    accessor: d => {
      const result =
        d.unloading_out_time && d.unloading_in_time
          ? moment.duration(
              (new Date(d.unloading_out_time).getTime() -
                new Date(d.unloading_in_time).getTime()) /
                1000,
              'seconds'
            )
          : null
      return d.unloading_out_time && d.unloading_in_time
        ? result
          ? result.format('h [hr] m [min]')
          : '43 mins'
        : ''
    },
    filterMethod: (filter, row) => {
      let lowerLimit = filter.value.split(',')[0]
      let upperLimit = filter.value.split(',')[1] || 100
      let rowVal = Number(row[filter.id].split(' ')[0])
      return rowVal >= lowerLimit && rowVal < upperLimit
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ''}
        style={{
          width: '100%',
          height: '100%'
        }}
      >
        <option value="">Show All</option>
        {props.vivoOptions &&
          props.vivoOptions.map((opt, index) => (
            <option value={opt} key={index}>
              {opt[0]} - {opt[1] || 'more'}
            </option>
          ))}
      </select>
    )
  },
  loading_vivo: {
    Header: 'VIVO',
    id: 'vivo',
    accessor: d => {
      const result =
        d.loading_out_time && d.loading_in_time
          ? moment.duration(
              (new Date(d.loading_out_time).getTime() -
                new Date(d.loading_in_time).getTime()) /
                1000,
              'seconds'
            )
          : null
      return d.loading_out_time && d.loading_in_time
        ? result
          ? result.format('h [hr] m [min]')
          : '43 mins'
        : ''
    },
    filterMethod: (filter, row) => {
      let lowerLimit = filter.value.split(',')[0]
      let upperLimit = filter.value.split(',')[1] || 100
      let rowVal = Number(row[filter.id].split(' ')[0])
      return rowVal >= lowerLimit && rowVal < upperLimit
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ''}
        style={{
          width: '100%',
          height: '100%'
        }}
      >
        <option value="">Show All</option>
        {props.vivoOptions &&
          props.vivoOptions.map((opt, index) => (
            <option value={opt} key={index}>
              {opt[0]} - {opt[1] || 'more'}
            </option>
          ))}
      </select>
    )
  },
  avg_vivo_percentage: {
    Header: '% of Avg VIVO',
    id: 'avg_vivo_percentage',
    accessor: d => {
      if (!d.loading_out_time || !d.loading_in_time) return '...'
      let diff =
        new Date(d.loading_out_time).getTime() -
        new Date(d.loading_in_time).getTime()
      let sign = diff / props.avgVIVO - 1
      let perChange = (Math.abs(sign) * 100).toFixed(2)
      return <Arrow value={perChange} sign={sign} />
    }
  },
  avg_loading_vivo_percentage: {
    Header: '% of Avg VIVO',
    id: 'avg_loading_vivo_percentage',
    accessor: d => {
      if (!d.loading_out_time || !d.loading_in_time) return '...'
      let diff =
        new Date(d.loading_out_time).getTime() -
        new Date(d.loading_in_time).getTime()
      let sign = diff / props.avgVIVO - 1
      let perChange = (Math.abs(sign) * 100).toFixed(2)
      return <Arrow value={perChange} sign={sign} />
    }
  },
  avg_unloading_vivo_percentage: {
    Header: '% of Avg VIVO',
    id: 'avg_unloading_vivo_percentage',
    accessor: d => {
      if (!d.unloading_out_time || !d.unloading_in_time) return '...'
      let diff =
        new Date(d.unloading_out_time).getTime() -
        new Date(d.unloading_in_time).getTime()
      let sign = diff / props.avgVIVO - 1
      let perChange = (Math.abs(sign) * 100).toFixed(2)
      return <Arrow value={perChange} sign={sign} />
    }
  },
  transit_time: {
    Header: 'Transit Time',
    id: 'transit_time',
    accessor: d => {
      let diff = moment.duration(
        (new Date(
          d.unloading_in_time ? d.unloading_in_time : new Date()
        ).getTime() -
          new Date(d.loading_out_time).getTime()) /
          1000,
        'seconds'
      )

      return d.loading_out_time && d.unloading_in_time
        ? diff > 0
          ? diff.format('h [hr] m [min]')
          : '...'
        : '...'
    },
    sortMethod: (a, b) => new Date(b) - new Date(a),
    filterMethod: (filter, row) =>
      filterDateColumn(filter, row, 'YY-MM-DD hh:mm A'),
    Filter: ({ filter, onChange }) => (
      <DateFilter filter={filter} onChange={onChange} />
    )
  },
  sta: {
    Header: 'S.T.A',
    id: 'sta',
    accessor: d => formatDate(d, 'eta', 'YY-MM-DD'),
    sortMethod: (a, b) => new Date(b) - new Date(a),
    filterMethod: (filter, row) => filterDateColumn(filter, row, 'YY-MM-DD'),
    Filter: ({ filter, onChange }) => (
      <DateFilter filter={filter} onChange={onChange} />
    )
  },
  stt: {
    Header: 'S.T.T.',
    id: 'standard_transit_time',
    accessor: d =>
      d.start_date && d.eta
        ? moment
            .duration(
              (new Date(d.eta).getTime() - new Date(d.start_date).getTime()) /
                1000,
              'seconds'
            )
            .format('h [hr]')
        : ''
  },
  state: {
    Header: 'Trip State',
    accessor: 'state',
    id: 'state',
    filterMethod: (filter, row) => row[filter.id] === filter.value,
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ''}
        style={{ width: '100%', height: '100%' }}
      >
        <option value="">Show All</option>
        {props.tripStates &&
          props.tripStates.map(state => (
            <option value={state} key={state}>
              {state}
            </option>
          ))}
      </select>
    )
  },
  status: {
    Header: 'Transit Status',
    accessor: 'status',
    id: 'status',
    filterMethod: (filter, row) => row[filter.id] === filter.value,
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ''}
        style={{ width: '100%', height: '100%' }}
      >
        <option value="">Show All</option>
        {props.tripStatuses &&
          props.tripStatuses.map(status => (
            <option value={status} key={status}>
              {status}
            </option>
          ))}
      </select>
    )
  },
  transporter: {
    Header: 'Vendor',
    accessor: 'transporter'
  },
  consigner_name: {
    Header: 'Consigner Name',
    accessor: 'consigner_name'
  },
  consignee_name: {
    Header: 'Consignee Name',
    accessor: 'consignee_name'
  }
})

export default Columns
