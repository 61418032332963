import React from 'react'
import InputRange from 'react-input-range'
import moment from 'moment'

const HistorySlider = props => (
  <div>
    {props.minValue && props.maxValue && props.maxValue > props.minValue && (
      <div>
        <small style={{ padding: '20px 0px' }}>
          Filter trip histories as per Date &amp; Time.
        </small>
        <div style={{ margin: '20px 60px' }}>
          <InputRange
            formatLabel={value => moment.utc(value).format('DD/MM/YY hh:mm A')}
            maxValue={props.maxValue}
            minValue={props.minValue}
            value={props.selectedValue}
            onChange={value => {
              value = {
                min: value.min < props.minValue ? props.minValue : value.min,
                max: value.max > props.maxValue ? props.maxValue : value.max
              }
              props.filterHistorySlider(value)
            }}
          />
        </div>
      </div>
    )}
  </div>
)

export default HistorySlider
