import React from "react";
import moment from "moment";
import { formatDate } from "../../../../components/Shipment/JourneyCard";

const TripInfo = (props) => {
  return (
    <div className="row" style={{ fontSize: "90%" }}>
      <div className="col-md-6">
        <strong>Loading date: </strong>
        {moment.utc(props.trip.start_date).format("YYYY-MM-DD")}
      </div>
      <div className="col-md-6">
        <strong>{props.trip.exp_start_time ? "Exp Start" : "S.T.A "}:</strong>{" "}
        {props.trip.exp_start_time
          ? formatDate(props.trip.exp_start_time)
          : moment.utc(props.trip.eta).format("YYYY-MM-DD")}
      </div>
      {props.trip.exp_end_time ? (
        <div className="col-md-6">
          <strong>Exp End:</strong> {formatDate(props.trip.exp_end_time)}
        </div>
      ) : null}
      <div className="col-md-6">
        {!!props.trip.state && props.trip.state.toLowerCase() === "enroute" && (
          <div>
            <strong>Status : </strong> {props.trip.status}
            <br />
          </div>
        )}
      </div>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-6">
            {props.trip.loading_in_time && (
              <div>
                <strong>Loading In : </strong>
                {moment
                  .utc(props.trip.loading_in_time)
                  .format("YYYY-MM-DD HH:mm:ss")}
                <br />
              </div>
            )}
          </div>
          <div className="col-md-6">
            {props.trip.loading_out_time && (
              <div>
                <strong>Loading Out : </strong>
                {moment
                  .utc(props.trip.loading_out_time)
                  .format("YYYY-MM-DD HH:mm:ss")}
                <br />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-6">
            {props.trip.unloading_in_time && (
              <div>
                <strong>Unloading In : </strong>
                {moment
                  .utc(props.trip.unloading_in_time)
                  .format("YYYY-MM-DD HH:mm:ss")}
                <br />
              </div>
            )}
          </div>
          <div className="col-md-6">
            {props.trip.unloading_out_time && (
              <div>
                <strong>Unloading Out : </strong>
                {moment
                  .utc(props.trip.unloading_out_time)
                  .format("YYYY-MM-DD HH:mm:ss")}
              </div>
            )}
          </div>
          {props.trip.net_wt ? (
            <div className="col-md-6">
              <strong>Net wt</strong>: {props.trip.net_wt}
            </div>
          ) : null}
          {props.trip.delivery_no ? (
            <div className="col-md-6">
              <strong>Delivery No</strong>: {props.trip.delivery_no}
            </div>
          ) : null}
          {props.trip.invoice_qty ? (
            <div className="col-md-6">
              <strong>Invoice quantity</strong>: {props.trip.invoice_qty}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TripInfo;
