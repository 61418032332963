import React from "react";
import SkyLight from "react-skylight";
import styled from "styled-components";
import AddIssueForm from "./AddIssueForm";
import AddUser from "./AddUser";
import CommentsContainer from "./CommentsContainer";
import SubscribeIssue from "./SubscribeIssue";
//import ReactModal from 'react-modal';
const AppLayout = styled("div")`
  display: grid;
  grid-gap: 1em;
`;

AppLayout.displayName = "AppLayout";

const IssueDiv = styled("div")`
  background: #fff;
  display: grid;
  grid-gap: 1em;
`;

IssueDiv.displayName = "IssueDiv";

const CustomDiv = styled("div")`
  background: #fff;
  display: grid;
  grid-gap: 1em;
`;

CustomDiv.displayName = "CustomDiv";

const Card = styled("div")`
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
  padding: 1em;
`;

const LabelDiv = styled("div")`
  background: #fff;
`;

LabelDiv.displayName = "LabelDiv";

function Comments({
  issues: issueList,
  resource_type,
  resource_id,
  formConfig,
  urls,
  token
}) {
  const simpleDialog = React.createRef();
  const [issues, setIssues] = React.useState(issueList || []);
  const [newIssueCreated, setNewIssueCreated] = React.useState(false);

  const fetchIssues = React.useCallback(async () => {
    try {
      const { issuesUrl } = urls;
      if (!issuesUrl) return;
      const response = await (await fetch(issuesUrl)).json();
      if (response && response.issues) {
        setIssues([...response.issues]);
        setNewIssueCreated(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, ["resource_id", "resource_type"]);

  React.useEffect(() => {
    if (newIssueCreated) {
      window.location.reload();
    }
  }, [newIssueCreated]);

  React.useEffect(() => {
    fetchIssues();
  }, [fetchIssues]);

  return (
    <AppLayout>
      {formConfig && (
        <div style={{ display: "grid", gridTemplateColumns: "auto 200px" }}>
          <AddIssueForm
            formConfig={formConfig.addIssueForm}
            setNewIssueCreated={setNewIssueCreated}
            resourceId={resource_id}
            resourceType={resource_type}
          />
          <div style={{ justifySelf: "flex-end" }}>
            <button
              type="button"
              className="btn btn-info"
              onClick={() => simpleDialog.current.show()}
            >
              Add User
            </button>
            <SkyLight
              hideOnOverlayClicked
              ref={simpleDialog}
              title="Create User"
            >
              <AddUser
                formConfig={formConfig.addUserForm}
                closeModal={() => simpleDialog.current.hide()}
              />
            </SkyLight>
          </div>
        </div>
      )}
      <div style={{ display: "grid", gridRowGap: "1em" }}>
        {issues.map((issue, index) => (
          <Card key={`issue_${issue.id}_${index}`}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <LabelDiv>
                {issue.category} - {issue.title}
              </LabelDiv>
              <SubscribeIssue
                issueId={issue.id}
                threadId={issue.thread_id}
                isSubscribed={issue.is_subscribed}
                token={token}
              />
            </div>
            <CommentsContainer
              issueId={issue.id}
              threadId={issue.thread_id}
              count={issue.comment_count}
              token={token}
            />
          </Card>
        ))}
      </div>
    </AppLayout>
  );
}

export default props => <Comments {...props} />;
