import _ from 'lodash';
import queryString from 'query-string';
import React from 'react';
import {avgOfNumbers} from '../../../../utils';
import {Col, Grid, MapBtn, Row} from '../../components/layouts/Grid';
import TripHistoryMap from './Map/TripHistoryMap';
import swal from 'sweetalert';
import TripHistorySidebar from './Sidebar/TripHistorySidebar';
import styled from '@emotion/styled';

const RowFlex = styled(Row)`
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

class TripHistory extends React.Component {
  state = {
    history: this.props.history,
    vehicleInfo: this.props.vehicleInfo,
    driverInfo: this.props.driverInfo,
    polyLineHistory: this.props.history
      ? this.props.history.map(h => ({
          lat: Number(h.latitude),
          lng: Number(h.longitude),
        }))
      : null,
    overspeedStoppagesLengthFlag: {
      overspeedFlag: true,
      stoppagesFlag: true,
    },
    startLocation: this.props.history ? this.props.history[0] : null,
    vehicleLocation: this.props.history
      ? this.props.history[this.props.history.length - 1]
      : null,
    dailyTripInfo: this.props.dailyTripInfo,
    showStoppages: true,
    showHistoricalPath: true,
    showOverspeedPath: true,
    historyMarkerTimestamp: null,
    stoppageMarkerTimestamp: null,
    selectedOverspeedingMarkerTimestamp: null,
    stoppagesArray: [],
    stoppages: null,
    stoppageSliderCurrentValue: null,
    stoppageSliderMaxValue: null,
    historySlider: this.props.history
      ? {
          min: Math.min(
            ...this.props.history.map(h => new Date(h.ist_timestamp).getTime()),
          ),
          max: Math.max(
            ...this.props.history.map(h => new Date(h.ist_timestamp).getTime()),
          ),
        }
      : null,
    newHistorySlider: this.props.history
      ? {
          min: Math.min(
            ...this.props.history.map(h => new Date(h.ist_timestamp).getTime()),
          ),
          max: Math.max(
            ...this.props.history.map(h => new Date(h.ist_timestamp).getTime()),
          ),
        }
      : null,
    overspeedInstances: null,
    overspeedArray: [],
    minOverspeedSlider: 50,
    maxOverspeedSlider: 100,
    overspeedSliderCurrentValue: 60,
    deviationData: this.props.deviation,
    deviatedPoints: this.props.deviatedPoints,
    selectedIdealPaths: [],
    ideal: this.props.ideal,
    deviationsReport: this.props.deviationsReport,
  };

  componentDidMount() {
    if (this.props.history) {
      this._add();
    }
  }

  generateUrl = () => {
    let {pathname} = window.location;
    let url = pathname;
    if (pathname.match(/trip_history|truck_history|journey_history/gi)) {
      url = `/gps/history_track/duration_meta/${this.props.vehicleNumber}`;
    }
    const qParams = queryString.stringify(
      _.pickBy({
        start_time: this.props.start_time,
        end_time: this.props.end_time,
        stoppages: true,
        overspeed: true,
      }),
      _.identity,
    );
    url += '.json';
    return qParams ? url + '?' + qParams : url;
  };

  _add = () => {
    const url = this.generateUrl();
    return fetch(url, {
      method: 'GET',
      headers: ReactOnRails.authenticityHeaders({
        'Content-Type': 'application/json',
      }),
      mode: 'cors',
      credentials: 'include',
    })
      .then(data => data.json())
      .then(res => {
        let overspeedArray =
          res && res.overspeedInfo
            ? res.overspeedInfo.filter(o => {
                return o.speed >= 50;
              })
            : null;
        let overSpeedArr = overspeedArray
          ? overspeedArray.map(instance => instance.speed)
          : null;

        let minOverspeedSlider = 50;
        let maxOverspeedSlider = 100;
        let overspeedSliderCurrentValue = 60;
        if (overSpeedArr && overSpeedArr.length > 0) {
          minOverspeedSlider = Math.min(...overSpeedArr);
          maxOverspeedSlider = Math.max(...overSpeedArr);
          overspeedSliderCurrentValue = avgOfNumbers(overSpeedArr);
        }
        this.setState({
          stoppages: res && res.stoppages,
          stoppagesArray: res && res.stoppages,
          overspeedInstances: overspeedArray,
          overspeedArray: overspeedArray,
          minOverspeedSlider,
          maxOverspeedSlider,
          overspeedSliderCurrentValue,
          overspeedStoppagesLengthFlag: {
            overspeedFlag: overspeedArray.length == 0,
            stoppagesFlag: res.stoppages.length == 0,
          },
        });
        if (res.stoppages) {
          let sliderValue = Math.min(
            Math.floor(
              Math.max(...res.stoppages.map(e => Math.abs(e.duration))) / 2,
            ),
            10800,
          );
          let sliderMaxValue = Math.floor(
            Math.max(...res.stoppages.map(e => Math.abs(e.duration))),
          );

          this.setState({
            stoppageSliderCurrentValue: sliderValue,
            stoppageSliderMaxValue: sliderMaxValue,
          });
        }
      })
      .catch(err => console.log(err));
  };

  _showHistoryInfoWindow = event => {
    this.setState({
      selectedHistoryMarkerTimestamp: event
        ? event.target.checked
          ? event.target.value
          : null
        : null,
    });
  };

  _showStoppageInfoWindow = event => {
    this.setState({
      selectedStoppageMarkerTimestamp: event.target.checked
        ? event.target.value
        : null,
    });
  };

  _showOverspeedInfoWindow = event => {
    this.setState({
      selectedOverspeedingMarkerTimestamp: event.target.checked
        ? event.target.value
        : null,
    });
  };

  _search = event => {
    let query = event.target.value;
    let filteredHistory = query
      ? this.props.history.filter(h =>
          h.landmark.toLowerCase().includes(query.toLowerCase()),
        )
      : this.props.history;
    this.setState({history: filteredHistory});
  };

  _filterStoppages = stoppageTimeSelected => {
    let filteredStoppages = stoppageTimeSelected
      ? this.state.stoppagesArray.filter(e => e.duration > stoppageTimeSelected)
      : this.state.stoppagesArray;
    this.setState({
      stoppageSliderCurrentValue: stoppageTimeSelected,
      stoppages: filteredStoppages,
    });
  };

  _filterHistorySlider = historySlider => {
    let {min, max} = historySlider;
    let filteredHistory = this.props.history.filter(
      h =>
        new Date(h.ist_timestamp).getTime() > min &&
        new Date(h.ist_timestamp).getTime() < max,
    );
    let polyLineHistory = filteredHistory.map(h => ({
      lat: h.latitude,
      lng: h.longitude,
    }));
    this.setState({
      history: filteredHistory,
      polyLineHistory,
      newHistorySlider: historySlider,
    });
  };

  _filterOverspeedInstances = speed => {
    let overspeedInstances = speed
      ? this.state.overspeedArray.filter(o => o.speed > speed)
      : this.state.overspeedArray;

    this.setState({
      overspeedSliderCurrentValue: speed,
      overspeedInstances,
    });
  };

  _filterIdealPaths = pathIndexes => {
    this.setState({selectedIdealPaths: pathIndexes});
  };

  _toggleStoppagesView = () =>
    this.setState({showStoppages: !this.state.showStoppages});

  _toggleHistoryPath = () =>
    this.setState({showHistoricalPath: !this.state.showHistoricalPath});

  _toggleOverspeedPath = () =>
    this.setState({showOverspeedPath: !this.state.showOverspeedPath});

  render() {
    return (
      <Grid>
        {this.props.history ? (
          <RowFlex>
            <Col size={1} containerStyle={{height: '80vh', overflow: 'auto'}}>
              <TripHistorySidebar
                tripInfo={this.props.tripInfo} // Info Panel data
                deviationData={this.state.deviationData}
                dailyTripInfo={this.state.dailyTripInfo} // Info Panel daily distance travelled
                stoppageSliderCurrentValue={
                  this.state.stoppageSliderCurrentValue
                } // Stoppage Slider Current Value
                stoppageSliderMaxValue={this.state.stoppageSliderMaxValue} // Stoppage Slider Max Value
                filterStoppages={this._filterStoppages}
                historySlider={this.state.historySlider}
                newHistorySlider={this.state.newHistorySlider}
                filterHistorySlider={this._filterHistorySlider}
                stoppages={
                  this.state.stoppages
                    ? this.state.stoppages.filter(
                        stoppage =>
                          stoppage.duration >
                          this.state.stoppageSliderCurrentValue,
                      )
                    : null
                }
                overspeedInstances={
                  this.state.overspeedInstances
                    ? this.state.overspeedInstances.filter(
                        overspeed =>
                          overspeed.speed >
                          this.state.overspeedSliderCurrentValue,
                      )
                    : null
                }
                overspeedStoppagesLengthFlag={
                  this.state.overspeedStoppagesLengthFlag
                }
                minOverspeedSlider={this.state.minOverspeedSlider}
                currentOverspeedSlider={this.state.currentOverspeedSlider}
                maxOverspeedSlider={this.state.maxOverspeedSlider}
                overspeedSliderCurrentValue={
                  this.state.overspeedSliderCurrentValue
                }
                filterOverspeedInstances={this._filterOverspeedInstances}
                selectedOverspeedingMarkerTimestamp={
                  this.state.selectedOverspeedingMarkerTimestamp
                }
                showStoppageInfoWindow={this._showStoppageInfoWindow}
                selectedStoppageMarkerTimestamp={
                  this.state.selectedStoppageMarkerTimestamp
                }
                search={this._search}
                history={this.state.history} // History Table data
                selectedHistoryMarkerTimestamp={
                  this.state.selectedHistoryMarkerTimestamp
                }
                showHistoryInfoWindow={this._showHistoryInfoWindow}
                showOverspeedInfoWindow={this._showOverspeedInfoWindow}
                filterIdealPaths={this._filterIdealPaths}
                deviationsReport={this.state.deviationsReport}
              />
            </Col>
            <Col
              size={2}
              containerStyle={{paddingLeft: '20px', paddingRight: '20px'}}>
              <MapBtn
                border={
                  !this.state.showStoppages ? '2px solid #E55B25' : '#fff'
                }
                onClick={this._toggleStoppagesView}>
                {this.state.showStoppages ? 'Hide' : 'Show'} Stoppages
              </MapBtn>
              <MapBtn
                border={
                  !this.state.showHistoricalPath ? '2px solid #E55B25' : '#fff'
                }
                onClick={this._toggleHistoryPath}
                style={{marginTop: '50px'}}>
                {this.state.showHistoricalPath ? 'Hide' : 'Show'} History
              </MapBtn>
              <MapBtn
                border={
                  !this.state.showOverspeedPath ? '2px solid #E55B25' : '#fff'
                }
                onClick={this._toggleOverspeedPath}
                style={{marginTop: '100px'}}>
                {this.state.showOverspeedPath ? 'Hide' : 'Show'} Overspeeding
              </MapBtn>

              <TripHistoryMap
                containerElement={
                  <div style={{height: `80vh`, width: `100%`}} />
                }
                mapElement={<div style={{height: `100%`}} />}
                history={this.props.history}
                polyLineHistory={this.state.polyLineHistory}
                startLocation={this.state.startLocation}
                vehicleLocation={this.state.vehicleLocation}
                stoppages={
                  this.state.stoppages
                    ? this.state.stoppages.filter(
                        stoppage =>
                          stoppage.duration >
                          this.state.stoppageSliderCurrentValue,
                      )
                    : null
                }
                overspeedInstances={
                  this.state.overspeedInstances
                    ? this.state.overspeedInstances.filter(
                        overspeed =>
                          overspeed.speed >
                          this.state.overspeedSliderCurrentValue,
                      )
                    : null
                }
                showOverspeedInfoWindow={this._showOverspeedInfoWindow}
                selectedOverspeedingMarkerTimestamp={
                  this.state.selectedOverspeedingMarkerTimestamp
                }
                stoppageSliderCurrentValue={
                  this.state.stoppageSliderCurrentValue
                }
                selectedHistoryMarkerTimestamp={
                  this.state.selectedHistoryMarkerTimestamp
                }
                selectedStoppageMarkerTimestamp={
                  this.state.selectedStoppageMarkerTimestamp
                }
                tripInfo={this.props.tripInfo} // Getting directlu from RAILS view
                showStoppages={this.state.showStoppages}
                showOverspeedPath={this.state.showOverspeedPath}
                showHistoricalPath={this.state.showHistoricalPath}
                deviationData={this.state.deviationData}
                deviatedPoints={this.state.deviatedPoints}
                selectedIdealPaths={this.state.selectedIdealPaths}
                idealRoute={this.state.ideal}
                showHistoryInfoWindow={this._showHistoryInfoWindow}
                tripPois={this.props.tripPois}
                vehicleInfo={this.props.vehicleInfo}
                driverInfo={this.props.driverInfo}
                extraMarkers={this.props.extraMarkers}
                moveVehicleMarker={this.props.moveVehicleMarker}
              />
            </Col>
          </RowFlex>
        ) : (
          <Col>Oops!!! Trip History not found.</Col>
        )}
      </Grid>
    );
  }
}

export default class TripHistoryContainer extends React.Component {
  intervalID = 0;

  state = {
    history: this.props.history,
    tripInfo: this.props.tripInfo,
    latestPing: this.props.latestPing || null,
    updatedAt: new Date().toLocaleString(),
  };

  componentDidMount() {
    if (this.props.latestPing) {
      this.intervalID = this._fetchLatesthistory();
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  _fetchLatesthistory = async () => {
    try {
      setInterval(async () => {
        let url = `/gps/history_track/trip_vehicle_location`;
        const {latestPing} = this.state;
        const tzOffset = new Date().getTimezoneOffset();
        let start_date = new Date(
          new Date(latestPing).getTime() - tzOffset * 60 * 1000,
        ).toISOString();
        const q = queryString.stringify({
          slug: this.props.tripInfo.slug,
          start_date,
        });
        url = url + `?${q}`;
        const {
          data: {location, trip, latestPing: latestPingTime},
        } = await (await fetch(url, {
          method: 'GET',
          headers: ReactOnRails.authenticityHeaders({
            'Content-Type': 'application/json',
          }),
          mode: 'cors',
          credentials: 'include',
        })).json();
        if (
          new Date(latestPingTime).getTime() >
          new Date(this.state.latestPing).getTime()
        ) {
          this.setState(({history}) => ({
            history: _.uniqBy([...history, ...location], x => x.ist_timestamp),
            tripInfo: trip,
            latestPing: latestPingTime,
          }));
        }
        this.setState({updatedAt: new Date().toLocaleString()});
        return this.intervalID++;
      }, 60000);
    } catch (error) {
      this.setState({updatedAt: 'Unable to update. Refresh'});
      console.log(error);
    }
  };

  render() {
    return (
      <div>
        <span style={{float: 'right', padding: '0 1em'}}>
          Updated at : {this.state.updatedAt}
        </span>
        <TripHistory {...this.props} {...this.state} />
      </div>
    );
  }
}
