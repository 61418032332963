import React from 'react'
import Table from './Table'

export default class StatusTable extends React.Component {
  state = {
    data: this.props.data,
    filteredData: this.props.data[this.props.selectedEntry['name']],
    tripStates: null,
    columns: [
      'vehicle_no',
      'origin',
      'destination',
      'state',
      'loading_in_time',
      'loading_out_time',
      'unloading_in_time',
      'unloading_out_time',
      'transit_time',
      'sta',
      'stt',
      'consigner_name',
      'consignee_name'
    ]
  }

  static getDerivedStateFromProps(props, state) {
    const result = {}
    if (!_.isEqual(props.data, state.data)) {
      result.data = props.data
    }
    if (
      !_.isEqual(props.data[props.selectedEntry['name']], state.filteredData)
    ) {
      result.filteredData = props.data[props.selectedEntry['name']]
    }
    if (Object.keys(result).length) {
      return result
    }
    return null
  }

  componentDidMount() {
    if (this.props.data[this.props.selectedEntry['name']]) {
      let tripStates = [
        ...new Set(
          this.props.data[this.props.selectedEntry['name']].map(
            trip => trip.state
          )
        )
      ].filter(n => n)
      this.setState({ tripStates })
    }
  }

  render() {
    return (
      <div>
        {this.state.columns && (
          <Table
            data={this.state.filteredData}
            columns={this.state.columns}
            tripCompleted={this.props.tripCompleted}
            userRole={this.props.userRole}
          />
        )}
      </div>
    )
  }
}
