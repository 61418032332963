import React from 'react'
import ColorHash from 'color-hash'
import MapRenderer from './MapRenderer'
import MapAllConsignmentSidebarItem from './MapAllConsignmentSidebarItem'
class ConsignmentTripsHistory extends React.Component {
  state = {
    zoom: 5,
    center: [20, 77],
    selectedTripId: null,
    histories: this.props.histories,
    trips: this.props.trips
  }

  sidebarItemClicked = obj => {
    this.setState({ ...obj })
  }

  render() {
    var id = 2

    const { center, zoom, histories, selectedTripId } = this.state

    const trips = this.props.trips.map((item, index) => {
      let cardColor = new ColorHash().hex(index)
      if (index === 0) {
        cardColor = '#00C853'
      }
      if (index && index === this.props.trips.length - 1) {
        cardColor = '#FF5722'
      }
      return {
        cardColor,
        selectedTripId: null,
        ...item
      }
    })

    return (
      <div className="row">
        <div
          className="col-md-3"
          style={{ position: 'relative', maxHeight: '92vh' }}
        >
          <div style={{ maxHeight: '92vh', overflow: 'auto' }}>
            <button
              className="btn btn-secondary btn-block"
              onClick={() =>
                this.sidebarItemClicked({
                  selectedTripId: null,
                  zoom: 5,
                  center: [20, 77]
                })
              }
              style={{ margin: '1em auto' }}
            >
              <b>Show All Trips</b>
            </button>
            {trips &&
              trips.map((item, index) => (
                <MapAllConsignmentSidebarItem
                  key={`${item.vehicle_no}_${index}`}
                  {...{ selectedTripId, item }}
                  sidebarItemClicked={this.sidebarItemClicked}
                />
              ))}
          </div>
        </div>
        <div className="col-md-9" key={id++} style={{ height: '92vh' }}>
          {trips && (
            <MapRenderer
              {...{ center, zoom, trips, histories, selectedTripId }}
              key={id++}
            />
          )}
        </div>
      </div>
    )
  }
}

export default ConsignmentTripsHistory
