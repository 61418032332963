import React from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  Cell,
  Legend,
  Tooltip
} from "recharts";
import _ from "lodash";
import StatusTable from "./StatusTable";
import "react-table/react-table.css";
import { SkyLightStateless } from "react-skylight";
import { bigModal, statusColor as COLORS } from "../../../../utils";

export default class StatusChart extends React.Component {
  state = {
    selectedEntry: { name: "Delayed" },
    modalVisible: false,
    data: this.props.trips
  };

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.trips, state.data)) {
      return { data: props.trips };
    }
    return null;
  }

  renderTable(entry, index) {
    this.setState({ selectedEntry: entry, modalVisible: true });
  }

  closeModal() {
    this.setState({ modalVisible: false });
  }

  render() {
    let data = [];
    let i = 0;
    _.forEach(this.props.trips, (key, value) => {
      if (value === "null") return;
      let temp = {};
      temp["name"] = value;
      temp["value"] = key.length;
      temp["fill"] = COLORS[value] || "#444";
      i = i + 1;
      data.push(temp);
    });

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index
    }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {`${data[index]["value"]}`}
        </text>
      );
    };

    return (
      <div>
        <h4 className="text-muted">Transit Performance</h4>
        <hr />
        <div className="chart-center">
          <ResponsiveContainer height={500} width="100%">
            <PieChart
              datakey="value"
              labelLine={false}
              width={800}
              height={400}
            >
              <Tooltip />
              <Legend align="center" />
              <Pie
                data={data}
                dataKey="value"
                labelLine={false}
                cx={200}
                cy={200}
                startAngle={90}
                endAngle={-270}
                outerRadius={120}
                label={renderCustomizedLabel}
              >
                {data.map((entry, index) => (
                  <Cell
                    onClick={() => this.renderTable(entry, index)}
                    key={index}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <SkyLightStateless
          dialogStyles={bigModal}
          isVisible={this.state.modalVisible}
          onCloseClicked={() => this.closeModal()}
          title={
            <div className="row skylight-dipper">
              <h4 className="text-center">
                Transit status : {this.state.selectedEntry["name"]}
              </h4>
            </div>
          }
        >
          <hr />
          <StatusTable
            data={this.state.data}
            selectedEntry={this.state.selectedEntry}
            userRole={this.props.userRole}
          />
        </SkyLightStateless>
      </div>
    );
  }
}
