import React from 'react'
import StoppageFilter from '../StoppageFilter'
import StoppagesTable from './StoppagesTable'
import { exportTableToCSV } from '../../../../../../utils'

const StoppagesPanel = props => {
  if (props.info.stoppages == null) {
    return <div>Still Loading...</div>
  } else if (props.info.overspeedStoppagesLengthFlag.stoppagesFlag) {
    return (
      <div style={{ padding: '50px', textAlign: 'center' }}>
        No Result Found
      </div>
    )
  } else {
    return (
      <div>
        <div className="button-f-right" style={{ float: 'right' }}>
          {props.info.stoppages.length != 0 && (
            <button
              className="btn btn-sm btn-info fa fa-file-excel-o"
              onClick={() =>
                exportTableToCSV(
                  'tripStoppagesTableReact',
                  `TripStoppages_${props.info.tripInfo.vehicle_no}_INV_${
                    props.info.tripInfo.invoice_no
                  }_Loading_${new Date(props.info.tripInfo.start_date)
                    .toJSON()
                    .slice(0, 10)}.csv`
                )
              }
            >
              &nbsp;Download
            </button>
          )}
        </div>
        <div>
          {props.info.stoppageSliderMaxValue > 0 && (
            <StoppageFilter
              maxValue={props.info.stoppageSliderMaxValue}
              sliderValue={props.info.stoppageSliderCurrentValue}
              filterStoppages={props.info.filterStoppages}
            />
          )}
          <StoppagesTable
            data={props.info.stoppages}
            showStoppageInfoWindow={props.info.showStoppageInfoWindow}
            selectedStoppageMarkerTimestamp={
              props.info.selectedStoppageMarkerTimestamp
            }
          />
        </div>
      </div>
    )
  }
}

export default StoppagesPanel
