import React from "react";
import ReactDom from "react-dom";
import { formatTime } from "../../../../utils";

const DayIntervalLine = ({ x, displayDate, svgHeight }) => (
  <React.Fragment>
    <path
      d={`M${x},${svgHeight - 40} v-50`}
      fill="yellow"
      stroke="#9E9E9E"
      strokeWidth="1"
    />
    <text
      x={x - 4}
      y={svgHeight - 25}
      fill="#616161"
      style={{ fontSize: "10px" }}
    >
      {displayDate.split("-")[0]}
    </text>
  </React.Fragment>
);

const DayInterval = ({ interval, svgHeight }) => {
  const displayDate = formatTime(interval.label, "DD-MM");
  return (
    <DayIntervalLine
      x={interval.x}
      displayDate={displayDate}
      svgHeight={svgHeight}
    />
  );
};

export default DayInterval;
