import React from "react";
import BarLoader from "react-spinners/BarLoader";

interface GraphContainer {
  title: string;
  loading: boolean;
  error: boolean;
  children: React.ReactNode;
  filters?: React.ReactNode;
}

const divStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 300
};

export default function GraphContainer({
  title,
  loading,
  error,
  children,
  filters
}: GraphContainer) {
  if (error) {
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div>
            <h5>{title}</h5>
          </div>
          {filters && <div>{filters}</div>}
        </div>
        <hr />
        <div style={divStyle}>Error !!!</div>
      </div>
    );
  }
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 2 }}>
          <h5>{title}</h5>
        </div>
        {filters && (
          <div style={{ flex: 1, alignItems: "flex-end" }}>{filters}</div>
        )}
      </div>
      <hr />

      {loading ? (
        <div style={divStyle}>
          <BarLoader
            sizeUnit={"px"}
            size={150}
            color={"#123abc"}
            loading={loading}
          />
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  );
}
