import React from "react";
import { LayersControl, Map, MapProps, TileLayer } from "react-leaflet";
import { GoogleLayer } from "react-leaflet-google";
const { BaseLayer } = LayersControl;
const key = "AIzaSyCK482B8_jLz2rUTdg8bWKAwjo7fo1z5vM";
const terrain = "TERRAIN";
const road = "ROADMAP";
const satellite = "SATELLITE";
const hybrid = "HYBRID";

interface MapContainerProps extends MapProps {
  children: React.ReactNode;
  layerPosition?: string;
}

const MapContainer = React.forwardRef(
  (
    { center, zoom, children, layerPosition = "topright", bounds }: any,
    ref
  ) => (
    <Map center={center} zoom={zoom} ref={ref} bounds={bounds}>
      <LayersControl position={layerPosition}>
        <BaseLayer name="OpenStreetMap.Mapnik">
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </BaseLayer>
        <BaseLayer name="OpenStreetMap.BlackAndWhite">
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
          />
        </BaseLayer>
        <BaseLayer name="Google Maps Roads">
          <GoogleLayer googlekey={key} maptype={road} />
        </BaseLayer>
        <BaseLayer name="Google Maps Terrain">
          <GoogleLayer googlekey={key} maptype={terrain} />
        </BaseLayer>
        <BaseLayer name="Google Maps Satellite">
          <GoogleLayer googlekey={key} maptype={satellite} />
        </BaseLayer>
        <BaseLayer checked name="Google Maps Hybrid">
          <GoogleLayer googlekey={key} maptype={hybrid} />
        </BaseLayer>
      </LayersControl>
      {children}
    </Map>
  )
);

MapContainer.displayName = "MapContainer";

export default MapContainer;
