import React from 'react'
import OverspeedFilter from './OverspeedFilter'
import OverspeedTable from './OverspeedTable'

const OverspeedPanel = props => {
  if (props.info.overspeedInstances == null) {
    return <div>Still Loading...</div>
  } else if (props.info.overspeedStoppagesLengthFlag.overspeedFlag) {
    return (
      <div style={{ padding: '50px', textAlign: 'center' }}>
        No Result Found
      </div>
    )
  } else {
    return (
      <div>
        {props.info.overspeedInstances && (
          <div>
            <OverspeedFilter
              minValue={props.info.minOverspeedSlider}
              maxValue={props.info.maxOverspeedSlider}
              selectedValue={props.info.overspeedSliderCurrentValue}
              filterOverspeedInstances={props.info.filterOverspeedInstances}
            />
            <br />
            <OverspeedTable
              data={props.info.overspeedInstances}
              showOverspeedInfoWindow={props.info.showOverspeedInfoWindow}
              selectedOverspeedMarkerTimestamp={
                props.info.selectedOverspeedingMarkerTimestamp
              }
            />
          </div>
        )}
      </div>
    )
  }
}

export default OverspeedPanel
