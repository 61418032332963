import React from 'react'
import InputRange from 'react-input-range'
import moment from 'moment'

const OverspeedFilter = props => (
  <div>
    {props.minValue && props.maxValue && props.maxValue > props.minValue && (
      <div>
        <small style={{ padding: '20px 0px' }}>
          Filter overspeeding by speed limit
        </small>
        <div style={{ margin: '20px 60px' }}>
          <InputRange
            formatLabel={value => value + 'Km/hr'}
            maxValue={props.maxValue}
            minValue={props.minValue}
            value={props.selectedValue}
            onChange={value => props.filterOverspeedInstances(value)}
          />
        </div>
      </div>
    )}
  </div>
)

export default OverspeedFilter
