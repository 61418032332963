import React from "react";

const DistanceIndicators = ({ indicator, svgHeight }) => {
  const heightObj = {
    verticalLine: svgHeight - 65,
    textHeight: svgHeight - 90
  };
  return (
    <React.Fragment>
      <path
        d={`M${indicator.x},${heightObj.verticalLine} v-20`}
        fill="yellow"
        stroke="#BA68C8"
        strokeWidth="1"
      />
      <text
        x={indicator.x - 15}
        y={heightObj.textHeight - 10}
        fill="#BA68C8"
        style={{ fontSize: "10px" }}
      >
        {indicator.km}
      </text>
      <text
        x={indicator.x - 15}
        y={heightObj.textHeight}
        fill="#BA68C8"
        style={{ fontSize: "10px" }}
      >
        km
      </text>
    </React.Fragment>
  );
};

export default DistanceIndicators;
