import { flatten } from "lodash";
import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import TripHistoryPanel from "./../TripHistory/Sidebar/TripHistoryPanel/TripHistoryPanel";
import ShipmentPanel from "./ShipmentPanel";

function ShipmentSidebar({
  shipments,
  colorConfig,
  onJourneySelect,
  selectedId,
  onHistorySelect,
  selectedHistoryMarkerTimestamp,
}) {
  const [searchTerm, setSearchTerm] = React.useState("");
  function search(e) {
    setSearchTerm(e.target.value);
  }
  const tripHistoryPanelInfo = { search };
  let history = flatten(
    !!selectedId
      ? shipments.filter((x) => x.sequence === selectedId).map((x) => x.history)
      : shipments.map((x) => x.history)
  );
  if (searchTerm) {
    history = history.filter((x) =>
      Object.values(x)
        .map((i) => String(i).toLowerCase())
        .join(",")
        .includes(searchTerm.toLowerCase())
    );
  }
  tripHistoryPanelInfo.history = history;
  tripHistoryPanelInfo.showHistoryInfoWindow = onHistorySelect;
  tripHistoryPanelInfo.selectedHistoryMarkerTimestamp = selectedHistoryMarkerTimestamp;

  return (
    <Tabs>
      <TabList>
        <Tab>Journeys</Tab>
        <Tab>History</Tab>
      </TabList>
      <TabPanel>
        <ShipmentPanel
          shipments={shipments}
          colorConfig={colorConfig}
          onJourneySelect={onJourneySelect}
          selectedId={selectedId}
        />
      </TabPanel>
      <TabPanel>
        <p>History</p>
        <TripHistoryPanel
          info={tripHistoryPanelInfo}
          showExportToExcel={true}
        />
      </TabPanel>
    </Tabs>
  );
}

export default ShipmentSidebar;
