import React from "react";
import moment from "moment";

const TripHistoryPanel = props => (
  <table className="table table-sm tripHistoryTableReact">
    <thead>
      <tr>
        <th />
        <th>Landmark</th>
        <th>Updated At</th>
      </tr>
    </thead>
    <tbody>
      {props.data.map((item, index) => (
        <tr key={index}>
          <td style={{ width: "50px" }}>
            <input
              type="checkbox"
              value={new Date(item.ist_timestamp).getTime()}
              id={`check_${index}`}
              className="form-control"
              onChange={props.showHistoryInfoWindow}
              checked={
                props.selectedHistoryMarkerTimestamp
                  ? new Date(item.ist_timestamp).getTime() ===
                    Number(props.selectedHistoryMarkerTimestamp)
                  : false
              }
            />
          </td>
          <td>
            <label htmlFor={`check_${index}`}>{item.landmark}</label>
          </td>
          <td>{moment.utc(item.ist_timestamp).format("llll")}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default TripHistoryPanel;
