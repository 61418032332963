import React from 'react'
import { formatDuration } from '../../../utils'

export default function CountdownTimer({ date }) {
  if (!date) return <React.Component>...</React.Component>
  return (
    <p>
      {formatDuration(new Date() - new Date(date) + 5.5 * 3600 * 1000, {
        compact: true,
        keepDecimalsOnWholeSeconds: false
      })}
    </p>
  )
}
