import format from "date-fns/format";
import queryString from "query-string";
import React from "react";
// import { DateRangePicker } from "react-date-range";
// import "react-date-range/dist/styles.css"; // main style file
// import "react-date-range/dist/theme/default.css"; // theme css file
import RSelect from "react-select";
import TripHistory from "../TripHistory/TripHistory";

export default class TruckHistory extends React.Component {
  state = {
    start_date: this.props.start_time || "",
    end_date: this.props.end_time || "",
    start_time: "",
    end_time: "",
    tripInfo: null,
    history: this.props.history || null,
    vehicleInfo: this.props.vehicleInfo || null,
    driverInfo: this.props.driverInfo || null,
    stoppages: null,
    dailyTripInfo: null,
    overspeedInfo: null,
    loading: false,
    error: false,
    // showDateRangePicker: false,
    // selectionRange: { startDate: new Date(), endDate: new Date() }
  };

  componentDidMount() {
    const {
      tripInfo,
      history,
      stoppages,
      dailyTripInfo,
      overspeedInfo,
    } = this.props;
    this.setState({
      tripInfo,
      history,
      stoppages,
      dailyTripInfo,
      overspeedInfo,
    });
  }
  // setShowDateRangePicker = value => {
  //   this.setState({ showDateRangePicker: value })
  // }
  // handleSelect = range => {
  //   const { startDate, endDate } = range.range1
  //   this.setState({
  //     selectionRange: range.range1,
  //     start_date: format(startDate, "YYYY-MM-DD"),
  //     end_date: format(endDate, "YYYY-MM-DD")
  //   })
  // }
  _submit = () => {
    const { start_date, end_date, start_time, end_time } = this.state;
    let params = {
      start_date: start_time ? start_date + "T" + start_time : start_date,
      end_date: end_time ? end_date + "T" + end_time : end_date,
    };
    const q = queryString.stringify(params, { arrayFormat: "bracket" });
    this.setState({ loading: true }, async () => {
      try {
        const data = await (await fetch(
          `/gps/history_track/truck_history/${this.props.vehicleNumber}?${q}`,
          {
            headers: ReactOnRails.authenticityHeaders({
              "Content-Type": "application/json",
            }),
            credentials: "same-origin",
          }
        )).json();
        const {
          tripInfo,
          history,
          stoppages,
          dailyTripInfo,
          overspeedInfo,
          vehicleInfo,
          driverInfo,
          start_time,
          end_time,
        } = data;
        this.setState({
          loading: false,
          tripInfo,
          history,
          stoppages,
          dailyTripInfo,
          overspeedInfo,
          vehicleInfo,
          driverInfo,
          start_date: start_time,
          end_date: end_time,
        });
      } catch (error) {
        this.setState({ loading: false, error: true });
        console.log(error);
      }
    });
  };

  _handleVehicleChange = (event) => {
    window.location = `/gps/history_track/truck_history/${event.value}`;
  };
  handleFromInput = (e) => {
    this.setState({ start_date: e.target.value });
  };
  handleTime = (e, target) => {
    this.setState({ [target]: e.target.value });
  };
  handleToInput = (e) => {
    this.setState({ end_date: e.target.value });
  };
  render() {
    const {
      tripInfo,
      history,
      stoppages,
      dailyTripInfo,
      overspeedInfo,
      loading,
      error,
      start_date,
      end_date,
      start_time,
      end_time,
      vehicleInfo,
      driverInfo,
      // selectionRange
      // showDateRangePicker
    } = this.state;
    // const { startDate, endDate } = selectionRange
    // let buttonClosedLabel = "Select Date"
    // if (isTodayAndEqual(startDate, endDate)) {
    //   buttonClosedLabel = format(startDate, "DD/MM/YY")
    // } else if (isEqual(startDate, endDate)) {
    //   buttonClosedLabel = format(startDate, "DD/MM/YY")
    // } else if (!isEqual(startDate, endDate)) {
    //   buttonClosedLabel =
    //     format(startDate, "DD/MM/YY") + " - " + format(endDate, "DD/MM/YY")
    // }
    return (
      <div>
        <h1 className="dipper">
          <div className="row">
            <div className="col-md-2">Vehicle History for</div>
            <div className="col-md-2">
              <RSelect
                options={this.props.allVehicles.map((vehicle_no) => ({
                  label: vehicle_no,
                  value: vehicle_no,
                }))}
                placeholder="Select Vehicle"
                onChange={this._handleVehicleChange}
                value={{label: this.props.vehicleNumber, value: this.props.vehicleNumber}}
              />
            </div>
            <div className="col-md-6">
              {/* <DateButton
                onClick={() =>
                  this.setShowDateRangePicker(!showDateRangePicker)
                }
              >
                {showDateRangePicker ? "Close" : buttonClosedLabel}
              </DateButton>
              {showDateRangePicker && (
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    marginTop: "3em",
                    zIndex: 1000
                  }}
                >
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={this.handleSelect}
                    maxDate={toIST(new Date())}
                  />
                </div>
              )} */}
              <div style={{ fontSize: "15px", marginTop: "4px" }}>
                <b>From : </b>
                <input
                  type="date"
                  onChange={(e) => this.handleFromInput(e)}
                  style={{ color: "#333" }}
                  max={format(new Date(), "YYYY-MM-DD[T]hh:mm")}
                  min={"2010-01-01T00:00"}
                  value={start_date}
                />
                <input
                  type="time"
                  name="start_time"
                  value={start_time}
                  onChange={(e) => this.handleTime(e, "start_time")}
                />
                <b style={{ marginLeft: "7px" }}>To : </b>
                <input
                  type="date"
                  onChange={(e) => this.handleToInput(e)}
                  style={{ color: "#333" }}
                  min={"2010-01-01T00:00"}
                  max={format(new Date(), "YYYY-MM-DD[T]hh:mm")}
                  value={end_date}
                />
                <input
                  type="time"
                  name="end_time"
                  onChange={(e) => this.handleTime(e, "end_time")}
                  value={end_time}
                />
              </div>
            </div>
            <div className="col-md-2">
              <button className="btn btn-warning" onClick={this._submit}>
                Find
              </button>
            </div>
          </div>
        </h1>
        <hr />
        <div className="row">
          <div className="col-md-12">
            {error ? (
              <div>Oops! Something went wrong</div>
            ) : history && history.length !== 0 ? (
              <React.Fragment>
                {loading ? (
                  <div>Loading ...</div>
                ) : (
                  <TripHistory
                    tripInfo={tripInfo}
                    history={history}
                    stoppages={stoppages}
                    vehicleInfo={vehicleInfo}
                    driverInfo={driverInfo}
                    dailyTripInfo={dailyTripInfo}
                    overspeedInfo={overspeedInfo}
                    vehicleNumber={this.props.vehicleNumber}
                    start_time={
                      start_time ? start_date + "T" + start_time : start_date
                    }
                    end_time={end_time ? end_date + "T" + end_time : end_date}
                  />
                )}
              </React.Fragment>
            ) : (
              <div>Oops! No Trip Result Found</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
