import axios from "axios";
import React from "react";
import AlertForm from "./AlertForm";
import AlertList from "./AlertList";
import AlertSidebar from "./AlertSidebar";
import styled from "@emotion/styled";

const AlertContainer = styled.div`
  padding: 1em;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1em;
  @media screen and (max-width: 768px) {
    grid-template-columns: unset;
  }
`

const CancelToken = axios.CancelToken;
let cancel;

function Alert({
  vehicle_nos: vehicleNos,
  subscriptions: subs,
  subscription_types,
  alert_users: alertUsers,
  mrole,
  providers,
  pois,
}) {
  const alertTypes = Object.keys(subscription_types);
  const [selectedAlert, setSelectedAlert] = React.useState({
    [alertTypes[0]]: subscription_types[alertTypes[0]],
  });
  const [subscriptions, setSubscriptions] = React.useState(subs);

  function changeForm(val) {
    const selectedAlert = {
      [val]: subscription_types[val],
    };
    setSelectedAlert(selectedAlert);
  }

  async function addSubscription(data) {
    try {
      cancel && cancel();
      const res = await axios({
        url: "/alert_subscriptions.json",
        method: "POST",
        headers: ReactOnRails.authenticityHeaders({
          "Content-Type": "application/json",
        }),
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
        data: data,
        mode: "cors",
        credentials: "include",
      });
      const response = res.data;
      if (response && response.subscription) {
        setSubscriptions(response.subscription);
        swal("Done", "New alert subscription has been created", "success");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteSubscription(id) {
    try {
      cancel && cancel();
      const res = await axios(`/alert_subscriptions/${id}.json`, {
        method: "DELETE",
        headers: ReactOnRails.authenticityHeaders({
          "Content-Type": "application/json",
        }),
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
        data: { id },
      });
      const { data } = res;
      if (data && data.success) {
        let newSubscriptions = subscriptions.filter(
          (subscription) => subscription.id !== id
        );
        setSubscriptions(newSubscriptions);
        swal("Done", "Alert subscription has been deleted", "success");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <AlertContainer>
      <div>
        <AlertSidebar
          alertTypes={alertTypes}
          selected={selectedAlert}
          changeForm={changeForm}
          delete={deleteSubscription}
          userRole={mrole.type.toLowerCase()}
        />
      </div>

      <div>
        <div>
          <AlertForm
            alert={selectedAlert}
            users={alertUsers}
            providers={providers}
            mrole={mrole}
            pois={pois}
            add={addSubscription}
            delete={deleteSubscription}
            vehicle_nos={vehicleNos}
          />
        </div>
        <div>
          {subscriptions && (
            <AlertList
              subscriptions={subscriptions}
              alert={selectedAlert}
              delete={deleteSubscription}
            />
          )}
        </div>
      </div>
    </AlertContainer>
  );
}

export default (props) => <Alert {...props} />;
