import axios from "axios";
import { titleCase } from "change-case";
import xor from "lodash/xor";
import qs from "qs";
import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { convertObjKeysToSnakeCase } from "../../../../utils";
const CancelToken = axios.CancelToken;
let cancel;

function TripFilter({
  onClick,
  selectedFilters,
  isActive,
  startDate,
  endDate,
}) {
  const [loading, setLoading] = React.useState(false);
  const [filterOptions, setFilterOptions] = React.useState(null);
  const [selectedOptions, setSelectedOptions] = React.useState({});
  const [searchOption, setSearchOption] = React.useState({});

  async function fetchFilters() {
    try {
      setLoading(true);
      const q = qs.stringify(
        convertObjKeysToSnakeCase({
          ...searchOption,
          is_active: isActive ? Number(isActive) : null,
          like_query: 1,
          start_date: startDate,
          end_date: endDate,
        }),
        {
          encode: false,
          skipNulls: true,
          arrayFormat: "bracket",
        }
      );
      cancel && cancel();
      const res = await axios.request({
        method: "GET",
        url: `/consigner/overview/populate_filters.json?${q}`,
        headers: ReactOnRails.authenticityHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        }),
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      });
      if (res.data) {
        setFilterOptions(res.data);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  React.useEffect(() => {
    setSelectedOptions(selectedFilters);
  }, [selectedFilters]);

  React.useEffect(() => {
    fetchFilters();
  }, [searchOption, isActive, startDate, endDate]);

  return (
    <div>
      {filterOptions ? (
        <Tabs>
          <TabList>
            {Object.keys(filterOptions).map((name, index) => (
              <Tab key={`tripfilterTab_${name}_${index}`}>
                {titleCase(name)}
                <span style={{ margin: "0 0.25em" }}>
                  {selectedOptions[name]
                    ? `(${selectedOptions[name].length})`
                    : null}
                </span>
              </Tab>
            ))}
          </TabList>
          {Object.keys(filterOptions).map((name, index) => (
            <TabPanel key={`tripfilterTabPanel_${name}_${index}`}>
              <OptionsListRenderer
                data={filterOptions[name]}
                name={name}
                onOptionSelect={(res) =>
                  setSelectedOptions({ ...selectedOptions, [name]: res })
                }
                selectedOptions={selectedOptions}
                onSearch={setSearchOption}
                loading={loading}
              />
              <button
                className="btn btn-success"
                onClick={() => onClick(selectedOptions)}
              >
                Apply
              </button>
            </TabPanel>
          ))}
        </Tabs>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

function OptionsListRenderer({
  data,
  onOptionSelect,
  name,
  selectedOptions,
  onSearch,
  loading,
}) {
  return (
    <div>
      <input
        type="search"
        onChange={(e) => onSearch({ [name]: e.target.value })}
        style={{ width: "100%" }}
        placeholder={`Search ${titleCase(name)}`}
      />
      {!loading ? (
        <div style={{ height: 300, overflowY: "auto", padding: "1em 0" }}>
          {data &&
            data.map((item, index) => {
              const selected = selectedOptions[name];
              const checked = selected && selected.includes(item);
              return (
                <label
                  style={{
                    display: "inline-flex",
                    height: "2em",
                    borderRadius: "1em",
                    padding: "0 0.5em",
                    background: checked ? "#03A9F4" : "#E2E2E2",
                    marginRight: "0.25em",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  key={`OptionsListRenderer_${item}_${index}`}
                >
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      onOptionSelect(xor(selected, [item]));
                    }}
                    checked={checked}
                    style={{ display: "none" }}
                  />
                  {item.toUpperCase()}
                </label>
              );
            })}
        </div>
      ) : (
        <p>Loading ...</p>
      )}
    </div>
  );
}

export default TripFilter;
