import React from 'react'
import { Marker, InfoWindow } from 'react-google-maps'
import StoppageInfoWindow from './StoppageInfoWindow'
import { stoppageMarker } from '../../../../../utils'
import OverspeedMarker from './OverspeedMarker'

const OverspeedInstances = props => (
  <div>
    {props.data &&
      props.data.map((overspeed, i) => (
        <OverspeedMarker
          info={overspeed}
          key={i}
          isOpen={
            Number(new Date(overspeed.ist_timestamp).getTime()) ===
            Number(props.selectedOverspeedingMarkerTimestamp)
          }
        />
      ))}
  </div>
)
export default OverspeedInstances
