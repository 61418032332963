import React from 'react'
import * as yup from 'yup'
import styled from "@emotion/styled";

export const FormContainer = styled.div`
  display: flex;
  gap: 1em;
  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`

type VALIDFIElDS = 'full_name' | 'email' | 'phone_no' | 'designation'

type IUserIfo = { [f in VALIDFIElDS]: string }

interface IAlertFormUserForm {
  populateUserInfo: (userInfo: Partial<IUserIfo>) => void
  fieldValues: any
  setUserFormErrors: (input: boolean) => void
}

export default function UserForm({
  populateUserInfo,
  fieldValues,
  setUserFormErrors
}: IAlertFormUserForm) {
  let formSchema = yup.object().shape({
    full_name: yup.string().required(),
    phone_no: yup
      .string()
      .matches(/^[6-9]\d{9}$/, 'Phone number is not valid')
      .required(),
    email: yup
      .string()
      .email()
      .required(),
    designation: yup.string().required()
  })

  const [form, setForm] = React.useState({
    full_name: undefined,
    phone_no: undefined,
    email: undefined,
    designation: undefined
  })

  function handleInput(e) {
    e.preventDefault()
    setForm({ ...form, [e.target.name]: e.target.value })
    populateUserInfo(form)
    e.stopPropagation()
  }

  function handleBlur(e) {
    setForm({ ...form, [e.target.name]: e.target.value })
    populateUserInfo(form)
    formSchema
      .validate(form)
      .then(() => {
        setUserFormErrors(null)
      })
      .catch(err => {
        if (err.errors) {
          setUserFormErrors(err.message)
        }
      })
  }

  return (
    <FormContainer>
      <div>
        <label htmlFor="full_name">Name</label>
        <input
          type="text"
          className="form-control"
          name="full_name"
          value={form.full_name}
          placeholder="Enter Username"
          style={{ marginRight: '5px' }}
          onInput={handleInput}
          onBlur={handleBlur}
        />
      </div>
      <div>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          className="form-control"
          name="email"
          value={form.email}
          placeholder="User email"
          style={{ marginRight: '5px' }}
          onInput={handleInput}
          onBlur={handleBlur}
        />
      </div>
      <div>
        <label htmlFor="phone_no">Phone No</label>
        <input
          type="text"
          className="form-control"
          name="phone_no"
          value={form.phone_no}
          placeholder="User phone number"
          style={{ marginRight: '5px' }}
          onInput={handleInput}
          onBlur={handleBlur}
        />
      </div>
      <div>
        <label htmlFor="designation">Designation</label>
        <input
          type="text"
          className="form-control"
          name="designation"
          value={form.designation}
          placeholder="User designation"
          style={{ marginRight: '5px' }}
          onInput={handleInput}
          onBlur={handleBlur}
        />
      </div>
    </FormContainer>
  )
}
