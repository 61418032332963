import styled from "@emotion/styled";
import Color from "color";
import countBy from "lodash/countBy";
import RandomMC from "random-material-color";
import React from "react";
import { FaUserCircle } from "react-icons/fa";
import titleCase from "title-case";
import Timeline from "../Timeline";
import {LrLink} from './JourneyCard'

const ShipmentContainer = styled("div")`
  display: grid;
  grid-template-rows: 120px minmax(120px, 1fr);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  min-height: 15em;
  grid-gap: 0.5em;
  background: #fff;
  @media screen and (max-width: 768px) {
    grid-template-rows:  repeat(2, minmax(120px, 1fr));
  }
`;

ShipmentContainer.displayName = "ShipmentContainer";

const ShipmentContainerHeader = styled("div")`
  display: grid;
  background: ${({ backgroundColor }) => backgroundColor || "#fff"};
  overflow: hidden;
  opacity: ${({ opacity }) => opacity || 1};
  @media screen and (min-width: 769px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media screen and (min-width: 426px) and (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 120px 120px;
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 120px 120px 120px;
  }
`;
ShipmentContainerHeader.displayName = "ShipmentContainerHeader";

const Flex = styled("div")`
  display: flex;
  flex-direction: ${(props) => props.direction || "column"};
  text-align: ${(props) => props.textAlign || "unset"};
  justify-content: ${(props) => props.justifyContent || "space-evenly"};
  align-items: ${(props) => props.alignItems || "unset"};
`;
Flex.displayName = "Flex";

function ShipmentCard({ shipment, dimension }) {
  return (
    <ShipmentContainer>
      <ShipmentContainerHeader
        backgroundColor={Color(
          RandomMC.getColor({
            text: titleCase(shipment.status),
          })
        )
          .alpha(0.25)
          .hsl()
          .string()}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          direction="column"
          style={{ padding: "1em" }}
        >
          <div>
            <LrLink
              href={`/gps/history_track/shipment_history/${
                shipment.shipmentNo
              }`}
              style={{
                textDecoration: "none",
                fontSize: "14px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={`Consignment: ${shipment.shipmentNo}`}
            >
              Consignment: {shipment.shipmentNo}
            </LrLink>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Flex
              alignItems="center"
              style={{
                height: "2em",
                padding: "0 1em",
                borderRadius: "1em",
                color: "#fff",
                fontSize: "12px",
                background: RandomMC.getColor({
                  text: titleCase(shipment.status),
                }),
              }}
            >
              {titleCase(shipment.status)}
            </Flex>
          </div>
        </Flex>
        <Flex justifyContent="space-between" style={{ padding: "1em" }}>
          <div style={{ fontSize: "18px", color: "#212121" }}>
            {shipment.vehicleNo.toUpperCase()}
          </div>
          <div>LR: {shipment.lrNo}</div>
        </Flex>
        <Flex alignItems="center" justifyContent="center">
          <div>
            <div style={{ fontSize: "14px", color: "#212121" }}>
              {shipment.startNode}
            </div>
            <div style={{ fontSize: "12px", color: "#727272" }}>
              {shipment.endNode}
            </div>
          </div>
        </Flex>
        <Flex>
          <div style={{ fontSize: "14px" }}>
            <small>{shipment.eta ? "E.T.A" : "Exp Start"}</small>:{" "}
            {shipment.eta
              ? shipment.eta.replace("UTC", "").trim()
              : shipment.startTime.replace("UTC", "").trim()}
          </div>
          <div style={{ fontSize: "12px", color: "#727272" }}>
            <small>Exp End</small>:{" "}
            {shipment.endTime ? shipment.endTime.replace("UTC", "") : "..."}
          </div>
        </Flex>
        <Flex>
          <Flex direction="row">
            {/* <div style={{ fontSize: '20px' }}>
              {shipment.childNodes.filter(x => x.exit_time).length} /{' '}
              {shipment.childNodes.length - 1}
            </div> */}
            <div>
              {Number(shipment.percentageTripCovered) > 0
                ? `${shipment.percentageTripCovered} %`
                : "..."}
            </div>
            <div>
              {Object.entries(
                countBy(shipment.childNodes.filter((_, i) => i > 0), "state")
              ).map(([state, value], index) => {
                if (state === "null") {
                  return null;
                }
                return (
                  <div key={`shipmentNodeState_${index}`}>
                    {state} : {value}
                  </div>
                );
              })}
            </div>
          </Flex>
        </Flex>
        <Flex
          direction="row"
          style={{ backgroundColor: "rgba(230,233,236,1)", padding: "0.75em" }}
        >
          <Flex style={{ flex: 2 }}>
            <div style={{ fontSize: "14px" }}>
              <FaUserCircle color="#D1D1D2" />{" "}
              <span style={{ color: "#006491", fontSize: "14px" }}>
                {shipment.driverName.toUpperCase()}
              </span>
            </div>
            <div style={{ color: "#727272", fontSize: "12px" }}>
              {shipment.driverPhoneNo}
            </div>
            {shipment.transporter && (
              <div style={{ fontSize: "12px", color: "#727272" }}>
                {shipment.transporter}
              </div>
            )}
          </Flex>
          <Flex style={{ flex: 1 }}>
            {shipment.deviceInfo && (
              <React.Fragment>
                <div>
                  {shipment.deviceInfo.batteryStrength && (
                    <div>
                      <i
                        data-toggle="tooltip"
                        data-placement="right"
                        className={`fa ${
                          shipment.deviceInfo.batteryStrength.icon
                        } ||
                          fa-spinner`}
                        text={shipment.deviceInfo.batteryStrength.value}
                      />
                    </div>
                  )}
                </div>
                <div>
                  {shipment.deviceInfo.signalStrength && (
                    <div>
                      <i
                        className="fa fa-signal"
                        data-toggle="tooltip"
                        data-placement="right"
                        style={{
                          color: shipment.deviceInfo.signalStrength.color,
                        }}
                        title={shipment.deviceInfo.signalStrength.value}
                      />
                    </div>
                  )}
                </div>
                <div>
                  {shipment.deviceInfo.gpsStatus && (
                    <div>
                      <i
                        className="fa fa-globe"
                        data-toggle="tooltip"
                        data-placement="right"
                        style={{ color: shipment.deviceInfo.gpsStatus.color }}
                        title={shipment.deviceInfo.batteryStrength.value}
                      />
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
          </Flex>
        </Flex>
      </ShipmentContainerHeader>
      <Timeline data={shipment} dimension={dimension} />
    </ShipmentContainer>
  );
}

export default ShipmentCard;
