import _ from "lodash";
import React from "react";
import Select from "react-select";
import { SkyLightStateless } from "react-skylight";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { bigModal, statusColor } from "./../../../../utils";
import VendorPerformanceTable from "./VendorPerformanceTable";

function sortGraphData(b, a) {
  return b.Delayed + b.Early + b.Ontime - (a.Delayed + a.Early + a.Ontime);
}
class VendorPerformance extends React.Component {
  state = {
    trips: this.props.trips,
    data: null,
    graphData: null,
    statuses: null,
    modalVisible: false,
    selectedVendor: null,
    selectedTrips: null,
    selectedStatus: null,
    selectedNumberOfVehicles: null,
    selectedPercentage: null,
    hasNewData: false,
    limit: 5,
    maxLimit: 15,
    selectedVendors: null,
    sortBy: "desc"
  };

  componentDidMount() {
    this._populateData(this.props);
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.trips, state.trips)) {
      return { trips: props.trips, hasNewData: true };
    } else {
      return { hasNewData: false };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.hasNewData !== this.state.hasNewData &&
      this.state.hasNewData
    ) {
      this._populateData(this.props);
    }
  }

  _populateData = dataSrc => {
    let statuses = [...new Set(dataSrc.trips.map(trip => trip.status))].filter(
      n => n
    );

    let vendors = [
      ...new Set(dataSrc.trips.map(trip => trip.transporter))
    ].filter(n => n);

    let statusesCountArr = Object.assign(
      {},
      ...statuses.map(status => {
        return {
          [status]: 0
        };
      })
    );

    let vendorStatusCountArr = {};

    vendors.forEach(vendor => {
      vendorStatusCountArr[vendor] = { ...statusesCountArr };
    });

    dataSrc.trips.forEach(trip => {
      if (trip.status && vendorStatusCountArr[trip.transporter]) {
        vendorStatusCountArr[trip.transporter][trip.status]++;
      }
    });

    let data = Object.keys(vendorStatusCountArr).map(key => {
      return {
        name: key,
        ...vendorStatusCountArr[key]
      };
    });

    data = data.sort((a, b) => {
      return (
        _.sum(Object.values(b).filter(e => typeof e !== "string")) -
        _.sum(Object.values(a).filter(e => typeof e !== "string"))
      );
    });

    let graphData = _.take(data, this.state.limit || 5);

    this.setState({ data, graphData, statuses });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      selectedTrips: null,
      selectedVendor: null
    });
    this._populateData(this.props);
  };

  _handleClick = (status, e) => {
    console.log(e, status);
    let trips = this.props.trips.filter(
      trip => trip.transporter === e.name && trip.status === status
    );
    if (e.payload) {
      let payload = e.payload;
      delete payload["name"];
      payload = { ...payload };
      let total = _.sum(Object.values(payload));
      let percentage = total
        ? Number((e.payload[status] / total) * 100).toFixed(2)
        : "...";
      this.setState({
        selectedNumberOfVehicles: e.payload[status],
        selectedPercentage: percentage
      });
    }

    this.setState({
      modalVisible: true,
      selectedTrips: trips,
      selectedVendor: e.name,
      selectedStatus: status
    });
  };

  selectVendors = selectedVendors => {
    const arr = selectedVendors.map(vendor => vendor.value);
    let graphData = this.state.data.filter(item => arr.includes(item.name));
    if (!arr.length) {
      graphData = _.take(this.state.data, this.state.limit || 5);
    }
    this.setState({ selectedVendors, graphData });
  };

  _load = event => {
    let limit = Math.max(event.target.value, 5);
    let graphData = _.take(
      this.state.data,
      Math.min(limit, this.state.maxLimit)
    );
    this.setState({ graphData, limit });
  };

  sort = e => {
    this.setState({ sortBy: e.target.value });
  };

  render() {
    let { sortBy, graphData, limit, data, maxLimit } = this.state;
    if (graphData) {
      if (sortBy === "desc") {
        graphData = graphData.sort((a, b) => sortGraphData(b, a));
      } else {
        graphData = graphData.sort((a, b) => sortGraphData(a, b));
      }
      graphData = graphData.filter((_, i) => i < Number(limit));
    }
    return (
      <div>
        <h4 className="text-muted">Vendor Performance</h4>
        <hr />
        {graphData && (
          <ResponsiveContainer height={500} width="100%">
            <BarChart
              layout="vertical"
              data={graphData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis type="number" tick={false} />
              <YAxis dataKey="name" type="category" width={150} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              <Bar
                dataKey={"Early"}
                stackId="a"
                fill={statusColor["Early"]}
                barSize={30}
                onClick={e => this._handleClick("Early", e)}
              />
              <Bar
                dataKey={"Ontime"}
                stackId="a"
                fill={statusColor["Ontime"]}
                barSize={30}
                onClick={e => this._handleClick("Ontime", e)}
              />
              <Bar
                dataKey={"Delayed"}
                stackId="a"
                fill={statusColor["Delayed"]}
                barSize={30}
                onClick={e => this._handleClick("Delayed", e)}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
        {data && (
          <div className="form-control">
            <div className="row">
              <div className="col-md-3">
                Showing{" "}
                <input
                  type="number"
                  min="0"
                  max={maxLimit}
                  step="1"
                  value={limit}
                  onChange={this._load}
                />{" "}
                / {this.state.data.length} entries.
              </div>
              <div className="col-md-3">
                <select onChange={this.sort} value={sortBy}>
                  <option value="" disabled>
                    Sort
                  </option>

                  <option value="desc">DESC</option>
                  <option value="asc">ASC</option>
                </select>
              </div>
              <div className="col-md-6">
                <Select
                  placeholder="Select Vendors"
                  name="select-vendor"
                  value={this.state.selectedVendors}
                  onChange={this.selectVendors}
                  options={this.state.data.map(item => ({
                    value: item.name,
                    label: item.name
                  }))}
                  isMulti
                />
              </div>
            </div>
          </div>
        )}
        <SkyLightStateless
          dialogStyles={bigModal}
          isVisible={this.state.modalVisible}
          onCloseClicked={() => this.closeModal()}
          title={
            <div className="row skylight-dipper">
              <h4 className="text-center">
                Vendor Performance : {this.state.selectedVendor}
              </h4>
              <h5 className="text-center">
                <div className="row">
                  <div className="col-md-4">
                    Transit Performance : {this.state.selectedStatus}
                  </div>
                  <div className="col-md-4">
                    Percentage: {this.state.selectedPercentage} %
                  </div>
                  <div className="col-md-4">
                    Number of vehicles: {this.state.selectedNumberOfVehicles}
                  </div>
                </div>
              </h5>
            </div>
          }
        >
          <hr />
          {this.state.selectedTrips && (
            <VendorPerformanceTable
              data={this.state.selectedTrips}
              tripCompleted={this.props.tripCompleted}
              userRole={this.props.userRole}
            />
          )}
        </SkyLightStateless>
      </div>
    );
  }
}

export default VendorPerformance;
