import { startCase } from "lodash";
import qs from "qs";
import React from "react";
import Select from "react-select";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import _ from "lodash";
import { IFilter } from ".";
import useDeepCompareEffect from "../../../../customHooks/useDeepCompareEffect";
import { IJourney } from "../../../../types";
import { colors } from "./colors";
import GraphContainer from "./GraphContainer";
import CustomTick from "./CustomTick";
import {CustomTooltip} from "./RoutePerformance";
import { formatDuration } from "../../../../utils";

interface VendorPerformanceProps {
  filter: IFilter;
  showJourney: (journeys: IJourney[]) => void;
}

interface IStatus {
  ontime: IJourney[];
  early: IJourney[];
  delayed: IJourney[];
  unknownStatus: IJourney[];
}

interface vendorPerformanceResponse {
  name: string;
  status: IStatus;
  avgTransitTime: number | null;
  onTime: number;
  delayed: number;
  early: number;
  unknownStatus: number;
}

export default function VendorPerformance({
  filter,
  showJourney,
}: VendorPerformanceProps) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState<null | vendorPerformanceResponse[]>(
    null
  );

  const [visibleCount, setVisibleCount] = React.useState(5);
  const [maxCount, setMaxCount] = React.useState(5);
  const [orderAsc, setOrderAsc] = React.useState({
    label: "DESC",
    value: false,
  });
  const [selectedXAxisLabels, setSelectedXAxisLabels] = React.useState(null);

  useDeepCompareEffect(() => {
    fetchData();
  }, [filter]);

  async function fetchData() {
    try {
      setLoading(true);
      const q = qs.stringify(filter, {
        encode: false,
        skipNulls: true,
        indices: false,
      });
      const url = `${location.href}/vendor_performance.json?${q}`;
      const response = await (await fetch(url, {
        headers: {
          "Key-Inflection": "camel",
        },
      })).json();
      if (response && response.data) {
        let graphData = response.data.map((item) => {
          const x = item;
          x["count"] = 0;
          Object.entries(x.status).forEach(([statusName, journeys]) => {
            x[statusName] = (journeys as IJourney[]).length;
            x["count"] += (journeys as IJourney[]).length;
          });
          return x;
        });
        setMaxCount(graphData.length);
        setVisibleCount(Math.min(graphData.length, 5));
        setData(graphData);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }
  let graphData =
    data &&
    data.sort((a, b) => {
      const x: { [key: string]: any } = { ...a };
      const y: { [key: string]: any } = { ...b };
      delete x["name"];
      delete x["avgTransitTime"];
      delete x["status"];
      delete y["name"];
      delete y["avgTransitTime"];
      delete y["status"];
      return orderAsc && orderAsc.value
        ? Object.values(x).reduce((x, y) => Number(x) + Number(y)) -
            Object.values(y).reduce((x, y) => Number(x) + Number(y))
        : Object.values(y).reduce((x, y) => Number(x) + Number(y)) -
            Object.values(x).reduce((x, y) => Number(x) + Number(y));
    });
  if (selectedXAxisLabels && graphData) {
    graphData = graphData.filter((item) =>
      selectedXAxisLabels.map((label) => label.value).includes(item.name)
    );
  }
  if (graphData && visibleCount) {
    graphData = graphData.filter((_, index) => index < visibleCount);
  }

  return (
    <GraphContainer title="Vendor Performance" loading={loading} error={error}>
      <ResponsiveContainer height={400} width="100%">
        <BarChart
          width={350}
          height={400}
          data={graphData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            interval={0}
            tick={({ x, y, payload }) => (
              <CustomTick x={x} y={y} payload={payload} />
            )}
          />
          <YAxis
            yAxisId={0}
            allowDecimals={false}
            orientation="left"
            dataKey="count"
            name="vehicle"
            stroke="#333"
          />
          {/* <YAxis
            yAxisId={1}
            allowDecimals={false}
            orientation="right"
            dataKey="avgTransitTime"
            name="duration"
            unit="min"
            stroke="#333"
            tickFormatter={time =>
              time > 0
                ? formatDuration(time, {
                    keepDecimalsOnWholeSeconds: false,
                    unitCount: 2
                  })
                : 0
            }
          /> */}
          <Tooltip content={<CustomTooltip />} />
          <Legend formatter={(value, entry, index) => startCase(value)} />
          <Bar
            dataKey="early"
            stackId="a"
            yAxisId={0}
            fill={colors["early"]}
            onClick={({ status }) => showJourney(status["early"])}
          />
          <Bar
            dataKey="onTime"
            stackId="a"
            yAxisId={0}
            fill={colors["onTime"]}
            onClick={({ status }) => showJourney(status["onTime"])}
          />
          <Bar
            dataKey="delayed"
            stackId="a"
            yAxisId={0}
            fill={colors["delayed"]}
            onClick={({ status }) => showJourney(status["delayed"])}
          />
          <Bar
            dataKey="unknownStatus"
            stackId="a"
            yAxisId={0}
            fill={colors["unknownStatus"]}
            onClick={({ status }) => showJourney(status["unknownStatus"])}
          />
          {/* <Bar yAxisId={1} dataKey="avgTransitTime" stackId="b" fill="#8884d8" /> */}
        </BarChart>
      </ResponsiveContainer>
      {data && (
        <React.Fragment>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1, marginLeft: "0.125em" }}>
              Showing
              <input
                type="number"
                min="1"
                step="1"
                max={maxCount}
                value={visibleCount}
                onChange={(e) => setVisibleCount(Number(e.target.value))}
              />{" "}
              of {data.length}
            </div>
            <div style={{ flex: 1, marginRight: "0.125em" }}>
              <Select
                placeholder="Sort VIVO"
                options={[
                  { label: "ASC", value: true },
                  { label: "DESC", value: false },
                ]}
                onChange={(selected) => setOrderAsc(selected)}
                value={orderAsc}
              />
            </div>
          </div>
          <div style={{ marginTop: "0.25em" }}>
            <Select
              placeholder="Select Vendors"
              options={data.map((item) => ({
                label: item.name,
                value: item.name,
              }))}
              onChange={(selected) => setSelectedXAxisLabels(selected)}
              value={selectedXAxisLabels}
              isMulti
            />
          </div>
        </React.Fragment>
      )}
    </GraphContainer>
  );
}
