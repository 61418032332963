import React from "react";
import Table from "./Table";

class RoutePerformanceTable extends React.Component {
    state = {
        data: this.props.data,
        statuses: [...new Set(this.props.data.map(trip => trip.status))].filter(
            n => n
        ),
        tripStates: [
            ...new Set(this.props.data.map(trip => trip.state))
        ].filter(n => n),
        columns: [
            "vehicle_no",
            "state",
            "loading_in_time",
            "loading_out_time",
            "unloading_in_time",
            "unloading_out_time",
            "transit_time",
            "sta",
            "percentage_trip_completed",
            "transporter",
            "consigner_name",
            "consignee_name"
        ]
    };

    render() {
        return (
            <div>
                {this.state.columns && (
                    <Table
                        data={this.state.data}
                        statuses={this.state.statuses}
                        tripStates={this.state.tripStates}
                        columns={this.state.columns}
                        tripCompleted={this.props.tripCompleted}
                        userRole={this.props.userRole}
                    />
                )}
            </div>
        );
    }
}

export default RoutePerformanceTable;
