import React from 'react';
import moment from 'moment';

export default class MuteIndex extends React.Component {
  state = {
    alertName: this.props.alertName,
    mutedSubscriptions: null,
  };

  componentDidMount() {
    this._fetchMutedVehicles();
  }

  _handleResponse = muted_subscriptions => {
    this.setState({
      mutedSubscriptions: muted_subscriptions,
    });
  };
  _unmuteSubscription = id => {
    let url = `alert_subscriptions/unmute_subscription.json?id=${id}`;
    fetch(url, {
      method: 'POST',
      headers: ReactOnRails.authenticityHeaders({
        'Content-Type': 'application/json',
      }),
      mode: 'cors',
      credentials: 'include',
    })
      .then(res => res.json())
      .then(data => {
        swal('Success', 'Removed successfully', 'success');
        this._handleResponse(data.muted_subscriptions);
      })
      .catch(err => {
        swal('Sorry!', 'Something Went Wrong, Please try again', 'warning');
      });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      alertname: nextProps.alertName,
    });
    //this._fetchMutedVehicles;
  }

  _fetchMutedVehicles = () => {
    let {alertName} = this.state;
    let url = `alert_subscriptions/muted_subscriptions_list.json?subscription_type=${
      this.state.alertName
    }`;
    fetch(url, {
      method: 'GET',
      headers: ReactOnRails.authenticityHeaders({
        'Content-Type': 'application/json',
      }),
      credentials: 'same-origin',
    })
      .then(res => res.json())
      .then(data => {
        this._handleResponse(data.muted_subscriptions);
      })
      .catch(err => {
        console.log(err);
        swal('Sorry!', 'Something Went Wrong, Please try again', 'warning');
      });
  };

  render() {
    return (
      <div className="row d-flex">
        <table className="table">
          <thead className="thead-inverse">
            <tr>
              <th>Vehicle No.</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Reason</th>
              <th>Unmute</th>
            </tr>
          </thead>

          <tbody>
            {this.state.mutedSubscriptions &&
              this.state.mutedSubscriptions.map(sub => (
                <tr key={sub.id}>
                  <td>{sub.vehicle_no}</td>
                  <td>
                    {moment(sub.mute_start_time).format('MMM Do YYYY, h:mm a')}
                  </td>
                  <td>
                    {moment(sub.mute_stop_time).format('MMM Do YYYY, h:mm a')}
                  </td>
                  <td>{sub.reason}</td>
                  <td>
                    <button
                      className="btn btn-success"
                      onClick={() => this._unmuteSubscription(sub.id)}>
                      {' '}
                      <i className="fa fa-truck" /> UnMute
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}
