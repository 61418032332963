import moment from "moment";
import React from "react";

const OverspeedTable = props => (
  <table className="table table-sm">
    <thead>
      <tr>
        <th />
        <th>Speed</th>
        <th>Landmark</th>
        <th>Date</th>
      </tr>
    </thead>
    <tbody>
      {props.data.map((item, index) => {
        return (
          <tr key={index}>
            <td style={{ width: "50px" }}>
              <input
                type="checkbox"
                value={new Date(item.ist_timestamp).getTime()}
                id={`check_${index}`}
                className="form-control"
                onChange={props.showOverspeedInfoWindow}
                checked={
                  props.showOverspeedInfoWindow
                    ? new Date(item.ist_timestamp).getTime() ===
                      Number(props.selectedOverspeedMarkerTimestamp)
                    : false
                }
              />
            </td>
            <td style={{ width: "100px" }}>
              <label htmlFor={`check_${index}`}>{item.speed} km/hr</label>
            </td>
            <td>{item.landmark}</td>
            <td style={{ width: "250px" }}>
              {moment.utc(new Date(item.ist_timestamp)).format("lll")}
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

export default OverspeedTable;
