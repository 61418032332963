import React from 'react';
import {compose, withProps, withStateHandlers} from 'recompose';
import {Marker, InfoWindow} from 'react-google-maps';
import StoppageInfoWindow from './StoppageInfoWindow';
import {stoppageMarker} from '../../../../../utils';

export default class StoppageMarker extends React.Component {
  state = {
    isOpen: this.props.isOpen,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({isOpen: !this.state.isOpen});
    }
  }

  _onToggleOpen = () => {
    this.setState({isOpen: !this.state.isOpen});
  };

  render() {
    return (
      <Marker
        position={{
          lat: Number(this.props.info.start_lat),
          lng: Number(this.props.info.start_long),
        }}
        icon={stoppageMarker}
        onClick={this._onToggleOpen}>
        {this.state.isOpen && (
          <InfoWindow onCloseClick={this._onToggleOpen}>
            <StoppageInfoWindow
              info={this.props.info}
              isOpen={this.state.isOpen}
            />
          </InfoWindow>
        )}
      </Marker>
    );
  }
}
