import React from 'react'
import { DirectionsRenderer, GoogleMap, withGoogleMap } from 'react-google-maps'
import { compose, lifecycle, withStateHandlers } from 'recompose'
import VehicleMarker from '../components/Maps/VehicleMarker'

const MapWithAMarker = compose(
  withStateHandlers(
    () => ({
      isOpen: false
    }),
    {
      onToggleOpen: ({ isOpen }) => () => ({
        isOpen: !isOpen
      })
    }
  ),
  withGoogleMap,
  lifecycle({
    componentDidMount() {
      const DirectionsService = new google.maps.DirectionsService()
      DirectionsService.route(
        {
          origin: new google.maps.LatLng(
            Number(this.props.route.origin.latitude),
            Number(this.props.route.origin.longitude)
          ),
          destination: new google.maps.LatLng(
            Number(this.props.route.destination.latitude),
            Number(this.props.route.destination.longitude)
          ),
          travelMode: google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            this.setState({
              directions: result
            })
          } else {
            console.error(`error fetching directions ${result}`)
          }
        }
      )
    }
  })
)(props => {
  if (!props.vehicle.location) return null
  const vehicleMarkerPosition = {
    lat: Number(props.vehicle.location.latitude),
    lng: Number(props.vehicle.location.longitude)
  }
  return (
    <GoogleMap defaultZoom={8} defaultCenter={vehicleMarkerPosition}>
      {!!props.vehicle.consigner_trip.state &&
        props.vehicle.consigner_trip.state.toLowerCase() !== 'completed' && (
          <VehicleMarker vehicle={props.vehicle} />
        )}
      {props.directions && <DirectionsRenderer directions={props.directions} />}
    </GoogleMap>
  )
})

class TransactionMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      location: this.props.location,
      trip: this.props.trip,
      driver: this.props.driver,
      tripState: this.props.trip.state
        ? this.props.trip.state.toLowerCase()
        : null,
      route: this.props.route
    }
  }

  render() {
    const vehicle = {
      consigner_trip: this.state.trip,
      location: this.state.location,
      driver: this.state.driver
    }

    return (
      <MapWithAMarker
        containerElement={<div style={{ height: `350px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        vehicle={vehicle}
        route={this.state.route}
      />
    )
  }
}

export default TransactionMap
