import { startCase } from "lodash";
import qs from "qs";
import React from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from "recharts";
import { IFilter } from ".";
import useDeepCompareEffect from "../../../../customHooks/useDeepCompareEffect";
import { IJourney } from "../../../../types";
import { colors } from "./colors";
import GraphContainer from "./GraphContainer";

interface JourneyStatePerformanceProps {
  filter: IFilter;
  showJourney: (journeys: IJourney[]) => void;
}

interface IStatus {
  ontime: IJourney;
  early: IJourney;
  delayed: IJourney;
  unknownStatus: IJourney;
}

interface LoadingPerformanceResponse {
  name: string;
  value: number;
  data: IJourney[];
}

export default function JourneyStatePerformance({
  filter,
  showJourney
}: JourneyStatePerformanceProps) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState<null | LoadingPerformanceResponse[]>(
    null
  );
  useDeepCompareEffect(() => {
    fetchData();
  }, [filter]);

  async function fetchData() {
    try {
      setLoading(true);
      const q = qs.stringify(filter, {
        encode: false,
        skipNulls: true,
        indices: false
      });
      const url = `${location.href}/state_performance.json?${q}`;
      const response = await (await fetch(url, {
        headers: {
          "Key-Inflection": "camel"
        }
      })).json();

      if (response && response.data) {
        const graphData = Object.entries(response.data).map(
          ([key, journeys]) => ({
            name: key,
            value: (journeys as IJourney[]).length,
            data: journeys as IJourney[]
          })
        );
        setData(graphData);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <GraphContainer title="State Performance" loading={loading} error={error}>
      <ResponsiveContainer height={400} width="100%">
        <PieChart width={800} height={400}>
          <Tooltip />
          <Legend
            align="center"
            formatter={(value, entry, index) => startCase(value)}
          />
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data}
            labelLine={false}
            cx={200}
            cy={200}
            startAngle={90}
            endAngle={-270}
            outerRadius={120}
            label
            onClick={({ data }) => showJourney(data)}
          >
            {data &&
              data.map(({ name }, index) => (
                <Cell key={`cell-${index}`} fill={colors[name]} />
              ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </GraphContainer>
  );
}
