import styled from "@emotion/styled";
import Color from "color";
import format from "date-fns/format";
import countBy from "lodash/countBy";
import RandomMC from "random-material-color";
import addMinutes from "date-fns/add_minutes";
import React from "react";
import { FaHistory, FaRoad } from "react-icons/fa";
import titleCase from "title-case";
import Timeline from "../Timeline";

export const LrLink = styled.a`
  display: block;
  width: 15ch;
  text-decoration: none;
  font-size: 14px;
  margin-left: 0.25em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`

export function formatDate(input) {
  const tzOffset = new Date().getTimezoneOffset()
  input = addMinutes(input, tzOffset)
  return format(input, "DD-MM-YYYY HH:mm:ss");
}

const JourneyCardContainer = styled("div")`
  display: grid;
  grid-template-rows: 120px minmax(120px, 1fr);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  min-height: 15em;
  grid-gap: 0.5em;
  background: #fff;
  @media screen and (max-width: 768px) {
    grid-template-rows:  repeat(2, minmax(120px, 1fr));
  }
`;

JourneyCardContainer.displayName = "JourneyCardContainer";

const JourneyCardContainerHeader = styled("div")`
  display: grid;
  background: ${({ backgroundColor }) => backgroundColor || "#fff"};
  opacity: ${({ opacity }) => opacity || 1};
  overflow: hidden;
  @media screen and (min-width: 769px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  @media screen and (min-width: 426px) and (max-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: 120px 120px;
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: 120px 120px 120px;
  }
`;
JourneyCardContainerHeader.displayName = "JourneyCardContainerHeader";

const Flex = styled("div")`
  display: flex;
  flex-direction: ${(props) => props.direction || "column"};
  text-align: ${(props) => props.textAlign || "unset"};
  justify-content: ${(props) => props.justifyContent || "space-evenly"};
  align-items: ${(props) => props.alignItems || "unset"};
`;
Flex.displayName = "Flex";

function JourneyCard({ journey, dimension }) {
  return (
    <JourneyCardContainer>
      <JourneyCardContainerHeader
        backgroundColor={Color(
          RandomMC.getColor({
            text: titleCase(journey.status),
          })
        )
          .alpha(0.25)
          .hsl()
          .string()}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          style={{flexDirection: "column" }}
        >
          <div>
            <LrLink
              href={`/shipments?journeys[journeys.lr_no]=${journey.lrNo}`}
              title={` LR: ${journey.lrNo}`}
              target="_blank"
            >
              LR: {journey.lrNo}
            </LrLink>
          </div>
          <div>
            <a
              href={`/gps/history_track/journey_history/${journey.slug}`}
              style={{
                textDecoration: "none",
                fontSize: "14px",
              }}
              target="_blank"
            >
              <FaHistory />
            </a>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Flex
              alignItems="center"
              style={{
                height: "2em",
                padding: "0 1em",
                borderRadius: "1em",
                color: "#fff",
                fontSize: "12px",
                background: RandomMC.getColor({
                  text: titleCase(journey.inState),
                }),
              }}
            >
              {titleCase(journey.inState)}
            </Flex>
          </div>
        </Flex>
        <Flex justifyContent="center" alignItems="center" >
          <div style={{ fontSize: "18px", color: "#212121" }}>
            {journey.vehicleNo.toUpperCase()}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Flex
              alignItems="center"
              style={{
                height: "2em",
                padding: "0 1em",
                borderRadius: "1em",
                color: "#fff",
                fontSize: "12px",
                background: RandomMC.getColor({
                  text: titleCase(journey.status),
                }),
              }}
            >
              {titleCase(journey.status)}
            </Flex>
          </div>
        </Flex>
        <Flex alignItems="center" justifyContent="center">
          <div>
            {journey.route.includes(journey.loadingPoint) ? (
              <React.Fragment>
                <div style={{ fontSize: "14px", color: "#212121" }}>
                  {journey.loadingPoint}
                </div>
                <div style={{ fontSize: "12px", color: "#727272" }}>
                  {journey.unloadingPoint}
                </div>
              </React.Fragment>
            ) : (
              <div style={{ fontSize: "24px", color: "#212121" }}>
                {journey.route}
              </div>
            )}
          </div>
        </Flex>
        <Flex>
          <div style={{ fontSize: "14px" }}>
            <small>{journey.eta ? "E.T.A" : "Exp Start"}</small>:{" "}
            {journey.eta
              ? formatDate(journey.eta)
              : formatDate(journey.expStartTime)}
          </div>
          <div style={{ fontSize: "12px", color: "#727272" }}>
            <small>Exp End</small>: {formatDate(journey.expEndTime)}
          </div>
        </Flex>
        <Flex>
          <Flex direction="row">
            <div style={{ fontSize: "20px" }}>
              {journey.nodesCovered.covered} / {journey.nodesCovered.total}{" "}
              <br />
              {journey.percTripCovered ? `${journey.percTripCovered} %` : `...`}
            </div>
            <div>
              {Object.entries(
                countBy(journey.childNodes.filter((_, i) => i > 0), "state")
              ).map(([state, value], index) => {
                if (state === "null") {
                  return null;
                }
                return (
                  <div key={`journeyNodeState_${index}`}>
                    {state} : {value}
                  </div>
                );
              })}
            </div>
          </Flex>
        </Flex>
        <Flex
          direction="row"
          style={{ backgroundColor: "rgba(230,233,236,1)", padding: "0.75em" }}
        >
          <Flex style={{ flex: 2 }}>
            <div style={{ fontSize: "14px" }}>
              <FaRoad color="#D1D1D2" />{" "}
              <span style={{ color: "#006491", fontSize: "14px" }}>
                {journey.driverName ? journey.driverName.toUpperCase() : null}
              </span>
            </div>
            <div style={{ color: "#727272", fontSize: "12px" }}>
              {journey.driverPhoneNo}
            </div>
            {journey.transporter && (
              <div style={{ fontSize: "12px", color: "#727272" }}>
                {journey.transporter}
              </div>
            )}
          </Flex>
          <Flex style={{ flex: 1 }}>
            {journey.trackingProvider &&
              journey.trackingProvider.toLowerCase() === "sim" && (
                <div>
                  <i
                    data-toggle="tooltip"
                    data-placement="right"
                    className={`fa ${
                      journey.consentApproved ? "fa-check-square" : "fa-times"
                    } `}
                    style={{
                      color: journey.consentApproved ? "green" : "red",
                    }}
                    title={
                      journey.consentStatus ||
                      `Consent status: ${journey.consentStatus}`
                    }
                  />
                </div>
              )}
            {journey.deviceInfo && (
              <React.Fragment>
                <div>
                  {journey.deviceInfo.batteryStrength && (
                    <div>
                      <i
                        data-toggle="tooltip"
                        data-placement="right"
                        className={`fa ${
                          journey.deviceInfo.batteryStrength.icon
                        } ||
                          fa-spinner`}
                        title={journey.deviceInfo.batteryStrength.value}
                      />
                    </div>
                  )}
                </div>
                <div>
                  {journey.deviceInfo.signalStrength && (
                    <div>
                      <i
                        className="fa fa-signal"
                        data-toggle="tooltip"
                        data-placement="right"
                        style={{
                          color: journey.deviceInfo.signalStrength.color,
                        }}
                        title={journey.deviceInfo.signalStrength.value}
                      />
                    </div>
                  )}
                </div>
                <div>
                  {journey.deviceInfo.gpsStatus && (
                    <div>
                      <i
                        className="fa fa-globe"
                        data-toggle="tooltip"
                        data-placement="right"
                        style={{ color: journey.deviceInfo.gpsStatus.color }}
                        title={journey.deviceInfo.gpsStatus.value}
                      />
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
          </Flex>
        </Flex>
      </JourneyCardContainerHeader>
      {journey && <Timeline data={journey} dimension={dimension} />}
    </JourneyCardContainer>
  );
}

export default JourneyCard;
