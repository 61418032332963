import React from "react";
import { InfoWindow, Marker } from "react-google-maps";
import {
  dipperTruckMarker,
  getIconByVehicleType,
  iconByState,
  trainIcon,
} from "../../../../utils";
import InfoWindowBox from "./InfoWindowBox";

function VehicleMarker({ vehicle: truck }) {
  const { location, vehicle, transport_mode } = truck;
  const [isOpen, setIsOpen] = React.useState(false);

  function onToggleOpen() {
    setIsOpen(!isOpen);
  }

  const vehicleType =
    vehicle &&
    vehicle.truck_type &&
    vehicle.truck_type.toLowerCase() !== "dummy"
      ? vehicle.truck_type.replace(/\s/g, "").toUpperCase()
      : null;
  let vehicleIcon = "";
  if(transport_mode === "rail") {
    vehicleIcon = trainIcon
  } else {
    if (vehicleType) {
      if (vehicleType.toLowerCase() === "train") {
        vehicleIcon = trainIcon;
      } else {
        vehicleIcon = getIconByVehicleType(vehicleType);
      }
    }
    if (!vehicleIcon && location.state) {
      vehicleIcon = iconByState[location.state] || iconByState.none;
    }
    if (!vehicleIcon && !location.state) {
      vehicleIcon = dipperTruckMarker;
    }
  }
  return (
    <Marker
      position={{
        lat: Number(location.latitude),
        lng: Number(location.longitude),
      }}
      icon={{
        url: vehicleIcon,
        scaledSize: new google.maps.Size(35, 35),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 0),
      }}
      onClick={() => onToggleOpen()}
      label={{
        text: location.vehicle_no.toUpperCase(),
        color: "white",
      }}
    >
      {isOpen && (
        <InfoWindow onCloseClick={() => onToggleOpen}>
          <InfoWindowBox info={truck} />
        </InfoWindow>
      )}
    </Marker>
  );
}

export default VehicleMarker;
