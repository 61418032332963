export { default as JourneyCard } from "./JourneyCard";
export { default as ShipmentCard } from "./ShipmentCard";

export const Flex = styled("div")`
  display: flex;
  flex-direction: ${props => props.direction || "column"};
  text-align: ${props => props.textAlign || "unset"};
  justify-content: ${props => props.justifyContent || "space-evenly"};
  align-items: ${props => props.alignItems || "unset"};
`;
Flex.displayName = "Flex";

export const PageContainer = styled("div")`
  display: grid;
  grid-gap: 1em;
  background: #d7d7d7;
`;

import styled from "styled-components";

export const CountContainer = styled("div")`
  display: grid;
  grid-gap: 1em;
  padding: 0 1em;
  @media screen and (min-width: 426px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const TopLevelFilters = styled("div")`
  display: grid;
  grid-gap: 1em;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(225, 225, 225, 0.5);
`;

export const FilterContainer = styled("div")`
  display: grid;
  margin-bottom: 1em;
  align-items: center;
  padding: 0 1em;
  @media screen and (min-width: 769px) {
    grid-template-columns: 100px 250px repeat(4, 1fr);
    grid-gap: 1em;
  }
`;

export const FilterButton = styled("div")`
  border-bottom: ${({ isActive }) =>
    isActive ? "2px solid #006491" : "unset"};
  padding: 0.5em 1em;
  margin-right: 1em;
  cursor: pointer;
`;
