import axios from "axios";
import xor from "lodash/xor";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import Select from "react-select";
import { SkyLightStateless } from "react-skylight";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer, 
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { bigModal } from "../../../../utils";
import LoadingVivoTable from "./LoadingVivoTable";
require("moment-duration-format");

const CancelToken = axios.CancelToken;
let cancel;

const formatTime = (time) =>
  moment.duration(time / 1000, "seconds").format("h [hr] m [min]");

const match = {
  min: "less_than_avg",
  avg: "avg_trips",
  max: "above_50_perc_avg",
};

const selectionMessage = {
  min: "Showing trips whose VIVO is 50% less than Avg. VIVO",
  avg: "Showing trips whose VIVO lies in between +/- 50% Avg. VIVO",
  max: "Showing trips whose VIVO is 50% greater than Avg. VIVO",
};

function vivoToData(vivo) {
  return Object.keys(vivo)
    .map((consigner) => {
      //     if (vivo[consigner].avg < 60 * 60 * 1000) return
      return {
        name: consigner,
        max_time_consumed: vivo[consigner].above_50_perc_avg.length,
        avg_time_consumed: vivo[consigner].avg_trips.length,
        min_time_consumed: vivo[consigner].less_than_avg.length,
        average_vivo: vivo[consigner].avg,
        vehicles: vivo[consigner].vehicles,
      };
    })
    .filter((x) => x);
}

function sortGraphData(b, a) {
  return (
    b.avg_time_consumed +
    b.max_time_consumed +
    b.min_time_consumed -
    (a.avg_time_consumed + a.max_time_consumed + a.min_time_consumed)
  );
}

const CustomTooltip = (props) => {
  let { label, data } = props;
  let selected = data.filter((d) => d.name === label)[0];
  return (
    <div style={{ background: "#fff", padding: "1em" }}>
      <h5>{label}</h5>
      {selected && (
        <div>
          <strong className="text-muted">
            Total no. of vehicles :{" "}
            {Object.keys(selected.vehicles).length || "..."}
          </strong>
          <br />
          <strong className="text-muted">Average VIVO</strong> :{" "}
          {formatTime(selected.average_vivo)} <br />
          <strong className="text-muted">
            Vehicles {">"} 150% Avg ViVo
          </strong> : {selected.max_time_consumed}
          <br />
          <strong className="text-muted">
            Vehicles {">"} 50% but {"<"} 150% Avg ViVo
          </strong>{" "}
          : {selected.avg_time_consumed}
          <br />
          <strong className="text-muted">
            Vehicles {"<"} 50% Avg ViVo
          </strong> : {selected.min_time_consumed}
        </div>
      )}
    </div>
  );
};

function LoadingVivo({
  startDate,
  endDate,
  trips: consignerTrips,
  userRole,
  vivo,
  type,
}) {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [trips, setTrips] = React.useState(consignerTrips);
  const [sortBy, setSortBy] = React.useState("desc");
  const [modalVisible, setModalVisible] = React.useState(false);
  const [selectedAvg, setSelectedAvg] = React.useState(null);
  const [selectedTrips, setSelectedTrips] = React.useState(null);
  const [selectionMsg, setSelectionMsg] = React.useState(null);
  const [duration, setDuration] = React.useState(null);
  const [selectedConsigner, setSelectedConsigner] = React.useState([]);
  const [limit, setLimit] = React.useState(5);

  React.useEffect(() => {
    fetchApi();
  }, []);

  async function fetchApi() {
    try {
      setLoading(true);
      let url = `/consigner/track/overview_data.json`;
      let qParams = {
        user_loading_time: duration,
      };
      if (startDate) {
        qParams = { ...qParams, start_date: startDate, end_date: endDate };
      }
      Object.keys(qParams).forEach((key) => {
        if (!qParams[key]) {
          delete qParams[key];
        }
      });
      url +=
        `?` + queryString.stringify({ ...qParams }, { arrayFormat: "bracket" });
      const { data } = await axios.request({
        url,
        method: "GET",
        headers: ReactOnRails.authenticityHeaders({
          "Content-Type": "application/json",
          "Key-Inflextion": "camel",
        }),
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      });
      if (data) {
        populateData(
          {
            vivo: data.loadingVivo,
            trips: data.trips,
            start_date: data.startDate,
            end_date: data.endDate,
          },
          true
        );
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function populateData(dataSrc, hasNewData = false) {
    const { vivo, trips } = dataSrc;
    const data = vivoToData(vivo);
    setData(data);
    setTrips(trips);
  }

  function handleClick(type, e) {
    const searchKey = match[type];
    const selectionMsg = selectionMessage[type];
    const name = e.name;
    const tripIDs = vivo[name][searchKey];
    const selectedAvg = vivo[name].avg;
    const selectedTrips = consignerTrips[name].filter(
      (trip) => tripIDs.indexOf(trip.id) > -1
    );
    setSelectionMsg(selectionMsg);
    setSelectedConsigner(xor(selectedConsigner, [name]));
    setSelectedTrips(selectedTrips);
    setSelectedAvg(selectedAvg);
    setModalVisible(true);
  }

  function closeModal() {
    setSelectedTrips(null);
    setSelectedConsigner([]);
    setSelectionMsg(null);
    setModalVisible(false);
  }
  let graphData = data
    ? data.sort((a, b) =>
        sortBy === "desc" ? sortGraphData(b, a) : sortGraphData(a, b)
      )
    : null;
  if (graphData) {
    graphData = selectedConsigner.length
      ? graphData
          .filter((item) =>
            selectedConsigner.map((c) => c.value).includes(item.name)
          )
          .filter((_, i) => i < Number(limit))
      : graphData.filter((_, i) => i < Number(limit));
  }
  return (
    <div>
      <div className="row">
        <div className="col-xs-4">
          <h4 className="text-muted" style={{ fontSize: "20px" }}>
            Loading Performance
          </h4>
        </div>
        <div className="col-xs-5">
          <input
            placeholder="Custom VIVO (hrs)"
            type="text"
            name="Select Time"
            onKeyUp={(event) => setDuration(event.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-xs-1">
          <button className="btn btn-secondary" onClick={() => fetchApi()}>
            Go
          </button>
        </div>
      </div>
      <hr style={{ marginTop: "0px" }} />
      {!loading ? (
        <React.Fragment>
          {graphData ? (
            <React.Fragment>
              <ResponsiveContainer height={400} width="100%">
                <BarChart
                  data={graphData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <XAxis dataKey="name" />
                  <YAxis
                    tickFormatter={(val) => (Number.isInteger(val) ? val : "")}
                  >
                    <Label
                      angle={-90}
                      value="Number of vehicles"
                      position="insideLeft"
                      style={{ textAnchor: "middle" }}
                    />
                  </YAxis>
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip content={<CustomTooltip data={graphData} />} />
                  <Legend />
                  <Bar
                    dataKey="min_time_consumed"
                    stackId="a"
                    fill="#2196F3"
                    onClick={(e) => handleClick("min", e)}
                  />
                  <Bar
                    dataKey="avg_time_consumed"
                    stackId="a"
                    fill="#4CAF50"
                    onClick={(e) => handleClick("avg", e)}
                  />
                  <Bar
                    dataKey="max_time_consumed"
                    stackId="a"
                    fill="#f44336"
                    onClick={(e) => handleClick("max", e)}
                  />
                </BarChart>
              </ResponsiveContainer>
              <div className="form-control">
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="number"
                      value={Number(limit)}
                      min={0}
                      step={1}
                      max={5}
                      onChange={(e) => setLimit(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <select
                      onChange={(e) => setSortBy(e.target.value)}
                      value={sortBy}
                    >
                      <option value="" disabled>
                        Sort
                      </option>

                      <option value="desc">DESC</option>
                      <option value="asc">ASC</option>
                    </select>
                  </div>
                  <div className="col-md-12">
                    {trips && (
                      <Select
                        placeholder="Select Consigner"
                        name="select-consigner"
                        value={selectedConsigner}
                        onChange={(selected) => setSelectedConsigner(selected)}
                        options={
                          data &&
                          data.map((item) => ({
                            value: item.name,
                            label: item.name,
                            key: item.name,
                          }))
                        }
                        isMulti
                      />
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div>No data</div>
          )}
          <SkyLightStateless
            dialogStyles={bigModal}
            isVisible={modalVisible}
            onCloseClicked={() => closeModal()}
          >
            <div className="row skylight-dipper">
              <h4 className="text-center">
                <i className="fa fa-clock-o" />
                Avg VIVO : {formatTime(selectedAvg)}
              </h4>
              <div className="row">
                <div
                  className="col-md-8 col-md-offset-2 text-center"
                  style={{ paddingRight: "40px" }}
                >
                  <h5>
                    <small>{selectionMsg}</small>
                  </h5>
                </div>
              </div>
            </div>
            <hr />
            {selectedTrips && (
              <LoadingVivoTable
                data={selectedTrips}
                avgVIVO={selectedAvg}
                userRole={userRole}
              />
            )}
          </SkyLightStateless>
        </React.Fragment>
      ) : (
        <div>Loading ...</div>
      )}
    </div>
  );
}

export default LoadingVivo;
