import React from 'react'
import queryString from 'query-string'

export default class Refresh extends React.Component {
  state = {
    interval: this.props.interval * 60000,
    disabled: false,
    isPristine: true,
    submitButtonClass: 'btn-secondary'
  }

  componentDidMount() {
    if (this.state.interval) {
      this._setRefreshInterval(true)
    }
  }

  _updateInterval = event => {
    const val = event.target.value
    this.setState({ interval: val * 1000 * 60 })
  }

  _setRefreshInterval = (fromDidMount = false) => {
    const parsedHash = queryString.parse(window.location.hash)
    const newHash = {
      ...parsedHash,
      interval: Math.floor(this.state.interval / 60000)
    }
    const stringified = queryString.stringify(newHash)
    const newUrl = window.location.pathname + '?' + stringified
    if (!fromDidMount && this.state.interval) {
      swal(
        'Done',
        `Page will be refreshed in every ${Math.floor(
          this.state.interval / 60000
        )} min`,
        'success'
      )
      this.setState(
        state => ({
          ...state,
          isPristine: false,
          submitButtonClass: 'btn-success'
        }),
        () => {
          window.location = newUrl
        }
      )
    } else {
      setTimeout(() => (window.location = newUrl), this.state.interval)
    }
  }

  _disableRefresh = () => {
    this.setState({ interval: 0 })
    const parsedHash = queryString.parse(window.location.hash)
    if (parsedHash['interval']) {
      delete parsedHash['interval']
    }
    const newHash = { ...parsedHash, interval: 0 }
    const stringified = queryString.stringify(newHash)
    const newUrl = window.location.pathname + '?' + stringified
    window.location = newUrl
  }

  render() {
    const { interval, disabled, submitButtonClass } = this.state
    const mins = Math.floor(interval / 60000)

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ fontSize: '12px', width: '60px' }}>Refresh Time :</div>
        <div
          className="input-group"
          style={{ width: '135px', margin: '0 10px' }}
        >
          <input
            type="number"
            min="5"
            className="form-control"
            placeholder="Refresh time"
            value={mins}
            onChange={this._updateInterval}
          />
          <span className="input-group-addon">mins</span>
        </div>
        <div>
          <button
            className={`btn ${submitButtonClass}`}
            onClick={() => this._setRefreshInterval(false)}
          >
            <i className="fa fa-check" />
          </button>
        </div>
        {interval > 0 && (
          <div style={{ paddingLeft: '10px' }}>
            <button className="btn btn-danger" onClick={this._disableRefresh}>
              <i className="fa fa-close" />
            </button>
          </div>
        )}
      </div>
    )
  }
}
