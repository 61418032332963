import React from "react";
import ReactTable from "react-table";
import { IJourney } from "../../../../types";
import { formatDate, formatDuration } from "../../../../utils";
interface JourneyTableProps {
  journeys: IJourney[];
}

export default function JourneyTable({ journeys }: JourneyTableProps) {
  const tableColumns = [
    {
      Header: "Invoice Number",
      id: "invoiceNo",
      accessor: d => {
        const href=`/gps/history_track/journey_history/${d.slug}`
        return <a href={href} target="_blank" >{d.invoiceNo}</a>
      },
    },
    {
      Header: "Vehicle",
      accessor: ({ vehicleNo }) => (vehicleNo ? vehicleNo.toUpperCase() : ""),
      id: "vehicleNo"
    },
    {
      Header: "State",
      accessor:  "inState",
      id: "inState"
    },
    {
      Header: "Status",
      accessor:  "status",
      id: "status"
    },
    {
      Header: "Start Time",
      accessor: d => formatDate(d, "expStartTime", "YY-MM-DD hh:mm A"),
      id: "startTime"
    },
    {
      Header: "End Time",
      accessor: d => formatDate(d, "actEndTime", "YY-MM-DD hh:mm A"),
      id: "endTime"
    },
    {
      Header: "Duration",
      accessor: d => d.actEndTime && d.expStartTime ? formatDuration( new Date(d.actEndTime).getTime() - new Date(d.expStartTime).getTime(), {
          keepDecimalsOnWholeSeconds: false,
          unitCount: 2
        }) : null,
      id: "duration"
    },
    {
      Header: "Consigner",
      accessor: "consignerName",
      id: "consignerName"
    },
    {
      Header: "Consignee",
      accessor: "consigneeName",
      id: "consigneeName"
    },
    {
      Header: "Driver Name",
      accessor: "driverName",
      id: "driverName"
    },
    {
      Header: "Driver Phone",
      accessor: "driverNo",
      id: "driveNo"
    }
  ];
  return (
    <div>
      <h5>Total: {journeys.length}</h5>
      <ReactTable
        defaultPageSize={7}
        className="-striped -highlight"
        filterable
        data={journeys}
        columns={tableColumns}
      />
    </div>
  );
}
