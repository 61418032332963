import Leaflet from "leaflet";
import _ from "lodash";
import React from "react";
import { Map, Marker, Popup } from "react-leaflet";
import MapPath from "./MapPath";
import Polyline from "./Polyline";

const markerHtmlStyles = (color, isDestination = false) => `
background-color: ${isDestination ? "#333" : color};
width: 2em;
height: 2em;
display: block;
position: relative;
border-radius: 1em 1em 0;
transform: rotate(45deg);
left: -24px;
top: -24px;
border: 2px solid ${isDestination ? color : "#333"};
opacity: ${isDestination ? 0.6 : 1}
`;

export default function MapRenderer({
  trips: allTrips,
  histories: tripHistories,
  center,
  zoom,
  selectedVehicleNo: vehicleNo,
  selectedTripId
}) {
  const [trips, setTrips] = React.useState(allTrips);
  const [histories, setHistories] = React.useState(tripHistories);
  const mapRef = React.createRef();

  React.useEffect(() => {
    filterTrips();
  }, [selectedTripId]);

  function filterTrips() {
    if (selectedTripId) {
      const filteredTrips = allTrips.filter(trip => trip.id === selectedTripId);
      const filteredHistories = {
        [selectedTripId]: tripHistories[selectedTripId]
      };
      setTrips(filteredTrips);
      setHistories(filteredHistories);
      // if (!!filteredHistories) {
      //   alert("Oops ! No trip history found");
      // }
    } else {
      setTrips(allTrips);
      setHistories(tripHistories);
    }
  }

  const validHistories = _.pickBy(histories, _.identity);
  const tripsById = _.groupBy(trips, "slug");

  return (
    <Map center={center} zoom={zoom} ref={mapRef}>
      <React.Fragment>
        {validHistories &&
          Object.keys(validHistories).map((tripId, index) => {
            const history = validHistories[tripId];
            const trip = tripsById[tripId][0];
            const color = trip.cardColor;
            return (
              <React.Fragment key={`map_path_${index}`}>
                <MapPath {...{ selectedTripId, color, data: history }} />
                <Polyline {...{ points: history, vehicleNo, color }} />
              </React.Fragment>
            );
          })}
        {trips &&
          trips.map((item, index) => (
            <React.Fragment key={`leaflet_marker_${index}`}>
              <Marker
                position={{
                  lat: item.consigner_lat,
                  lng: item.consigner_long
                }}
                icon={
                  new Leaflet.divIcon({
                    className: "my-custom-pin",
                    iconAnchor: [0, 0],
                    labelAnchor: [-6, 0],
                    popupAnchor: [0, -36],
                    html: `<span style="${markerHtmlStyles(item.cardColor)}" />`
                  })
                }
              >
                <Popup>
                  <b>Consigner: </b>
                  {item.consigner_name}
                  <br />
                  <b>Leg : </b>
                  {item.leg_no}
                </Popup>
              </Marker>
              <Marker
                position={{
                  lat: item.consignee_lat,
                  lng: item.consignee_long
                }}
                icon={
                  new Leaflet.divIcon({
                    className: "my-custom-pin",
                    iconAnchor: [0, 0],
                    labelAnchor: [-6, 0],
                    popupAnchor: [0, -36],
                    html: `<span style="${markerHtmlStyles(
                      item.cardColor,
                      true
                    )}" />`
                  })
                }
              >
                <Popup>
                  <b>Consignee: </b>
                  {item.consignee_name}
                  <br />
                  <b>Leg : </b>
                  {item.leg_no}
                </Popup>
              </Marker>
            </React.Fragment>
          ))}
      </React.Fragment>
    </Map>
  );
}
