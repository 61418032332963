import React from "react";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";

export default class Geocomplete extends React.Component {
  state = {
    value: "",
    data: [],
    coords: null
  };

  fetchPlacesData = async val => {
    try {
      if (val && val.length < 3) return;
      const result = await (await fetch(
        `/search_location/find.json?input=${val}`
      )).json();
      if (result && result.results) {
        const locationNames = result.results.map(({ data }) => ({
          location: data.display_name,
          coords: { lat: Number(data.lat), lng: Number(data.lon) }
        }));
        return locationNames;
      }
    } catch (error) {
      console.log(error);
    }
  };

  onChange = selected => {
    if (!!selected) {
      const { location, coords } = selected;
      this.setState({
        value: { location, coords }
      });
      if (this.props.onSelect) {
        this.props.onSelect(selected);
      }
    } else {
      this.setState({ value: "" });
    }
  };

  selectPlace = place => {
    console.log("place", place);
  };

  render() {
    return (
      <AsyncSelect
        value={this.state.value}
        onChange={this.onChange}
        onValueClick={this.selectPlace}
        getOptionValue={ele => ele.cords}
        getOptionLabel={ele => ele.location}
        loadOptions={debounce(this.fetchPlacesData, 500)}
        placeholder="Enter location to search"
      />
    );
  }
}
