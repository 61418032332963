import React from 'react'
import { Polyline } from 'react-leaflet'

const LeafletPolyline = ({ vehicleNo, color, points }) => {
  if (!points) return null
  const position = points.map(item => ({
    lat: Number(item.latitude),
    lng: Number(item.longitude)
  }))

  if (!vehicleNo) {
    return <Polyline positions={position} color={color} />
  }

  const location = points.map(val => ({
    lat: val.vehicle_no === vehicleNo ? Number(val.latitude) : null,
    lng: val.vehicle_no === vehicleNo ? Number(val.longitude) : null
  }))
  return <Polyline positions={location} color={color} />
}
export default LeafletPolyline
