import React from "react";

const MapAllConsignmentSidebarItem = ({
  item,
  selectedTripId,
  sidebarItemClicked
}) => (
  <div>
    <div
      className="card"
      style={{
        border: "0.5px solid black",
        borderLeftColor: item.cardColor,
        borderLeftWidth: "7px",
        background: selectedTripId === item.id ? item.cardColor : "#fff",
        color: selectedTripId === item.id ? "#fff" : "#333",
        padding: "0.5em"
      }}
      onClick={() =>
        sidebarItemClicked({
          selectedTripId: item.id,
          center: [item.consigner_lat, item.consigner_long],
          zoom: 9
        })
      }
    >
      <b>Consignment No</b> :{item.card_no}
      <br />
      <b>Vehicle No : </b>
      {item.vehicle_no}
      <br />
      <b>Origin : </b>
      {item.origin}
      <br />
      <b>Destination : </b>
      {item.destination}
      <br />
      <b> Lorry receipt-no : </b>
      {item.lorry_receipt_no}
      <br />
      <b>State : </b>
      {item.state}
      <br />
      <b>Loading In Time: </b>
      {item.loading_in_time}
      <br />
      <b>Loading Out Time: </b>
      {item.loading_out_time}
      <br />
      <b>Unloading In Time: </b>
      {item.unloading_in_time}
      <br />
      <b>Unloading Out Time: </b>
      {item.unloading_out_time}
      <br />
      <b>Leg No: </b>
      {item.leg_no}
      <br />
      <a
        href={`/consigner/track/${item.slug}/trip_trucks_history`}
        className="btn btn-block"
        title="Trip Overview"
        target="_blank"
      >
        Trip (Leg) Overview
      </a>
    </div>
  </div>
);

export default MapAllConsignmentSidebarItem;
