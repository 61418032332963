import React from "react";
import InputRange from "react-input-range";
import moment from "moment";

const StoppageFilter = props => (
  <div>
    <h6>Filter Stoppages</h6>
    <small style={{ padding: "20px 0px" }}>Please select stoppages to filter halts by time period, landmark history to see journey summary.</small>
    <div style={{ margin: "20px 40px" }}>
      {props.maxValue && (
        <InputRange
          formatLabel={value =>
            value
              ? value > 3540
                ? moment(moment.duration(value * 1000)._data).format(
                    "HH [hr] mm [mins]"
                  )
                : Math.floor(value/60) + " mins"
              : 0 + " min"}
          maxValue={props.maxValue}
          minValue={0}
          value={props.sliderValue || 0}
          onChange={value => props.filterStoppages(value)}
        />
      )}
    </div>
  </div>
);

export default StoppageFilter;
