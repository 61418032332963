import React from 'react'
import { Marker, InfoWindow } from 'react-google-maps'
import moment from 'moment'
const momentDurationFormatSetup = require('moment-duration-format')
import InfoWindowBox from '../../components/Maps/InfoWindowBox'
import { customCircleMarker } from '../../../../utils'

const COLOR_CODES = {
  '0': '#FFEB3B',
  '1': '#FDD835',
  '2': '#FFC107',
  '3': '#EF6C00',
  '4': '#CDDC39',
  '5': '#7CB342',
  '6': '#388E3C',
  '7': '#FF8A65',
  '8': '#F4511E',
  '9': '#f44336'
}

export default class VehicleMAMarker extends React.Component {
  state = {
    isOpen: this.props.isOpen
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: !this.state.isOpen })
    }
  }

  onToggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { isOpen } = this.state
    const { location, colorRef } = this.props
    const { trip, duration } = location
    const colorIndex = Math.floor(duration / (3600 * colorRef))
    return (
      <Marker
        icon={customCircleMarker({
          fillColor: COLOR_CODES[colorIndex] || '#4FC3F7',
          strokeWeight: 0.2,
          strokeColor: 'black',
          strokeOpacity: 1,
          fillOpacity: 1
        })}
        opacity={1}
        position={{
          lat: Number(location.lat_long.split(',')[0]),
          lng: Number(location.lat_long.split(',')[1])
        }}
        onClick={this.onToggleOpen}
      >
        {isOpen && (
          <InfoWindow onCloseClick={this.onToggleOpen}>
            <InfoWindowBox info={{ consigner_trip: trip }}>
              <div>
                Duration :{' '}
                {moment.duration(duration, 'second').format('HH [hr] mm [min]')}
              </div>
            </InfoWindowBox>
          </InfoWindow>
        )}
      </Marker>
    )
  }
}
