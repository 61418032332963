import format from "date-fns/format";
import addMinutes from "date-fns/add_minutes";
import { divIcon, latLng } from "leaflet";
import React from "react";
import { Marker, Popup } from "react-leaflet";

export default function HistoryMarker({
  location,
  shipment,
  selected,
  colorConfig,
}) {
  function openPopup(marker) {
    if (marker && selected) {
      marker.leafletElement.openPopup();
    }
    if (marker && !selected) {
      marker.leafletElement.closePopup();
    }
  }

  return (
    <Marker
      position={latLng(location.latitude, location.longitude)}
      icon={divIcon({
        className: "shipment-history-marker-container",
        html: `<div class="history-dot" style="background: ${
          colorConfig.path
        }"></div>`,
      })}
      ref={(marker) => openPopup(marker)}
    >
      <Popup>
        <h5>Vehicle</h5>
        <hr />
        <p>
          <b>Vehicle No:</b> {location.vehicle_no} <br />
          <b>Landmark :</b> {location.landmark}
          <br />
          <b>Vehicle State :</b> {location.state}
          <br />
          <b>Time :</b> {format(addMinutes(location.ist_timestamp, new Date().getTimezoneOffset()), "DD-MM-YY HH:mm A")}
          <br />
        </p>
        <h5>Journey</h5>
        <hr />
        <p>
          <b>Invoice :</b> {shipment.journey.invoice_no}
          <br />
          <b>LR No :</b> {shipment.journey.lr_no}
          <br />
          <b>Status :</b> {shipment.journey.is_active ? "Active" : "In-active"}
          <br />
          <b>Start Date :</b>{" "}
          {format(shipment.journey.start_date, "DD-MM-YY HH:mm A")}
          <br />
          {shipment.journey.end_date && (
            <p>
              <b>End Date :</b>
              {format(shipment.journey.end_date, "DD-MM-YY HH:mm A")}
            </p>
          )}
        </p>
      </Popup>
    </Marker>
  );
}
