import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import DeviationsReportPanel from "./DeviationsReportPanel";
import InfoPanel from "./InfoPanel/InfoPanel";
import ShowChildNodes from "./InfoPanel/ShowChildNodes";
import OverspeedPanel from "./OverspeedPanel/OverspeedPanel";
import StoppagesPanel from "./StoppagesPanel/StoppagesPanel";
import TripHistoryPanel from "./TripHistoryPanel/TripHistoryPanel";
import EPodPanel from "./EpodPanel";

const TripHistorySidebar = (props) => (
  <Tabs>
    <TabList>
      <Tab>Info</Tab>
      {props.tripInfo &&
        props.tripInfo.child_nodes &&
        props.tripInfo.child_nodes.length > 0 && <Tab>Nodes</Tab>}
      {props.stoppages && <Tab>Stoppages</Tab>}
      {props.overspeedInstances && <Tab>Overspeeding</Tab>}
      {props.history && props.history.length > 0 && <Tab>History</Tab>}
      {!!props.deviationsReport && <Tab>Deviations</Tab>}
      <Tab>E-POD</Tab>
    </TabList>
    <TabPanel>
      <InfoPanel info={props} />
    </TabPanel>
    {props.tripInfo &&
      props.tripInfo.child_nodes &&
      props.tripInfo.child_nodes.length > 0 && (
        <TabPanel>
          <ShowChildNodes childNodes={props.tripInfo.child_nodes} />
        </TabPanel>
      )}
    {props.stoppages && (
      <TabPanel>
        <StoppagesPanel info={props} />
      </TabPanel>
    )}
    {props.overspeedInstances && (
      <TabPanel>
        <OverspeedPanel info={props} />
      </TabPanel>
    )}
    <TabPanel>
      <TripHistoryPanel info={props} />
    </TabPanel>
    {props.deviationsReport && (
      <TabPanel>
        <DeviationsReportPanel info={props} />
      </TabPanel>
    )}
    <TabPanel>
      <EPodPanel info={props} />
    </TabPanel>
  </Tabs>
);

export default TripHistorySidebar;
