import React from "react";
import styled from "styled-components";

export const Grid = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  ${props => props.containerStyle};
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  ${props => props.containerStyle};
`;

export const Col = styled.div`
  flex: ${props => props.size || 1};
  ${props => props.containerStyle};
`;

export const MapBtn = styled.button`
  position: ${props => props.position || "absolute"};
  z-index: 10;
  right: ${props => props.right ? "" : "50px"};
  padding: 2px 5px;
  border-radius: 4px;
  min-width: 90px;
  border: ${props => props.border || "2px solid rgb(255, 255, 255)"};
  background: ${props => props.backGroundColor || "#fff"};
  color: ${props => props.color || "#373737"};
  top: ${props => props.top || "50px"};
  margin-top: ${props => props.marginTop };
  ${props => props.containerStyle};
`;
