import React from 'react'
import ReactTable from 'react-table'
import moment from 'moment'
require('moment-duration-format')

const COLUMNS = {
  vehicle_no: {
    Header: 'Vehicle No',
    id: 'vehicle_no',
    accessor: 'vehicle_no'
  },
  stopped: {
    Header: 'Stoppage',
    id: 'stoppage',
    accessor: d => {
      return moment.duration(d.stopped, 'seconds').format('h [hrs], m [min]')
    }
  },
  distance_travelled: {
    Header: 'Distance',
    id: 'distance',
    accessor: d =>
      d.distance_travelled > 0
        ? d.distance_travelled.toFixed(2) + ' Km'
        : '0 km'
  }
}

const PerformanceChartTable = ({ data, hasTimeUnits }) => {
  const columns = Object.keys(data[0])
  let tableColumns = []
  columns.forEach(column => {
    if (COLUMNS[column]) tableColumns.push(COLUMNS[column])
  })
  return (
    <div>
      <ReactTable
        defaultPageSize={7}
        className="-striped -highlight"
        filterable
        data={data}
        columns={tableColumns}
      />
    </div>
  )
}

export default PerformanceChartTable
