import React from "react";
import Select from "react-select";
import _ from "lodash";
import moment from "moment";
import titleize from "titleize";
import queryString from "query-string";
import Pagination from "rc-pagination";
import DateRangePicker from "react-bootstrap-daterangepicker";

export default class AlertIncidents extends React.Component {
  state = {
    populating: false,
    incidents: this.props.incidents.events,
    searchTerm: "",
    alertTypes: null,
    users: null,
    selectedOption: {
      alertType: "",
      user: "",
    },
    currentPage: this.props.incidents.current_page,
    perPage: this.props.incidents.per_page,
    totalPages: Math.floor(
      this.props.incidents.incidents_count / this.props.incidents.per_page + 1
    ),
    start_date: null,
    end_date: null,
    startDate: moment(),
    endDate: moment(),
    today: moment().format("YYYY-MM-DD"),
    ranges: {
      "Active Trips": [null, null],
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment()
          .subtract(1, "month")
          .startOf("month"),
        moment()
          .subtract(1, "month")
          .endOf("month"),
      ],
    },
  };

  componentDidMount() {
    this._populate(this.props.incidents);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.incidents.current_page !== state.current_page) {
      return { current_page: props.incidents.current_page };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.current_page !== this.state.current_page) {
      // TODO: check if this works
      this._populate(this.props.incidents);
    }
  }

  _dateRangeHandler = (event, picker) => {
    this.setState({
      start_date: picker.startDate.isValid()
        ? picker.startDate.format("YYYY-MM-DD")
        : null,
      end_date: picker.endDate.isValid()
        ? picker.endDate.format("YYYY-MM-DD")
        : null,
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
  };

  _fetchData = (q) => {
    this.setState({ populating: true });
    fetch(`/alert_incidents.json?${q}`, {
      method: "GET",
      headers: ReactOnRails.authenticityHeaders({
        "Content-Type": "application/json",
      }),
      mode: "cors",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        this._populate(response.data);
      })
      .catch((err) => console.log(err));
  };

  _populate = (data) => {
    const { types, events, incidents_count, per_page, current_page } = data;
    const alertTypes = types.map((type) => ({
      label: type.split("Alert")[0] + " Alert",
      value: type,
    }));

    const users =
      events && events.length
        ? _.uniqBy(
            events
              .map((incident) => incident.alert_user)
              .map((user) => ({
                label: user.full_name,
                value: user.id,
              })),
            "value"
          )
        : this.state.users;
    this.setState({
      users,
      alertTypes,
      currentPage: Number(current_page),
      perPage: Number(per_page),
      incidents: events.filter((event) =>
        this.state.selectedOption.user
          ? event.alert_user.id === this.state.selectedOption.user.value
          : event.alert_user.id === users[0].value
      ),
      selectedOption: { ...this.state.selectedOption, user: users[0] },
      totalPages: Math.floor(incidents_count / Number(per_page) + 1),
      populating: false,
    });
  };

  handleChange = (type, selectedOption) => {
    selectedOption = { ...this.state.selectedOption, [type]: selectedOption };
    this.setState({ selectedOption });
  };

  _searchIncidents = (event) => {
    const searchTerm = event.target.value;
    this._filterIncidents(searchTerm);
  };

  _filterIncidents = (searchTerm) => {
    const incidents = this._filterMessage(searchTerm);
    this.setState({ searchTerm, incidents });
  };

  _filterMessage = (searchTerm) => {
    return this.props.incidents.events.filter((incident) =>
      incident.data.message.toUpperCase().includes(searchTerm.toUpperCase())
    );
  };

  _filterBtnClicked = () => {
    const { selectedOption, searchTerm } = this.state;
    const { alertType, user } = selectedOption;
    let { start_date, end_date } = this.state;
    const q = queryString.stringify(
      {
        alert_user_id: user ? user.value : null,
        type: alertType ? alertType.value : null,
        start_date,
        end_date,
      },
      { arrayFormat: "bracket" }
    );
    this._fetchData(q);
  };

  _changeAlertDate = (event, target) => {
    this.setState({
      alertDate: { ...this.state.alertDate, [target]: event.target.value },
    });
  };

  _paginationChange = (currentPage, pageSize) => {
    let { start_date, end_date } = this.state;
    const q = queryString.stringify(
      {
        alert_user_id: this.state.selectedOption.user.value,
        type: this.state.selectedOption.alertType.value,
        start_date,
        end_date,
        page: currentPage,
      },
      { arrayFormat: "bracket" }
    );
    this._fetchData(q);
  };

  render() {
    const {
      selectedOption,
      users,
      alertTypes,
      searchTerm,
      populating,
    } = this.state;
    const { alertType, user } = selectedOption;
    const alertVal = alertType && alertType.value;
    const userVal = user && user.value;
    const start = this.state.startDate.format("YYYY-MM-DD");
    const end = this.state.endDate.format("YYYY-MM-DD");
    const label = start + " - " + end;
    return (
      <div
        className="col-md-12"
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
          background: "#f9f9f9",
        }}
      >
        <h3>Alert Incidents</h3>
        <hr />
        <div className="row d-flex" style={{ marginBottom: "0.5em" }}>
          <div className="col p-0" style={{ padding: 0 }}>
            <input
              className="form-control"
              type="text"
              value={searchTerm}
              onChange={this._searchIncidents}
              placeholder="Search to filter alert incidents."
            />
          </div>
        </div>
        <div className="row d-flex" style={{ marginBottom: "0.5em" }}>
          <div className="col p-0" style={{ padding: 0 }}>
            <Select
              name="form-field-name"
              value={userVal}
              onChange={(opt) => this.handleChange("user", opt)}
              options={users}
              placeholder="Filter user"
            />
          </div>
          <div className="col p-0" style={{ padding: 0 }}>
            <Select
              name="form-field-name"
              value={alertVal}
              onChange={(opt) => this.handleChange("alertType", opt)}
              options={alertTypes}
              placeholder="Filter alert type"
            />
          </div>
          <div className="col">
            <DateRangePicker
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              ranges={this.state.ranges}
              maxDate={moment()}
              onEvent={this._dateRangeHandler}
            >
              <button
                className="selected-date-range-btn"
                style={{ width: "100%", height: "37px" }}
              >
                <div className="pull-left">
                  <i className="fa fa-calendar" />
                </div>
                <div className="pull-right">
                  <span>{label}</span>
                  <span className="caret" />
                </div>
              </button>
            </DateRangePicker>
          </div>
          <div className="col" style={{ textAlign: "right" }}>
            <button
              type="button"
              className="btn btn-warning"
              onClick={this._filterBtnClicked}
            >
              Search
            </button>
          </div>
        </div>
        {this.state.populating ? (
          <div
            style={{
              flex: 1,
              height: "70vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Loading data ...
          </div>
        ) : (
          <div className="row mt-2" style={{ marginTop: 12 }}>
            {this.state.incidents &&
              this.state.incidents.map((incident) => (
                <div
                  className="card mb-2"
                  style={{ marginBottom: 12 }}
                  key={incident.id}
                >
                  <div className="card-block">
                    <h4 className="card-title">
                      <div className="row d-flex">
                        <div
                          className="col pr-0"
                          style={{ paddingLeft: "0.6em" }}
                        >
                          <small>{incident.data.subject}</small>
                        </div>
                        <div className="col text-center">
                          <small>
                            Sent to: {titleize(incident.alert_user.full_name)}
                          </small>
                        </div>
                        <div className="col text-right">
                          <small>
                            {moment
                              .utc(incident.generated_at)
                              .format("DD-MM-YY hh:mm A")}
                          </small>
                        </div>
                      </div>
                    </h4>
                    <p className="card-text">{incident.data.message}</p>
                  </div>
                </div>
              ))}
          </div>
        )}
        <div
          className="d-flex"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <Pagination
            className="ant-pagination"
            defaultCurrent={this.state.currentPage}
            total={this.state.totalPages}
            onChange={this._paginationChange}
          />
        </div>
      </div>
    );
  }
}
