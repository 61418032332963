import React from 'react'

const imageStyle = {
  width: '100%',
  maxWidth: '100% !important'
}

const GetEpodImages = props => {
  const data = props.data.processData
  if (!data || data.current_status !== 'success') return ''
  const { image_1, image_2, signature } = data
  return (
    <div className="row">
      <div className="col-md-12">
        <strong>E-POD Details</strong>
      </div>
      <div className="col-md-4">
        <img src={image_1} alt="Image 1" height="200" style={imageStyle} />
      </div>
      <div className="col-md-4">
        <img src={image_2} alt="Image 2" height="200" style={imageStyle} />
      </div>
      <div className="col-md-4">
        <img src={signature} alt="Signature" height="200" style={imageStyle} />
      </div>
    </div>
  )
}

export default GetEpodImages
