import React from "react";
import ReactTable from "react-table";
import moment from "moment";
require("moment-duration-format");
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { ranges } from "./../../../../utils";

export default class PoiVivoChartTable extends React.Component {
	state = {
		trips: this.props.data,
		vivo: this.props.avgVivo,
		startDate: this.props.date
	}

	componentWillReceiveProps (nextProps) {
		this.setState({
			trips: nextProps.data,
                	vivo: nextProps.avgVivo,
                	startDate: nextProps.date
		})
	}

	render() {
		const columns = [{
			Header: 'Vehicle No',
			accessor: 'vehicle_no'
		},{
			Header: 'POI Enter Time',
			id: 'enter_time',
			accessor: d => moment.utc(new Date(d.enter_time)).format('YYYY-MM-DD hh:mm A'),
			     filterMethod: (filter, row) => {
                                if(!filter.value.startDate){
                                        return true;
                                }
                                if(row[filter.id]) {
                                        let selectedDate = moment(row[filter.id], "YYYY-MM-DD hh:mm A")
                                        let startDate = filter.value.startDate
                                        let endDate = filter.value.endDate
                                        return (selectedDate >= startDate && selectedDate <= endDate)
                                  }
                                return false;
                                },
                                Filter: ({ filter, onChange }) =>

                                {
                                let klass="hidden fa fa-filter fa-lg"
                                if(filter && filter.value.startDate){
                                klass="fa fa-times-circle fa-lg"
                                }

                                return (

                                <div className="row">
                                <div className="col-md-6">
                                <DateRangePicker ranges={ranges} startDate={filter ? filter.value.startDate : moment()} endDate={filter ? filter.value.endDate : moment()} onEvent={(event,picker) => onChange(picker)} >
                                <div>
                                <i className="fa fa-filter fa-lg" style={{marginTop: "0.5em", color: "#E55B25"}}></i>
                                </div>
                                </DateRangePicker>
                                </div>
                                <div className="col-md-6 text-center">
                                <i className={klass} style={{marginTop: "0.5em", color: "#bebdbd"}} onClick={event => onChange(event)}></i>
                                </div>
                                </div>
                               )}

		},{
                        Header: 'POI Exit Time',
			id: 'exit_time',
                        accessor: d => moment.utc(new Date(d.exit_time)).format('YYYY-MM-DD hh:mm A'),
			     filterMethod: (filter, row) => {
                                if(!filter.value.startDate){
                                        return true;
                                }
                                if(row[filter.id]) {
                                        let selectedDate = moment(row[filter.id], "YYYY-MM-DD hh:mm A")
                                        let startDate = filter.value.startDate
                                        let endDate = filter.value.endDate
                                        return (selectedDate >= startDate && selectedDate <= endDate)
                                  }
                                return false;
                                },
                                Filter: ({ filter, onChange }) =>

                                {
                                let klass="hidden fa fa-filter fa-lg"
                                if(filter && filter.value.startDate){
                                klass="fa fa-times-circle fa-lg"
                                }

                                return (

                                <div className="row">
                                <div className="col-md-6">
                                <DateRangePicker ranges={ranges} startDate={filter ? filter.value.startDate : moment()} endDate={filter ? filter.value.endDate : moment()} onEvent={(event,picker) => onChange(picker)} >
                                <div>
                                <i className="fa fa-filter fa-lg" style={{marginTop: "0.5em", color: "#E55B25"}}></i>
                                </div>
                                </DateRangePicker>
                                </div>
                                <div className="col-md-6 text-center">
                                <i className={klass} style={{marginTop: "0.5em", color: "#bebdbd"}} onClick={event => onChange(event)}></i>
                                </div>
                                </div>
                               )}
		},{
			Header: 'Vivo',
			id: 'vivo',
			accessor: d => moment.duration((new Date(d.exit_time).getTime() - new Date(d.enter_time).getTime())/1000, "seconds").format("hh [hrs] mm [mins]")
 
		}]
		return(
			<div>
				<hr />
					{this.state.trips && <ReactTable
                                		defaultPageSize={15}
                                		data={this.state.trips}
                                		columns={columns}
						filterable
						defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                        		/>}
			</div>
		)
	}
}
