import React from "react";
import _ from "lodash";
import OverspeedFilter from "../OverspeedPanel/OverspeedFilter";
import StoppageFilter from "../StoppageFilter";
import HistorySliderFilter from "../TripHistoryPanel/HistorySliderFilter";
import DistancePerDayChart from "./DistancePerDayChart";
import TripInfo from "./TripInfo";

class IdealRoutes extends React.Component {
  state = {
    idealRoutes: this.props.deviationData
      ? this.props.deviationData.map(data => data.history)
      : null,
    selectedPaths: []
  };

  _change = event => {
    const selectedPaths = _.xor(this.state.selectedPaths, [event.target.value]);
    this.setState({ selectedPaths });
    this.props.onSelect(selectedPaths);
  };
  render() {
    return (
      <div className="checkbox">
        {this.state.idealRoutes &&
          this.state.idealRoutes.map((route, index) => (
            <label className="c-input c-checkbox" key={index}>
              <input
                type="checkbox"
                value={index}
                checked={this.state.selectedPaths.includes(String(index))}
                onChange={this._change}
              />
              <span className="c-indicator" />P {index + 1}
            </label>
          ))}
      </div>
    );
  }
}

const InfoPanel = ({ info }) => {
  return (
    <div>
      {info.tripInfo && <TripInfo trip={info.tripInfo} />}
      {info.dailyTripInfo && info.dailyTripInfo.length > 0 && (
        <DistancePerDayChart data={info.dailyTripInfo} />
      )}
      <br />
      <hr />
      {/*info.deviationData && (<div>
      <h6>Ideal Paths</h6>
        <IdealRoutes {...info} onSelect={info.filterIdealPaths} />
      <br /></div>)*/}
      <h6>Filter Landmark</h6>
      <HistorySliderFilter
        minValue={info.historySlider.min}
        maxValue={info.historySlider.max}
        selectedValue={info.newHistorySlider}
        filterHistorySlider={info.filterHistorySlider}
      />
      <br />
      <hr />
      {info.stoppageSliderMaxValue > 0 && (
        <StoppageFilter
          maxValue={info.stoppageSliderMaxValue}
          sliderValue={info.stoppageSliderCurrentValue}
          filterStoppages={info.filterStoppages}
        />
      )}
      {info.overspeedInstances && (
        <div>
          <br />
          <hr />
          <h6>Filter Overspeeding Instances</h6>
          <OverspeedFilter
            minValue={info.minOverspeedSlider}
            maxValue={info.maxOverspeedSlider}
            selectedValue={info.overspeedSliderCurrentValue}
            filterOverspeedInstances={info.filterOverspeedInstances}
          />
        </div>
      )}
    </div>
  );
};
export default InfoPanel;
