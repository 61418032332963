import React, { useEffect, useState } from "react";
import { SkyLightStateless } from "react-skylight";
import ReactTable from "react-table";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { statusColor as colors } from "./../../../../utils";

const BarCharts = ({ data, groupedVehicle, dataKey, optionSelected }) => {
  const [modalVisible, changeModalValue] = useState(false);
  const [title, changeTitle] = useState("");
  const [componentDataKey, setComponentDataKey] = useState(dataKey);
  const [modalTableData, changeModalData] = useState([]);
  let displayData = [];
  let tableColumns = [
    {
      Header: "Vehicle Number",
      accessor: "truck_number",
      Cell: e => (
        <a
          target="_blank"
          href={`/gps/set_gps/show_trucks_trucknum/${e.value}`}
        >
          {" "}
          {e.value}{" "}
        </a>
      )
    }
    // { Header: "Status", accessor: "status" }
  ];
  Object.keys(data).map(key => {
    if (key !== "Total") {
      displayData.push({ text: key, value: data[key] });
    }
  });
  let _barClicked = data => {
    changeModalValue(true);
    changeTitle(data.text);
    changeModalData(groupedVehicle[dataKey][data.text]);
  };
  let _closeModal = () => {
    changeModalValue(false);
  };

  useEffect(() => {
    if (optionSelected) {
      const [routeName, status] = optionSelected.replace("]", "").split("[");
      changeModalValue(true);
      changeTitle(status);
      setComponentDataKey(routeName);
      changeModalData(groupedVehicle[routeName][status]);
    }
  }, [optionSelected]);

  return (
    <React.Fragment>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={displayData}
          margin={{
            top: 10,
            right: 10,
            left: 10,
            bottom: 20
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="text"
            interval={0}
            tick={{ fontSize: "10px" }}
            dy={13}
          />
          <YAxis domain={["auto", "dataMax + 3"]} />
          <Tooltip />
          <Bar
            dataKey="value"
            fill="#8884d8"
            label={{ position: "top" }}
            onClick={_barClicked}
          >
            {displayData.map((val, index) => (
              <Cell key={`cell-${index}`} fill={colors[val.text]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <SkyLightStateless
        dialogStyles={{
          top: "40%",
          left: "50%",
          transform: "translate('-50%','-50%')"
        }}
        hideOnOverlayClicked
        isVisible={modalVisible}
        onCloseClicked={_closeModal}
        title={
          <div className="row skylight-dipper">
            <h4 className="text-center">{`${componentDataKey} (${title})`}</h4>
          </div>
        }
      >
        <div style={{ maxHeight: "500px", overflowY: "auto" }}>
          {modalTableData && (
            <ReactTable
              defaultPageSize={7}
              className="-striped -highlight"
              filterable
              data={modalTableData}
              columns={tableColumns}
            />
          )}
        </div>
      </SkyLightStateless>
    </React.Fragment>
  );
};

export default BarCharts;
