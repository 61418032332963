import React from "react";
import Select from "react-select";
import Geocomplete from "../Geocomplete";

export default function NearbyForm({
  filterOptions,
  vehicles,
  pois,
  location,
  fetchData,
}) {
  const [selected, setSelected] = React.useState({
    label: "",
    value: "",
  });
  const [radius, setRadius] = React.useState(0);
  const [type, setType] = React.useState({ label: "km", value: "km" });
  const [form, setForm] = React.useState({
    vehicle_no: null,
    radius,
    poi_id: null,
    lat: null,
    lng: null,
  });

  function onPlaceSelect({ coords, location }) {
    setForm({ ...form, ...coords, poi_id: null, vehicle_no: null });
  }

  React.useEffect(() => {
    setForm({
      ...form,
      radius: type.value === "m" ? Number(radius) / 1000 : Number(radius),
    });
  }, [radius, type]);

  const vehicleOptions = vehicles
    ? vehicles.map((vehicle) => ({
        label: vehicle,
        value: vehicle,
      }))
    : null;
  const poiOptions = pois.map((poi) => ({
    label: poi.poi_nick_name,
    value: poi.id,
  }));

  const recordsToShow = 30;
  let flag = 0;

  return (
    <div
      style={{ display: "flex", flexDirection: "column", padding: "0 0.25em" }}
    >
      <div>
        <label>Select filter type</label>
        <Select
          placeholder={"Select filter type"}
          options={filterOptions}
          value={selected}
          onChange={(selectedOption) => {
            setSelected(selectedOption);
          }}
          style={{ width: "100%" }}
        />
      </div>
      <div style={{ display: "flex", marginTop: "1em" }}>
        {selected && (
          <div style={{ flex: 1 }}>
            {selected.value === "vehicle" && (
              <Select
                placeholder={"Select Vehicle"}
                options={vehicleOptions}
                filterOption={({ label }, query) =>
                  label &&
                  label.toLowerCase().indexOf(query.toLowerCase()) >= 0 &&
                  flag++ < recordsToShow
                }
                //reset flag after each input
                onInputChange={() => {
                  flag = 0;
                }}
                value={
                  form.vehicle_no
                    ? { label: form.vehicle_no, value: form.vehicle_no }
                    : null
                }
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setForm({
                      ...form,
                      vehicle_no: selectedOption.value,
                      poi_id: null,
                      lat: null,
                      lng: null,
                    });
                  }
                }}
              />
            )}
            {selected.value === "location" && (
              <Geocomplete onSelect={onPlaceSelect} />
            )}
            {selected.value === "poi" && (
              <Select
                placeholder={"Select POI"}
                options={poiOptions}
                value={
                  form.poi_id && poiOptions
                    ? poiOptions.find((poi) => poi.value === form.poi_id)
                    : null
                }
                onChange={({ value }) => {
                  setForm({
                    ...form,
                    poi_id: value,
                    vehicle_no: null,
                    lat: null,
                    lng: null,
                  });
                }}
              />
            )}
          </div>
        )}
      </div>
      <div style={{ display: "flex", marginTop: "1em" }}>
        <div style={{ flex: 1 }}>
          <input
            type="number"
            min={0}
            value={radius}
            onChange={(e) => setRadius(Number(e.target.value))}
            style={{ height: "2.25em" }}
          />
        </div>
        <div style={{ flex: 1, marginLeft: 5, marginRight: 5 }}>
          <Select
            options={[{ label: "km", value: "km" }, { label: "m", value: "m" }]}
            value={type}
            onChange={(selectedOptions) => {
              if (selectedOptions) {
                setType(selectedOptions);
              } else {
                setType({ label: "km", value: "km" });
              }
            }}
          />
        </div>
        {selected && (
          <div style={{ flex: 1 }}>
            <button
              style={{ height: "2.25em", width: "100%" }}
              onClick={() => fetchData(form)}
              disabled={!selected.value}
            >
              Search
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
