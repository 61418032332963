import L from "leaflet";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { Circle, Marker, Popup } from "react-leaflet";
import { iconByState } from "../../../../utils";
import InfoWindowBox from "../../components/Maps/InfoWindowBox";
import MapContainer from "../../components/Maps/MapContainer";

export default function NearbyMap({ data, selectedVehicle, center }) {
  const [currentLocation, setCurrentLocation] = React.useState({
    lat: center.lat,
    lng: center.lng,
  });

  React.useEffect(() => {
    setCurrentLocation({
      lat: center.lat,
      lng: center.lng,
    });
  }, [center.lat, center.lng]);

  const mapRef = React.createRef();

  React.useEffect(() => {
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(({ coords }) => {
        setCurrentLocation({ lat: coords.latitude, lng: coords.longitude });
      });
    }
  }, []);

  let bounds = L.latLngBounds(
    L.latLng(center.lat, center.lng),
    L.latLng(13.0827, 80.2707)
  );
  if (!isEmpty(data)) {
    bounds = Object.keys(data).map((vehicle: string) =>
      L.latLng(
        data[vehicle].location.latitude,
        data[vehicle].location.longitude
      )
    );
  }

  return (
    <MapContainer
      center={currentLocation}
      zoom="8"
      ref={mapRef}
      bounds={bounds}
    >
      {center.radius && (
        <>
          <Marker position={L.latLng(center.lat, center.lng)}>
            <Popup>
              Coords: {center.lat}, {center.lng}
            </Popup>
          </Marker>
          <Circle
            center={L.latLng(center.lat, center.lng)}
            radius={center.radius * 1000}
          />
        </>
      )}
      {!isEmpty(data) &&
        Object.keys(data).map((vehicle: string) => {
          const position = L.latLng(
            data[vehicle].location.latitude,
            data[vehicle].location.longitude
          );
          return (
            <NearbyMapMarker
              selected={selectedVehicle === vehicle}
              position={position}
              info={data[vehicle]}
              key={`nearestVehicleMarker_${vehicle}`}
            />
          );
        })}
    </MapContainer>
  );
}

function NearbyMapMarker({ position, info, selected }) {
  function openPopup(marker: any) {
    if (marker && selected) {
      marker.leafletElement.openPopup();
    }
    if (marker && !selected) {
      marker.leafletElement.closePopup();
    }
  }

  return (
    <Marker
      position={position}
      icon={
        new L.Icon({
          iconUrl: iconByState.none,
          className: "in-plant-vehicle-marker",
        })
      }
      ref={(el) => openPopup(el)}
    >
      <Popup>
        <div style={{ overflow: "scroll" }}>
          <InfoWindowBox info={info} />
        </div>
      </Popup>
    </Marker>
  );
}
