import React from "react";
import { SkyLightStateless } from "react-skylight";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip,
  Label,
  XAxis,
  YAxis
} from "recharts";
import PerformanceChartTable from "./PerformanceChartTable";
import { bigModal } from "../../../../utils";

const KEY_NAME = {
  stopTimePerformance: {
    d: {
      key1: "0-1 hrs",
      key2: "1-4 hrs",
      key3: "4-14 hrs",
      key4: "14+ hrs"
    },
    w: {
      key1: "0-24 hrs",
      key2: "24-72 hrs",
      key3: "3-6 days",
      key4: "6+ days"
    },
    m: {
      key1: "0-6 days",
      key2: "6-15 days",
      key3: "15-25 days",
      key4: "25+ days"
    }
  },
  vehiclePerformance: {
    d: {
      key1: "0-10 km",
      key2: "10-100 km",
      key3: "100-250 km",
      key4: "250+ km"
    },
    w: {
      key1: "0-100 km",
      key2: "100-700 km",
      key3: "700-1350 km",
      key4: "1350+ km"
    },
    m: {
      key1: "0-1000 km",
      key2: "1000-3500 km",
      key3: "3500-6000 km",
      key4: "6000+ km"
    }
  }
};

export default class PerformanceChart extends React.Component {
  state = {
    selectedOption: "d",
    options: [
      { value: "d", name: "Yesterday" },
      { value: "w", name: "Last Week" },
      { value: "m", name: "Last Month" }
    ],
    graphData: null,
    loading: false,
    rawData: null,
    modalVisible: false,
    modalTitle: "",
    modalTableData: null
  };

  componentDidMount() {
    this.fetchTableData("d");
  }

  fetchTableData = async (duration = "d") => {
    try {
      this.setState({ loading: true });
      const response = await (await fetch(
        `${this.props.url}_data.json?duration=${duration}`,
        {
          headers: ReactOnRails.authenticityHeaders({
            "Content-Type": "application/json"
          }),
          credentials: "same-origin"
        }
      )).json();
      if (response) {
        this.populateData(response);
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  selectDuration = event => {
    event.preventDefault();
    const selectedOption = event.target.value;
    this.setState(
      state => ({ ...state, selectedOption }),
      () => this.fetchTableData(selectedOption)
    );
  };

  populateData = data => {
    const graphData = Object.keys(data).map(item => ({
      name: KEY_NAME[this.props.chartName][this.state.selectedOption][item],
      value: data[item].length
    }));
    this.setState({ graphData, rawData: data, loading: false });
  };

  _barClicked = (data, activeIndex) => {
    const modalTableData = this.state.rawData[`key${activeIndex + 1}`];
    this.setState({
      modalVisible: true,
      modalTitle: data.name,
      modalTableData
    });
  };

  _closeModal = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    const {
      options,
      graphData,
      selectedOption,
      loading,
      modalVisible,
      modalTitle,
      modalTableData
    } = this.state;

    return (
      <div>
        <h5>
          <div className="row">
            <div className="col-md-6">
              <p className="text-muted">{this.props.title}</p>
            </div>
            <div className="col-md-6">
              <select
                className="form-control pull-right"
                name="duration"
                value={selectedOption}
                onChange={this.selectDuration}
              >
                {options &&
                  options.map(item => (
                    <option key={item.name} value={item.value}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </h5>
        {!loading ? (
          <BarChart
            width={600}
            height={400}
            data={graphData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name">
              <Label
                value={
                  this.props.chartName === "stopTimePerformance"
                    ? "Time"
                    : "Distance"
                }
                offset={0}
                position="insideBottom"
              />
            </XAxis>
            <YAxis
              label={{
                value: "Number of Vehicles",
                angle: -90,
                position: "insideLeft"
              }}
            />
            <Tooltip />
            <Bar dataKey="value" fill="#8884d8" onClick={this._barClicked} />
          </BarChart>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <img
              src="https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif"
              alt="Loading..."
            />
          </div>
        )}
        <SkyLightStateless
          dialogStyles={bigModal}
          hideOnOverlayClicked
          isVisible={modalVisible}
          onCloseClicked={this._closeModal}
          title={
            <div className="row skylight-dipper">
              <h4 className="text-center">
                {this.props.chartName === "stopTimePerformance"
                  ? `Vehicle Stop Time: ${modalTitle}`
                  : `Vehicle Km Performance: ${modalTitle}`}
              </h4>
            </div>
          }
        >
          {modalTableData && (
            <PerformanceChartTable
              data={modalTableData}
              hasTimeUnits={this.props.chartName === "stopTimePerformance"}
              userRole={this.props.userRole}
            />
          )}
        </SkyLightStateless>
      </div>
    );
  }
}
