import React from "react";
import { Tooltip } from "react-svg-tooltip";
import { formatDuration, getStatusName, statusColor } from "../../../../utils";

const Segments = ({ segment, svgHeight }) => {
  const {
    runningDuration,
    stoppageDuration,
    totalDuration,
    stoppages,
    idealTimeTaken
  } = segment;
  const diff = 1 - totalDuration / (idealTimeTaken * 3600 * 1000);
  const status = getStatusName(diff);
  let title;
  if (totalDuration) {
    title = `Time Taken : ${formatDuration(totalDuration)}`;
  }
  if (idealTimeTaken) {
    title += `| Ideal : ${formatDuration(idealTimeTaken * 3600 * 1000)}`;
  }
  const segmentRef = React.createRef();
  const textArr = {};
  if (title) {
    textArr["title"] = title;
    textArr["runningDuration"] = `Running Time : ${formatDuration(
      runningDuration
    )}`;
    textArr["stoppageDuration"] = `Stoppage Time : ${formatDuration(
      stoppageDuration
    )}`;
    textArr["stoppages"] = `Stoppages : ${stoppages.length}`;
  }
  const baseTextY = -70;
  if (segment.x <= 0) {
    return null;
  }
  return (
    <React.Fragment>
      <g ref={segmentRef}>
        <rect
          x={segment.x}
          y={svgHeight - 68.5}
          width={segment.width}
          height="5"
          style={{
            fill: statusColor[status],
            strokeWidth: 1,
            stroke: statusColor[status]
          }}
        />
      </g>
      <Tooltip for={segmentRef}>
        <rect
          x={0}
          y={-90}
          width={275}
          height={70}
          rx={5}
          ry={5}
          fill="rgba(0, 0, 0, 0.75)"
        />
        {Object.keys(textArr).length &&
          Object.keys(textArr).map((key, index) => (
            <text
              x={5}
              y={baseTextY + 15 * index}
              fontSize={index !== 0 ? 12 : 13.5}
              fill="white"
              key={`segment_hover_${index}`}
            >
              {textArr[key]}
            </text>
          ))}
      </Tooltip>
    </React.Fragment>
  );
};

export default Segments;
