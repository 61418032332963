import React from "react";
import styled from "@emotion/styled";

const AlertTypeListContainer = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const AlertTypeSelectContainer = styled.div`
  @media screen and (min-width: 769px) {
    display: none;
    width: 100%;
  }
`;

const AlertTypeSelectBox = styled.select`
  width: 100%;
  height: 2.5em;
  box-sizing: border-box;
`;

function AlertSidebar({ alertTypes, selected, changeForm, userRole }) {
  return (
    <>
      <AlertTypeListContainer className="list-group">
        {alertTypes &&
          alertTypes.map((type) => {
            const subscriptionName = type
              .replace(/([A-Z])/g, " $1")
              .replace("Subscription", "")
              .trim();
            return (
              <a
                href={type}
                key={type}
                className={`list-group-item list-group-item-action ${
                  selected && type === Object.keys(selected)[0] ? "active" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  changeForm(type);
                }}
              >
                {subscriptionName}
              </a>
            );
          })}
      </AlertTypeListContainer>
      <AlertTypeSelectContainer>
        <AlertTypeSelectBox
          onChange={(e) => {
            changeForm(e.target.value);
          }}
          placeholder="Select Alert type"
          value={Object.keys(selected)[0]}
        >
          {alertTypes &&
            alertTypes.map((type) => {
              const subscriptionName = type
                .replace(/([A-Z])/g, " $1")
                .replace("Subscription", "")
                .trim();
              return (
                <option key={type} value={type}>
                  {subscriptionName}
                </option>
              );
            })}
        </AlertTypeSelectBox>
      </AlertTypeSelectContainer>
    </>
  );
}

export default AlertSidebar;
