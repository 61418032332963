import React from 'react';
import moment from 'moment';
const momentDurationFormatSetup = require('moment-duration-format');

const StoppageInfoWindow = props => (
  <div>
    <h5 className="text-muted">Stoppage Instance</h5>
    <hr />
    <div>
      <strong>Landmark</strong>: {props.info.end_landmark}
    </div>
    <div>
      <strong>Time</strong>:{' '}
      {moment.utc(props.info.start_timestamp).format('lll')} to{' '}
      {moment.utc(props.info.end_timestamp).format('lll')}
    </div>
    <div>
      <strong>Duration</strong>:{' '}
      {moment
        .duration(
          moment(props.info.end_timestamp).diff(
            moment(props.info.start_timestamp),
          ),
        )
        .format('HH [hr] mm [min]')}
    </div>
  </div>
);

export default StoppageInfoWindow;
