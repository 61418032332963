import isEmpty from "lodash/isEmpty";
import React from "react";
import { exportTableToCSV } from "../../../../utils";
import { NearbyRangeFilter } from "./NearbyRangeFilter";

export default function NearbyList({
  data,
  onVehicleSelect,
  filterNearby,
  sliderRange,
}) {
  const [selectedVehicle, setSelectedVehicle] = React.useState("");

  function onRadioSelect(checked, vehicle) {
    setSelectedVehicle(checked ? vehicle : "");
  }

  React.useEffect(() => {
    onVehicleSelect(selectedVehicle);
  }, [selectedVehicle]);

  const { maxValue, minValue } = sliderRange;

  return (
    <div>
      {!isEmpty(data) && (
        <React.Fragment>
          <NearbyRangeFilter
            maxValue={maxValue}
            minValue={minValue}
            filterNearby={filterNearby}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "0 1em",
            }}
          >
            <button
              className="btn btn-sm btn-info fa fa-file-excel-o"
              onClick={() =>
                exportTableToCSV(
                  "nearByVehiclesList",
                  `NearbyVehicles_${new Date().getDate()}_${new Date().getMonth()}_${new Date().getFullYear()}.csv`
                )
              }
              style={{ margin: "1em 0" }}
            >
              &nbsp;Download ({Object.keys(data).length})
            </button>
          </div>
          <div style={{ height: "35vh", overflowY: "auto" }}>
            <table
              className="table nearByVehiclesList"
              style={{ fontSize: 12 }}
            >
              <thead>
                <tr style={{ fontWeight: "bold" }}>
                  <td>Vehicle No</td>
                  <td>Transporter</td>
                  <td>Vehicle Type</td>
                  <td>Driver</td>
                </tr>
              </thead>
              <tbody>
                {Object.keys(data).map((vehicle: string) => (
                  <tr key={`tr_${vehicle}`}>
                    <td>
                      <label style={{ width: 120 }}>
                        <input
                          type="radio"
                          onChange={(e) =>
                            onRadioSelect(e.target.checked, vehicle)
                          }
                          checked={selectedVehicle === vehicle}
                        />
                        <span style={{ marginLeft: "0.5em" }}>{vehicle}</span>
                      </label>
                    </td>
                    <td>{data[vehicle].transporter}</td>
                    <td>{data[vehicle].vehicle.truck_type}</td>
                    <td>
                      {data[vehicle].driver
                        ? data[vehicle].driver.driver_name
                        : ""}
                      {data[vehicle].driver
                        ? " - " + data[vehicle].driver.phone_no
                        : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
