import { startCase } from "lodash";
import qs from "qs";
import React from "react";
import Select from "react-select";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { IFilter } from ".";
import useDeepCompareEffect from "../../../../customHooks/useDeepCompareEffect";
import { IJourney } from "../../../../types";
import GraphContainer from "./GraphContainer";
import CustomTick from "./CustomTick";

interface NodePerformanceProps {
  filter: IFilter;
  showJourney: (journeys: IJourney[]) => void;
}

interface NodePerformanceResponse {
  id: null | number;
  count: number;
  locationCode: string;
}

export default function NodePerformance({ filter }: NodePerformanceProps) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState<null | NodePerformanceResponse[]>(
    null
  );

  const [visibleCount, setVisibleCount] = React.useState(5);
  const [maxCount, setMaxCount] = React.useState(5);
  const [orderAsc, setOrderAsc] = React.useState({
    label: "DESC",
    value: false
  });
  const [selectedXAxisLabels, setSelectedXAxisLabels] = React.useState(null);

  useDeepCompareEffect(() => {
    fetchData();
  }, [filter, orderAsc]);

  async function fetchData() {
    try {
      setLoading(true);
      if (orderAsc.value) {
        filter = { ...filter, asc: orderAsc.value };
      }
      const q = qs.stringify(filter, {
        encode: false,
        skipNulls: true,
        indices: false
      });
      const url = `${location.href}/node_performance.json?${q}`;
      const response = await (await fetch(url, {
        headers: {
          "Key-Inflection": "camel"
        }
      })).json();
      if (response && response.data) {
        setMaxCount(response.data.length);
        setVisibleCount(Math.min(response.data.length, 5));
        setData(response.data);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }
  return (
    <GraphContainer
      title={`Node Performance ${orderAsc.value ? "Top 10" : "Bottom 10"}`}
      loading={loading}
      error={error}
    >
      <ResponsiveContainer height={400} width="100%">
        <BarChart
          width={400}
          height={400}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="locationCode"
            interval={0}
            tick={({ x, y, payload }) => (
              <CustomTick x={x} y={y} payload={payload} />
            )}
          />
          <YAxis />
          <Tooltip
            content={props => {
              const { active } = props;
              if (active) {
                const { payload, label } = props;
                return (
                  <div style={{ background: "#fff", padding: "1em" }}>
                    <p>{label}</p>
                    <br />
                    <p>
                      {`${orderAsc.value ? "Delayed" : "Early"} by : ${
                        payload[0].value
                      }`}
                    </p>
                  </div>
                );
              }
              return null;
            }}
          />
          <Legend formatter={(value, entry, index) => startCase(value)} />
          <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
      {data && (
        <React.Fragment>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1, marginLeft: "0.125em" }}>
              Showing
              <input
                type="number"
                min="1"
                step="1"
                max={maxCount}
                value={visibleCount}
                onChange={e => setVisibleCount(Number(e.target.value))}
              />{" "}
              of {data.length}
            </div>
            <div style={{ flex: 1, marginRight: "0.125em" }}>
              <Select
                placeholder="Sort VIVO"
                options={[
                  { label: "ASC", value: true },
                  { label: "DESC", value: false }
                ]}
                onChange={selected => setOrderAsc(selected)}
                value={orderAsc}
              />
            </div>
          </div>
          <div style={{ marginTop: "0.25em" }}>
            <Select
              placeholder="Select Nodes"
              options={data.map(item => ({
                label: item.locationCode,
                value: item.locationCode
              }))}
              onChange={selected => setSelectedXAxisLabels(selected)}
              value={selectedXAxisLabels}
              isMulti
            />
          </div>
        </React.Fragment>
      )}
    </GraphContainer>
  );
}
