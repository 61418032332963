import React from 'react';
import {
  GoogleMap,
  Marker,
  Polygon,
  Circle,
  withGoogleMap,
  InfoWindow,
} from 'react-google-maps';
import {SkyLightStateless} from 'react-skylight';
import moment from 'moment';
require('moment-duration-format');
import EditPoi from './EditPoi';

function PoiMapComponent({data, monthlyVivo, vehiclesInside, isMarkerShown}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const mapRef = React.useRef('');

  React.useLayoutEffect(() => {
    if (mapRef && mapRef.current) {
      console.log(mapRef);
      const {poi_bounds} = data;
      if (
        poi_bounds &&
        poi_bounds.geometry &&
        poi_bounds.geometry.coordinates
      ) {
        let bounds = new google.maps.LatLngBounds();
        poi_bounds.geometry.coordinates.forEach(coordArr => {
          bounds.extend(new google.maps.LatLng(coordArr[0], coordArr[1]));
        });
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [data.id]);
  let zoom = 15 - Math.ceil(Math.log(data.radius) / Math.log(2));

  return !data ? null : (
    <GoogleMap
      ref={mapRef}
      zoom={zoom}
      mapTypeId="satellite"
      center={{lat: data.latitude, lng: data.longitude}}>
      {isMarkerShown && (
        <Marker position={{lat: data.latitude, lng: data.longitude}} />
      )}
      {/* <a
        href={`/pois/${data.id}/edit_geofence`}
        style={{
          position: 'absolute',
          top: '10px',
          right: '50px',
          height: '27px',
          padding: '1.5px 10px',
          textAlign: 'center',
          background: 'white',
          borderRadius: '2.5px',
          textDecoration: 'none',
        }}>
        Edit
      </a>
      <SkyLightStateless
        dialogStyles={{width: '60%', closeButtonStyle: {color: '#333'}}}
        isVisible={modalVisible}
        onCloseClicked={() => setModalVisible(false)}
        title="Edit Point of Interests">
        <EditPoi poi={data} types={types} />
      </SkyLightStateless> */}
      {data.poi_bounds &&
      JSON.parse(data.poi_bounds) &&
      JSON.parse(data.poi_bounds).geometry ? (
        <div>
          <Polygon
            paths={JSON.parse(data.poi_bounds).geometry.coordinates[0].map(
              coords => ({
                lat: Number(coords[0]),
                lng: Number(coords[1]),
              }),
            )}
            options={{
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 3,
              fillColor: '#FF0000',
              fillOpacity: 0.35,
            }}
            visible
          />
          <Marker
            position={{lat: data.latitude, lng: data.longitude}}
            onClick={() => setIsOpen(!isOpen)}>
            {isOpen && (
              <InfoWindow onCloseClick={() => setIsOpen(!isOpen)}>
                <div>
                  <h5>{data.poi_nick_name}</h5>
                  <hr />
                  <div>
                    <strong>Type</strong> : {data.poi_type}
                  </div>
                  <br />
                  {monthlyVivo ? (
                    <div>
                      <strong>Monthly Vivo</strong> :{' '}
                      {moment
                        .duration(monthlyVivo, 'seconds')
                        .format('hh [hrs] mm [mins]')}{' '}
                    </div>
                  ) : null}
                  <br />
                  {vehiclesInside ? (
                    <div>
                      <strong>Vehicles inside P.O.I</strong> : {vehiclesInside}{' '}
                    </div>
                  ) : null}
                </div>
              </InfoWindow>
            )}
          </Marker>
        </div>
      ) : (
        <div>
          <Circle
            center={{lat: data.latitude, lng: data.longitude}}
            radius={data.radius * 1000}
            options={{
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#FF0000',
              fillOpacity: 0.35,
            }}
          />
          <Marker
            position={{lat: data.latitude, lng: data.longitude}}
            onClick={() => setIsOpen(!isOpen)}>
            {isOpen && (
              <InfoWindow onCloseClick={() => setIsOpen(!isOpen)}>
                <div>
                  <h5>{data.poi_nick_name}</h5>
                  <hr />
                  <div>
                    <strong>Type</strong> : {data.poi_type}
                  </div>
                  <br />
                  <div>
                    <strong>Radius</strong> : {data.radius * 1000} m (
                    {data.radius} Km)
                  </div>
                  <br />
                  {monthlyVivo && (
                    <div>
                      <strong>Monthly Vivo</strong> :{' '}
                      {moment
                        .duration(monthlyVivo, 'seconds')
                        .format('hh [hrs] mm [mins]')}{' '}
                    </div>
                  )}
                  <br />
                  {vehiclesInside && (
                    <div>
                      <strong>Vehicles inside P.O.I</strong> : {vehiclesInside}{' '}
                    </div>
                  )}
                </div>
              </InfoWindow>
            )}
          </Marker>
        </div>
      )}
    </GoogleMap>
  );
}

class PMC extends React.Component {
  render() {
    return <PoiMapComponent {...this.props} />;
  }
}

const PoiMap = withGoogleMap(
  ({
    data: {
      poi_info: info,
      month_vivo: monthlyVivo,
      vehicles_inside: vehiclesInside,
    },
    types,
    ...rest
  }) => (
    <PMC
      data={info}
      types={types}
      monthlyVivo={monthlyVivo}
      vehiclesInside={vehiclesInside}
      {...rest}
    />
  ),
);

export default PoiMap;
