import React from 'react'
import swal from 'sweetalert'
import RenderForm from './RenderForm'

export default function AddUser({ formConfig, closeModal }) {
  const [form, setForm] = React.useState({
    full_name: '',
    email: '',
    phone_no: '',
    designation: ''
  })

  const [loading, setLoading] = React.useState(false)

  function onChange(type, value) {
    setForm({ ...form, [type]: value })
  }

  async function handleSubmit() {
    try {
      setLoading(true)
      const response = await (await fetch('/create_alert_user.json', {
        method: 'POST',
        headers: ReactOnRails.authenticityHeaders({
          'Content-Type': 'application/json'
        }),
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({ alert_user: form })
      })).json()
      if (response && response.success) {
        swal('Success', 'User created successfully', 'success')
        closeModal()
      } else {
        swal('Error', 'Unable to create user', 'error')
      }
    } catch (error) {
      console.log('catch called')
      swal('Error', 'Unable to create user', 'error')
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      {formConfig &&
        formConfig.map((config, index) => (
          <RenderForm
            {...config}
            key={config.name}
            value={form[config.name]}
            index={index + 1}
            onChange={onChange}
          />
        ))}
      <div style={{ marginTop: '1em' }}>
        <button
          type="submit"
          className="btn btn-success"
          onClick={e => handleSubmit(e)}
          disabled={loading}
        >
          Submit
        </button>
      </div>
    </div>
  )
}
