import React from 'react';
import {compose, withProps, lifecycle} from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
  Polygon,
} from 'react-google-maps';
import {DrawingManager} from 'react-google-maps/lib/components/drawing/DrawingManager';
import get from 'lodash/get';
import swal from 'sweetalert';

const circleOptions = {
  fillColor: '#E55B25',
  fillOpacity: 0.2,
  strokeWeight: 3,
  clickable: false,
  editable: true,
  zIndex: 1,
};
const polygonOptions = {
  clickable: true,
  draggable: true,
  editable: true,
  fillColor: '#E55B25',
  fillOpacity: 0.5,
};

const EditPoi = compose(
  withProps({
    containerElement: <div style={{height: `90%`}} />,
    mapElement: <div style={{height: `100%`}} />,
  }),
  lifecycle({
    componentDidMount() {
      const {poi} = this.props;
      const {
        id: poi_id,
        radius,
        latitude,
        longitude,
        poi_type,
        poi_nick_name,
        poi_bounds,
      } = poi;
      const polygonArr = poi_bounds ? JSON.parse(poi_bounds).geometry.coordinates[0] : ([[Number(latitude),Number(longitude)]]);
      const center = {lat: Number(latitude), lng: Number(longitude)};
      this.setState({
        poi_id,
        radius,
        center,
        poi_nick_name,
        polygonArr,
        poi_type,
      });
    },
    componentWillMount() {
      const refs = {};

      this.setState({
        drawingControl: true,
        drawingModes: [
          google.maps.drawing.OverlayType.CIRCLE,
          google.maps.drawing.OverlayType.POLYGON,
        ],
        bounds: null,
        poi_nick_name: null,
        poi_type: null,
        radius: 0,
        showRadiusInput: false,
        center: {
          lat: 28.62,
          lng: 77.21,
        },
        centerCoords: null,
        markers: [],
        polygonArr: [],
        onMapMounted: ref => {
          refs.map = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          });
        },
        onDrawingManagerMounted: ref => {
          refs.drawingManager = ref;
        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onCircleComplete: circle => {
          if (refs.circle) {
            refs.circle.setMap(null);
          }
          if (refs.polygon) {
            refs.polygon.setMap(null);
            this.setState({polygonArr: []});
          }
          refs.circle = circle;
          let radius = (Number(circle.getRadius()) / 1000).toFixed(2);
          let lat = circle.getCenter().lat();
          let lng = circle.getCenter().lng();
          let centerCoords = {lat, lng};
          this.setState({radius, centerCoords});

          let vm = this;

          google.maps.event.addListener(circle, 'radius_changed', function() {
            vm.setState({radius: (Number(this.getRadius()) / 1000).toFixed(2)});
          });

          google.maps.event.addListener(circle, 'center_changed', function() {
            vm.setState({
              centerCoords: {
                lat: circle.getCenter().lat(),
                lng: circle.getCenter().lng(),
              },
            });
          });
        },
        onOverlayComplete: event => {
          if (event.type === google.maps.drawing.OverlayType.CIRCLE) {
            if (refs.polygon) {
              refs.polygon.setMap(null);
              this.setState({polygonArr: []});
            }
            this.setState({showRadiusInput: true});
          } else {
            if (refs.circle) refs.circle.setMap(null);
            this.setState({showRadiusInput: false, radius: 0});
          }
        },
        updateCircle: event => {
          refs.circle.setRadius(Number(event.target.value) * 1000);
          this.setState({radius: event.target.value});
        },
        clear: () => {
          if (refs.circle) {
            refs.circle.setMap(null);
            this.setState({
              radius: 0,
              showRadiusInput: false,
              centerCoords: null,
            });
          }
          if (refs.polygon) {
            refs.polygon.setMap(null);
            this.setState({polygonArr: []});
          }
        },
        onPolygonComplete: polygon => {
          if (refs.circle) {
            refs.circle.setMap(null);
            this.setState({
              radius: 0,
              showRadiusInput: false,
              centerCoords: null,
            });
          }
          if (refs.polygon) {
            refs.polygon.setMap(null);
          }
          refs.polygon = polygon;
          let polygonArr = [];
          for (var i = 0; i < polygon.getPath().getLength(); i++) {
            polygonArr.push(
              polygon
                .getPath()
                .getAt(i)
                .toUrlValue(6),
            );
          }
          this.setState({polygonArr});
          let vm = this;

          google.maps.event.addListener(
            polygon.getPath(),
            'set_at',
            function() {
              polygonArr.length = 0;
              let len = polygon.getPath().getLength();
              for (let i = 0; i < len; i++) {
                polygonArr.push(
                  polygon
                    .getPath()
                    .getAt(i)
                    .toUrlValue(6),
                );
              }
              vm.setState({polygonArr});
            },
          );

          google.maps.event.addListener(
            polygon.getPath(),
            'insert_at',
            function() {
              polygonArr.length = 0;
              let len = polygon.getPath().getLength();
              for (let i = 0; i < len; i++) {
                polygonArr.push(
                  polygon
                    .getPath()
                    .getAt(i)
                    .toUrlValue(6),
                );
              }
              vm.setState({polygonArr});
            },
          );
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new google.maps.LatLngBounds();

          places.forEach(place => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });

          const nextMarkers = places.map(place => ({
            position: place.geometry.location,
          }));

          const nextCenter = get(nextMarkers, '0.position', this.state.center);

          this.setState({
            center: nextCenter,
            markers: nextMarkers,
          });
          // refs.map.fitBounds(bounds);
        },
        setPoiName: event => {
          this.setState({poi_nick_name: event.target.value});
        },
        setPoiType: event => {
          this.setState({poi_type: event.target.value});
        },
        updatePOI: () => {
          let {
            radius,
            centerCoords,
            polygonArr,
            poi_nick_name,
            poi_type,
          } = this.state;
          let loc = String(polygonArr);
          let {lat, lng} = centerCoords ? centerCoords : {lat: null, lng: null};
          radius = radius * 1000;
          if (!radius && !loc) {
            swal(
              'Oops',
              'You must either draw a POI with a center and radius or a polygon. The tools to draw are at top of map.',
              'error',
            );
          }
          fetch(`/consigner/pois/update_geofence.json`, {
            method: 'POST',
            headers: ReactOnRails.authenticityHeaders({
              'Content-Type': 'application/json',
            }),
            credentials: 'same-origin',
            body: JSON.stringify({
              radius,
              lat,
              lng,
              loc,
              poi_nick_name,
              poi_type,
            }),
          })
            .then(res => res.json())
            .then(data => {
              swal('Done', `POI ${poi_nick_name} has been created`);
              window.location.reload();
            })
            .catch(err => console.log(err));
        },
      });
    },
  }),
  withGoogleMap,
)(props => (
  <GoogleMap
    ref={props.onMapMounted}
    defaultZoom={15}
    center={props.center}
    onBoundsChanged={props.onBoundsChanged}>
    <DrawingManager
      ref={props.onDrawingManagerMounted}
      defaultDrawingMode={null}
      defaultOptions={{
        drawingControl: props.drawingControl,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: props.drawingModes,
        },
        circleOptions,
        polygonOptions,
      }}
      onOverlayComplete={props.onOverlayComplete}
      onCircleComplete={props.onCircleComplete}
      onPolygonComplete={props.onPolygonComplete}
    />
    <div
      style={{
        position: 'absolute',
        top: '25.5%',
        left: '10.5%',
      }}>
      <input
        className="form-control"
        placeholder="Enter POI name"
        style={{
          width: '250px',
          height: '35px',
          display: 'inline-block',
          marginLeft: '-7.5px',
          marginRight: '7.5px',
        }}
        onChange={props.setPoiName}
        value={props.poi_nick_name}
      />
      {props.showRadiusInput && (
        <div style={{display: 'inline-block', width: '100px'}}>
          <label
            htmlFor="radius"
            style={{
              position: 'absolute',
              fontSize: '8px',
              color: 'red',
              padding: '0 2.5px',
            }}>
            Radius ( km )
          </label>
          <input
            type="number"
            min="5"
            step="0.01"
            className="form-control"
            id="radius"
            value={props.radius}
            placeholder="Radius (km)"
            onChange={props.updateCircle}
          />
        </div>
      )}
      <select
        className="form-control"
        style={{
          display: 'inline-block',
          width: '175px',
          height: '35px',
        }}
        onChange={props.setPoiType}
        value={props.poi_type}>
        <option value="">Select POI type</option>
        {props.types &&
          Object.keys(props.types).map(type => (
            <option value={type} key={type}>
              {type}
            </option>
          ))}
      </select>
      <button
        className="btn btn-danger"
        onClick={props.createPOI}
        style={{display: 'inline-block', marginLeft: '10px'}}>
        Update
      </button>
      <button
        className="btn btn-secondary"
        onClick={props.clear}
        style={{marginLeft: '10px'}}>
        Clear
      </button>
    </div>
  </GoogleMap>
));

export default EditPoi;
