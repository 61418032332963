import _ from "lodash";
import React from "react";
import { FeatureGroup, LayersControl, Map, TileLayer } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import FullscreenControl from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/dist/styles.css";
import { GoogleLayer } from "react-leaflet-google";
import SearchLocation from "./SearchLocation";

const { BaseLayer } = LayersControl;
const key = "AIzaSyCK482B8_jLz2rUTdg8bWKAwjo7fo1z5vM";
const terrain = "TERRAIN";
const road = "ROADMAP";
const satellite = "SATELLITE";
const hybrid = "HYBRID";

const mapRef = React.createRef();
class MapDraw extends React.Component {
  state = {
    center: [28, 77],
    zoom: 4,
    metric: null,
    lat: 28.77,
    lng: 77,
    radius: 0,
    visible: false,
    type: null,
    created: false,
    circleCoord: null,
    loc: null,
    poiTypes: {
      "Loading Point": "loading",
      "Other Point of Interest": "poi",
      "Petrol Pump": "iocl",
      "State Border": "border",
      "Unloading Point": "unloading",
      "Calculated Unloading": "calculated_unloading",
      Hub: "hub"
    },
    poiType: "",
    nickname: "",
    mapBounds: undefined
  };

  _onDrawStop = r => {
    //console.log(r);
    r.radius = r.layerType === "circle" ? this.state.radius : null;
  };
  _onEditPath = r => {
    {
      //console.log(r);
      this.state.type === "circle" &&
        this.setState({
          radius: this.state.metric === "m" ? r : r / 1000
        });
    }
  };

  _onCreate = result => {
    if (result.layerType !== "polygon") {
      this.populateCirclePoiData(result);
    } else {
      this.populatePolygonPoiData(result);
    }
  };

  populateCirclePoiData = result => {
    this.setState({
      radius: result.layer._mRadius / 1000,
      first: this.state.first === null ? result.layerType : this.state.first,
      visible: true,
      created: true,
      circleCoord: result.layer._latlng,
      type: result.layerType
    });
  };

  populatePolygonPoiData = result => {
    this.setState({
      radius: result.layer._mRadius / 1000,
      first: this.state.first === null ? result.layerType : this.state.first,
      visible: true,
      created: true,
      loc: String(result.layer._latlngs[0].map(item => Object.values(item))),
      type: result.layerType
    });
  };

  _onDeleted = () => {
    this.setState({
      metric: null,
      radius: 0,
      visible: false,
      type: null,
      created: false
    });
  };

  handleInput = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeRadius = e => {
    this.setState({ radius: e.target.value }, () => {
      Object.values(
        this.refs.poiDrawn.leafletElement.options.edit.featureGroup._layers
      )[0].setRadius(
        this.state.metric === "m" ? this.state.radius : this.state.radius * 1000
      );
    });
  };

  changeMetric = e => {
    this.setState(
      {
        metric: e.target.value
      },
      () => {
        Object.values(
          this.refs.poiDrawn.leafletElement.options.edit.featureGroup._layers
        )[0].setRadius(
          this.state.metric === "m"
            ? this.state.radius
            : this.state.radius * 1000
        );
      }
    );
  };

  searchLocation = loc => {
    const { data } = loc;
    if (data) {
      this.setState({
        center: [Number(data.y), Number(data.x)],
        mapBounds: data.bounds
      });
    }
  };

  createPoi = async () => {
    try {
      const {
        circleCoord,
        poiType: poi_type,
        nickname: poi_nick_name,
        loc,
        type,
        metric
      } = this.state;
      const radius =
        metric === "m" ? this.state.radius : this.state.radius * 1000;
      let lat, lng;
      if (circleCoord) {
        lat = circleCoord.lat;
        lng = circleCoord.lng;
      }
      const body = _.pickBy(
        {
          radius,
          lat,
          lng,
          loc,
          poi_nick_name,
          poi_type
        },
        _.identity
      );
      if (body.poi_nick_name && body.poi_type) {
        if (type !== "circle" && !loc) {
          swal("Wrong Input", "Please draw polygon POI first");
        } else {
          const url = this.props.apiUrl || `/consigner/pois/new_geofence.json`;
          const data = await (await fetch(url, {
            method: "POST",
            headers: ReactOnRails.authenticityHeaders({
              "Content-Type": "application/json"
            }),
            credentials: "same-origin",
            body: JSON.stringify(body)
          })).json();
          if (data) {
            swal("Done", `POI ${poi_nick_name} has been created`);
            // window.location.reload();
          }
        }
      } else {
        swal("Wrong Input", "Please enter nick name and type for POI");
      }
    } catch (error) {
      swal("Sorry", `POI creation failed. Please retry`);
      console.log(error);
    }
  };

  render() {
    const {
      center,
      poiType,
      mapBounds,
      poiTypes,
      nickname,
      radius
    } = this.state;
    return (
      <div
        style={{
          display: "flex",
          position: "relative",
          height: "100%",
          width: "100%"
        }}
      >
        <div style={{ flex: 1, flexDirection: "column" }}>
          <div>
            <label style={{ display: "block" }}>POI nickname :</label>
            <input
              type="text"
              name="nickname"
              onChange={this.handleInput}
              value={nickname}
            />
          </div>
          <div>
            <label style={{ display: "block", marginTop: "1em" }}>
              POI type :
            </label>
            <select onChange={this.handleInput} name="poiType" value={poiType}>
              <option value="">Select POI type</option>
              {poiTypes &&
                Object.keys(poiTypes).map(type => (
                  <option value={type} key={type}>
                    {type}
                  </option>
                ))}
            </select>
          </div>
          {this.state.visible && this.state.type === "circle" && (
            <div>
              <label style={{ display: "block", marginTop: "1em" }}>
                Radius :
              </label>
              <input
                id="radius"
                type="number"
                name="radius"
                value={radius}
                step="10"
                onChange={this.changeRadius}
                style={{ width: "50%" }}
              />
              <select
                onChange={this.changeMetric}
                style={{ width: "30%", marginLeft: "1em" }}
              >
                <option>km</option>
                <option>m</option>
              </select>
            </div>
          )}
          {this.state.created && (
            <React.Fragment>
              <button
                style={{ marginTop: "1em" }}
                className="btn btn-warning"
                onClick={() => this.createPoi()}
              >
                Add
              </button>
            </React.Fragment>
          )}
        </div>
        <div
          style={{
            flex: 3,
            height: "100%",
            width: "100%",
            zIndex: 1
          }}
        >
          <Map
            center={center}
            zoom={this.state.zoom}
            bounds={mapBounds}
            // addControl={LocationSearch}
            ref="mapRef"
          >
            <FullscreenControl position="bottomleft" />
            <LayersControl position="topright">
              <BaseLayer name="OpenStreetMap.Mapnik">
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </BaseLayer>
              <BaseLayer name="Google Maps Roads">
                <GoogleLayer googlekey={key} maptype={road} />
              </BaseLayer>
              <BaseLayer name="Google Maps Terrain">
                <GoogleLayer googlekey={key} maptype={terrain} />
              </BaseLayer>
              <BaseLayer name="Google Maps Satellite">
                <GoogleLayer googlekey={key} maptype={satellite} />
              </BaseLayer>
              <BaseLayer checked name="Google Maps Hybrid">
                <GoogleLayer googlekey={key} maptype={hybrid} />
              </BaseLayer>
            </LayersControl>
            <SearchLocation submit={this.searchLocation} />
            <FeatureGroup>
              <EditControl
                position="topright"
                onEdited={this._onEditPath}
                onCreated={this._onCreate}
                onDeleted={this._onDeleted}
                onDrawStop={this._onDrawStop}
                draw={{
                  rectangle: false,
                  circlemarker: false,
                  circle: !this.state.visible,
                  polygon: !this.state.visible,
                  marker: false,
                  polyline: false
                }}
                ref="poiDrawn"
              />
              {/* <Circle
                center={{
                  lat: this.state.lat,
                  lng: this.state.lng
                }}
                radius={this.state.radius}
              /> */}
              )
            </FeatureGroup>
          </Map>
        </div>
      </div>
    );
  }
}
export default MapDraw;
