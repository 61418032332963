import childNode from "images/react/childNode.png";
import _ from "lodash";
import React from "react";
import {
  Circle,
  GoogleMap,
  Marker,
  Polygon,
  Polyline,
  withGoogleMap
} from "react-google-maps";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
import { compose, lifecycle } from "recompose";
import OverspeedInstances from "./OverspeedInstances";
import Stoppages from "./Stoppages";
import TripHistoryPath from "./TripHistoryPath";

const TripPoi = ({ data }) => {
  const { poi_bounds, poi_type } = data;
  const isUnloading = poi_type.includes("unload");
  if (!poi_bounds) {
    return (
      <Circle
        center={{ lat: data.latitude, lng: data.longitude }}
        radius={data.radius * 1000}
        options={{
          strokeColor: isUnloading ? "#f50057" : "#ffb300",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: isUnloading ? "#f50057" : "#ffb300",
          fillOpacity: 0.35
        }}
      />
    );
  }
  const route = JSON.parse(poi_bounds).geometry.coordinates[0].map(
    ([lat, lng], i) => ({
      lat: Number(lat),
      lng: Number(lng)
    })
  );
  return (
    <Polygon
      paths={route}
      options={{
        strokeColor: isUnloading ? "#f50057" : "#ffb300",
        strokeOpacity: 0.75,
        strokeWeight: 5,
        fillColor: isUnloading ? "#f50057" : "#ffb300",
        fillOpacity: 0.35
      }}
    />
  );
};

const TripPois = ({ data }) => {
  return (
    <React.Fragment>
      {data &&
        data.map((item, index) => (
          <TripPoi data={item} key={`tripPoi_${index}`} />
        ))}
    </React.Fragment>
  );
};

const TripHistoryMap = compose(
  lifecycle({
    componentWillMount() {
      const refs = [];
      this.setState({
        bounds: null,
        onMapMounted: ref => {
          refs.map = ref;
          if (!ref) {
            return;
          }
          let bounds = new google.maps.LatLngBounds();
          this.props.polyLineHistory.forEach(coord => {
            bounds.extend(new google.maps.LatLng(coord.lat, coord.lng));
          });
          refs.map.fitBounds(bounds);
        },
        center: { lat: 22.0574, lng: 78.9382 },
        count: this.props.polyLineHistory.length,
        needsUpdate: false
      });
    }
  }),
  withGoogleMap
)(props => {
  let idealRoutes, deviatedRoute, nonDeviatedRoute, nonDeviatedIds;
  if (props.deviationData) {
    idealRoutes = props.deviationData.map(data => data.history);
    deviatedRoute = _.flatten(
      props.deviationData.map(d => d.ideal_trip_comaprison_with_curerent)
    ).filter(x => x.diverge);
    nonDeviatedRoute = _.flatten(
      props.deviationData.map(d => d.ideal_trip_comaprison_with_curerent)
    ).filter(x => !x.diverge);
    deviatedRoute = _.uniqBy(deviatedRoute, "id");
    nonDeviatedRoute = _.uniqBy(nonDeviatedRoute, "id");
    nonDeviatedIds = nonDeviatedRoute.map(route => route.id);
    deviatedRoute = deviatedRoute.filter(
      route => !nonDeviatedIds.includes(route.id)
    );
  }
  let deviatedPoints = props.deviatedPoints;
  if (props.selectedIdealPaths && props.selectedIdealPaths.length) {
    idealRoutes = idealRoutes.filter((route, index) =>
      props.selectedIdealPaths.includes(String(index))
    );
  }
  return (
    <GoogleMap
      defaultZoom={7}
      defaultCenter={{
        lat: props.history[Math.floor(props.history.length / 2)]
          ? Number(props.history[Math.floor(props.history.length / 2)].latitude)
          : 28.7041,
        lng: props.history[Math.floor(props.history.length / 2)]
          ? Number(
              props.history[Math.floor(props.history.length / 2)].longitude
            )
          : 77.1025
      }}
      ref={props.onMapMounted}
    >
      {props.tripPois && <TripPois data={props.tripPois} />}
      {props.polyLineHistory && (
        <Polyline
          path={props.polyLineHistory}
          options={{
            geodesic: true,
            strokeColor: "#7C4DFF",
            strokeOpacity: 0.9,
            strokeWeight: 5
          }}
        />
      )}
      {props.idealRoute &&
        props.idealRoute.map((route, index) => (
          <Polyline
            path={route}
            options={{
              geodesic: true,
              strokeColor: "#4CAF50",
              strokeOpacity: 0.75,
              strokeWeight: 5
            }}
            key={index}
          />
        ))}
      {/*idealRoutes && idealRoutes.length && idealRoutes.map((route, index) => {
      const path = route.map(data => {
      return {
		      lat: Number(data.latitude), 
		      lng: Number(data.longitude)
	      }})
      return   <Polyline                                                                                                                                                        
                path={path}                                                                                                                                         
                options={{                                                                                                                                                           
                        geodesic: true,                                                                                                                                              
                        strokeColor: '#4CAF50',                                                                                                                                      
                        strokeOpacity: 0.9,                                                                                                                                          
                        strokeWeight: 2                            
                }}                                                      
	    key={index}
        /> 
    })*/}
      {deviatedPoints &&
        deviatedPoints.map((item, index) => (
          <Marker position={{ lat: item.lat, lng: item.long }} key={index} />
        ))}
      {/*deviatedRoute && (<Polyline                                                                                                                                                             
                path={deviatedRoute.map(data => ({                                                                                                                                                                                                                                                                                                
                      lat: Number(data.lat),
		      lng: Number(data.long)
                }))}                                                                                                                                                         
                options={{                                                                                                                                                           
                        geodesic: true,                                                                                                                                              
                        strokeColor: '#f44336',                                                                                                                                      
                        strokeOpacity: 0.9,                                                                                                                                          
                        strokeWeight: 3                                                                                                                                              
                }}                                                                                                                                                                   
        /> 
    )*/}
      {props.history && (
        <TripHistoryPath
          history={props.history}
          selectedHistoryMarkerTimestamp={props.selectedHistoryMarkerTimestamp}
          tripInfo={props.tripInfo}
          showHistoricalPath={props.showHistoricalPath}
          startLocation={props.startLocation}
          vehicleLocation={props.vehicleLocation}
          vehicleInfo={props.vehicleInfo}
          driverInfo={props.driverInfo}
          showHistoryInfoWindow={props.showHistoryInfoWindow}
          moveVehicleMarker={props.moveVehicleMarker}
        />
      )}
      {props.stoppages && props.showStoppages && (
        <Stoppages
          data={props.stoppages}
          selectedStoppageMarkerTimestamp={
            props.selectedStoppageMarkerTimestamp
          }
        />
      )}
      {props.overspeedInstances && props.showOverspeedPath && (
        <OverspeedInstances
          data={props.overspeedInstances}
          selectedOverspeedingMarkerTimestamp={
            props.selectedOverspeedingMarkerTimestamp
          }
        />
      )}
      {props.extraMarkers &&
        props.extraMarkers.map((marker, index) => (
          <MarkerWithLabel
            position={{ lat: marker.latitude, lng: marker.longitude }}
            labelAnchor={new google.maps.Point(0, 0)}
            labelStyle={{
              backgroundColor: "yellow",
              fontSize: "12px",
              padding: "2px"
            }}
            icon={childNode}
            key={`extraMarker_${index}`}
          >
            <div>
              {marker.sequence} - {marker.name}
            </div>
          </MarkerWithLabel>
        ))}
    </GoogleMap>
  );
});

export default TripHistoryMap;
